import moment from 'moment'
import { StandardFonts } from 'pdf-lib'

import { paperworkPdfPageParams } from '../../../helpers/paperwork'

import PdfService from '../../../services/pdfService'

import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { IDocument } from '../../../fakeData/fakeDocumentList'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  registrationByDay: IRegistrationByDayInterface
  isMature: boolean
  eventDraftId: string
  isStaff?: boolean
  isSigned?: boolean
  teamMemberRole?: string[]
  index?: number
}
export const USEFEntryAgreementPDFTemplate = async (
  {
    userData,
    EventDetails,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    registrationByDay,
    isMature,
    isStaff,
    eventDraftId,
    isSigned,
    teamMemberRole,
    index,
  }: IPaperworkTemplateData,
  customDocId?: string
) => {
  const pdfService = new PdfService(paperworkPdfPageParams)
  pdfService.registerFontkit()

  const timeRomanFontBold = await pdfService.embedFont(StandardFonts.TimesRomanBold)
  const timeRomanFont = await pdfService.embedFont(StandardFonts.TimesRoman)

  const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
  const signatureFont = await pdfService.embedFont(fontByte)

  const paperworkTitle = `USEF Entry Agreement, ${isMature ? '18+' : 'Minor'}`

  /**
   * -----------------------------------------------------
   * First page
   * -----------------------------------------------------
   */
  await pdfService.writeFirstPage({
    title: paperworkTitle,
    EventDetails,
    organizerDetails,
    isStaff,
    registrationByDay,
    ridersTeamMember,
    teamMemberRole,
  })

  /**
   * -----------------------------------------------------
   * Content pages
   * -----------------------------------------------------
   */
  await pdfService.addNewPdfPage()

  await pdfService.writeLine({
    content: 'FEDERATION AND ASSOCIATION ENTRY AGREEMENT',
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFontBold,
    fontSize: 16,
  })

  await pdfService.writeLine({
    content: paperworkTitle,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFontBold,
    fontSize: 14,
  })

  await pdfService.writeLine({
    content: `${EventDetails.competitionName}`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `${EventDetails.competitionAddressLine1}`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `By entering a Federation-licensed Competition and signing this entry blank as the Owner, Lessee, Trainer, Manager, Agent, Coach, Driver, Rider, Handler, Vaulter or Longeur and on behalf of myself and my principals, representatives, employees and agents, I agree that I am subject to the Bylaws and Rules of The United States Equestrian Federation, Inc. (the “Federation”) and the local rules of ${EventDetails.competitionName} (Competition). I agree to be bound by the Bylaws and Rules of the Federation and of the competition. I will accept as final the decision of the Hearing Committee on any question arising under the Rules, and agree to release and hold harmless the competition, the Federation, their officials, directors and employees for any action taken under the Rules. I represent that I am eligible to enter and/or participate under the Rules, and every horse I am entering is eligible as entered. I also agree that as a condition of and in consideration of acceptance of entry, the Federation and/or the Competition may use or assign photographs, videos, audios, cable - casts, broadcasts, internet, film, new media or other likenesses of me and my horse taken during the course of the competition for the promotion, coverage or benefit of the competition, sport, or the Federation. Those likenesses shall not be used to advertise a product and they may not be used in such a way as to jeopardize amateur status. I hereby expressly and irrevocably waive and release any rights in connection with such use, including any claim to compensation, invasion of privacy, right of publicity, or to misappropriation. The construction and application of Federation rules are governed by the laws of the State of New York, and any action instituted against the Federation must be filed in New York State. See GR908.1.`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `If not currently a USEF Active Competing member or Subscriber, I acknowledge that I will be enrolled for no cost as a USEF Fan and my USEF Fan Account will continue to annually automatically renew in USEF’s sole discretion. Additionally, I acknowledge that the benefits of a USEF Fan are subject to change without notice. USEF may in its sole discretion, at any time, terminate my USEF Fan status.`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `BY SIGNING BELOW, I AGREE`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFontBold,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `that I have read, understand, and agree to be`,
    x: pdfService.pageHorizontalStartPoint + 180,
    y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `bound by all applicable Federation Bylaws, rules, and policies including the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at www.usef.org, as amended from time to time, as well as all terms and provisions of this Prize List. If I am signing and submitting this Agreement electronically, I acknowledge that my electronic signature shall have the same validity, force and effect as if I affixed my signature by my own hand.`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFont,
    fontSize: 12,
  })

  // Signers name
  await pdfService.writeLine({
    content: `Name:`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFontBold,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `${ridersTeamMember.teamMemberName}`,
    x: pdfService.pageHorizontalStartPoint + 36,
    y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  // Date
  await pdfService.writeLine({
    content: `Signed as of:`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFontBold,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `${moment(new Date()).format('MM-DD-YYYY')}`,
    x: pdfService.pageHorizontalStartPoint + 67,
    y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  // Date
  await pdfService.writeLine({
    content: `Signature:`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: timeRomanFontBold,
    fontSize: 12,
  })

  // Signature
  const coordinatesToSignOn = {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
  }

  await pdfService.writeLine({
    content: `${ridersTeamMember.teamMemberName}`,
    x: coordinatesToSignOn.x,
    y: coordinatesToSignOn.y,
    font: signatureFont,
    fontSize: 28,
  })

  /**
   * -----------------------------------------------------
   * Audit Trail page
   * -----------------------------------------------------
   */
  if (isSigned) {
    await pdfService.writeAuditTrailPage({ title: paperworkTitle, ridersTeamMember })
  }

  const { returnedData } = await pdfService.savePaperwork({
    title: paperworkTitle,
    ridersTeamMember,
    eventId,
    paperwork,
    registrationByDay,
    isSigned,
    userData,
    isStaff,
    EventDetails,
    coordinatesToSignOn,
    isMature,
    customDocId,
    organizerDetails,
    eventDraftId,
    index,
  })

  return returnedData
}
