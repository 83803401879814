import { createAsyncThunk } from '@reduxjs/toolkit'
import { httpService } from '../../../services/httpService'
import { MESSAGES_CONST } from '../../../const/messages-const'

const refundAmountThunk = createAsyncThunk('refundAmountThunk', async (arg: any, thunkApi) => {
  try {
    await httpService({
      url: 'refund_items_amount',
      data: arg,
      method: 'POST',
    })
  } catch (error: any) {
    thunkApi.rejectWithValue({
      message: error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
    })
  }
})

export { refundAmountThunk }
