import React from 'react'
import { IconEmpty } from '../../../../../components/icons/IconEmpty'

interface IProps {
  title: string
  isHorse?: boolean
  btnCreateTitle: string
  createHandler: () => void
}

export const NoProfileData: React.FC<IProps> = ({ title, btnCreateTitle, createHandler }) => {
  return (
    <div
      className={'w-full lg:w-[48%] flex flex-col justify-center items-center min-h-[50vh] gap-7'}
    >
      <h4 className={'text-[16px] text-[#122B46]'}>{title}</h4>
      <IconEmpty />
      <button className={`text-SeabiscuitMainThemeColor text-[16px]`} onClick={createHandler}>
        {btnCreateTitle}
      </button>
    </div>
  )
}
