import TimeLib from '../../../../lib/Time'
import { IRegistrationFeesStatsInterface } from '../../../../models/registration-fees-stats/registrationFeesStats.interface'

export const DATA_FIRESTORE_V01_REGISTRATION_FEES_STATS = {
  NAME: 'v01_registration_fees_stats',
  FIELDS: {
    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: {
        PAID: 'paid' as IRegistrationFeesStatsInterface['paymentStatus'],
        DEFAULT: 'pending' as IRegistrationFeesStatsInterface['paymentStatus'],
        PENDING: 'pending' as IRegistrationFeesStatsInterface['paymentStatus'],
      },
    },

    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    USER_DOC_ID: {
      KEY: 'userDocId',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    EVENT_DOC_ID: {
      KEY: 'eventDocId',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    USER_NAME: {
      KEY: 'userName',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_EMAIL: {
      KEY: 'userEmail',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    USER_PROFILE_PICTURE: {
      KEY: 'userProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_NAME_N_GRAM: {
      KEY: 'userNameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    FEES_ITEM_CATEGORY: {
      KEY: 'feesItemCategory',
      VALUES: {
        DEFAULT: null,
      },
    },

    BOUGHT_ITEMS_COUNT: {
      KEY: 'boughtItemsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    REGISTRATION_FEES_DOCIDS_DETAILS: {
      KEY: 'registrationFeesDocIdsDetails',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
