import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventPoliciesFormModal from './EventPoliciesFormModal'
import { EventDraggableFormElement } from './EventDraggableFormElement'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import ViewsLoader from '../../../loader/ViewsLoader'

import { EventPoliciesModel } from '../../../../models/event-policies/event-policies.model'
import { IEventPolicies } from '../../../../models/event-policies/event-policies.interface'

import MessageHelperComp from '../../../../helpers/MessageHelper'

import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'

import useGetEventData from '../../../../hooks/users/common/useGetEventData'

import { defaultPolicies } from '../../views/details/helpers/getEventPolicy'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'

const eventPoliciesFormDefaultValues = new EventPoliciesModel().toObject()

const eventPoliciesFormValidationSchema = EventPoliciesModel.validationSchema()

interface EventPoliciesFormComponentProps {
  onValid: any
  onInvalid: any
  handleModal?: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  nextEventTab?: string
  eventTab?: string
  eventId: string
}

const EventPoliciesFormComponent: React.FC<EventPoliciesFormComponentProps> = ({
  onValid,
  onInvalid,
  handleModal,
  nextEventTab,
  eventTab,
  eventId,
}) => {
  const { getAllData } = useGetEventData()
  const toastFunctions = useToasterHelper()

  const selectedEventR = useAppSelector(selectedEvent)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<IEventPolicies>({
    defaultValues: { ...eventPoliciesFormDefaultValues },
    resolver: yupResolver(eventPoliciesFormValidationSchema),
    mode: 'onChange',
  })

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'policies',
  })

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    const fieldsExtra = watch('policies')

    const parentIndex = fieldsExtra?.findIndex((field) => field.uniqId === active.id)

    if (parentIndex !== -1) {
      const oldIndex = fieldsExtra!?.map(({ uniqId }) => uniqId).indexOf(String(active.id))
      const newIndex = fieldsExtra!?.map(({ uniqId }) => uniqId).indexOf(String(over?.id))

      const newArray = arrayMove(fieldsExtra!, oldIndex, newIndex)
      replace(newArray)
    }
  }

  useEffect(() => {
    if (eventId) getAllData(eventId, ['v01_event_policies']).then()
  }, [eventId])

  useEffect(() => {
    setLoading(true)
    let eventPolicies: IEventPolicies
    if (selectedEventR?.EventPolicies) {
      eventPolicies = selectedEventR.EventPolicies
    } else {
      eventPolicies = eventPoliciesFormDefaultValues
    }

    const filterPolicies = defaultPolicies.filter(
      (itm) =>
        itm.ruleType !==
        eventPolicies.policies?.find((policy) => policy?.ruleType === itm?.ruleType)?.ruleType
    )

    const newPolicies = [...(filterPolicies ?? []), ...(eventPolicies.policies ?? [])].map(
      (policy, index) => ({
        ...policy,
        uniqId: `${index + 1}`,
      })
    )

    reset({ ...selectedEventR.EventPolicies, policies: newPolicies })
    setLoading(false)
  }, [reset, selectedEventR.EventPolicies])

  if (loading) {
    return (
      <div className="flex justify-center items-center p-10">
        <ViewsLoader
          className="flex items-center w-full justify-center"
          size="lg"
          color="#F70763"
        />
      </div>
    )
  }

  return (
    <>
      <EventPoliciesFormModal show={showModal} onHide={() => setShowModal(false)} />
      <EventFormContainerComponent>
        <div className="w-full flex-1 m-1 pb-4 pr-5 md:pr-0">
          <EventFormHeaderComponent
            title="Policies"
            description="Add policies that will display on your event page"
          >
            <EventFormFooterCommonComponent
              eventTab={eventTab}
              nextEventTab={nextEventTab}
              onNext={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventPolicies',
                      validFormHandler: onValid,
                    }),
                  onInvalid
                )(e)
              }}
              onSaveAndExit={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      publish: false,
                      tabName: 'EventPolicies',
                      validFormHandler: onValid,
                    }),
                  onInvalid
                )(e)
              }}
            />
          </EventFormHeaderComponent>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={fields.map(({ uniqId }) => uniqId)}
              strategy={verticalListSortingStrategy}
            >
              {fields.map((field, index) => {
                const isExtra = !field.ruleType

                return (
                  <div key={field.uniqId} className="relative">
                    <EventDraggableFormElement
                      index={index}
                      id={field.uniqId}
                      register={register}
                      field={field}
                      isExtra={isExtra}
                      header={watch(`policies.${index}.name`)}
                    />
                    {errors.policies?.[index]?.name?.message && (
                      <MessageHelperComp
                        isError
                        message={errors.policies?.[index]?.name?.message}
                      />
                    )}
                    {isExtra && (
                      <button
                        onClick={() =>
                          handleModal &&
                          handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_MODAL, {
                            confirmDelete: () => {
                              try {
                                remove(index)
                                handleModal(false, MODAL_CONSTS.CONFIRM_REMOVE_MODAL)
                                toastFunctions.success({
                                  message: MESSAGES_CONST.REMOVED_SUCCESSFULLY,
                                })
                              } catch (error) {
                                console.error(error, error)
                                toastFunctions.error({
                                  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
                                })
                              }
                            },
                            title: watch(`policies.${index}.name`),
                          })
                        }
                        className={'absolute left-full w-[24px] h-[24px] top-0 ml-1.5 mt-3.5'}
                      >
                        <div className="cursor-pointer hover:opacity-50 transition-all">
                          <img
                            src="/assets/og_icons/Cancel.svg"
                            className="h-[24px] w-[24px]"
                            alt="Cancel"
                          />
                        </div>
                      </button>
                    )}
                  </div>
                )
              })}
            </SortableContext>
          </DndContext>
          <div className="flex justify-between my-3">
            <button
              className="text-[14px] text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2  underline hover:no-underline"
              onClick={() => {
                handleModal?.(true, MODAL_CONSTS.ADD_POLICIES_MODAL, {
                  onSave: (title: string, description: string) => {
                    append({
                      name: title,
                      policy: description,
                      uniqId: `${fields.length + 1}`,
                    })
                  },
                })
              }}
            >
              <img src={`/assets/og_icons/Cancel.svg`} className=" rotate-45" alt="plus icon" />
              Add Policy
            </button>
          </div>
        </div>
      </EventFormContainerComponent>
    </>
  )
}

export default EventPoliciesFormComponent
