/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

// Third party
import { AutorenewRounded } from '@mui/icons-material'

// Types
import { ITeamMember, IUserInterface } from '../../../../models/users/user.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectUserId, setUserProfileComplete } from '../../../../store/user/userSlice'

// Constants
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Custom hooks
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import helpers from '../../../../commonHelpers/helpers'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { CONST } from '../../../../const/const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../helpers/helpers'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'
import { getConvertedData } from '../../../../models/interface.helper'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import { RootState } from '../../../../store/store'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import { ProfileCard } from './components/ProfileCard'
import clsx from 'clsx'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../home/modals/UserProfileTeamTabAddTeamMember'
import { IProfileItem } from './components/ProfileItem'
import { selectAllUsers } from '../../../../store/users/usersSlice'
import { formatConnectedDate } from '../../../../helpers/formatConnectedDate'
import { selectMappings } from '../../../../store/horses/horseSlice'

// Types
type Props = {
  title?: string
  description?: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}
interface IUserExtendedWithStatus extends IUserInterface {
  status: string
  connectedDate: Date | string | null
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ProfileTeamTabCompetitor = (props: Props) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const dispatch = useAppDispatch()
  const userId = useAppSelector<string | null>(selectUserId) ?? ''
  const users = useAppSelector(selectAllUsers)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const { getModelledUserDetails, updateUserDetails } = useProfileHook({ dontFetch: true })
  const [saveLoading, setSaveLoading] = useState(false)

  // const [eventTab, setEventTab] = useState(CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE)
  const teamMembersFetched = useRef(false)
  const usersHolderRef = useRef<IUserInterface[]>([])
  const [loading, setLoading] = useState(true)
  const [teamMembers, setTeamMembers] = useState<IProfileItem[]>([])
  const [authorizedMeUsers, setAuthorizedMeUsers] = useState<IUserExtendedWithStatus[]>([])
  const mappings = useAppSelector(selectMappings)

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  useEffect(() => {
    let owner: ITeamMember | null = null
    let teamMembersList_: ITeamMember[] = []

    const getTeamMemberDetails = async () => {
      let userIdsList: string[] = []
      let memberName: string | null = null
      let usersList: IUserInterface[] = []
      let currUser: IUserInterface | null = null
      let teamMembersListLocal: ITeamMember[]
      let usersSnaps: QueryDocumentSnapshot<DocumentData>[]

      const ACTIVE = '1'
      owner = (() => {
        memberName = profileDetails ? getUserFullName(profileDetails) : ''

        return {
          memberStatus: ACTIVE,
          memberName: memberName,
          memberShipActive: false,
          defaultRole: COLLECTIONS.USERS.FIELDS.USER_DEFAULT_ROLE.VALUES.OWNER,
          memberprofileSynced: false,
          memberSafeSupportTraining: false,
          memberId: profileDetails.id ?? '',
          memberEmail: profileDetails?.userEmail ?? '',
          memberAddress: profileDetails?.userAddress ?? '',
          memberCountry: profileDetails?.userNationality ?? '',
          memberPhoneNumber: profileDetails?.userPhoneNumber ?? '',
          memberProfilePicture: profileDetails?.userProfilePicture ?? '',
          memberDob: profileDetails?.userDOB ? getConvertedData(profileDetails.userDOB) : '',
        }
      })()
      teamMembersList_ = [...(profileDetails?.userTeamMembers ?? [])]

      teamMembersListLocal = [owner, ...(profileDetails?.userTeamMembers ?? [])]
      userIdsList = []

      teamMembersListLocal.forEach((currMember) => {
        if (currMember.memberId) {
          userIdsList.push(currMember.memberId)
        }
      })

      userIdsList = [...new Set(userIdsList)]
      userIdsList = userIdsList.reduce((acc: string[], curr) => {
        if (!usersHolderRef.current.find((c) => c.id === curr)) acc.push(curr)
        return acc
      }, [])

      usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)
      usersSnaps.forEach((curr) => {
        currUser = getConvertedData(UserModel.fromFirestoreDoc(curr).toObject())
        usersList.push(currUser)
        usersHolderRef.current.push(currUser)
      })

      teamMembersListLocal = teamMembersListLocal.map((currMember) => {
        currUser = usersHolderRef.current.find((c) => c.id === currMember.memberId) ?? null
        if (!currUser)
          currUser = usersList.find((currUser_) => currUser_?.id === currMember.memberId) ?? null
        if (!currUser) return currMember
        else {
          return {
            ...currMember,
            memberName: getUserFullName(currUser),
            memberEmail: currUser?.userEmail,
            memberId: currUser?.id,
            memberProfilePicture: currUser?.userProfilePicture,
            memberUserName: currUser.userName,
          }
        }
      })

      teamMembersList_ = teamMembersListLocal
    }

    getTeamMemberDetails()
      .then(() => {
        teamMembersFetched.current = true
        const listTeam = teamMembersList_.map((member) => ({
          id: member.memberId || '',
          name: member.memberName || '',
          profilePicture: member.memberProfilePicture || '',
          userDesc: `${member?.memberUserName ? `@${member.memberUserName}` : 'N/A'}`,
          role: member.memberRole || '',
        }))

        const listHorseTeamMembers: IProfileItem[] = []

        mappings.forEach((itm) => {
          Array.isArray(itm.teamMembers) &&
            itm.teamMembers.forEach((member) => {
              const user = users.find((u) => u.id === member.memberId)
              listHorseTeamMembers.push({
                id: member.memberId || '',
                name: member.memberName || '',
                profilePicture: member.memberProfilePicture || '',
                userDesc: `${user?.userName ? `@${user.userName}` : 'N/A'} • ${itm.horseName}`,
                role: member.memberRole || '',
                horseId: member.id,
              })
            })
        })

        setTeamMembers([...listTeam, ...listHorseTeamMembers])
      })
      .catch((error) => {
        helpers.logger({
          isError: true,
          message: error,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [profileDetails.userTeamMembers, mappings, users])

  useEffect(() => {
    const filteredUsers: IUserExtendedWithStatus[] = []

    if (users.length > 0) {
      users.forEach((user) => {
        user.userTeamMembers?.forEach((currentMember: ITeamMember) => {
          if (currentMember.memberId === userId) {
            filteredUsers.push({
              ...user,
              status: currentMember.memberAuthorized === '1' ? 'Authorized' : 'Unauthorized',
              connectedDate: currentMember.connectedDate ?? null,
            })
          }
        })
      })
      setAuthorizedMeUsers(filteredUsers)
    }
  }, [users])

  // Saves team members data in DB
  const saveTeamMemberToDB = async () => {
    setSaveLoading(true)
    const structuredData: IUserInterface = getModelledUserDetails(profileDetails)

    if (structuredData.userEmail) {
      structuredData.userTeamMembers = profileDetails.userTeamMembers?.filter((member) => {
        return !(member.memberRole?.toLocaleLowerCase() === 'owner' || !member.memberRole)
      })

      structuredData.userTeamMembers?.forEach((member, index) => {
        structuredData.userTeamMembers![index] = {
          ...member,
        }
      })

      const response = await updateUserDetails(structuredData)
      setSaveLoading(false)
      if (response.status) {
        toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBERS_LIST_UPDATED })
      } else {
        toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      }
    }
  }

  const handleTeamInvite = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
      type: 5,
      maxWidth: 430,
    })

    return dispatch(setUserProfileComplete(false))
  }

  const removeTeamMemberHandler = (
    id: string,
    role: string,
    horseId: string | null | undefined
  ) => {
    if (!horseId) {
      return props.handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
        type: role,
        id,
        modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
        title: 'Remove team member',
        desc: 'Are you sure you would like to remove this team member?',
        titleStyles: '!font-bold !-mt-5',
      })
    }

    return props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: async () => {
        const newTeamMembers = [...teamMembers]

        const removedIndex = newTeamMembers.findIndex((u) => u.id === id && horseId === u.horseId)

        if (removedIndex < 0) {
          console.error(`Can't find removedIndex`)
          return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        }

        const removedItm = newTeamMembers.splice(removedIndex, 1)
        try {
          await FirestoreService.deleteItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM.NAME,
            removedItm[0]?.horseId || ''
          )
          setTeamMembers(newTeamMembers)
          toastFunctions.success({
            message: MESSAGES_CONST.HORSE_TEAM_MEMBER_REMOVED,
          })
        } catch (e) {
          console.error(e)
          toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        }
      },
      alertText: (
        <div>
          <h5 className="font-bold -mt-5 text-xl text-SeabiscuitDark200ThemeColor">
            Remove horse team member
          </h5>
          <p className={'mt-5'}>This team member was add in the horses profile settings.</p>
          <p className={'mb-5 mt-2'}>
            Removing this person here, will also remove them from the horses profile.
          </p>
        </div>
      ),
    })
  }

  const removeUserWHoAddedYou = async (id: string) => {
    const newAuthorizedMeUsers = [...authorizedMeUsers]
    const removedIndex = newAuthorizedMeUsers.findIndex((user) => user.id === id)
    if (removedIndex !== -1) {
      const removedObject = newAuthorizedMeUsers.splice(removedIndex, 1)[0]

      removedObject.userTeamMembers = removedObject.userTeamMembers?.filter(
        (member) => member.memberId !== userId
      )
      try {
        await FirestoreService.updateItem(COLLECTIONS.USERS.NAME, removedObject.id, {
          userTeamMembers: removedObject.userTeamMembers,
        })
        setAuthorizedMeUsers(newAuthorizedMeUsers)
        toastFunctions.success({
          message: MESSAGES_CONST.TEAM_MEMBERS_LIST_UPDATED,
        })
      } catch (error) {
        console.error(error, 'error')
        toastFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }
    }
  }

  return (
    <>
      <FormHeader
        title="Team Settings"
        description={
          'Add team members that you will select when registering for events. Eg. Riders, Trainers, Owners, Guardians, etc.'
        }
        removeBreakLine={true}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="submit"
              disabled={saveLoading}
              onClick={() => handleTeamInvite()}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {saveLoading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Invite friends'
              )}
            </button>
            <button
              type="submit"
              disabled={saveLoading}
              onClick={saveTeamMemberToDB}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {saveLoading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        }
      />

      <div className={clsx('flex-1 flex flex-col', formStyles.className)} style={formStyles.styles}>
        <div className={'flex flex-1 flex-col lg:flex-row gap-6 mt-4 lg:mt-0'}>
          <ProfileCard
            subTitle={'Team members'}
            teamMembers={teamMembers}
            loading={loading}
            removeHandler={removeTeamMemberHandler}
            title={'People you add'}
            desc={'People you add to your team'}
            addTeamMemberHandler={() =>
              props.handleModal(true, MODAL_CONSTS.TEAM, {
                modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
              })
            }
          />
          <ProfileCard
            loading={false}
            title={'People who added you'}
            subTitle={'Connections'}
            desc={'People who added you to their team'}
            teamMembers={authorizedMeUsers.map((user) => ({
              id: user.id,
              name: getUserFullName(user),
              userDesc: `@${user.userName} • connected ${user.connectedDate ? formatConnectedDate(new Date(user.connectedDate)) : 'N/A'}`,
              role: '',
              profilePicture: user.userProfilePicture || '',
            }))}
            removeHandler={(id) => {
              props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                removeItem: () => removeUserWHoAddedYou(id),
                alertText: (
                  <div>
                    <h5 className="font-bold -mt-5 text-xl text-SeabiscuitDark200ThemeColor">
                      Remove connection
                    </h5>
                    <p className={'my-5'}>Are you sure you would like to remove this connection?</p>
                  </div>
                ),
              })
            }}
            isConnections
          />
        </div>
      </div>
    </>
  )
}

export default ProfileTeamTabCompetitor
