import { FC } from 'react'

import ModalComponent from '../global/modalComponent'
import { getEventPolicyImage } from '../helpers/getEventPolicy'

import { EventPoliciesModel } from '../../../../../models/event-policies/event-policies.model'
import { IExtraField } from '../../../../../models/event-policies/event-policies.interface'

const EventDetailViewComponentAllPoliciesModal: FC<{
  show: boolean
  handleModal?: (showHide: boolean, typeOfModal: string, dataToPassOn?: EventPoliciesModel) => void
  dataToPassOn?: { policies: IExtraField[] }
}> = ({ show, handleModal, dataToPassOn }) => {
  return (
    <ModalComponent
      show={show}
      handleModal={handleModal}
      type="full-modal"
      modalFor="policies"
      title="Policies"
      size="lg"
    >
      <div className="grid grid-2 md:grid-cols-1 gap-2">
        <div className="grid grid-cols-1 gap-2 md:gap-y-3">
          {dataToPassOn?.policies?.map((item) => {
            if (!item?.policy.replace(/\s+/g, '')) return <></>
            const icon = getEventPolicyImage(item.ruleType || '')

            return (
              <div
                key={item.name}
                className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE]  rounded-xl"
              >
                <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                  {item?.name}
                  <img src={`/assets/cp_icons/${icon}`} alt="event rules" />
                </div>
                <div className="text-SeabiscuitDark200ThemeColor text-[16px]">{item?.policy}</div>
              </div>
            )
          })}
        </div>
      </div>
    </ModalComponent>
  )
}

export default EventDetailViewComponentAllPoliciesModal
