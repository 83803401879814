import React, { useEffect } from 'react'
import clsx from 'clsx'
import { LightTooltip } from '../../tooltip/LightTooltip'

interface BrowserZoomStateProps {}

export const BrowserZoomState: React.FC<BrowserZoomStateProps> = () => {
  const [zoomNumber, setZoomNumber] = React.useState(100)

  const detectZoom = () => {
    const isSafari = () => {
      const ua = navigator.userAgent
      return /^((?!chrome|android).)*safari/i.test(ua)
    }
    let zoom = 100

    if (isSafari()) {
      zoom = Math.round((window.outerWidth / window.innerWidth) * 100)
    } else {
      zoom = Math.round((window.devicePixelRatio * 100) / 2)
    }

    setZoomNumber(zoom)
  }

  useEffect(() => {
    detectZoom()
    window.addEventListener('resize', detectZoom)
    return () => {
      window.removeEventListener('resize', detectZoom)
    }
  }, [])
  return (
    <div className={'hidden lg:flex gap-4'}>
      <span
        className={
          'text-nowrap text-SeabiscuitLightParagraphTextColor flex items-center gap-2 text-[14px]'
        }
      >
        <span>Browser zoom state</span>
        <LightTooltip
          title={
            <div
              className={'max-w-[184px] p-2 text-[13px] font-Poppins tracking-normal font-normal'}
            >
              <p>Your web browsers zoom should be set to 100% for optimal performance.</p>
              <p className={'mt-3'}>
                To learn how to change the zoom level,{' '}
                <a
                  rel={'noreferrer'}
                  target={'_blank'}
                  className={'underline hover:text-SeabiscuitMainThemeColor transition-all'}
                  href="https://intercom.help/pegasus-app/en/articles/9542934-web-browser-screen-settings"
                >
                  click here
                </a>
                .
              </p>
            </div>
          }
          placement="top"
        >
          <span
            className={clsx(
              `flex items-center justify-center w-[70px] h-[33px] rounded-[6px]`,
              zoomNumber === 100
                ? 'text-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor/20'
                : 'text-black/50 bg-black/5'
            )}
          >
            {zoomNumber}%
          </span>
        </LightTooltip>
      </span>
    </div>
  )
}
