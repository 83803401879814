import { CONST } from '../../../const/const'

import ShowsDetailPageEventDetailFormSubSection from './ShowsDetailPageEventDetailFormSubSection'
import ShowsDetailPageEventFeesFormSubSection from './ShowsDetailPageEventFeesFormSubSection'
import ShowsDetailPageEventPaperworkFormSubSection from './ShowsDetailPageEventPaperworkFormSubSection'
import ShowsDetailPageEventPoliciesFormSubSection from './ShowsDetailPageEventPoliciesFormSubSection'
import ShowsDetailPageEventReviewPublishFormSubSection from './ShowsDetailPageEventReviewPublishFormSubSection'
import ShowsDetailPageEventSchedulingFormSubSection from './ShowsDetailPageEventSchedulingFormSubSection'
import ShowsDetailPageEventScoringFormSubSection from './ShowsDetailPageEventScoringFormSubSection'
import ShowsDetailPageEventSendNotificationsFormSubSection from './ShowsDetailPageEventSendNotificationsFormSubSection'
import ShowsDetailPageEventSponsorsFormSubSection from './ShowsDetailPageEventSponsorsFormSubSection'
import ShowsDetailPageEventStaffFormSubSection from './ShowsDetailPageEventStaffFormSubSection'
import ShowsDetailPageEventTicketingFormSubSection from './ShowsDetailPageEventTicketingFormSubSection'
import ShowsDetailPageEventVendorsFormSubSection from './ShowsDetailPageEventVendorsFormSubSection'
import ShowDetailPageEventPaymentSettingsSubSection from './ShowDetailPageEventPaymentSettingsSubSection'
import { ShowDetailPageEventRequiredFields } from './ShowDetailPageEventRequiredFields'
import ShowsDetailPageEventEntriesFormSubSection from './ShowsDetailPageEventEntriesFormSubSection'
import { ShowsDetailPageEventQuestions } from './ShowsDetailPageEventQuestions'

interface ShowsDetailPageTabDisplaySectionProps {
  eventTab: string
  nextEventTab: string
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  mainEvent?: any
  loading: boolean
}

const ShowsDetailPageTabDisplaySection: React.FC<ShowsDetailPageTabDisplaySectionProps> = (
  props
) => {
  const selectTab = (event_tab: string) => {
    switch (event_tab) {
      case CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE:
        return (
          <ShowsDetailPageEventDetailFormSubSection
            loading={props.loading}
            mainEvent={props.mainEvent}
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE:
        return (
          <ShowsDetailPageEventStaffFormSubSection
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE:
        return (
          <ShowsDetailPageEventEntriesFormSubSection
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE:
        return (
          <ShowsDetailPageEventScoringFormSubSection
            // nextEventTab={props.nextEventTab}
            onSetEventTab={props.onSetEventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE:
        return (
          <ShowsDetailPageEventSchedulingFormSubSection
            handleModal={props.handleModal}
            onSetEventTab={props.onSetEventTab}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE:
        return (
          <ShowsDetailPageEventFeesFormSubSection
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE:
        return (
          <ShowsDetailPageEventTicketingFormSubSection
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE:
        return (
          <ShowsDetailPageEventPoliciesFormSubSection
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE:
        return (
          <ShowsDetailPageEventPaperworkFormSubSection
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
            onSetEventTab={props.onSetEventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE:
        return (
          <ShowsDetailPageEventSponsorsFormSubSection
            onSetEventTab={props.onSetEventTab}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE:
        return (
          <ShowsDetailPageEventVendorsFormSubSection
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            nextEventTab={props.nextEventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE:
        return (
          <ShowsDetailPageEventSendNotificationsFormSubSection
            onSetEventTab={props.onSetEventTab}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE:
        return (
          <ShowsDetailPageEventReviewPublishFormSubSection
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            mainEvent={props.mainEvent}
            nextEventTab={props.nextEventTab}
            eventTab={props.eventTab}
            onSetNextEventTab={props.onSetNextEventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE:
        return (
          <ShowDetailPageEventPaymentSettingsSubSection
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            mainEvent={props.mainEvent}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE:
        return (
          <ShowDetailPageEventRequiredFields
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            mainEvent={props.mainEvent}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )

      case CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE:
        return (
          <ShowsDetailPageEventQuestions
            onSetEventTab={props.onSetEventTab}
            dataToPassOn={props.dataToPassOn}
            handleModal={props.handleModal}
            mainEvent={props.mainEvent}
            nextEventTab={props.nextEventTab}
            onSetNextEventTab={props.onSetNextEventTab}
            eventTab={props.eventTab}
          />
        )
    }
  }
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <>{selectTab(props.eventTab)}</>
}

export default ShowsDetailPageTabDisplaySection
