import React from 'react'

export const IconRegularDocument: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" fill="none" viewBox="0 0 65 65">
      <path
        fill="#D3DAEE"
        d="M16.928 5.417c-3.342 0-6.094 2.752-6.094 6.094V53.49c0 3.341 2.752 6.094 6.094 6.094h31.146c3.341 0 6.093-2.753 6.093-6.094V11.51c0-3.34-2.752-6.093-6.093-6.093zm0 4.062h31.146a2 2 0 0 1 2.03 2.032V53.49a2 2 0 0 1-2.03 2.031H16.928a2 2 0 0 1-2.032-2.031V11.51a2 2 0 0 1 2.032-2.03m6.77 6.771a2.031 2.031 0 1 0 0 4.063h6.771a2.031 2.031 0 1 0 0-4.063zm0 12.188a2.031 2.031 0 1 0 0 4.062h17.605a2.031 2.031 0 1 0 0-4.062zm0 8.125a2.031 2.031 0 1 0 0 4.062h17.605a2.031 2.031 0 1 0 0-4.062zm0 8.125a2.031 2.031 0 1 0 0 4.062h12.188a2.03 2.03 0 1 0 0-4.062z"
      ></path>
    </svg>
  )
}
