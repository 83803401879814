import { createSlice, current } from '@reduxjs/toolkit'
import { IReduxStatus, RootState } from '../store'
import { IHorseData } from '../../models/horse/horse.interface'
import { IHorseCompetitorDocument } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseLineage } from '../../models/horse-lineage/horse-lineage.interface'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'
import { IHorseTeamInterface } from '../../models/horse-team/horseTeam.interface'
import { UserHorseMappingModel } from '../../models/user-horse-mapping/userHorseMapping.model'
import * as horseSliceThunks from './thunks'

export interface IInitialSystemState {
  loading: boolean
  data: IHorseData[]
  status: IReduxStatus
  message: string | null
  mappings: IUserHorseMappingInterface[]
  /** @info Indicates that whether we have fetched the horses or not from db */
  loadedFromDb: boolean
  isAddHorsePage: boolean | null
  selected: {
    horse: {
      status: IReduxStatus
      message: string | null
      data: IHorseData | null
      isMyHorse: boolean | null
      indexInRedux: number | null
    }
    mapping: {
      status: IReduxStatus
      message: string | null
      data: IUserHorseMappingInterface | null
    }
    team: {
      status: IReduxStatus
      message: string | null
      dataInDb: IHorseTeamInterface[]
      data: IHorseTeamInterface[]
    }
    lineage: {
      status: IReduxStatus
      message: string | null
      data: IHorseLineage | null
    }
    paperwork: {
      data: IHorseCompetitorDocument | null
      status: IReduxStatus
      message: string | null
    }
  }
  selectedForCompetetion: IHorseData[]
}

const initialSystemState: IInitialSystemState = {
  loading: true,
  message: null,
  status: 'idle',
  loadedFromDb: false,
  isAddHorsePage: null,
  data: [],
  mappings: [],
  selected: {
    horse: {
      data: null,
      status: 'idle',
      message: null,
      isMyHorse: null,
      indexInRedux: null,
    },
    mapping: {
      data: null,
      status: 'idle',
      message: null,
    },
    paperwork: {
      data: null,
      status: 'idle',
      message: null,
    },
    team: {
      data: [],
      dataInDb: [],
      message: null,
      status: 'idle',
    },
    lineage: {
      data: null,
      message: null,
      status: 'idle',
    },
  },
  selectedForCompetetion: [],
}

const horseSlice = createSlice({
  name: 'horses',
  initialState: initialSystemState,
  reducers: {
    setHorses(state, { payload }) {
      state.data = payload
    },
    setMappings(state, { payload }: { payload: IUserHorseMappingInterface[] }) {
      if (payload && Array.isArray(payload)) {
        state.mappings = payload
      } else {
        state.mappings = [...state.mappings, payload]
      }
    },
    removeHorse(state, { payload }: { payload: { horseId: string } }) {
      state.data = current(state).data.filter((currHorse) => currHorse.id !== payload.horseId)
    },
    updateHorse(
      state,
      { payload }: { payload: { data: IHorseData | IHorseData[]; index?: number } }
    ) {
      let horseIndexInRedux = -1
      let mappingIndexInRedux = -1
      let mappingsInRedux = current(state).mappings
      let horsesInRedux = current(state).data

      // Runs when index in not provided
      if (horseIndexInRedux !== -1 && !Array.isArray(payload.data))
        state.data[horseIndexInRedux] = payload.data
      // Runs when index is provided
      else if (Array.isArray(payload.data)) state.data = payload.data
      else if (horseIndexInRedux === -1 && payload.data && !Array.isArray(payload.data)) {
        if (!(payload.data as any).id) return

        horseIndexInRedux = horsesInRedux.findIndex(
          (currHorse) => currHorse.id === (payload.data as any).id
        )
        state.selected.horse.data = payload.data

        mappingIndexInRedux = mappingsInRedux.findIndex(
          (currMapping) => currMapping.horseId === (payload.data as any).id
        )

        if (horseIndexInRedux !== -1) state.data[horseIndexInRedux] = payload.data

        if (mappingIndexInRedux !== -1)
          state.mappings[mappingIndexInRedux] = {
            ...new UserHorseMappingModel({
              ...mappingsInRedux[mappingIndexInRedux],
              horseZone: payload.data.horseZone,
              horseName: payload.data.horseName,
              horseDiscipline: payload.data.horseName,
              horseNameNGram: payload.data.horseNameNGram,
              horseProfilePicture: payload.data.horseProfilePicture,
            }).toObject(),
            teamMembers: mappingsInRedux[mappingIndexInRedux]?.teamMembers ?? [],
          }
      }
    },
    setSelectedHorseCompetetionPaperwork(
      state,
      { payload }: { payload: { competitionPaperwork?: IHorseCompetitorDocument; reset?: boolean } }
    ) {
      if (payload.reset) {
        state.selected.paperwork.data = initialSystemState.selected.paperwork.data
      } else if (payload.competitionPaperwork) {
        state.selected.paperwork.data = payload.competitionPaperwork
      }
    },
    setSelectedHorseMapping(state, { payload }: { payload: IUserHorseMappingInterface }) {
      state.selected.mapping.data = payload
    },
    setSelectedHorseTeamInDb(state, { payload }: { payload: IHorseTeamInterface[] }) {
      let mappingId = state.selected.mapping.data?.id
      let mappingIndex = -1

      state.selected.team.dataInDb = payload

      if (!mappingId) return

      let mappings_ = current(state).mappings
      mappingIndex = mappings_.findIndex((currMapping) => currMapping.id === mappingId)
      state.mappings[mappingIndex].teamMembers = payload
    },
    setSelectedHorseTeam(state, { payload }: { payload: IHorseTeamInterface[] }) {
      if (state.selected.horse.data) {
        state.selected.horse.data.horseTeamMembers_ = payload
        state.selected.team.data = payload
      }
    },
    setSelectedHorse(
      state,
      { payload }: { payload: IInitialSystemState['selected']['horse']['data'] }
    ) {
      state.selected.horse.data = payload
    },
    setHorseLoaded(state, { payload }: { payload: boolean }) {
      state.loadedFromDb = payload
    },
    setSelectedHorseLineage(
      state,
      { payload }: { payload: { data?: IHorseLineage; reset?: boolean } }
    ) {
      if (payload.reset) {
        state.selected.lineage.data = initialSystemState.selected.lineage.data
      } else if (payload.data) {
        state.selected.lineage.data = payload.data
      }
    },
    updateHorseProfileImage(state, { payload }) {
      if (!state.data[payload.index]) return
      state.data[payload.index].horseProfilePicture = payload.image
    },
    updateKeysInMappings(
      state,
      {
        payload,
      }: {
        payload: {
          keysToUpdate: {
            [K in keyof IHorseTeamInterface]?: IHorseTeamInterface[K]
          }
          mappingDocId: string
        }
      }
    ) {
      state.mappings = state.mappings.map((currMapping) => {
        return {
          ...currMapping,
          ...(currMapping.id === payload.mappingDocId && payload.keysToUpdate),
        }
      })
    },
    setIsMyHorse(state, { payload }: { payload: boolean }) {
      state.selected.horse.isMyHorse = payload
      if (state.selected.horse.data) {
        state.selected.horse.data.isMyHorse = payload
      }
    },
    setIsAddHorsePage(state, { payload }: { payload: IInitialSystemState['isAddHorsePage'] }) {
      state.isAddHorsePage = payload
      state.selected.horse.status = 'idle'
    },
    setSelectedHorseIndex(
      state,
      { payload }: { payload: IInitialSystemState['selected']['horse']['indexInRedux'] }
    ) {
      state.selected.horse.indexInRedux = payload
    },
    setSelectedHorseStatus(
      state,
      { payload }: { payload: IInitialSystemState['selected']['horse']['status'] }
    ) {
      state.selected.horse.status = payload
    },
    resetSelectedHorseDetails(state) {
      state.selected = initialSystemState.selected
    },
    setTeamMemberInMappings(
      state,
      { payload }: { payload: { mappingDocId: string; teamMembers: IHorseTeamInterface[] } }
    ) {
      state.mappings = state.mappings.map((currMapping) => {
        return {
          ...currMapping,
          teamMembers:
            currMapping.id === payload.mappingDocId ? payload.teamMembers : currMapping.teamMembers,
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(horseSliceThunks.getHorseViaHorseDocIdThunk.pending, (state) => {
      state.selected.horse.message = null
      state.selected.horse.status = 'loading'
    })
    builder.addCase(horseSliceThunks.getHorseViaHorseDocIdThunk.fulfilled, (state, action) => {
      state.selected.horse.status = 'fulfilled'
      state.selected.horse.data = action.payload.horse
      state.selected.horse.message = null
      state.selected.horse.isMyHorse = action.payload.isMyHorse
    })
    builder.addCase(horseSliceThunks.getHorseViaHorseDocIdThunk.rejected, (state, action) => {
      state.selected.horse = {
        ...initialSystemState.selected.horse,
        status: 'failed',
        message: action.error.message ?? null,
      }
    })

    builder.addCase(horseSliceThunks.getHorseViaMappingDocIdThunk.pending, (state) => {
      state.selected.mapping.message = null
      state.selected.horse.message = null
      state.selected.horse.status = 'loading'
      state.selected.mapping.status = 'loading'
    })
    builder.addCase(horseSliceThunks.getHorseViaMappingDocIdThunk.fulfilled, (state, action) => {
      state.selected.mapping.status = 'fulfilled'
      state.selected.horse.status = 'fulfilled'
      state.selected.horse.data = action.payload.horse
      state.selected.mapping.data = action.payload.mapping
      state.selected.horse.message = null
      state.selected.mapping.message = null
      state.selected.horse.isMyHorse = action.payload.isMyHorse
    })
    builder.addCase(horseSliceThunks.getHorseViaMappingDocIdThunk.rejected, (state, action) => {
      state.selected.mapping = {
        ...initialSystemState.selected.mapping,
        status: 'failed',
        message: action.error.message ?? null,
      }
      state.selected.horse = {
        ...initialSystemState.selected.horse,
        status: 'failed',
        message: action.error.message ?? null,
      }
    })

    builder.addCase(horseSliceThunks.getHorseTeamMembersThunk.pending, (state) => {
      state.selected.team.message = null
      state.selected.team.status = 'loading'
    })
    builder.addCase(horseSliceThunks.getHorseTeamMembersThunk.fulfilled, (state, action) => {
      state.selected.team.message = null
      state.selected.team.status = 'fulfilled'
      state.selected.team.data = action.payload.team
      state.selected.team.dataInDb = action.payload.team
    })
    builder.addCase(horseSliceThunks.getHorseTeamMembersThunk.rejected, (state, action) => {
      state.selected.team = {
        ...initialSystemState.selected.team,
        status: 'failed',
        message: action.error.message ?? null,
      }
    })

    builder.addCase(horseSliceThunks.getHorsePaperworkThunk.pending, (state) => {
      state.selected.paperwork.message = null
      state.selected.paperwork.status = 'loading'
    })
    builder.addCase(horseSliceThunks.getHorsePaperworkThunk.fulfilled, (state, action) => {
      state.selected.paperwork.message = null
      state.selected.paperwork.status = 'fulfilled'
      state.selected.paperwork.data = action.payload.paperwork
    })
    builder.addCase(horseSliceThunks.getHorsePaperworkThunk.rejected, (state, action) => {
      state.selected.paperwork = {
        ...initialSystemState.selected.paperwork,
        status: 'failed',
        message: action.error.message ?? null,
      }
    })

    builder.addCase(horseSliceThunks.getHorseLineageThunk.pending, (state) => {
      state.selected.lineage.message = null
      state.selected.lineage.status = 'loading'
    })
    builder.addCase(horseSliceThunks.getHorseLineageThunk.fulfilled, (state, action) => {
      state.selected.lineage.message = null
      state.selected.lineage.status = 'fulfilled'
      state.selected.lineage.data = action.payload.lineage
    })
    builder.addCase(horseSliceThunks.getHorseLineageThunk.rejected, (state, action) => {
      state.selected.lineage = {
        ...initialSystemState.selected.lineage,
        status: 'failed',
        message: action.error.message ?? null,
      }
    })
  },
})

export const {
  setHorses,
  updateHorse,
  removeHorse,
  setMappings,
  setSelectedHorse,
  setSelectedHorseStatus,
  setIsMyHorse,
  setIsAddHorsePage,
  setSelectedHorseIndex,
  setTeamMemberInMappings,
  setSelectedHorseMapping,
  updateHorseProfileImage,
  updateKeysInMappings,
  setHorseLoaded,
  resetSelectedHorseDetails,
  setSelectedHorseTeamInDb,
  setSelectedHorseTeam,
  setSelectedHorseLineage,
  setSelectedHorseCompetetionPaperwork,
} = horseSlice.actions

export { horseSliceThunks }

export const selectHorses = (state: RootState) => state.horses.data
export const selectMappings = (state: RootState) => state.horses.mappings
export const getSelectedHorse = (state: RootState) => state.horses?.selected.horse.data
export const getSelectedHorseMapping = (state: RootState) => state.horses.selected.mapping
export const getSelectedHorseLineage = (state: RootState) => state.horses?.selected.lineage.data
export const getSelectedHorseCompetitonPaperwork = (state: RootState) =>
  state.horses.selected.paperwork.data
export const selectHorseReducer = (state: RootState) => state.horses

export default horseSlice.reducer
