// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// Third party
import { IonItem } from '@ionic/react'
import { motion } from 'framer-motion'

import InfiniteScroll from 'react-infinite-scroll-component'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import CardsLoader from '../../../loader/CardsLoader'
import EventOrganizationHorizontalCardComponent from '../../cards/organization-horizontal-card/EventOrganizationHorizontalCardComponent'
import { useEffect, useState } from 'react'
import FirestoreService from '../../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../../../models/interface.helper'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { CONST } from '../../../../const/const'
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
interface ShowsHorizontalCardStackComponentProps {
  events: IEventInterface[]
  onEventClicked: any
  hasMore?: boolean
  loading?: boolean
  fetchMoreLoading?: boolean
  fetchMore?: (cursorId: string) => void
}

const EventOrganizationHorizontalCardStackComponent: React.FC<
  ShowsHorizontalCardStackComponentProps
> = (props) => {
  const fetchMore = () => {
    props.fetchMore?.(props?.events?.at(-1)?.id ?? (null as any))
  }

  const [registeredUsers, setRegisteredUsers] = useState<TEventRegisteredUsers[]>([])
  const [users, setUsers] = useState<IUserInterface[]>([])

  const getRegisteredUsers = async () => {
    let registeredUsers: TEventRegisteredUsers[] = []
    let users: IUserInterface[] = []

    const eventIds = props?.events.map((event) => event.id)

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
        ]),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      const eventRegisteredUsers = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
      if (eventIds.includes(eventRegisteredUsers?.eventId ?? '')) {
        registeredUsers.push(
          getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
        )
      }
    })

    const userIds: string[] = registeredUsers.map((user) => user?.userId ?? '')
    const userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIds)

    userSnaps.forEach((currDoc) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    setRegisteredUsers(registeredUsers)
    setUsers(users)
  }
  useEffect(() => {
    getRegisteredUsers()
  }, [])

  return (
    <IonItem lines="none" color="#F6F7FB" className="mx-1">
      <div id="mainscrollDiv" className="transition-all overflow-auto no-scrollbar flex-1">
        <InfiniteScroll
          dataLength={props.events.length}
          next={fetchMore}
          hasMore={props?.hasMore ?? false}
          loader={<CardsLoader />}
          scrollableTarget="mainscrollDiv"
        >
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {props.events.map((item) => {
              const registeredCountIncludingUnpaid = registeredUsers.filter(
                (user) => user.eventId === item?.id
              )

              let currentUsers: IUserInterface[] = []
              let currentUser: IUserInterface

              registeredCountIncludingUnpaid.forEach((registeredUser) => {
                users.forEach((user) => {
                  if (registeredUser.userId === user.id) {
                    currentUser = user
                  }
                })
                if (currentUser && currentUser.id) currentUsers.push(currentUser)
              })

              const registeredUsersImages = currentUsers.map(
                (item) => item.userProfilePicture ?? ''
              )

              return (
                <motion.div
                  key={item.id}
                  className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.1,
                  }}
                >
                  <div
                    key={item.id}
                    onClick={(_) => props.onEventClicked(item.id)}
                    className="inline-block rounded-lg shrink-0 pr-3 mb-6 w-full"
                  >
                    <EventOrganizationHorizontalCardComponent
                      subType={''}
                      id={item?.id}
                      locationHash={''}
                      tags={item?.tags}
                      city={item?.city}
                      state={item?.state}
                      status={item?.status}
                      requiredFields={item.requiredFields || []}
                      requiredHorseFields={item.requiredHorseFields || []}
                      country={item?.country}
                      category={item?.category}
                      eventName={item?.eventName}
                      goToViewPage={() => {}}
                      eventImages={item?.eventImages}
                      eventEndDate={item?.eventEndDate}
                      eventPriceUSD={item?.eventPriceUSD}
                      eventStartDate={item?.eventStartDate}
                      competitionCourseMapImages={item?.eventImages}
                      eventStartDateRange={item?.eventStartDateRange}
                      currentTicketRevenue={item.currentTicketRevenue}
                      registeredUsersImages={registeredUsersImages}
                      eventCardCoverImageUrl={item?.eventCardCoverImageUrl}
                      totalRegistrationRevenue={item.totalRegistrationRevenue}
                      registeredCountIncludingUnpaid={registeredCountIncludingUnpaid.length}
                    />
                  </div>
                </motion.div>
              )
            })}
          </div>
        </InfiniteScroll>
        {props.fetchMoreLoading ? <CardsLoader /> : null}
      </div>
    </IonItem>
  )
}

EventOrganizationHorizontalCardStackComponent.defaultProps = {
  events: [
    {
      ...EventModel.fromObject({}),
    },
  ],
}

export default EventOrganizationHorizontalCardStackComponent
