import React from 'react'
import { ITeamMember } from '../../models/users/user.interface'
import MainModal from './common/MainModal'
import Select from 'react-select'
import { customStyles } from '../customStyles/ReactSelectCustomStyle'
import { CONST } from '../../const/const'
import customImageComponent from '../common/CustomImageComponent'
import { MODAL_CONSTS } from '../../const/modal-const'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import FirestoreService from '../../services/firestoreService'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import helpers from '../../commonHelpers/helpers'
import { CustomError } from '../../helpers/helpers'
import { getConvertedData } from '../../models/interface.helper'
import { where } from 'firebase/firestore'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IDocumentList } from '../../fakeData/fakeDocumentList'
import { getFilteredPaperworks } from '../../helpers/documents'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { IPaperworkTeam, setRidersTeamMembersUpdates } from '../../store/paperworks/paperworkSlice'
import { AddTeamMemberToPaperworkHeader } from './components/AddTeamMemberToPaperworkHeader'
import useToasterHelper from '../../helpers/ToasterHelper'
import { riderActions } from '../../store/rider/riderSlice'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const FILE_NAME = 'addTeamMemberToPaperwork'

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}
type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    users: ITeamMember[]
    teamMembers: IPaperworkTeam[]
    registrationByDay: IRegistrationByDayInterface
    rider: IPaperworkTeam
  }
}
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

interface ISelectedTeamMember extends ITeamMember {
  role: {
    label: string
    value: string
  } | null
}
export const AddTeamMemberToPaperworkStep2: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch()

  const ROLES = Object.values(TEAM_MEMBER_ROLES)
    .filter((role) => role !== TEAM_MEMBER_ROLES.RIDER)
    .map((value) => ({
      value,
      label: value,
    }))
  const [loading, setLoading] = React.useState(false)
  const [selectedUsers, setSelectedUsers] = React.useState<ISelectedTeamMember[]>([])

  const exibitorR = useAppSelector((store) => store.exhibitor.registration)

  const toastFunction = useToasterHelper()
  const rider = props.dataToPassOn.rider

  React.useEffect(() => {
    if (props.dataToPassOn.users) {
      const updatedUsers: ISelectedTeamMember[] = props.dataToPassOn.users.map((m) => ({
        ...m,
        role: null,
      }))
      setSelectedUsers(updatedUsers)
    }
  }, [props.dataToPassOn])

  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  const savePaperworkTabData = async ({
    filteredPaperworkDocuments,
    ridersTeamMembersToAdd,
  }: {
    ridersTeamMembersToAdd: IRiderTeamMemberInterface[]
    filteredPaperworkDocuments: IDocumentList
  }) => {
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'savePaperworkTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      const userDocumentsToAdd: IUserDocument[] = []

      for (const document of filteredPaperworkDocuments) {
        if (ridersTeamMembersToAdd) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            const eventsSnapshot = await FirestoreService.filterItems(
              COLLECTIONS.USERS_DOCUMENTS.NAME,
              [
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
                  '==',
                  ridersTeamMember.teamMemberId
                ),
                where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', exibitorR!.eventId),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY,
                  '==',
                  ridersTeamMember.riderId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY,
                  '==',
                  document.document_name
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
                  '==',
                  ridersTeamMember.teamMemberRole
                ),
              ]
            )

            if (eventsSnapshot.size <= 0) {
              userDocumentsToAdd.push(
                getConvertedData({
                  eventId: exibitorR!.eventId!,
                  status: 'Not Signed',
                  activityUser: exibitorR?.userName ?? '',
                  competitorId: exibitorR?.id ?? '',
                  eventLogo: exibitorR!.eventLogo!,
                  eventName: exibitorR!.eventName!,
                  documentName: document.key,
                  documentUrl: document.document_image[0],
                  documentOriginalName: document.document_name,
                  riderId: ridersTeamMember.riderId,
                  riderName: ridersTeamMember.riderName,
                  documentOwner: ridersTeamMember.teamMemberId,
                  documentNameAsPerPdf: '',
                  signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
                  signatoryDefaultRole: ridersTeamMember.teamMemberRole,
                  signatoryEmail: ridersTeamMember.teamMemberEmail,
                  signatoryName: ridersTeamMember.teamMemberName,
                  signatoryId: ridersTeamMember.teamMemberId,
                  reminder: false,
                  registrationDocId: rider.registrationDocId,
                  registrationByDayDocId: ridersTeamMember.registrationByDayDocId,
                  registrationByDayUuid: ridersTeamMember.registrationByDayUuid || '',
                  registrationByDayUniqId: ridersTeamMember.registrationByDayUniqId || '',
                  riderTeamMemberDocId: ridersTeamMember.id ?? null,
                  pageNumberToSignOn: null,
                  coordinatesToSignOn: { x: null, y: null },
                })
              )
            }
          }
        }
      }
      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
          },
        })
      }

      return true
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const saveHandler = async () => {
    if (!props.dataToPassOn || !props.dataToPassOn.teamMembers) return null
    if (!exibitorR) return null
    const doesNotHaveRole = selectedUsers.some((u) => !u.role)

    if (doesNotHaveRole)
      return toastFunction.error({ message: 'All selected team members should have a role' })
    setLoading(true)

    try {
      for (let user of selectedUsers) {
        const teamMember: IRiderTeamMemberInterface = new RiderTeamMemberModel({
          mailLog: [],
          userId: exibitorR.userId,
          eventId: exibitorR.eventId ?? '',
          eventName: rider.eventName,
          riderDob: rider.riderDob,
          riderId: rider.riderId,
          riderName: rider.riderName,
          registrationDocId: rider.registrationDocId,
          registrationByDayDocId: rider.registrationByDayDocId,
          registrationByDayUuid: rider.registrationByDayUuid,
          registrationByDayUniqId: rider.registrationByDayUniqId,
          teamMemberId: user.memberId,
          teamMemberDob: user.memberDob,
          teamMemberName: user.memberName,
          teamMemberRole: user.role?.value,
          teamMemberEmail: user.memberEmail,
          teamMemberProfilePicture: user.memberProfilePicture,
        }).toObject()

        let universalOrganizerWaver = false
        let USEFWaiverAndReleaseOfLiability = false
        let USEFEntryAgreement = false
        let USDFWaiverAndReleaseOfLiability = false
        let USEAWaiverAndReleaseOfLiability = false
        let USHJAWaiverAndReleaseOfLiability = false

        if (rider.documents && Array.isArray(rider.documents)) {
          universalOrganizerWaver = !!rider.documents.find(
            (r) => r.documentName === 'universalOrganizerWaver'
          )
          USEFEntryAgreement = !!rider.documents.find(
            (r) => r.documentName === 'USEFEntryAgreement'
          )
          USHJAWaiverAndReleaseOfLiability = !!rider.documents.find(
            (r) => r.documentName === 'USHJAWaiverAndReleaseOfLiability'
          )
          USDFWaiverAndReleaseOfLiability = !!rider.documents.find(
            (r) => r.documentName === 'USDFWaiverAndReleaseOfLiability'
          )
          USEAWaiverAndReleaseOfLiability = !!rider.documents.find(
            (r) => r.documentName === 'USEAWaiverAndReleaseOfLiability'
          )
          USEFWaiverAndReleaseOfLiability = !!rider.documents.find(
            (r) => r.documentName === 'USEFWaiverAndReleaseOfLiability'
          )
        }

        await savePaperworkTabData({
          filteredPaperworkDocuments: getFilteredPaperworks({
            universalOrganizerWaver,
            USEFWaiverAndReleaseOfLiability,
            USEFEntryAgreement,
            USDFWaiverAndReleaseOfLiability,
            USEAWaiverAndReleaseOfLiability,
            USHJAWaiverAndReleaseOfLiability,
          }),
          ridersTeamMembersToAdd: [teamMember],
        })
      }

      dispatch(setRidersTeamMembersUpdates())
      dispatch(riderActions.setIsAppPaperworkSign(false))
      props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2)
    } catch (e) {
      console.log('=>(AddTeamMemberToPaperworkStep2.tsx:260) e', e)
      toastFunction.error({ message: 'Something went wrong please try again later!' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      title="Add team member to entry"
      show={true}
      type={loading ? 'ADD_TEAM_MEMBER_TO_PAPERWORK' : 'ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2'}
      size="md"
      titleClassName="!font-normal"
      buttons={[
        {
          label: loading ? 'Loading ....' : 'Assign & Send Paperwork',
          bgClass: 'bg-SeabiscuitMainThemeColor !w-full',
          textClass: 'text-white',
          onClick: loading ? () => null : saveHandler,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor !w-full',
          className: 'outline-none',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          onClick: () =>
            loading
              ? null
              : props.handleModal(false, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2),
        },
      ]}
    >
      <>
        <AddTeamMemberToPaperworkHeader
          rider={props.dataToPassOn.rider}
          horseName={props.dataToPassOn.registrationByDay?.horseName || ''}
          horseProfilePicture={props.dataToPassOn.registrationByDay?.horseProfilePicture || ''}
        />
        <h2>Assign roles</h2>
        <p>Assign roles to new team members to send paperwork to sign</p>
        <ul className={'flex flex-col gap-5'}>
          {selectedUsers.map((user, index) => (
            <li key={user.id + '' + index} className={'flex gap-3 items-center'}>
              <div className={'flex flex-1 gap-2 items-center'}>
                {customImageComponent(
                  user.memberProfilePicture,
                  user.memberName,
                  'w-[45px] h-[45px] avatarImg rounded object-cover'
                )}
                <div>
                  <h4 className={'text-[#122B46] font-normal text-[16px]'}>{user.memberName}</h4>
                  <p className={'text-[#122B4680] text-[14px]'}>{user.memberEmail}</p>
                </div>
              </div>
              <div>
                <Select
                  isMulti={false}
                  onChange={(select) => {
                    const newUsers = [...selectedUsers]
                    if (newUsers[index]) newUsers[index].role = select as (typeof ROLES)[0]
                    setSelectedUsers(newUsers)
                  }}
                  value={user.role}
                  // onBlur={onBlur}
                  placeholder={<div className="flex text-[13px]">Role</div>}
                  options={ROLES}
                  className={`border rounded-md w-40 !border-[#D3DAEE]`}
                  isClearable={false}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </li>
          ))}
        </ul>
      </>
    </MainModal>
  )
}
