import AllEventPageWrapper from '../layouts/AllEventPageWrapper'

import './allEventRootPage.css'

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  settingsOnly?: boolean
  isOthersProfile?: boolean
}

const AllEventRootPage = (props: Props) => {
  // Hooks and vars
  const activeTab = (() => {
    return 1
  })()

  return (
    <AllEventPageWrapper
      title="profile"
      activeTab={activeTab}
      handleModal={props.handleModal}
      isOthersProfile={props?.isOthersProfile}
    />
  )
}

export default AllEventRootPage
