import React from 'react'
import { IconRegularDocument } from '../../../../../components/icons/IconRegularDocument'

interface IProps {
  message?: string
}

export const NoDocumentsToSign: React.FC<IProps> = ({ message }) => {
  return (
    <div
      className={
        'p-4 flex flex-col justify-center items-center flex-1 gap-1 bg-[#F6F7FB80] rounded-[8px]'
      }
    >
      <div className={'flex items-center justify-center rounded-full w-[130px] h-[130px] bg-white'}>
        <IconRegularDocument />
      </div>

      <h3 className={'text-SeabiscuitDark200ThemeColor/50 text-[14px] font-bold mt-3'}>
        No Documents to Sign
      </h3>
      {message && (
        <div className="max-w-xl text-SeabiscuitDark200ThemeColor/50  text-[14px] text-center">
          {message}
        </div>
      )}
    </div>
  )
}
