import { useContext } from 'react'

import EventReviewPublishInput from './EventReviewPublishInput'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'

import { IExtraField } from '../../../../../models/event-policies/event-policies.interface'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'

const DetailRow = ({
  title,
  note,
  onClick,
}: {
  title: string
  note: string
  onClick: (text: string) => void
}) => {
  return (
    <div className="flex w-full max-w-full">
      <EventReviewPublishInput
        className="rounded-lg flex items-center text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 whitespace-nowrap overflow-hidden text-ellipsis h-12 shrink-0"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 grow overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
        onClick={() => onClick(note)}
        data={note}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 shrink-0 text-center flex items-center  justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type EventPoliciesReviewPublishFormProps = {
  policies?: IExtraField[]
  loading?: boolean
}

const EventPoliciesReviewPublishForm = ({
  policies,
  loading,
}: EventPoliciesReviewPublishFormProps) => {
  const handleModalContext = useContext(HandleModalContext)

  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  if (loading) {
    return null
  }

  return policies && policies?.length > 0 ? (
    <>
      {policies.map((policy, index) => {
        if (!policy?.name || !policy.policy) return null
        return (
          <DetailRow
            key={index}
            title={policy.name}
            note={policy.policy}
            onClick={openAddNotesModal}
          />
        )
      })}
    </>
  ) : (
    <DataNotAvailable
      mode="text"
      containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
      text={MESSAGES_CONST.POLICIES_DATA_NOT_AVAILABLE}
    />
  )
}

export default EventPoliciesReviewPublishForm
