export function formatConnectedDate(connectedData: Date): string {
  const date = new Date(connectedData)
  const now = new Date()

  const isToday = date.toDateString() === now.toDateString()

  const yesterday = new Date()
  yesterday.setDate(now.getDate() - 1)
  const isYesterday = date.toDateString() === yesterday.toDateString()

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }
  const time = date.toLocaleTimeString('en-US', timeOptions)

  if (isToday) {
    return ` today, ${time}`
  }
  if (isYesterday) {
    return ` yesterday`
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }
  const formattedDate = date.toLocaleDateString('en-US', dateOptions)
  return ` ${formattedDate}`
}
