// Third party
import { cloneDeep } from 'lodash'

// Redux
import {
  selectRegisterEventData,
  selectRegisterEventPaperworksData,
  setRegisterEventData,
  setRegisterPaperworkData,
} from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  getSelectedHorse,
  getSelectedHorseLineage,
  selectHorseReducer,
  setSelectedHorse,
  setSelectedHorseLineage,
  setSelectedHorseTeam,
  setTeamMemberInMappings,
  updateHorse,
} from '../../../store/horses/horseSlice'
import {
  selectHorseTeamMembers,
  selectProfileData,
  setHorseTeamMembers,
  setProfileDetails,
} from '../../../store/user/userSlice'

// Models
import { HorseModel } from '../../../models/horse/horse.model'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Services
import { AutorenewRounded, Clear } from '@mui/icons-material'

// Types
import { MODAL_CONSTS } from '../../../const/modal-const'
import useHorses from '../../../hooks/users/competitor/profile/useHorses'
import { IPaperworkTab, ISignatory } from '../../../models/event-drafts/event-draft.interface'
import { THorseTeamMembers } from '../../../models/horse-lineage/horse-lineage.interface'
import { ITeamMember } from '../../../models/users/user.interface'
import { CONST } from '../../../const/const'
import FirestoreService from '../../../services/firestoreService'
import { useState } from 'react'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import clsx from 'clsx'

type Props = { show: boolean; handleModal: any; dataToPassOn: any }
type IModalType = {
  HORSE: boolean
  TEAM: boolean
  CLINIC_TABS: boolean
  RIDER_TEAM_MEMBER: boolean
  HORSE_LINEAGE: boolean
}
const HORSES_TEAM_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM

const RemoveTeamModal = (props: Props) => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const horseReducer = useAppSelector(selectHorseReducer)
  const registerFormData = useAppSelector(selectRegisterEventData)
  const HorseteamMembersInProfile = useAppSelector(selectHorseTeamMembers) as ITeamMember[]
  const selectedHorse = useAppSelector(getSelectedHorse)
  const data = useAppSelector(getSelectedHorseLineage)
  const paperworkTabDataInRedux: IPaperworkTab = useAppSelector(selectRegisterEventPaperworksData)
  const profileDetails = useAppSelector(selectProfileData)

  const types: IModalType = {
    HORSE: props?.dataToPassOn?.modal_type === 'forHorses',
    TEAM: props?.dataToPassOn?.modal_type === 'forTeam',
    CLINIC_TABS: props?.dataToPassOn?.modal_type === 'forClinicTabs',
    RIDER_TEAM_MEMBER: props?.dataToPassOn?.modal_type === 'forRiderTeamMember',
    HORSE_LINEAGE: props?.dataToPassOn?.modal_type === 'removeHorseLineage',
  }
  const dataToPassOn = props?.dataToPassOn

  const { removeHorseTeamMember: removeHorseTeamMate } = useHorses()

  // Functions

  // Handles remove team member types
  const handleRemoveTeamMember = async () => {
    if (types.HORSE_LINEAGE) {
      if (!data) return null

      let cloneHorseLineage = cloneDeep(data.horseLineageMembers)
      let remaingHorseLineage = cloneHorseLineage.filter((item: THorseTeamMembers) => {
        return props?.dataToPassOn.removingHorse.id !== item.id
      })

      dispatch(
        setSelectedHorseLineage({
          data: {
            ...data,
            horseLineageMembers: remaingHorseLineage,
          },
        })
      )
      props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)
    } else if (types.RIDER_TEAM_MEMBER) {
      const data = cloneDeep(registerFormData)
      const { event, memberIndex, riderTeamMemberIndex } = props?.dataToPassOn

      const newData = data.events.reduce((acc: any[], currentEvent: any, currentIndex) => {
        acc.push(currentEvent)
        if (currentIndex === event) {
          acc[currentIndex].members.forEach((currentMember: any, currentMemberIndex: number) => {
            if (currentMemberIndex === memberIndex) {
              const riders = currentMember.riderTeamMembers.filter(
                (item: any, RiderMemberIndex: number) => {
                  return RiderMemberIndex !== riderTeamMemberIndex
                }
              )
              acc[currentIndex].members[memberIndex].riderTeamMembers = riders
            }
          })
        }
        return acc
      }, [])

      dispatch(
        setRegisterEventData({
          ...registerFormData,
          events: newData,
        })
      )
      props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)
    } else if (types.HORSE) {
      removeHorseTeamMember()
    } else if (types.TEAM) {
      removeProfileTeamMember()
    } else if (types.CLINIC_TABS) {
      removeTeamMemberFromPaperworkTab()
    }
  }

  // Removes team member from paperwork tab
  const removeTeamMemberFromPaperworkTab = () => {
    let updatedPaperworks: IPaperworkTab = cloneDeep(paperworkTabDataInRedux)

    if (paperworkTabDataInRedux?.hasOwnProperty(dataToPassOn?.paperworkDocumentKey)) {
      let updatedSignatoryList: ISignatory[] = []

      updatedPaperworks[dataToPassOn?.paperworkDocumentKey].forEach((currSignatory) => {
        if (currSignatory.memberId === dataToPassOn?.memberId) {
          if (currSignatory.signedStatus !== 'Not sent') {
            updatedSignatoryList.push({
              ...currSignatory,
              signedStatus: 'Removed',
            })
          }
        } else {
          updatedSignatoryList.push(currSignatory)
        }
      })

      updatedPaperworks[dataToPassOn?.paperworkDocumentKey] = updatedSignatoryList
      dispatch(setRegisterPaperworkData(updatedPaperworks))
    }

    props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)
  }

  // Removes team member from profile
  const removeProfileTeamMember = () => {
    if (profileDetails.userTeamMembers?.find((data) => data.memberId === dataToPassOn?.id)) {
      let remainingTeamMembers = profileDetails.userTeamMembers?.filter((item: any) => {
        return item?.memberId !== props?.dataToPassOn?.id
      })
      dispatch(
        setProfileDetails({
          ...profileDetails,
          userTeamMembers: remainingTeamMembers,
        })
      )
    } else {
      if (
        HorseteamMembersInProfile.length &&
        HorseteamMembersInProfile.find((data) => data.memberId === dataToPassOn?.id)
      ) {
        let remainingTeamMembers = HorseteamMembersInProfile.filter((item: any) => {
          return item?.memberId !== props?.dataToPassOn?.id
        })
        let selectedTeamMember = HorseteamMembersInProfile.filter((item: any) => {
          return item?.memberId === props?.dataToPassOn?.id
        })
        removeHorseTeamMate({
          teamMemberDocId: selectedTeamMember[0].id,
          mappingDocId: selectedTeamMember[0].userHorseMappingId,
        })

        remainingTeamMembers = remainingTeamMembers.map((curr) => getConvertedData(curr))
        dispatch(setHorseTeamMembers(remainingTeamMembers))
      }
    }
    props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)
  }

  // Removes team member from a particular horse profile
  const removeHorseTeamMember = async () => {
    try {
      if (!selectedHorse) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      let horse = new HorseModel(selectedHorse)?.toObject()
      let horseTeamMembersList = horseReducer.selected.team.data
      let updatedHorseTeamMembers: IHorseTeamInterface[] = []

      if (!props?.dataToPassOn?.memberId && !props?.dataToPassOn?.removingHorse)
        throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      if (!props?.dataToPassOn?.removingHorse) {
        updatedHorseTeamMembers = horseTeamMembersList.filter((horseTeamMember) => {
          return horseTeamMember?.memberId !== props?.dataToPassOn?.memberId
        })
      } else {
        setLoading(true)

        updatedHorseTeamMembers = horseTeamMembersList.filter((horseTeamMember) => {
          return horseTeamMember?.id !== props?.dataToPassOn?.removingHorse
        })

        let data = {
          ...horse,
          horseTeamMembers: updatedHorseTeamMembers,
        }

        await FirestoreService.deleteItem(
          HORSES_TEAM_COLLECTION.NAME,
          props?.dataToPassOn?.removingHorse
        )

        dispatch(setSelectedHorseTeam(updatedHorseTeamMembers))
        horse = getConvertedData(data)

        dispatch(
          setTeamMemberInMappings({
            mappingDocId: props?.dataToPassOn.mappingId!,
            teamMembers: updatedHorseTeamMembers,
          })
        )
      }

      dispatch(setSelectedHorseTeam(updatedHorseTeamMembers))
      dispatch(updateHorse({ data: horse }))
      dispatch(setSelectedHorse({ ...horse, horseTeamMembers_: updatedHorseTeamMembers }))

      toastFunctions.success({
        message: MESSAGES_CONST.HORSE_TEAM_MEMBER_REMOVED,
      })
      props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      setLoading(false)
    }
  }

  return (
    <div
      className={`modal fade rounded fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <div>
              <h5
                className={clsx(
                  'text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2',
                  props.dataToPassOn.titleStyles
                )}
                id="exampleModalScrollableLabel"
              >
                {props.dataToPassOn.title
                  ? props.dataToPassOn.title
                  : types.HORSE_LINEAGE
                    ? 'Are you sure you want to remove this horse from lineage?'
                    : 'Are you sure you want to remove this team member?'}
              </h5>
              {props.dataToPassOn.desc && (
                <p className={'my-2 text-SeabiscuitDark200ThemeColor'}>{props.dataToPassOn.desc}</p>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={handleRemoveTeamMember}
            className="w-full md:w-[70%] h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            {!loading ? 'Remove' : <AutorenewRounded fontSize="small" className="animate-spin" />}
          </button>

          <button
            onClick={() => props.handleModal(false, MODAL_CONSTS.REMOVE_TEAM_MEMBER)}
            type="button"
            className="w-full md:w-[70%] mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default RemoveTeamModal
