import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IProductInterface } from './product.interface'
import * as yup from 'yup'

import { CONST } from '../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const PRODUCTS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.PRODUCTS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class ProductModel extends ModelBaseModel<IProductInterface> implements IModelBaseModel {
  // Vars
  public v: IProductInterface['v']
  public id: IProductInterface['id']
  public tags: IProductInterface['tags']
  public creatorId: IProductInterface['creatorId']
  public pictures: IProductInterface['pictures']
  public couponCode: IProductInterface['couponCode']
  public name: IProductInterface['name']
  public url: IProductInterface['url']
  public nameNGrams: IProductInterface['nameNGrams']
  public creatorType: IProductInterface['creatorType']
  public category: IProductInterface['category']
  public price: IProductInterface['price']
  public discountPrice: IProductInterface['discountPrice']
  public status: IProductInterface['status']
  public description: IProductInterface['description']
  public creatorName: IProductInterface['creatorName']
  public creatorNameNGrams: IProductInterface['creatorNameNGrams']
  public creatorPicture: IProductInterface['creatorPicture']
  public created: IProductInterface['created']
  public modified: IProductInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj?: IProductInterface) {
    super()
    this.id = obj?.id ?? PRODUCTS_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.tags = Array.isArray(obj?.tags)
      ? obj?.tags!
      : PRODUCTS_COLLECTION.FIELDS.TAGS.VALUES.DEFAULT
    this.v = obj?.v ?? PRODUCTS_COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.creatorId = obj?.creatorId ?? PRODUCTS_COLLECTION.FIELDS.CREATOR_ID.VALUES.DEFAULT
    this.pictures = obj?.pictures ?? PRODUCTS_COLLECTION.FIELDS.PICTURES.VALUES.DEFAULT
    this.name = obj?.name ?? PRODUCTS_COLLECTION.FIELDS.NAME.VALUES.DEFAULT
    this.url = obj?.url ?? PRODUCTS_COLLECTION.FIELDS.URL.VALUES.DEFAULT
    this.couponCode = obj?.couponCode ?? PRODUCTS_COLLECTION.FIELDS.COUPON_CODE.VALUES.DEFAULT
    this.nameNGrams = this.getCalculatedNGrams(obj?.name)
    this.creatorType = obj?.creatorType ?? PRODUCTS_COLLECTION.FIELDS.CREATOR_TYPE.VALUES.DEFAULT
    this.category = obj?.category ?? PRODUCTS_COLLECTION.FIELDS.CATEGORY.VALUES.DEFAULT
    this.price = this.getNum(obj?.price)
    this.discountPrice =
      this.getNum(obj?.discountPrice) === 0 ? null : this.getNum(obj?.discountPrice)
    this.status = obj?.status ?? PRODUCTS_COLLECTION.FIELDS.STATUS.VALUES.DEFAULT
    this.description = obj?.description ?? PRODUCTS_COLLECTION.FIELDS.DESCRIPTION.VALUES.DEFAULT
    this.creatorName = obj?.creatorName ?? PRODUCTS_COLLECTION.FIELDS.CREATOR_NAME.VALUES.DEFAULT
    this.creatorNameNGrams = this.getCalculatedNGrams(obj?.creatorName)
    this.creatorPicture =
      obj?.creatorPicture ?? PRODUCTS_COLLECTION.FIELDS.CREATOR_PICTURE.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.created!,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      value: obj?.modified!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new ProductModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object().shape({
      url: yup.string().url().nullable(),
      couponCode: yup
        .string()
        .test('coupon validation', 'invalid coupon code', (value) => {
          return !!value ? !/[^A-Za-z0-9]+/gi.test(value) : true
        })
        .nullable(),
      discountPrice: yup.string().nullable(),
      pictures: yup.array(yup.string()).required(),
      name: yup.string().typeError('name is a required field').required(),
      description: yup.string().typeError('description is a required field').required(),
      price: yup
        .string()
        .notOneOf(['0', '$0', '', null, undefined], 'price should be more than $0'),
      status: yup
        .string()
        .typeError('status is a required field')
        .oneOf(['live', 'draft'])
        .required(),
    })
  }
}
