import { DocumentData, QuerySnapshot, where } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import helpers from '../../../commonHelpers/helpers'
import MainModal from '../../../components/modals/common/MainModal'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { RecipientModel } from '../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import EventService from '../../../services/eventService'
import FirestoreService from '../../../services/firestoreService'
import { useAppDispatch } from '../../../store/hooks'
import { setAllTickets } from '../../../store/tickets/ticketslice'
import SendTicketComponent from '../component/SendTicketComponent'
import ViewTicket from '../component/ViewTicket'
import { IGuestInterface } from '../../../models/guests/guest.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { ITicketBuyer } from '../../../models/ticket-buyers/ticket-buyers.interface'
import { UserModel } from '../../../models/users/user.model'
import PROFILE_DETAIL_CARD_CONST from '../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'

// Types

type Props = {
  show: boolean
  handleModal: (show: boolean, modal_name: string, data?: any) => void
  dataToPassOn: any
}
export type OldDbData = {
  ticketCountToCreate: number
  ticketType: 'spectator' | 'register'
  registrationTicketDocId: string | null
  ticketTitle: IRegistrationTicketInterface['ticketTitle']
  ticketItemId: IRegistrationTicketInterface['ticketItemId']
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const ASSIGNED_TICKETS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.ASSIGNED_TICKETS

const CUSTOM_ERROR_PROPS = {
  fileName: 'TicketsDistributionModal',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TicketsDistributionModal = (props: Props) => {
  // Hooks and vars
  const dataToPassOn = props?.dataToPassOn
  const [eventLocation, setEventLocation] = useState('')
  const [eventDetails, setEventDetails] = useState<IEventReviewPublish['EventDetails'] | null>(null)
  const dispatch = useAppDispatch()
  const already = useRef<boolean>(false)
  const toastFunction = useToasterHelper()

  const [checkValidation, setCheckValidation] = useState(true)
  const [loading, setLoading] = useState(false)
  const [ticketStep, setTicketStep] = useState(1)
  const [assignTicket, setaAssignTicket] = useState<IAssignedTicket[]>([])
  const [SavedTicket, setaSavedTicket] = useState<IAssignedTicket[]>([])
  const [assignedUser, setAssignedUser] = useState(false)
  const [remainingTicketsCount, setRemainingTicketsCount] = useState(0)

  useEffect(() => {
    try {
      if (!dataToPassOn.eventId) throw new Error(`event id is ${dataToPassOn.eventId}`)

      const eventService = new EventService(dataToPassOn.eventId as string)

      eventService.getPublishedEventDetails
        .then((eventDetails_) => {
          setEventDetails(eventDetails_)
        })
        .catch((error) => {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              devMessage: error,
              ...CUSTOM_ERROR_PROPS,
            }),
          })
        })

      eventService.getEventLocation
        .then((location) => {
          setEventLocation(location.combinedLocation)
        })
        .catch((error) => {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              devMessage: error,
              ...CUSTOM_ERROR_PROPS,
            }),
          })
        })
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          devMessage: error?.message,
          ...CUSTOM_ERROR_PROPS,
          moduleName: 'useEffect',
        }),
      })
    }
  }, [dataToPassOn.eventId])

  const getMergedAssignedTicketsWithNotCreated = async ({
    recipient,
    assignedTickets,
    user,
  }: {
    recipient: IRecipientInterface
    assignedTickets: IAssignedTicket[]
    user: IUserInterface | null
  }): Promise<IAssignedTicket[]> => {
    let unCreatedTicketCount = 0
    let createdItemsCount = 0
    let assignedTickets_: IAssignedTicket[] = []
    let registrationTickets: IRegistrationTicketInterface[] = []
    let registrationTicket: IRegistrationTicketInterface | null = null

    const registrationTicketSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.RECIPIENT_ID.KEY, '==', recipient.recipientId),
        where(
          COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          recipient.registrationDocId
        ),
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.IS_SCRATCHED.KEY, '==', false),
      ]
    )

    registrationTicketSnapshots.forEach((currRegistrationTicketSnapshot) => {
      registrationTicket = RegistrationTicketModel.fromFirestoreDoc(currRegistrationTicketSnapshot)
      registrationTickets.push(registrationTicket)
      unCreatedTicketCount = registrationTicket.remainingTicketsCount

      createdItemsCount = 0

      while (createdItemsCount < unCreatedTicketCount) {
        assignedTickets_.push({
          ...new AssignTicketsModal({
            ...registrationTicket,
            id: null,
            amountRefunded: 0,
            amountScratched: 0,
            scratchedOn: null,
            chargeId: registrationTicket.invoiceId ?? null,
            isPlatformUser: true,
            ticketBuyerDocId: registrationTicket.userId,
            ticketTitle: registrationTicket.ticketTitle,
            userId: recipient?.recipientId ?? null,
            ticketBuyerName: recipient?.recipientName ?? null,
            ticketBuyerEmailId: user?.userEmail ?? null,
            paymentStatus: 'paid',
            ticketType: 'register',
            ticketHolderEmailId: null,
            registrationTicketDocId: registrationTicket.id ?? null,
            updated: null,
            created: null,
            receipetUrl: registrationTicket.invoiceUrl ?? null,
            ticketStatus: 'available',
            assignmentDate: null,
            isScratched: false,
            eventDocId: recipient?.eventId ?? null,
            checkInDate: null,
            spectatorTicketDocId: null,
            registrationDocId: recipient.registrationDocId,
            ticketHolderName: null,
          }).toObject(),
        })
        createdItemsCount++
      }
    })

    assignedTickets_ = [...assignedTickets, ...assignedTickets_]

    return assignedTickets_
  }

  const handleAssignticketcalculate = async () => {
    let isSpectatorTicket = false
    let alreadytickets: IAssignedTicket[] = []
    let recipient: IRecipientInterface | null = null
    let assignedTicketSnapshots: QuerySnapshot<DocumentData>
    let user: IGuestInterface | IUserInterface | null = null
    let ticketBuyer: ITicketBuyer | null = dataToPassOn?.registration?.ticket_Buyers ?? null

    try {
      if (!ticketBuyer) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      if (ticketBuyer?.type === 'spectator') {
        isSpectatorTicket = true

        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(
              COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
              'in',
              ticketBuyer?.spectatorTicketsIds
            ),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', ticketBuyer?.type),
          ]
        )
      } else {
        if (!ticketBuyer?.recipientDocId) {
          throw new Error(`RecipientDocId is ${ticketBuyer?.recipientDocId}`)
        }

        const recipientSnapshot = await FirestoreService.getItem(
          COLLECTIONS.RECIPIENT.NAME,
          ticketBuyer?.recipientDocId!
        )

        recipient = RecipientModel.fromFirestoreDoc(recipientSnapshot)

        if (!recipient.recipientId) {
          throw new Error(`recipient.recipientId is ${recipient.recipientId}`)
        }

        const userSnapshot = await FirestoreService.getItem(
          COLLECTIONS.USERS.NAME,
          recipient.recipientId
        )

        if (!userSnapshot.exists()) {
          throw new Error(MESSAGES_CONST.USER_NOT_FOUND)
        }

        user = UserModel.fromFirestoreDoc(userSnapshot)

        if (!recipientSnapshot.exists()) {
          throw new Error(`Recipient with docId: ${ticketBuyer?.recipientDocId} does not exist`)
        }

        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.USER_ID, '==', recipient.recipientId),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', ticketBuyer?.type),
          ]
        )
      }

      assignedTicketSnapshots?.forEach((currDoc) => {
        let assignedTicket = AssignTicketsModal.fromFirestoreDoc(currDoc).toObject()
        alreadytickets.push(getConvertedData(assignedTicket))
      })

      if (!isSpectatorTicket) {
        alreadytickets = await getMergedAssignedTicketsWithNotCreated({
          recipient: recipient!,
          assignedTickets: alreadytickets,
          user,
        })
      }
    } catch (error) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setaAssignTicket(alreadytickets)
    }
  }

  useEffect(() => {
    setRemainingTicketsCount(dataToPassOn?.registration?.ticket_Buyers?.unusedTicketCount)
    handleAssignticketcalculate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn?.registration?.ticket_Buyers?.unusedTicketCount])

  const handelTicketStep = (step: number) => {
    setTicketStep(step)
  }

  const decrementUnusedTicketCount = () => {
    setRemainingTicketsCount((prev) => prev - 1)
  }

  // ############################################################
  /**
   * @todo Document this
   */
  const getTickets = async () => {
    let ticketsToCreate: OldDbData[] = []
    const assignedTickets: IAssignedTicket[] = []

    if (!dataToPassOn?.registration?.ticket_Buyers?.registrationDocId)
      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          fileName: 'TicketsDistributionModal',
          moduleName: 'getTickets',
          devMessage: `dataToPassOn?.registration?.id is ${dataToPassOn?.registration?.ticket_Buyers?.registrationDocId}`,
        }),
      })

    const tickets = await FirestoreService.filterItems(ASSIGNED_TICKETS.NAME, [
      where(
        ASSIGNED_TICKETS.FIELDS.REGISTRATION_DOC_ID,
        '==',
        dataToPassOn?.registration?.ticket_Buyers?.registrationDocId
      ),
      where(
        ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY,
        '==',
        ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.VALUE.REGISTERED
      ),
      where(ASSIGNED_TICKETS.FIELDS.IS_SCRATCHED, '!=', true),
    ])

    tickets?.forEach((currDoc) => {
      let assignedTicket = AssignTicketsModal.fromFirestoreDoc(currDoc).toObject()
      assignedTickets.push(getConvertedData(assignedTicket))
    })

    if (tickets?.size < dataToPassOn.remainingTicketsCount) {
      ;(dataToPassOn?.tickets ?? []).forEach((currBoughTicketItem: any) => {
        if (!currBoughTicketItem.ticketItemId) return

        ticketsToCreate.push({
          ticketCountToCreate: currBoughTicketItem.remainingTicketsCount,
          ticketItemId: currBoughTicketItem.ticketItemId,
          ticketTitle: currBoughTicketItem.ticketTitle,
          registrationTicketDocId: currBoughTicketItem.id!,
          ticketType: 'register',
        })
      })
    }

    setLoading(false)
    setaSavedTicket(assignedTickets)
    dispatch(setAllTickets(assignedTickets))
  }

  useEffect(() => {
    try {
      if (!already.current && dataToPassOn?.assignedTicketsModal !== true) {
        setLoading(true)
        getTickets()
        already.current = true
      } else {
        dispatch(setAllTickets(dataToPassOn?.assignedTickets ?? []))
        setAssignedUser(dataToPassOn?.assignedTicketsModal ?? false)
      }
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: error?.message,
          fileName: 'TicketsDistributionModal',
          moduleName: 'createTickets',
        }),
      })
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }

    return () => {
      dispatch(setAllTickets([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainModal
      customTitle={
        <>
          <div className="flex-1 flex items-center gap-3 mb-2">
            {eventDetails?.eventLogo && (
              <img
                className="mr-2 !w-[60px] !h-[60px] rounded-lg shrink-0"
                src={eventDetails?.eventLogo ?? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC}
                onError={(e) => {
                  e.currentTarget.src = PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                }}
                alt="icon"
              />
            )}
            <div className="text-SeabiscuitDark200ThemeColor font-semibold text-xl capitalize">
              {eventDetails?.competitionName ?? 'Unknown'}
            </div>
          </div>
        </>
      }
      show={props.show}
      type="TICKETS_DISTRIBUTER_MODAL"
      size="4xl"
    >
      <div className="flex items-center mt-4">
        <img src="/assets/og_icons/Two Tickets-1.svg" className="mr-2" alt="ticket" />
        <span className="text-[#122B46] text-base leading-1">
          {remainingTicketsCount} remaining ticket{remainingTicketsCount > 1 ? 's' : ''}
        </span>
      </div>

      <div className="flex items-center mt-4">
        <img src="/assets/og_icons/Calendar-1.svg" className="mr-2" alt="calendar" />
        <span className="text-[#122B46] text-base leading-1">
          {moment(
            assignedUser ? dataToPassOn?.registration?.created : dataToPassOn?.registration?.created
          ).format('MMMM Do, YYYY')}
        </span>
      </div>

      <div className="flex items-center mt-4">
        <img src="/assets/og_icons/Location-1.svg" className="mr-2" alt="location" />
        <span className="text-[#122B46] text-base leading-1">{eventLocation}</span>
      </div>
      <hr className="text-[#D3DAEE] my-6"></hr>
      <div className="w-full pb-[12px] rounded-md">
        {ticketStep === 1 ? (
          <SendTicketComponent
            loading={loading}
            assignTicket={assignTicket}
            dataToPassOn={dataToPassOn}
            SavedTicket={SavedTicket}
            handelTicketStep={handelTicketStep}
            setaAssignTicket={setaAssignTicket}
            decrementUnusedTicketCount={decrementUnusedTicketCount}
            check_validation={{ setCheckValidation, checkValidation }}
            assigned_user={assignedUser}
          />
        ) : ticketStep === 2 ? (
          <ViewTicket
            handelTicketStep={handelTicketStep}
            dataToPassOn={dataToPassOn}
            assigned_user={assignedUser}
          />
        ) : (
          ''
        )}
      </div>
    </MainModal>
  )
}

export default TicketsDistributionModal
