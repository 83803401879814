import { CONST } from '../../const/const'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import IRefundRequest from './refundRequest.interface'

type IRefundRequestInterface = IRefundRequest['IRefundRequestInterface']

const REFUND_REQUEST_COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.REFUND_REQUEST

class RefundRequestModel
  extends ModelBaseModel<IRefundRequestInterface>
  implements IModelBaseModel
{
  public v: IRefundRequestInterface['v']
  public id: IRefundRequestInterface['id']
  public invoiceId: IRefundRequestInterface['invoiceId']
  public invoiceUrl: IRefundRequestInterface['invoiceUrl']
  public registrationDocId: IRefundRequestInterface['registrationDocId']
  public amountRequested: IRefundRequestInterface['amountRequested']
  public recipientName: IRefundRequestInterface['recipientName']
  public cardNo: IRefundRequestInterface['cardNo']
  public refundId: IRefundRequestInterface['refundId']
  public refundDocId: IRefundRequestInterface['refundDocId']
  public amountRefunded: IRefundRequestInterface['amountRefunded']
  public userId: IRefundRequestInterface['userId']
  public recipientDocId: IRefundRequestInterface['recipientDocId']
  public recipientProfilePicture: IRefundRequestInterface['recipientProfilePicture']
  public recipientUserId: IRefundRequestInterface['recipientUserId']
  public eventId: IRefundRequestInterface['eventId']
  public isPaidByOwner: IRefundRequestInterface['isPaidByOwner']
  public eventName: IRefundRequestInterface['eventName']
  public eventLogo: IRefundRequestInterface['eventLogo']
  public refundStatus: IRefundRequestInterface['refundStatus']
  public amountPaid: IRefundRequestInterface['amountPaid']
  public refundRequestedFor: IRefundRequestInterface['refundRequestedFor']
  public registrationDate: IRefundRequestInterface['registrationDate']
  public refundedAt: IRefundRequestInterface['refundedAt']

  public created: IRefundRequestInterface['created']
  public modified: IRefundRequestInterface['modified']

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  constructor(obj?: IRefundRequestInterface) {
    super()
    this.v = obj?.v ?? REFUND_REQUEST_COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.id = obj?.id ?? REFUND_REQUEST_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.recipientName =
      obj?.recipientName ?? REFUND_REQUEST_COLLECTION.FIELDS.RECIPIENT_NAME.VALUES.DEFAULT
    this.amountRefunded =
      obj?.amountRefunded ?? REFUND_REQUEST_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.invoiceId = obj?.invoiceId ?? REFUND_REQUEST_COLLECTION.FIELDS.INVOICE_ID.VALUES.DEFAULT
    this.invoiceUrl = obj?.invoiceUrl ?? REFUND_REQUEST_COLLECTION.FIELDS.INVOICE_URL.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ?? REFUND_REQUEST_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.amountRequested =
      obj?.amountRequested ?? REFUND_REQUEST_COLLECTION.FIELDS.AMOUNT_REQUESTED.VALUES.DEFAULT
    this.cardNo = obj?.cardNo ?? REFUND_REQUEST_COLLECTION.FIELDS.CARD_NUMBER.VALUES.DEFAULT
    this.refundId = obj?.refundId ?? REFUND_REQUEST_COLLECTION.FIELDS.REFUND_ID.VALUES.DEFAULT
    this.refundDocId =
      obj?.refundDocId ?? REFUND_REQUEST_COLLECTION.FIELDS.REFUND_DOC_ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? REFUND_REQUEST_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.recipientDocId =
      obj?.recipientDocId ?? REFUND_REQUEST_COLLECTION.FIELDS.RECIPIENT_DOC_ID.VALUES.DEFAULT
    this.recipientProfilePicture =
      obj?.recipientProfilePicture ??
      REFUND_REQUEST_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.VALUES.DEFAULT
    this.recipientUserId =
      obj?.recipientUserId ?? REFUND_REQUEST_COLLECTION.FIELDS.RECIPIENT_USER_ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? REFUND_REQUEST_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.isPaidByOwner =
      obj?.isPaidByOwner ?? REFUND_REQUEST_COLLECTION.FIELDS.IS_PAID_BY_OWNER.VALUES.DEFAULT
    this.eventName = obj?.eventName ?? REFUND_REQUEST_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.eventLogo = obj?.eventLogo ?? REFUND_REQUEST_COLLECTION.FIELDS.EVENT_LOGO.VALUES.DEFAULT
    this.refundStatus =
      obj?.refundStatus ?? REFUND_REQUEST_COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.amountPaid = obj?.amountPaid ?? REFUND_REQUEST_COLLECTION.FIELDS.AMOUNT_PAID.VALUES.DEFAULT
    this.refundRequestedFor =
      obj?.refundRequestedFor ??
      REFUND_REQUEST_COLLECTION.FIELDS.REFUND_REQUESTED_FOR.VALUES.DEFAULT
    this.registrationDate =
      obj?.registrationDate ?? REFUND_REQUEST_COLLECTION.FIELDS.REGISTRATION_DATE.VALUES.DEFAULT
    this.refundedAt = obj?.refundedAt ?? REFUND_REQUEST_COLLECTION.FIELDS.REFUNDED_AT.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created as any,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified as any,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  static fromFirestoreDoc(doc: any) {
    return new RefundRequestModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}

export default RefundRequestModel
