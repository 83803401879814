import { useMemo, useState } from 'react'

import { AutorenewRounded } from '@mui/icons-material'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

import { useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import ModalComponent from '../../../../../components/events/views/details/global/modalComponent'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import { EventModel } from '../../../../../models/events/event.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import FirestoreService from '../../../../../services/firestoreService'
import { setSelectedEventKey } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { useHistory } from 'react-router-dom'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Types
type ICancelEventConfirmationModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

export const CancelEventConfirmationModal = (props: ICancelEventConfirmationModalProps) => {
  const selectedEvent = useAppSelector((store) => store).events.selectedEvent

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalRevenue = useMemo(() => calculateRevenue(), [selectedEvent.Event])
  function calculateRevenue() {
    return (
      (selectedEvent.Event?.currentFeesRevenue ?? 0) +
      (selectedEvent.Event?.currentRegistrationRevenue ?? 0) +
      (selectedEvent.Event?.currentTicketRevenue ?? 0)
    )
  }
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useDispatch()
  const toastMethods = useToasterHelper()
  // const profileDetails = useAppSelector(state => state.user.profileDetails)

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<'primary' | 'secondary'>('primary')

  const { headingClassName, eventId } = props?.dataToPassOn

  // Functions
  // const sendMailsToNotify = async () => {
  // try {
  //     const res: any = await httpService({
  //         url: "send_emails",
  //         method: "POST",
  //         data: {
  //             mail_type: "cancel_event",
  //             mail_data: {
  //                 owner_name: getUserFullName(profileDetails),
  //                 event_id: eventId
  //             }
  //         }
  //     })

  //     if (res?.status) {
  //         return true
  //     }
  // } catch (err) {
  //     return false
  // }

  //     return 0;
  // }

  const cancelEvent = async () => {
    try {
      setLoading(true)

      if (!eventId)
        return toastMethods.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      const eventSnap = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)

      const publishSnap = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (!eventSnap.exists() || !publishSnap.exists())
        return toastMethods.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      const event = EventModel.fromFirestoreDoc(eventSnap).cloneDeep()
      const publishedEvent = EventReviewPublishModel.fromFirestoreDoc(publishSnap).cloneDeep()

      event.status = COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL
      publishedEvent.status = COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL

      await FirestoreService.updateItem(COLLECTIONS.EVENTS.NAME, eventId, {
        status: event.status,
      })

      await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
        status: publishedEvent.status,
      })

      /**
       * @status Skipped for now
       **/
      router.push(
        `${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST}`
      )
      history.push(
        `${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST}`
      )

      dispatch(
        setSelectedEventKey({
          key: 'EventDetails',
          value: getConvertedData(event),
        })
      )

      setStep('secondary')
    } catch (error) {
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const closeModal = (cancel: 'closeClicked' | 'cancelClicked' | 'cancelEventBtnClicked') => {
    if (cancel === 'cancelEventBtnClicked') return cancelEvent()

    if (cancel === 'cancelClicked') props?.handleModal(true, MODAL_CONSTS.CANCEL_EVENT_MODAL)

    return props?.handleModal(false, MODAL_CONSTS.CANCEL_EVENT_MODAL)
  }

  const onStartRefundsBtnClick = () => {
    closeModal('closeClicked')
  }

  return (
    <ModalComponent
      size="sm"
      show={true}
      fullScreen={true}
      onHide={() => closeModal('closeClicked')}
      headingClassName={headingClassName ?? ''}
      modaltopclassname={clsx(
        'md:max-h-[450px] h-[500px] pb-0',
        step === 'secondary' && '!min-h-fit'
      )}
      type={MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION}
    >
      <div className={'flex items-center flex-col max-h-[56vh] overflow-auto'}>
        {step === 'primary' ? (
          <>
            <div className="w-full relative flex justify-center items-center mb-10">
              <img
                src="/assets/modals/closeEventIcon-1.svg"
                alt="close event"
                className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
              />
              <div className="flex flex-col absolute items-center">
                <span className="text-SeabiscuitMainThemeColor text-[33px]">${totalRevenue}</span>
                <span className="text-SeabiscuitMainThemeColor text-[13px]">Funds Collected</span>
              </div>
            </div>
            <span className="font-extrabold text-xl text-SeabiscuitDark200ThemeColor mb-2">
              Cancel event?
            </span>

            <div className="text-center text-sm xl:text-[13px] 2xl:text-base text-SeabiscuitDark200ThemeColor pb-5">
              Once cancelled, this event cannot be reopened. <br />
              <br />
              All registered riders, spectators, sponsors and vendors will be notified.
              <br />
              <br />
              All event data and paperwork will remain accessible in your ‘Past Events’ dashboard.{' '}
              <br />
              <br />
              The collected funds will not be automatically refunded. You will need to manually
              refund all payments in line with your refund policies. <br /> <br />
              Once all refunds are processed, transfer the remaining funds to your bank account in
              your settings. <br /> <br />
            </div>
            <div className="w-[90%] bottom-5 absolute">
              <button
                type="button"
                onClick={() => closeModal('cancelEventBtnClicked')}
                className="w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-0  capitalize"
              >
                {loading ? <AutorenewRounded className="animate-spin" /> : 'Cancel Event'}
              </button>
              <button
                type="button"
                onClick={() => closeModal('cancelClicked')}
                className="w-full h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor capitalize"
              >
                CANCEL
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full relative flex justify-center items-center mb-3">
              <div className="w-full relative flex justify-center items-center mb-3">
                <img
                  src="/assets/modals/cancelEventIcon.svg"
                  alt="close event"
                  className="w-[190px] max-w-[calc(100%-90px)] mx-auto  relative"
                />
              </div>
            </div>
            <span className="font-extrabold text-xl text-SeabiscuitDark200ThemeColor mb-10 text-center">
              Event successfully cancelled
            </span>

            <div className="w-[90%] bottom-5 absolute">
              <button
                type="button"
                onClick={() => onStartRefundsBtnClick()}
                className="w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-0  capitalize"
              >
                Start Refunds
              </button>
              <button
                type="button"
                onClick={() => closeModal('cancelClicked')}
                className="w-full h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor capitalize"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </ModalComponent>
  )
}
