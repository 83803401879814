export const UI_REGISTER_CONST = {
  TABS: {
    STATUS: 'Status',
    TEAM: 'Start',
    CLASSES: 'Classes',
    HORSES: 'Horses',
    FEES: 'Fees',
    PAPERWORK: 'Roles',
    SIGN: 'Sign',
    COMMENTS: 'Comments',
    CONFIRMATION: 'Confirmation',
    REVIEWANDPAY: 'Pay',
    REGISTER: 'Register',
    TICKETS: 'Tickets',
  },
}

const EVENT_REG_CLINIC_TABS_CONST = [
  {
    tabName: UI_REGISTER_CONST.TABS.TEAM,
    icon: '/assets/og_icons/start-1.svg',
    activeIcon: '/assets/og_icons/start-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.REGISTER,
    activeIcon: '/assets/og_icons/Shortlist-2.svg',
    icon: '/assets/og_icons/Shortlist-1.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.HORSES,
    icon: '/assets/og_icons/YearofHorse-1.svg',
    activeIcon: '/assets/og_icons/YearofHorse-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.FEES,
    icon: 'assets/og_icons/Calculator-1.svg',
    activeIcon: '/assets/og_icons/Calculator-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.TICKETS,
    icon: 'assets/og_icons/Two Tickets-1.svg',
    activeIcon: '/assets/og_icons/Two Tickets-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.PAPERWORK,
    icon: '/assets/og_icons/teamClinic-1.svg',
    activeIcon: '/assets/og_icons/teamClinic-2.svg',
    // icon: '/assets/cp_icons/Google Docs-1.svg',
    // activeIcon: '/assets/cp_icons/Google Docs-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.SIGN,
    icon: '/assets/cp_icons/QuillPen-1.svg',
    activeIcon: '/assets/cp_icons/Quill Pen-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.COMMENTS,
    icon: '/assets/og_icons/Messaging-1.svg',
    activeIcon: '/assets/og_icons/Messaging-2.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.CONFIRMATION,
    icon: '/assets/img/dark/inspection.svg',
    activeIcon: '/assets/img/color/Inspection.svg',
    max_steps: 1,
  },
  {
    tabName: UI_REGISTER_CONST.TABS.REVIEWANDPAY,
    icon: '/assets/cp_icons/UsDollarCircled1.svg',
    activeIcon: '/assets/cp_icons/UsDollarCircled.svg',
    max_steps: 1,
  },
]

export const MEMBER_ROLES = {
  AGENT: 'Agent',
  COACH: 'Coach',
  FRIEND: 'Friend',
  GROOM: 'Groom',
  GUARDIAN: 'Guardian',
  OWNER: 'Owner',
  RIDER: 'Rider',
  SPONSOR: 'Sponsor',
  STAFF: 'Staff',
  TRAINER: 'Trainer',
}

export { EVENT_REG_CLINIC_TABS_CONST }
