// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import User from './assets/PointOfContact/user.svg'
import Phone from './assets/PointOfContact/phone.svg'
import { IEventDetails } from '../../../../models/event-details/event-details.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const EventDetailViewComponentPointOfContactElement: React.FC<{
  icon: string
  title: string
  type?: string
  details: string
}> = ({ icon, title, type, details }) => {
  let phoneNumber1 = details
  let phoneNumber = phoneNumber1.toString()
  let formattedPhoneNumber = phoneNumber
  formattedPhoneNumber =
    phoneNumber.substring(0, 3) +
    '-' +
    phoneNumber.substring(3, 6) +
    '-' +
    phoneNumber.substring(6, 10)
  return (
    <div className="items-center gap-10 border border-[#D3DAEE] p-5 rounded-lg">
      <div className="flex mb-4 items-center">
        <img className="mr-2 w-6 h-6" src={icon} alt="icon" />
        <p className="text-SeabiscuitDark200ThemeColor font-semibold">{title}</p>
      </div>
      {type === 'phone' ? (
        <a
          className="whitespace-nowrap text-SeabiscuitDark200ThemeColor capitalize"
          href={`tel:${formattedPhoneNumber}`}
        >
          {formattedPhoneNumber}
        </a>
      ) : (
        <p className="whitespace-nowrap text-SeabiscuitDark200ThemeColor capitalize">{details}</p>
      )}
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const EventDetailViewComponentPointOfContact: React.FC<{ eventData?: IEventDetails }> = (props) => {
  return (
    <div className="grid sm:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-x-2 gap-y-4">
      <EventDetailViewComponentPointOfContactElement
        title="Point of Contact"
        icon={User}
        details={props.eventData?.competitionPOCName ?? 'N/A'}
      />
      <EventDetailViewComponentPointOfContactElement
        title="Phone"
        icon={Phone}
        type="phone"
        details={props.eventData?.competitionPOCPhone ?? 'N/A'}
      />
    </div>
  )
}

export default EventDetailViewComponentPointOfContact
