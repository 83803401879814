import React, { useEffect } from 'react'
import clsx from 'clsx'

import { IconChevron } from '../../../../../components/icons/IconChevron'
import customImageComponent from '../../../../../components/common/CustomImageComponent'
import { TextField } from './TextField'

import { IHorseCompetitorDocument } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { IField, REQUIERED_FILEDS } from '../../../../../const/requeredFields'
import {
  getHorseProfileReqField,
  getUserProfileReqField,
} from '../../../../../helpers/requiredFields'
import { IconDelete2 } from '../../../../../components/icons/IconDelete2'

interface IProps {
  headerTitle: string
  headerSubTitle?: string
  headerImgSrc: string
  editHandler: (data: IProfileReqField, horse?: IHorseData) => void
  user?: IUserInterface
  teamTabDataWithUser?: IUserInterface[]
  myHorses?: IHorseData[]
  horse?: IHorseData
  eventRequiredFields: string[]
  horsesPaperwork: IHorseCompetitorDocument[] | null
  removeHandler: () => void
  isDeleteBtnHide?: boolean
}

export interface IProfileReqField extends IField {
  profileValue: string
  isExpired?: boolean
  isTextField?: boolean
  expiredDate?: string
}

export const ProfileFields: React.FC<IProps> = ({
  headerTitle,
  headerSubTitle,
  headerImgSrc,
  eventRequiredFields,
  user,
  horse,
  editHandler,
  horsesPaperwork,
  teamTabDataWithUser,
  myHorses,
  removeHandler,
  isDeleteBtnHide,
}) => {
  const [requiredField, setRequiredField] = React.useState<IProfileReqField[]>([])
  const [errorsCount, setErrorsCount] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    const fields: IProfileReqField[] = []
    let _errors = 0

    if (horse?.id) {
      const requiredFiled = [
        ...REQUIERED_FILEDS.horseMain.filter((itm) => eventRequiredFields.includes(itm.value)),
        ...REQUIERED_FILEDS.horseSecondary.filter((itm) => eventRequiredFields.includes(itm.value)),
        ...REQUIERED_FILEDS.horseNumbers.filter((itm) => eventRequiredFields.includes(itm.value)),
      ]
      requiredFiled.forEach((field) => {
        const profileReqField = getHorseProfileReqField({ horse, field, horsesPaperwork })
        if (!profileReqField.profileValue || profileReqField.isExpired) _errors++
        fields.push(profileReqField)
      })
    }

    if (user?.id) {
      const requiredFiled = [
        ...REQUIERED_FILEDS.people.filter((itm) => eventRequiredFields.includes(itm.value)),
        ...REQUIERED_FILEDS.peopleNumbers.filter((itm) => eventRequiredFields.includes(itm.value)),
      ]
      requiredFiled.forEach((field) => {
        const profileReqField = getUserProfileReqField({ user, field })
        if (!profileReqField.profileValue || profileReqField.isExpired) _errors++
        fields.push(profileReqField)
      })
    }
    setErrorsCount(_errors)
    setRequiredField(fields)
  }, [eventRequiredFields, teamTabDataWithUser, myHorses, user, horse, horsesPaperwork])

  return (
    <div className={'flex gap-2.5 items-start'}>
      <div className={'border border-[#D3DAEE] w-full rounded-lg py-3 px-3'}>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={'flex justify-between w-full items-center cursor-pointer'}
        >
          <div className={'flex flex-1 gap-4 items-center'}>
            <div className={'w-[45px] h-[45px] rounded-lg overflow-hidden'}>
              {customImageComponent(headerImgSrc, headerTitle, 'w-full h-full object-cover')}
            </div>
            <div className={'flex flex-col'}>
              <span>{headerTitle}</span>
              <span className={'text-[12px] text-SeabiscuitBlackThemeColor/50'}>
                {headerSubTitle}
              </span>
            </div>
          </div>
          <div
            className={clsx(
              'flex items-center gap-4',
              errorsCount ? 'text-SeabiscuitRedThemeColor' : 'text-SeabiscuitDark200ThemeColor/50'
            )}
          >
            {!!errorsCount && <span>{errorsCount} errors</span>}
            {!errorsCount && requiredField[0] && <span>View</span>}

            {requiredField[0] && (
              <span className={isOpen ? `rotate-180` : ``}>
                <IconChevron color={errorsCount ? '#122B46' : '#122B4680'} />
              </span>
            )}
          </div>
        </div>

        {isOpen && requiredField[0] && (
          <div className={'flex flex-col mt-5 gap-4'}>
            {requiredField.map((itm, index) => (
              <div
                className={`h-auto lg:h-[55px] rounded-lg flex flex-wrap lg:flex-nowrap items-center justify-between gap-3 p-3 ${itm.profileValue && !itm.isExpired ? 'bg-[#F6F7FB] text-[#122B46]' : 'bg-[#E800000D] text-[#E80000]'}`}
                key={itm.value + index}
              >
                <p>{itm.title}</p>

                {itm.profileValue && !itm.isExpired ? (
                  <span>{itm.profileValue}</span>
                ) : itm.isTextField ? (
                  <TextField
                    isHorse={!!horse?.id}
                    horseId={horse?.id}
                    keyValue={itm.value}
                    userId={user?.id}
                    cb={(value) => {
                      const newRequiredFields = [...requiredField]
                      newRequiredFields[index] = {
                        ...requiredField[index],
                        profileValue: value,
                      }
                      setRequiredField(newRequiredFields)
                      setErrorsCount((v) => v - 1)
                    }}
                    title={itm.profileValue}
                  />
                ) : (
                  <div className={'flex gap-2'}>
                    <span>{itm.isExpired ? 'Expired' : 'Missing'}</span>
                    <span>•</span>
                    <button
                      onClick={() => editHandler(itm, horse)}
                      className={'underline hover:no-underline'}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {isDeleteBtnHide ? (
        <div className="w-5" />
      ) : (
        <button
          className={'transition-all hover:opacity-55 active:opacity-20 mt-6'}
          onClick={removeHandler}
        >
          <IconDelete2 />
        </button>
      )}
    </div>
  )
}
