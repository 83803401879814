import React, { FC } from 'react'

import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'
import clsx from 'clsx'
import Select, { components } from 'react-select'
import { Controller } from 'react-hook-form'

import Input from '../../../../../common/inputs/Input'
import TogglesElement from '../../../../../elements/toggles/toggles/TogglesElement'
import AmountInput from '../../../../../common/inputs/AmountInput'
import { PriceWithPercent } from '../../../../common/PriceWithPercent/PriceWithPercent'
import { NoteField } from '../NoteField/NoteField'

import { DurationList } from '../../../../../../fakeData/durationList'
import { customStyles } from '../../../../../customStyles/ReactSelectCustomStyle'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'

export type IFeeSimpleName =
  | 'shavingFees'
  | 'strawFees'
  | `extraBeddingFees.${number}`
  | 'coastalHay'
  | 'timothyHay'
  | 'alfalfa'
  | 'TnA'
  | `extraFeedFees.${number}`
  | 'paddockFee'
  | 'RVHookupFee'
  | `extraOtherFees.${number}`
  | 'temporaryStallsFee'
  | 'permanentStallsFee'
  | 'privateStallsFee'
  | 'circuitStallsFee'
  | 'muckFee'
  | `extraStallFees.${number}`

interface EventFeeSimpleProps {
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  watch: UseFormWatch<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  label?: string
  name: IFeeSimpleName
  onExtraRemove?: () => void
  withHeader?: boolean
  getDurationSelectedValue: (value: string) => { label: string; value: string } | undefined
  dataIncrementAttrProps: { [key: string]: string }
}

export const EventFeeSimple: FC<EventFeeSimpleProps> = ({
  control,
  watch,
  setValue,
  register,
  label,
  name,
  onExtraRemove,
  withHeader,
  getDurationSelectedValue,
  dataIncrementAttrProps,
}) => {
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      <div className="w-full lg:w-[200px] flex items-center gap-1">
        {onExtraRemove ? (
          <Input
            className="!h-12 px-4"
            register={register}
            placeholder="Enter Name..."
            valid={watch(`${name}.status`)}
            name={`${name}.name`}
            clear={!watch(`${name}.status`)}
            value={watch(`${name}.title`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
            disabled={!watch(`${name}.status`)}
          />
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor">{label}</div>
        )}
      </div>
      <div className="w-[120px] flex items-end">
        <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
        <Controller
          name={`${name}.status`}
          control={control}
          render={({ field: { value } }) => (
            <TogglesElement
              on={value}
              onToggle={() => setValue(`${name}.status`, !watch(`${name}.status`))}
            />
          )}
        />
      </div>
      <div className="flex-1 min-w-[120px] lg:max-w-[200px]">
        {withHeader && (
          <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
            Units available
          </div>
        )}
        <div className="relative">
          <Input
            className="!h-12 px-4"
            register={register}
            placeholder="Enter Units available..."
            valid={watch(`${name}.status`)}
            name={`${name}.available`}
            clear={!watch(`${name}.status`)}
            value={watch(`${name}.available`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
            disabled={!watch(`${name}.status`)}
          />
        </div>
      </div>
      <div className="flex-1 min-w-[150px] lg:max-w-[200px]">
        {withHeader && (
          <div
            className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"
            {...dataIncrementAttrProps}
          >
            Price per unit
          </div>
        )}
        <div className="relative">
          <AmountInput
            name={`${name}.actualCostAlias`}
            disable={!watch(`${name}.status`)}
            register={register}
            prefix="$"
            clear={!watch(`${name}.status`)}
            placeholder="$0"
            childWidth={24}
            value={watch(`${name}.actualCostAlias`)}
            className="!h-[48px] flex"
            inputClassName="px-4"
            valid={watch(`${name}.status`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
          />
          <PriceWithPercent
            status={watch(`${name}.status`)}
            className="bottom-[14px] translate-y-0"
            price={watch(`${name}.actualCostAlias`)}
            percent={+dataIncrementAttrProps['data-increment-by'] || 0}
          />
        </div>
      </div>
      <div className="flex-1 min-w-[170px] lg:max-w-[200px]">
        {withHeader && (
          <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
            Duration
          </div>
        )}
        <div
          className={clsx(
            watch(`${name}.status`)
              ? 'bg-SeabiscuitGrayThemeColor'
              : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
            'text-[14px] rounded-md flex items-center pl-2 h-12'
          )}
        >
          {watch(`${name}.status`) ? (
            <Controller
              control={control}
              name={`${name}.duration`}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Select
                    isMulti={false}
                    onChange={onChange}
                    isDisabled={!watch(`${name}.status`)}
                    components={{
                      DropdownIndicator: (props) => {
                        return <components.DropdownIndicator {...props} />
                      },
                      IndicatorSeparator: () => null,
                    }}
                    value={getDurationSelectedValue(value)}
                    onBlur={onBlur}
                    placeholder="Select Duration"
                    options={DurationList}
                    getOptionLabel={(props: any) => {
                      const { dark_icon, label } = props
                      return (
                        <div className="flex items-center gap-2">
                          {dark_icon && (
                            <img src={dark_icon} width="16px" height="16px" alt="icons" />
                          )}
                          <span>{label}</span>
                        </div>
                      ) as unknown as string
                    }}
                    className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={true}
                  />
                )
              }}
            />
          ) : (
            <p className="text-[#8D97A1] pl-2">Select Duration</p>
          )}
        </div>
      </div>
      <NoteField
        name={name}
        watch={watch}
        register={register}
        setValue={setValue}
        withHeader={withHeader}
      />
      {onExtraRemove && (
        <button onClick={onExtraRemove}>
          <div className="cursor-pointer">
            <img src="/assets/og_icons/Cancel.svg" className="h-[24px] w-[24px]" alt="Cancel" />
          </div>
        </button>
      )}
    </div>
  )
}
