import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { INewsInterface } from './news.interface'
import * as yup from 'yup'

import { CONST } from '../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const NEWS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.NEWS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class NewsModel extends ModelBaseModel<INewsInterface> implements IModelBaseModel {
  // Vars
  public v: INewsInterface['v']
  public id: INewsInterface['id']
  public creatorId: INewsInterface['creatorId']
  public pictures: INewsInterface['pictures']
  public title: INewsInterface['title']
  public url: INewsInterface['url']
  public titleNGrams: INewsInterface['titleNGrams']
  public creatorType: INewsInterface['creatorType']
  public headLine: INewsInterface['headLine']
  public status: INewsInterface['status']
  public description: INewsInterface['description']
  public creatorName: INewsInterface['creatorName']
  public creatorNameNGrams: INewsInterface['creatorNameNGrams']
  public creatorPicture: INewsInterface['creatorPicture']
  public created: INewsInterface['created']
  public modified: INewsInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj?: INewsInterface) {
    super()
    this.id = obj?.id ?? NEWS_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.v = obj?.v ?? NEWS_COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.creatorId = obj?.creatorId ?? NEWS_COLLECTION.FIELDS.CREATOR_ID.VALUES.DEFAULT
    this.pictures = obj?.pictures ?? NEWS_COLLECTION.FIELDS.PICTURES.VALUES.DEFAULT
    this.url = obj?.url ?? NEWS_COLLECTION.FIELDS.URL.VALUES.DEFAULT
    this.title = obj?.title ?? NEWS_COLLECTION.FIELDS.TITLE.VALUES.DEFAULT
    this.titleNGrams = this.getCalculatedNGrams(obj?.title)
    this.creatorType = obj?.creatorType ?? NEWS_COLLECTION.FIELDS.CREATOR_TYPE.VALUES.DEFAULT
    this.headLine = obj?.headLine ?? NEWS_COLLECTION.FIELDS.HEAD_LINE.VALUES.DEFAULT
    this.status = obj?.status ?? NEWS_COLLECTION.FIELDS.STATUS.VALUES.DEFAULT
    this.description = obj?.description ?? NEWS_COLLECTION.FIELDS.DESCRIPTION.VALUES.DEFAULT
    this.creatorName = obj?.creatorName ?? NEWS_COLLECTION.FIELDS.CREATOR_NAME.VALUES.DEFAULT
    this.creatorNameNGrams = this.getCalculatedNGrams(obj?.creatorName)
    this.creatorPicture =
      obj?.creatorPicture ?? NEWS_COLLECTION.FIELDS.CREATOR_PICTURE.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.created!,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      value: obj?.modified!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new NewsModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object().shape({
      url: yup.string().url().nullable(),
      pictures: yup.array(yup.string()).required(),
      title: yup.string().typeError('title is a required field').required(),
      description: yup.string().typeError('description is a required field').required(),
      status: yup
        .string()
        .typeError('status is a required field')
        .oneOf(['live', 'draft'])
        .required(),
      headLine: yup
        .string()
        .typeError('head Line is a required field')
        .required('head Line is a required field'),
    })
  }
}
