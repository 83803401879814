import React from 'react'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { CommentsQuestion } from '../../../organizer/manage/clinic/components/CommentsQuestion'
import { IEventInterface, IQuestion } from '../../../../models/events/event.interface'
import { useAppDispatch } from '../../../../store/hooks'
import { setRegistrationAnswers } from '../../../../store/registration/registrationSlice'
import { IAnswer } from '../../../../models/event-registered-users/event-registered-users.interface'

interface IProps {
  event: IEventInterface | null | undefined
  comments: IAnswer[]
}

export const CompetitorEventRegisterComments: React.FC<IProps> = ({ event, comments }) => {
  const dispatch = useAppDispatch()
  const newComments = [...comments]

  const handlerChangeComment = (value: string, question: IQuestion, index: number) => {
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex >= 0) {
      newComments[currentCommentIndex] = {
        ...newComments[currentCommentIndex],
        answer: value,
      }

      dispatch(setRegistrationAnswers(newComments))
      return null
    }

    const newData = [...newComments]

    newData.push({
      answer: value,
      id: `${new Date().getTime()}`,
      questionId: question.id,
      files: [],
    })

    dispatch(setRegistrationAnswers(newData))
    return null
  }

  const addFile = (value: string, question: IQuestion, index: number) => {
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex >= 0) {
      newComments[currentCommentIndex] = {
        ...newComments[currentCommentIndex],
        files: [...newComments[currentCommentIndex].files, value],
      }

      dispatch(setRegistrationAnswers(newComments))
      return null
    }

    const newData = [...newComments]

    newData.push({
      answer: '',
      id: `${new Date().getTime()}`,
      questionId: question.id,
      files: [value],
    })

    dispatch(setRegistrationAnswers(newData))
    return null
  }

  const removeFile = (index: number, question: IQuestion) => {
    const currentCommentIndex = newComments.findIndex((c) => c.questionId === question.id)

    if (currentCommentIndex < 0) {
      return null
    }
    const newData = [...newComments]

    const newFiles = [...newData[currentCommentIndex].files]
    newFiles.splice(index, 1)

    newData[currentCommentIndex] = {
      ...newData[currentCommentIndex],
      files: newFiles,
    }

    dispatch(setRegistrationAnswers(newData))
  }
  const renderBody = () => {
    if (!event) {
      return <>No event data</>
    }

    if (!Array.isArray(event.questions) || !event.questions[0]) {
      return <h3>No questions</h3>
    }

    return event.questions?.map((question, index) => {
      const currentComment = comments.find((c) => c.questionId === question.id)

      return (
        <div key={question.id} className={'flex gap-8 w-full mt-8'}>
          <CommentsQuestion
            isRequired={question.isRequired}
            addFile={(v) => addFile(v, question, index)}
            files={currentComment?.files || []}
            questionId={question.id}
            defaultValue={currentComment?.answer || ''}
            handleChange={(value) => handlerChangeComment(value, question, index)}
            title={question.value}
            removeFile={(v, i) => removeFile(i, question)}
          />
        </div>
      )
    })
  }

  return (
    <CompetitorEventRegisterWrapper
      title={'Comments'}
      description={'Add comments for show management'}
    >
      {renderBody()}
    </CompetitorEventRegisterWrapper>
  )
}
