import React, { FC } from 'react'

interface EventFeeActionsProps {
  onAdd: () => void
  onClearAll: () => void
}
export const EventFeeActions: FC<EventFeeActionsProps> = ({ onAdd, onClearAll }) => {
  return (
    <div className="flex items-center gap-2 justify-between">
      <button
        onClick={onAdd}
        className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2 hover:opacity-70 transition-all"
      >
        <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
        <span>Add</span>
      </button>
      <button
        onClick={onClearAll}
        className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
      >
        <span>Clear All</span>
      </button>
    </div>
  )
}
