import React, { FC, useContext } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'
import { v4 as uuidv4 } from 'uuid'

import { Subheader } from '../Subheader/Subheader'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'
import { EventFeeSimple } from '../EventFeeSimple/EventFeeSimple'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'

interface EventFeesFeedProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
  dataIncrementAttrProps: { [key: string]: string }
  getDurationSelectedValue: (value: string) => { label: string; value: string } | undefined
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
export const EventFeesFeed: FC<EventFeesFeedProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
  dataIncrementAttrProps,
  getDurationSelectedValue,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraFeedFees = useFieldArray({
    control,
    name: 'extraFeedFees',
  })

  const onAdd = () => {
    extraFeedFees.append({
      status: false,
      category: 'feed',
      title: '',
      available: 0,
      sold: 0,
      actualCostAlias: '$0',
      duration: '',
      note: '',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraFeedFees.remove()
    reset({
      ...getValues(),
      coastalHay: eventFeesFormDefaultValues.coastalHay,
      timothyHay: eventFeesFormDefaultValues.timothyHay,
      alfalfa: eventFeesFormDefaultValues.alfalfa,
      TnA: eventFeesFormDefaultValues.TnA,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraFeedFees.remove(index)
      },
    })
  }

  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader title="Feed" subtitle="Add feed options for this event" />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Coastal hay"
        name="coastalHay"
        withHeader
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Timothy hay"
        name="timothyHay"
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Alfalfa hay"
        name="alfalfa"
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="TnA hay"
        name="TnA"
      />
      {extraFeedFees.fields.map((field, index) => (
        <EventFeeSimple
          key={field.id}
          getDurationSelectedValue={getDurationSelectedValue}
          dataIncrementAttrProps={dataIncrementAttrProps}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraFeedFees.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
