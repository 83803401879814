// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import UserLoginViewComponentProps from '../../../components/user/views/login/UserLoginViewComponentProps'
import { CONST } from '../../../const/const'
import FirebaseApp from '../../../services/firebaseApp'
import { useAppDispatch } from '../../../store/hooks'
import { storeUseMode } from '../../../store/system/systemThunk'
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'

import PegasusLogo from '../../../icons/logo/Logo.svg'
import { UserModel } from '../../../models/users/user.model'
import firestoreService from '../../../services/firestoreService'
// import { storeDisplayName } from "../../../store/user/userThunk";
// import {UserLoginViewComponent} from "../../../components/user/views/login/UserLoginViewComponent";
// import UserSignupViewComponent from "../../../components/user/views/sign-up/UserSignupViewComponent";
import useToasterHelper from '../../../helpers/ToasterHelper'

import FIREBASE_CONST from '../../../const/firebase-const'
import './UserLoginPage.css'
import { CustomError, authHelpers, getUserFullName } from '../../../helpers/helpers'
import { httpService } from '../../../services/httpService'
import { IonContent, IonPage } from '@ionic/react'

// Inside AuthProvider
const provider = new GoogleAuthProvider()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export const UserLoginPage = ({ handleModal }: { handleModal: any }) => {
  // Hooks and vars
  let isMounted = true

  const history = useHistory()
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMounted = false
    }
  }, [])

  const handlfailedAttemptsend = async (email: string) => {
    const res = await httpService({
      url: 'send_failed_login_attempts',
      method: 'POST',
      data: {
        emailId: email,
      },
    })

    if (res) {
      toastFunctions.info({ message: 'Maximum limits mail send' })
    }
  }

  const handlefailLoginAttempts = (data: any) => {
    const LOGIN_ATTEMPT = 'loginAttempts'
    let loginAttemptsDetailsArray: string | Record<any, any>

    if (LOGIN_ATTEMPT in localStorage) {
      loginAttemptsDetailsArray = localStorage.getItem(LOGIN_ATTEMPT) ?? '{}'
      loginAttemptsDetailsArray = JSON.parse(loginAttemptsDetailsArray)

      if (Array.isArray(loginAttemptsDetailsArray) && loginAttemptsDetailsArray.length >= 1)
        handlfailedAttemptsend(loginAttemptsDetailsArray[0].email)
    } else {
      loginAttemptsDetailsArray = [{ email: data.email, attempts: 1 }]
      localStorage.setItem(LOGIN_ATTEMPT, JSON.stringify(loginAttemptsDetailsArray))
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleOnLoginValid = async (data: any) => {
    setLoading(true)

    try {
      const emailOrError = await authHelpers.getEmail(data.email)

      if (emailOrError instanceof CustomError) {
        setLoading(false)
        return toastFunctions.error({ message: emailOrError.message })
      }

      const user = await signInWithEmailAndPassword(
        FirebaseApp.auth,
        emailOrError as any,
        data.password
      )

      if (user) {
        const user_data_doc = await firestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setEmail(userData.userEmail?.trim()))
        dispatch(setUsername(userData.userName?.trim()))
        dispatch(setDisplayName(getUserFullName(userData)))
        dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          history.replace('/home', { direction: 'none' })
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, { direction: 'none' })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))
      }
    } catch (error: any) {
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND)
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD) {
        handlefailLoginAttempts(data)
        return toastFunctions.error({ message: 'Credentials are incorrect' })
      }
      if (error.code === FIREBASE_CONST.TOO_MANY_REQUESTS) {
        handlefailLoginAttempts(data)
        return toastFunctions.error({ message: 'Firebase login Maximum attempts reached' })
      }

      toastFunctions.error({ message: 'Something went wrong' })
    } finally {
      if (isMounted) setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleLoginInvalid = async () => {}

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleSignInWithGoogle = async () => {
    signInWithPopup(FirebaseApp.auth, provider)
      .then(() => {
        setIsLoggedIn(true)
        history.replace('/', { direction: 'none' })
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.log(error.message, error.email, error.code, credential, 'err')
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonPage>
      <IonContent>
        <motion.div
          className="w-full mx-auto h-full !bg-white "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 20,
            delay: 0.7,
          }}
        >
          {/*<UserSignupViewComponent />*/}

          <UserLoginViewComponentProps
            loading={loading}
            onLoginValid={handleOnLoginValid}
            onLoginInvalid={handleLoginInvalid}
            onSignInWithGoogleButtonPressed={() => handleSignInWithGoogle()}
            onSignUpButtonPressed={() => {
              history.push(CONST.ROUTES.SIGNUP.URL)
            }}
            logo={PegasusLogo}
            handleModal={handleModal}
            logoAlt="Pegasus"
          />
        </motion.div>
      </IonContent>
    </IonPage>
  )
}

export default UserLoginPage
