import * as yup from 'yup'

import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IEventReviewPublish } from './event-review-publish.interface'
import EventPaymentSettingsModel from '../event-payment-settings/event-payment-settings.model'

export class EventReviewPublishModel
  extends ModelBaseModel<IEventReviewPublish>
  implements IModelBaseModel
{
  public v: number
  public id: IEventReviewPublish['id']
  public title: IEventReviewPublish['title']
  public owner: IEventReviewPublish['owner']
  public status: IEventReviewPublish['status']
  public EventFees: IEventReviewPublish['EventFees']
  public EventStaff: IEventReviewPublish['EventStaff']
  public EventNearby: IEventReviewPublish['EventNearby']
  public EventTickets: IEventReviewPublish['EventTickets']
  public EventVendors: IEventReviewPublish['EventVendors']
  public EventDetails: IEventReviewPublish['EventDetails']
  public EventPolicies: IEventReviewPublish['EventPolicies']
  public EventSchedule: IEventReviewPublish['EventSchedule']
  public EventSponsors: IEventReviewPublish['EventSponsors']
  public EventPaperwork: IEventReviewPublish['EventPaperwork']
  public briefDescription: IEventReviewPublish['briefDescription']
  public EventPaymentSettings: IEventReviewPublish['EventPaymentSettings']
  public EventRequiredFields: IEventReviewPublish['EventRequiredFields']
  public EventQuestions: IEventReviewPublish['EventQuestions']

  public created: Date
  public modified: Date

  public constructor(obj?: IEventReviewPublish) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.title = obj?.title ?? ''
    this.briefDescription = obj?.briefDescription ?? ''
    this.EventDetails = obj?.EventDetails ?? {}
    this.EventStaff = obj?.EventStaff ?? {}
    this.EventSchedule = obj?.EventSchedule ?? {}
    this.EventFees = obj?.EventFees ?? {}
    this.EventPolicies = obj?.EventPolicies ?? {}
    this.EventPaperwork = obj?.EventPaperwork ?? {}
    this.EventTickets = obj?.EventTickets ?? {}
    this.EventSponsors = obj?.EventSponsors ?? {}
    this.EventVendors = obj?.EventVendors ?? {}
    this.EventRequiredFields = obj?.EventRequiredFields ?? {
      requiredFields: [],
      requiredHorseFields: [],
    }
    this.EventQuestions = obj?.EventQuestions ?? {}

    this.EventPaymentSettings =
      obj?.EventPaymentSettings ?? new EventPaymentSettingsModel().toFirestore()
    this.EventNearby = obj?.EventNearby ?? {}
    this.status = obj?.status ?? null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  static fromFirestoreDoc(doc: any) {
    return new EventReviewPublishModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object({}).required()
  }
}
