import React, { FC, useEffect, useState } from 'react'

import {
  FieldErrors,
  UseFieldArrayReturn,
  UseFieldArrayUpdate,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'

import Input from '../../../../common/inputs/Input'
import MessageHelperComp from '../../../../../helpers/MessageHelper'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

import { IEventScheduleInterface } from '../../../../../models/event-scheduling/event-scheduling.interface'
import { useDebounceWithSetter } from '../../../../../hooks/useDebounceWithSetter'
import Accordion from '../../../../accordion/common/Accordion'
import { v4 as uuidv4 } from 'uuid'
import { AddCircleOutline } from '@mui/icons-material'
import { clearAllArr } from '../../../../../commonHelpers/helpers'

interface FacilitiesProps {
  watch: UseFormWatch<IEventScheduleInterface>
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  facilities: UseFieldArrayReturn<IEventScheduleInterface, 'facilities'>
  update: UseFieldArrayUpdate<any, 'custom'>
  fields: Record<'id', string>[]
  setLocationOptions: (value: { label: string; value: string }[]) => void
  errors: FieldErrors<IEventScheduleInterface>
  setValue: UseFormSetValue<IEventScheduleInterface>
  register: UseFormRegister<IEventScheduleInterface>
}
export const Facilities: FC<FacilitiesProps> = ({
  watch,
  handleModal,
  facilities,
  update,
  fields,
  setLocationOptions,
  errors,
  setValue,
  register,
}) => {
  const [listingRemove, setListingRemove] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState(true)

  const removeFacility = (index: number) => {
    handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_MODAL, {
      confirmDelete: () => {
        let value = facilities.fields[index]
        let indexsOfCustomToUpdate: number[] = []

        fields.forEach((curr, index) => {
          if ((curr as any)?.location === (value as any)?.facility)
            indexsOfCustomToUpdate.push(index)
        })

        if (indexsOfCustomToUpdate.length) {
          indexsOfCustomToUpdate.forEach((i) => {
            update(i, {
              ...fields[i],
              location: null,
            })
          })
        }
        facilities.remove(index)
        setListingRemove(!listingRemove)
        handleModal(false, MODAL_CONSTS.CONFIRM_REMOVE_MODAL)
      },
      removeCardId: index,
    })
  }

  const handleInputChange = (e: any, index: any) => {
    // Call your debounceSetter function here
    debounceSetter({ index, value: e.target.value })
  }

  const { debounceSetter, debouncedValue } = useDebounceWithSetter({
    delay: 300,
    value: null,
    functionToFireOnDelay: updateFields,
  })

  function updateFields() {
    let index = debouncedValue?.index ?? -1
    let value = debouncedValue?.value ?? ''
    let customScheduleFields = watch().custom
    let locationsSet: Set<string> = new Set()

    if (watch().facilities && watch().facilities?.length) {
      const locations = watch().facilities?.reduce((acc: any, current: any, currIndex: number) => {
        if (!!current.facility || currIndex === index) {
          if (currIndex === index) {
            acc.push({
              label: value,
              value: value,
            })
            locationsSet.add(value)
          } else {
            acc.push({
              label: current.facility,
              value: current.facility,
            })
            locationsSet.add(current.facility)
          }
        }
        return acc
      }, [])

      setLocationOptions([...locations])
      setValue(
        'custom',
        customScheduleFields?.map((currCustomScheduleField: any) => {
          return {
            ...currCustomScheduleField,
            location: locationsSet.has(currCustomScheduleField.location)
              ? currCustomScheduleField.location
              : '',
          }
        })
      )
    } else {
      setValue(
        'custom',
        customScheduleFields?.map((currCustomScheduleField: any) => {
          return {
            ...currCustomScheduleField,
            location: '',
          }
        })
      )
      setLocationOptions([])
    }
  }

  useEffect(() => {
    updateFields()
  }, [facilities.fields, listingRemove, debouncedValue])

  return (
    <>
      <Accordion
        onClose={() => setIsOpen(true)}
        onOpen={() => setIsOpen(false)}
        header="Facilities"
        info={`${facilities.fields.length ?? 0} facilities`}
      >
        {facilities.fields.map((field, index) => (
          <div key={field.id} className="mb-3">
            <div className="relative flex items-center">
              <Input
                placeholder="Eg. Ring 1, Secretary’s Office"
                register={register}
                name={`facilities.${index}.facility`}
                onChange={(e) => handleInputChange(e, index)}
                className="px-6 pr-11 py-3 !rounded-lg relative"
                valid={!!watch(`facilities.${index}.facility`)}
                value={watch(`facilities.${index}.facility`) as any}
                validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              />
              {index !== 0 && (
                <div
                  className="absolute z-10 right-3 top-1/2 -translate-y-1/2 w-6 flex items-center cursor-pointer hover:opacity-70"
                  onClick={() => removeFacility(index)}
                >
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    alt="remove"
                    className="w-full h-full cursor-pointer"
                  />
                </div>
              )}
            </div>
            {!!errors?.facilities?.[index]?.facility?.message && (
              <MessageHelperComp
                isError={true}
                message={errors?.facilities?.[index]?.facility?.message}
              />
            )}
          </div>
        ))}
        <div className="mt-2 flex gap-4 items-center justify-between">
          <button
            className="text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2 text-sm group"
            onClick={() => {
              facilities.append({
                uuid: uuidv4(),
                facility: '',
              })
            }}
          >
            <AddCircleOutline className="!w-5" />
            <span className="underline group-hover:no-underline">Add Facility</span>
          </button>

          <button
            onClick={() => {
              facilities.remove(clearAllArr(facilities.fields.length, 0))
            }}
            className="text-SeabiscuitDark200ThemeColor/50 text-sm underline hover:no-underline"
          >
            Clear All
          </button>
        </div>
      </Accordion>
      <div className="mt-1">
        {!isOpen &&
          errors?.facilities &&
          errors.facilities.length &&
          errors.facilities.length > 0 && (
            <MessageHelperComp isError={true} message="Facility is a required field" />
          )}
      </div>
    </>
  )
}
