import React, { FC, useContext } from 'react'

import { useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'

import { Subheader } from '../Subheader/Subheader'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'
import { EventFeeSimple } from '../EventFeeSimple/EventFeeSimple'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'

interface EventFeesBeddingProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
  dataIncrementAttrProps: { [key: string]: string }
  getDurationSelectedValue: (value: string) => { label: string; value: string } | undefined
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
export const EventFeesBedding: FC<EventFeesBeddingProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
  dataIncrementAttrProps,
  getDurationSelectedValue,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraBeddingFees = useFieldArray({
    control,
    name: 'extraBeddingFees',
  })

  const onAdd = () => {
    extraBeddingFees.append({
      status: false,
      category: 'bedding',
      title: '',
      available: 0,
      sold: '0',
      actualCostAlias: '',
      duration: '',
      note: '',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraBeddingFees.remove()
    reset({
      ...getValues(),
      shavingFees: eventFeesFormDefaultValues.shavingFees,
      strawFees: eventFeesFormDefaultValues.strawFees,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraBeddingFees.remove(index)
      },
    })
  }

  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader title="Bedding" subtitle="Add bedding options for this event" />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Shaving"
        name="shavingFees"
        withHeader
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Straw"
        name="strawFees"
      />
      {extraBeddingFees.fields.map((field, index) => (
        <EventFeeSimple
          key={field.id}
          getDurationSelectedValue={getDurationSelectedValue}
          dataIncrementAttrProps={dataIncrementAttrProps}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraBeddingFees.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
