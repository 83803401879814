import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { IconGuardian } from '../../../../components/icons/IconGuardian'

interface GuardianBadgeProps {
  classes?: string
  isLabel?: boolean
  isGuardianIsMinor?: boolean
  hasGuardian: boolean
}

export const GuardianBadge: React.FC<GuardianBadgeProps> = ({
  classes,
  isLabel,
  hasGuardian,
  isGuardianIsMinor,
}) => {
  const [message, setMessage] = useState('')

  const isValidGuardian = hasGuardian && !isGuardianIsMinor

  useEffect(() => {
    if (isLabel) {
      setMessage('Guardian required')
    } else if (hasGuardian && isGuardianIsMinor) {
      setMessage('The guardian is a minor and requires the presence of a legal guardian.')
    } else if (hasGuardian) {
      setMessage('Guardian added')
    } else {
      setMessage(
        'This rider is a minor and requires a legal guardian. Select a guardian from the list.'
      )
    }
  }, [hasGuardian, isGuardianIsMinor, isLabel])

  return (
    <div
      className={clsx(
        `inline-flex items-center gap-1 font-normal rounded-[6px]`,
        classes,
        isLabel ? 'text-[10px] h-5 px-2' : 'text-[12px] px-4 py-2',
        isValidGuardian ? 'text-[#00B6AA] bg-[#00B6AA1A]' : 'text-[#E06C30] bg-[#FCEFE7]'
      )}
    >
      {isLabel && <IconGuardian color={isValidGuardian ? '#00B6AA' : '#E06C30'} />}
      <span>{message}</span>
    </div>
  )
}
