import React from 'react'

interface IProps {
  color?: string
}

export const IconGuardian: React.FC<IProps> = ({ color = '#E06C30' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none" viewBox="0 0 10 12">
      <path
        fill={color}
        d="M4.985.75A.38.38 0 0 0 4.77.83c-.392.304-.692.455-1.158.55s-1.102.12-2.08.12a.38.38 0 0 0-.297.145l-.656.844a.375.375 0 0 0 .049.513s.622.54.622 1.499c0 .479-.134.649-.331 1.019S.5 6.413.5 7.312c0 1.349 1.186 3.183 4.416 3.928a.4.4 0 0 0 .169 0C8.315 10.495 9.5 8.66 9.5 7.312c0-.899-.222-1.423-.419-1.793s-.33-.54-.33-1.02c0-.958.621-1.498.621-1.498a.375.375 0 0 0 .033-.531l-.75-.844a.38.38 0 0 0-.28-.126c-.931 0-1.544-.026-1.997-.121s-.756-.246-1.147-.55A.38.38 0 0 0 4.985.75M5 1.566c.378.258.762.45 1.224.547.509.107 1.15.122 1.973.126l.441.496C8.385 3.028 8 3.561 8 4.5c0 .646.241 1.038.419 1.371.178.334.331.653.331 1.441 0 .893-.815 2.467-3.75 3.173-2.935-.706-3.75-2.28-3.75-3.173 0-.788.153-1.107.331-1.44.178-.334.42-.726.42-1.372 0-.958-.402-1.497-.652-1.78l.375-.482c.857-.004 1.522-.02 2.039-.125.471-.096.858-.29 1.237-.547M5 3.76a.24.24 0 0 0-.224.128l-.51 1.034-1.142.166a.25.25 0 0 0-.139.426l.826.806-.195 1.136a.25.25 0 0 0 .363.264L5 7.184l1.02.537a.25.25 0 0 0 .264-.019.25.25 0 0 0 .1-.245l-.195-1.136.826-.806a.25.25 0 0 0-.138-.426l-1.142-.166-.51-1.034A.24.24 0 0 0 5 3.761"
      ></path>
    </svg>
  )
}
