import { ModelBaseModel } from '../../../models/model-base/model-base.model'
import BillsHeader from './common/BillsHeader'
import { CONST } from '../../../const/const'

// Types

type Props = {
  data: any
  modalThemeColor: `#${string}`
  showRefund: boolean
  refundgovernanceTotalState: number
}

type ITotalRow = {
  numbersOfUnit: string
  price: string
  modalThemeColor: `#${string}`
  scratched: number
  refunded: number
  showRefund: boolean
  refundgovernanceTotalState: number
}

type IEventReviewPublishInput = {
  className?: string
  data?: any
  price?: any
  onClick?: any
  moreBtn?: boolean
  icon?: string
  style?: React.CSSProperties
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const EventReviewPublishInput: React.FC<IEventReviewPublishInput> = (args) => {
  const { className, data, price, onClick, moreBtn, icon, style = {} } = args

  return (
    <>
      {onClick ? (
        <div
          onClick={onClick}
          style={style}
          className={`${className} text-SeabiscuitDark200ThemeColor`}
        >
          <div className="text-SeabiscuitDark200ThemeColor mr-1 font-normal">{price}</div>
          <div>{data}</div>
          {moreBtn ? <span className="cursor-pointer underline">more</span> : null}
        </div>
      ) : (
        <div className={`${className} text-SeabiscuitDark200ThemeColor`} style={style}>
          {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
          <div className="text-SeabiscuitDark200ThemeColor mr-1 font-normal">{price}</div>
          <div>{data}</div>
          {moreBtn ? <span className="cursor-pointer underline">more</span> : null}
        </div>
      )}
    </>
  )
}

const DetailRow = ({
  item,
  numberOfUnit,
  cost,
  amountScratched,
  showRefund,
  amountRefunded,
  paymentStatus,
}: {
  item: string
  numberOfUnit: string
  cost: string
  amountScratched: number
  amountRefunded: number
  showRefund: boolean
  paymentStatus: string
}) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        className={`rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'}`}
        data={item}
      />
      <EventReviewPublishInput
        className={`rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'}`}
        data={numberOfUnit}
      />
      <EventReviewPublishInput
        className={`rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'}`}
        data={`$${cost}`}
        icon={
          paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/img/dark/credit-card.svg'
            : '/assets/img/dark/cash.svg'
        }
      />
      {showRefund && (
        <>
          {' '}
          <EventReviewPublishInput
            className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
            data={`${amountScratched > 0 ? 'Scratched' : 'No'}`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
            data={`$${amountRefunded}`}
          />
        </>
      )}
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TotalRow = (args: ITotalRow) => {
  const {
    numbersOfUnit,
    price,
    modalThemeColor,
    scratched,
    showRefund,
    refundgovernanceTotalState,
  } = args

  return (
    <>
      {showRefund ? (
        <>
          <div className="flex flex-col md:flex-row md:items-start w-full">
            <EventReviewPublishInput
              className={
                'rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-[21%] font-semibold bg-SeabiscuitGreenLightThemeColor'
              }
              // style={{
              //     backgroundColor: args.modalThemeColor
              // }}
              data={`Total`}
            />
            <EventReviewPublishInput
              className="rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-10/12 font-semibold flex md:justify-end bg-SeabiscuitGreenLightThemeColor"
              price={`$${price} paid, `}
              data={`$${refundgovernanceTotalState} refunded`}
              //${numbersOfUnit} items
              // style={{
              //     backgroundColor: args.modalThemeColor
              // }}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col md:flex-row md:items-start w-full">
          <EventReviewPublishInput
            className={`rounded-lg text-sm p-3 m-1 ml-0 ${!showRefund ? 'w-4/12' : ' w-3/12'} font-semibold`}
            data={`Total`}
            style={{
              background: modalThemeColor,
            }}
          />
          <EventReviewPublishInput
            className={`rounded-lg relative flex items-center justify-center text-sm p-3 m-1 ${!showRefund ? 'w-4/12' : ' w-3/12'} font-semibold`}
            data={`${numbersOfUnit} tickets`}
            style={{
              background: modalThemeColor,
            }}
          />
          <EventReviewPublishInput
            className={`rounded-lg relative flex items-center justify-center text-sm p-3 m-1 ${!showRefund ? 'w-4/12' : ' w-3/12'} font-semibold`}
            data={`$${price}`}
            style={{
              background: modalThemeColor,
            }}
          />
          {showRefund && (
            <>
              {' '}
              <EventReviewPublishInput
                className="rounded-lg relative flex items-center justify-center text-sm p-3 m-1 w-3/12 font-semibold"
                data={`${scratched} tickets`}
                style={{
                  background: modalThemeColor,
                }}
              />
              <EventReviewPublishInput
                className="rounded-lg relative flex items-center justify-center text-sm p-3 m-1 w-3/12 font-semibold"
                data={`$${scratched}`}
                style={{
                  background: modalThemeColor,
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const BillDetailsTicketsData = (props: Props) => {
  let ticketTotal = 0
  const { showRefund, refundgovernanceTotalState } = props

  return (
    <>
      {props?.data && props?.data?.length ? (
        <div className="flex items-center">
          <BillsHeader title="Item" tooltipText="Line item" />
          <BillsHeader title="Units" tooltipText="Number of units" />
          <BillsHeader title="Price per unit" tooltipText="Price per unit" />
          {showRefund && (
            <>
              {' '}
              <BillsHeader title="Scratched" tooltipText="Scratched" />
              <BillsHeader title="Refunded" tooltipText="Refunded" />
            </>
          )}
        </div>
      ) : null}

      {props?.data && props?.data?.length ? (
        props?.data.map((item: any, index: number) => {
          ticketTotal += item.ticketPrice * item.selectedUnitsCount
          return (
            <DetailRow
              key={`${JSON.stringify(item)}${index}`}
              item={item?.ticketTitle ?? ''}
              numberOfUnit={item?.selectedUnitsCount ?? 0}
              cost={item?.ticketPrice}
              amountScratched={item?.amountScratched}
              amountRefunded={item?.amountRefunded}
              paymentStatus={item?.paymentStatus}
              showRefund={showRefund}
            />
          )
        })
      ) : (
        <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
          No tickets selected
        </span>
      )}

      {props?.data && props?.data?.length ? (
        <TotalRow
          modalThemeColor={props.modalThemeColor}
          numbersOfUnit={
            Array.isArray(props?.data)
              ? props?.data.reduce((accumulator, currentValue) => {
                  return +accumulator + +currentValue.selectedUnitsCount
                }, 0)
              : 0
          }
          price={new ModelBaseModel().getNum(ticketTotal).toString()}
          scratched={0}
          refunded={0}
          showRefund={showRefund}
          refundgovernanceTotalState={refundgovernanceTotalState}
        />
      ) : null}
    </>
  )
}

export default BillDetailsTicketsData
