import { createSlice } from '@reduxjs/toolkit'
import { billsSliceInitialState } from './billsSlice.data'
import { IBillsSliceInitialStateInterface as ibsisi } from './billsSlice.types'
import { RootState } from '../store'

const billsSlice = createSlice({
  name: 'bills',
  initialState: billsSliceInitialState,
  reducers: {
    setUsers(state, { payload }: { payload: ibsisi['holder']['users'] }) {
      state.holder.users = payload
    },
    setEvents(state, { payload }: { payload: ibsisi['holder']['events'] }) {
      state.holder.events = payload
    },
    setPaidBills(state, { payload }: { payload: ibsisi['data']['paidBills'] }) {
      state.data.paidBills = payload
    },
    setUnPaidBills(state, { payload }: { payload: ibsisi['data']['unPaidBills'] }) {
      state.data.unPaidBills = payload
    },
    setSelectedEvent(state, { payload }: { payload: ibsisi['selected']['event'] }) {
      state.selected.event = payload
    },
    setRefundedBills(state, { payload }: { payload: ibsisi['data']['refundedBills'] }) {
      state.data.refundedBills = payload
    },
    setCountDetails(state, { payload }: { payload: ibsisi['selected']['countDetails'] }) {
      state.selected.countDetails = payload
    },
    setSelectedEventFees(state, { payload }: { payload: ibsisi['selected']['eventFees'] }) {
      state.selected.eventFees = payload
    },
    setSelectedRegistration(state, { payload }: { payload: ibsisi['selected']['registration'] }) {
      state.selected.registration = payload
    },
    setRegistrationCountDetails(
      state,
      { payload }: { payload: ibsisi['selected']['countDetails'] }
    ) {
      state.selected.countDetails = payload
    },
    setEventFees(state, { payload }: { payload: ibsisi['holder']['eventFees'] }) {
      state.holder.eventFees = payload
    },
  },
})

// Redux selectors
const selectUsers = (state: RootState) => state.bills.holder.users
const selectPaidBills = (state: RootState) => state.bills.data.paidBills
const selectUnPaidBills = (state: RootState) => state.bills.data.unPaidBills
const selectRefundedBills = (state: RootState) => state.bills.data.refundedBills
const selectCountDetails = (state: RootState) => state.bills.selected.countDetails

export { selectUsers, selectPaidBills, selectUnPaidBills, selectCountDetails, selectRefundedBills }

export const {
  setUsers,
  setEvents,
  setPaidBills,
  setEventFees,
  setUnPaidBills,
  setCountDetails,
  setRefundedBills,
  setSelectedEvent,
  setSelectedEventFees,
  setSelectedRegistration,
} = billsSlice.actions
export default billsSlice.reducer
