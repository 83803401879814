import { where } from 'firebase/firestore'
import { cloneDeep } from 'lodash'

import { getConvertedData } from '../../../../models/interface.helper'
import helpers from '../../../../commonHelpers/helpers'
import FirestoreService from '../../../../services/firestoreService'
import useToasterHelper from '../../../../helpers/ToasterHelper'

import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  removeHorse,
  selectHorses,
  selectMappings,
  setHorseLoaded,
  setHorses,
  setMappings,
} from '../../../../store/horses/horseSlice'

import { IHorseTeamInterface } from '../../../../models/horse-team/horseTeam.interface'
import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../../models/user-horse-mapping/userHorseMapping.model'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../models/horse/horse.model'

import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { HorseTeamModel } from '../../../../models/horse-team/horseTeam.model'
import { ITeamMember } from '../../../../models/users/user.interface'
import { selectProfileData } from '../../../../store/user/userSlice'

// Types
type IHorsesTeamMembers = {
  [horseId: string]: IHorseTeamInterface[]
}

type IRemoveHorseFromDbFnArgs = {
  forceRemove?: boolean
  dontShowToast?: boolean
  mappingDocId: IUserHorseMappingInterface['id']
}

type IRemoveHorseTeamMemberFnArgs = {
  teamMemberDocId: IHorseTeamInterface['id']
  mappingDocId: IUserHorseMappingInterface['id']
  dontShowToast?: boolean
}

type IRemoveHorseTeamMembersByIdArgs = {
  teamMemberDocIds: IHorseTeamInterface['id'][]
  mappingDocId: IUserHorseMappingInterface['id']
}

export interface ITeamMemberHorse extends IHorseData {
  user: ITeamMember | null | undefined
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const useHorses = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()

  const mappings = useAppSelector(selectMappings)
  const myHorses = useAppSelector(selectHorses)
  const profileDetails = useAppSelector(selectProfileData)

  const getHorseTeams = async (userId: string) => {
    const horsesTeams: IHorseTeamInterface[] = []

    try {
      const horsesTeamSnaps = await FirestoreService.filterItems(COLLECTIONS.HORSE_TEAM.NAME, [
        where(COLLECTIONS.HORSE_TEAM.FIELDS.USER_ID.KEY, '==', userId),
      ])

      horsesTeamSnaps.forEach((currDoc) => {
        horsesTeams.push(HorseTeamModel.fromFirestoreDoc(currDoc).toObject())
      })
      return horsesTeams
    } catch (error) {
      console.error(error, 'error')
      return []
    }
  }

  const getMergedHorsesTeams = (horsesTeams: IHorseTeamInterface[]) => {
    let horsesTeams_ = [...horsesTeams]
    let horseId: string | null = null
    let combinedHorsesTeamMembers: IHorsesTeamMembers

    combinedHorsesTeamMembers = horsesTeams_.reduce((acc, currHorseTeam) => {
      horseId = currHorseTeam.horseId

      if (!horseId) return acc

      acc[horseId] = [...(acc[horseId] ?? []), currHorseTeam]
      return acc
    }, {} as IHorsesTeamMembers)

    return combinedHorsesTeamMembers
  }

  async function getMyHorses(
    mapping: IUserHorseMappingInterface[],
    horsesTeams: IHorseTeamInterface[]
  ): Promise<IUserHorseMappingInterface[]> {
    let horses: IUserHorseMappingInterface[] = []
    let horsesTeams_ = [...horsesTeams]

    mapping.forEach((currMapping) => {
      horses.push({
        ...currMapping,
        teamMembers: [],
      })
    })

    const mergedHorsesTeams = getMergedHorsesTeams(horsesTeams_)

    horses = horses.map((currHorse) => {
      return getConvertedData({
        ...currHorse,
        teamMembers: currHorse.horseId ? (mergedHorsesTeams?.[currHorse.horseId] ?? []) : [],
      })
    })

    return horses
  }

  const loadHorsesFromDb = async (id: string) => {
    const { horsesTeamsMappings } = await getMapping(id)

    const horses = await getHorseAsPerMapping(horsesTeamsMappings)

    dispatch(setMappings(horsesTeamsMappings))
    dispatch(setHorses(horses))
    dispatch(setHorseLoaded(true))
  }

  async function getMapping(userId: string): Promise<{
    mappings_: IUserHorseMappingInterface[]
    horsesTeamsMappings: IUserHorseMappingInterface[]
  }> {
    let mappings_: IUserHorseMappingInterface[] = []

    try {
      let fetchMappingSnaps = await FirestoreService.filterItems(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        [
          where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.USER_ID.KEY, '==', userId),
          where(
            COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_SELECTED_FOR_COMPETETION.KEY,
            '==',
            COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_SELECTED_FOR_COMPETETION.VALUES.YES
          ),
        ]
      )
      fetchMappingSnaps.forEach((currDoc) => {
        mappings_.push(UserHorseMappingModel.fromFirestoreDoc(currDoc).toObject())
      })

      const horsesTeams = await getHorseTeams(userId)
      const horsesTeamsMappings = await getMyHorses(mappings_, horsesTeams)
      return { mappings_, horsesTeamsMappings }
    } catch (error) {
      console.error(error, 'error')
      helpers.logger({
        isError: true,
        message: error,
      })
      return { mappings_: [], horsesTeamsMappings: [] }
    }
  }

  const updateHorseTeamMember = async (args: { teamData: IHorseTeamInterface }) => {
    const { teamData } = args
    try {
      if (!teamData)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `data is empty`,
        })

      await FirestoreService.updateItem(COLLECTIONS.HORSE_TEAM.NAME, teamData.id, teamData)
    } catch (error) {
      toastFunction.success({
        message: `${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`,
      })

      helpers.logger({
        message: error,
      })
    }
  }

  const removeHorseTeamMember = async (args: IRemoveHorseTeamMemberFnArgs) => {
    const { mappingDocId, teamMemberDocId, dontShowToast } = args

    let mappings_ = cloneDeep(mappings)
    let mappingIndexInRedux: number
    let updatedTeamMembers: IHorseTeamInterface[]

    mappingIndexInRedux = mappings_.findIndex((mapping_) => mapping_.id === mappingDocId)
    updatedTeamMembers = cloneDeep(mappings_[mappingIndexInRedux].teamMembers) ?? []

    try {
      if (!mappings_[mappingIndexInRedux])
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappings_[${mappingIndexInRedux}] is empty`,
        })

      if (!teamMemberDocId)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `teamMemberDocId is empty`,
        })

      await FirestoreService.deleteItem(COLLECTIONS.HORSE_TEAM.NAME, teamMemberDocId)

      updatedTeamMembers = updatedTeamMembers?.filter(
        (teamMember) => teamMember.id !== teamMemberDocId
      )
      mappings_[mappingIndexInRedux].teamMembers = updatedTeamMembers ?? []

      if (!dontShowToast)
        toastFunction.success({
          message: MESSAGES_CONST.REMOVED_HORSE_TEAM_MEMBER,
        })
    } catch (error) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.log(error, 'error')
    } finally {
      dispatch(setMappings(mappings_))
    }
  }

  const removeHorseTeamMembersById = async (args: IRemoveHorseTeamMembersByIdArgs) => {
    const { mappingDocId, teamMemberDocIds } = args

    let isError = false
    let idsOfMembersRemoved: string[] = []
    let currMemberId: string | null = null

    try {
      let currIndex = 0

      while (currIndex < teamMemberDocIds.length) {
        await removeHorseTeamMember({
          mappingDocId,
          teamMemberDocId: teamMemberDocIds?.[currIndex],
          dontShowToast: true,
        })

        if (typeof teamMemberDocIds?.[currIndex] === 'string') {
          currMemberId = teamMemberDocIds?.[currIndex] as any
          if (currMemberId) idsOfMembersRemoved.push(currMemberId)
        }

        currIndex++
      }
    } catch (error) {
      isError = true
      helpers.logger({
        message: error,
      })
    } finally {
      return {
        isError,
        idsOfMembersRemoved,
      }
    }
  }

  const removeHorseFromDb = async (args: IRemoveHorseFromDbFnArgs) => {
    const { mappingDocId, forceRemove = false, dontShowToast = false } = args

    let removed = false
    let mappings_ = cloneDeep(mappings)
    let horseToRemove: IUserHorseMappingInterface | null =
      mappings_.find((currMapping) => currMapping.id === mappingDocId) ?? null
    let horseId = horseToRemove?.horseId
    let teamMembers = horseToRemove?.teamMembers ?? []

    try {
      if (!horseToRemove)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappings_[horseIndex] is empty`,
        })

      if (!mappingDocId)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappingDocId is empty`,
        })

      if (teamMembers.length && !forceRemove) throw new Error(MESSAGES_CONST.REMOVE_TEAM_MEMBERS)

      if (forceRemove && teamMembers.length)
        await removeHorseTeamMembersById({
          mappingDocId,
          teamMemberDocIds: teamMembers.map((currMember) => currMember.id),
        })

      await FirestoreService.deleteItem(COLLECTIONS.USER_HORSE_MAPPING.NAME, mappingDocId)

      mappings_ = mappings_?.filter((mapping) => mapping.id !== mappingDocId)

      if (!dontShowToast)
        toastFunction.success({
          message: MESSAGES_CONST.HORSE_REMOVED,
        })

      removed = true
    } catch (error: any) {
      toastFunction.error({
        message: error?.message ?? `${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`,
      })

      helpers.logger({
        message: error?.cause ?? error,
      })

      removed = false
    } finally {
      if (horseId)
        dispatch(
          removeHorse({
            horseId,
          })
        )

      dispatch(setMappings(mappings_))
      return removed
    }
  }

  async function getHorseAsPerMapping(
    mapping: IUserHorseMappingInterface[]
  ): Promise<IHorseData[]> {
    let horseIds: string[] = []
    let horses: IHorseData[] = []
    let prefetchedHorses: IHorseData[] = []
    let prefetchedHorse: null | IHorseData = null

    mapping.forEach((currMapping) => {
      if (currMapping.horseId) {
        prefetchedHorse =
          myHorses.find((currHorseInRedux) => currHorseInRedux.id === currMapping.horseId) ?? null
        if (prefetchedHorse === null) horseIds.push(currMapping.horseId)
        else prefetchedHorses.push(prefetchedHorse)
      }
    })

    horseIds = [...new Set(horseIds)]

    if (!horseIds.length) return [...horses, ...prefetchedHorses]
    try {
      let fetchedHorseSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.HORSES.NAME,
        horseIds
      )

      fetchedHorseSnaps.forEach((currDoc) => {
        horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })
    } catch (error) {
      console.error('error', error)
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return [...horses, ...prefetchedHorses]
    }
  }

  const getTeamMembersHorses = async () => {
    const horses: IUserHorseMappingInterface[] = []
    const ids: string[] = []
    const promises: IUserHorseMappingInterface[][] = []
    const allHorses: IHorseData[] = []

    profileDetails.userTeamMembers?.forEach((member) => {
      if (member.memberId) ids.push(member.memberId)
    })

    for (const id of ids) {
      const { horsesTeamsMappings } = await getMapping(id)
      promises.push(horsesTeamsMappings)
    }

    const mappingSnaps = await Promise.all(promises)

    const horsesSnaps = await FirestoreService.filterItems(COLLECTIONS.HORSES.NAME)

    horsesSnaps.forEach((currDoc) => {
      allHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
    })

    mappingSnaps.forEach((mapping) => horses.push(...mapping))
    return horses
      .map((horse) => {
        const horseProfile = allHorses.find((current) => current.id === horse.horseId)
        return {
          ...horseProfile,
          user: profileDetails.userTeamMembers?.find(
            (member) => member.memberId === horse.horseOwnerId
          ),
        }
      })
      .filter((horse) => !!horse.user) as ITeamMemberHorse[]
  }

  return {
    removeHorseFromDb,
    removeHorseTeamMember,
    removeHorseTeamMembersById,
    updateHorseTeamMember,
    getMapping,
    loadHorsesFromDb,
    getTeamMembersHorses,
  }
}

export default useHorses
