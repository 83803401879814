import React, { useEffect } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { PoliciesData } from '../data/PoliciesData'
import './HelpRootPage.css'
import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'

// Types
type IPolicy = {
  title: string
  scriptName: string
  dataId: string | null
  policy: string
}

type IDataToPassOn = {
  data?: any
  policy: IPolicy
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const DataPassOn = (props: IDataToPassOn) => {
  // Hooks and vars
  const {
    policy: { scriptName, dataId, policy },
    handleModal,
  } = props
  const scriptId = `#${scriptName}`
  let scriptAdded = document.querySelector(scriptId)

  const closeModal = () => {
    if (scriptAdded) scriptAdded.remove()
    handleModal(false, MODAL_CONSTS.HELP_VIDEO, {
      ...props?.data,
    })
  }

  useEffect(() => {
    const removeScriptByDataAttribute = (dataAttribute: string, value: string) => {
      const script = document.querySelector(`script[data-${dataAttribute}="${value}"]`)
      if (script) {
        script.remove()
      }
    }

    const createScript = (scriptName: string) => {
      const script = document.createElement('script')
      script.src = 'https://app.termly.io/embed-policy.min.js'
      script.async = true
      script.id = scriptName
      script.setAttribute('data-id', 'embed-policies')

      document.body.appendChild(script)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scriptAdded = script
    }
    removeScriptByDataAttribute('id', 'embed-policies')
    createScript(scriptName)
  }, [scriptName])

  return (
    <>
      <div className="text-right text-SeabiscuitDark200ThemeColor text-sm w-200px">
        <button
          type="button"
          onClick={closeModal}
          className="border-solid hover:opacity-70 transition-all border border-SeabiscuitDark200ThemeColor px-4 py-1 rounded-md"
        >
          Close
        </button>
      </div>
      <div className="px-2 pt-4">
        <hr className="text-SeabiscuitDark200ThemeColor" />
        {dataId ? (
          <div
            className="max-h-[80vh] overflow-auto text-sm text-SeabiscuitDark200ThemeColor mb-2"
            // @ts-ignore
            name="termly-embed"
            data-id={dataId}
            data-type="iframe"
          />
        ) : (
          <div className="max-h-[80vh] overflow-auto text-sm text-SeabiscuitDark200ThemeColor mb-2 pt-10">
            {policy}
          </div>
        )}
      </div>
    </>
  )
}

const HelpRootPagePoliciesTab = ({
  handleModal,
}: {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}) => {
  return (
    <WrapperWithHeader
      title="Policies"
      description="Review Pegasus’ varying terms, conditions and policies"
    >
      <>
        <hr className="mb-4"></hr>
        {PoliciesData && PoliciesData.length
          ? PoliciesData.map((policy, index: number) => {
              return (
                <div
                  key={index}
                  className="flex flex-wrap gap-2 item-center justify-between mb-4 pb-4 border-b border-b-SeabiscuitGray500ThemeColor"
                >
                  <div className="flex items-center w-60">
                    <img
                      className="w-[20px] h-[20px] mr-4"
                      src="/assets/cp_icons/Clipboard.svg"
                      alt="ClipboardIcon"
                    />
                    <div className="text-SeabiscuitDark200ThemeColor text-[14px]">
                      {policy.title}
                    </div>
                  </div>

                  <button
                    type="button"
                    className="w-full border hover:opacity-70 transition-all border-[#D3DAEE] border-solid flex items-center justify-between rounded-xl p-4 md:w-40 cursor-pointer disabled:bg-[#dddddd]"
                    disabled={!policy.dataId}
                    onClick={() =>
                      handleModal(true, MODAL_CONSTS.HELP_VIDEO, {
                        component: <DataPassOn policy={policy} handleModal={handleModal} />,
                      })
                    }
                  >
                    <span className="text-SeabiscuitDark200ThemeColor text-[14px]">
                      View policy
                    </span>
                    <img src="/assets/cp_icons/FullScreen.svg" alt="FullScreen" />
                  </button>
                </div>
              )
            })
          : null}
      </>
    </WrapperWithHeader>
  )
}

export default HelpRootPagePoliciesTab
export { DataPassOn as HelpRootPageDataPassOn }
