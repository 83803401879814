import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { httpService } from '../../../services/httpService'

type ISendEmailForSignProps = {
  ridersTeamMember: IRiderTeamMemberInterface
  paperwork: any
  paperworkUrl: string
  eventId: string
  isMature: boolean
  isStaff?: boolean
  eventDraftId: string
  senderData: {
    senderName: string
    senderEmail: string
  }
}

export const sendEmailForSign = async (props: ISendEmailForSignProps) => {
  const {
    ridersTeamMember,
    paperwork,
    paperworkUrl,
    eventId,
    isMature,
    eventDraftId,
    senderData,
    isStaff,
  } = props

  try {
    // toast.error("Sending mails in progress...")

    const response: any = await httpService({
      url: 'send_sign_mail',
      method: 'POST',
      data: {
        signData: [
          {
            receiverMail: ridersTeamMember.teamMemberEmail,
            receiverName: ridersTeamMember.teamMemberName,
            signatoryId: ridersTeamMember.teamMemberId,
            paperworkName:
              'relatedPaperworks' in paperwork
                ? paperwork.relatedPaperworks[0].paperworkName
                : paperwork.document_name,
            paperworkUrl,
            signatoryRole: ridersTeamMember.teamMemberRole,
            riderName: ridersTeamMember.riderName,
            riderId: ridersTeamMember.riderId,
            isMinor: !isMature,
            isStaff: isStaff,
          },
        ],
        senderData,
        eventDraftId,
        eventId,
      },
    })

    if (response?.status === true && 'relatedPaperworks' in paperwork) {
      toast.success('Mails successfully sent to signatories')
      // return dispatch(setRegisterPaperworkData(updatedPaperworkTab))
    }
  } catch (error: any) {
    // console.log({error})
    if (error instanceof AxiosError) {
      // let responseData = error.response?.data;
      // toast.error(responseData?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG)
    }
  }
}
