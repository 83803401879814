export enum EInputPolicyName {
  eventRules = 'eventRules',
  orderOfGo = 'orderOfGo',
  attireTack = 'attireTack',
  parking = 'parking',
  ribbons = 'ribbons',
  rvs = 'rvs',
  substitutions = 'substitutions',
}

export const getEventPolicyImage = (policyName: EInputPolicyName | string) => {
  const policyImages: Record<string, string> = {
    [EInputPolicyName.eventRules]: 'Scroll-1.svg',
    [EInputPolicyName.orderOfGo]: 'Shortlist-1.svg',
    [EInputPolicyName.attireTack]: 'Suit-1.svg',
    [EInputPolicyName.parking]: 'Fiat 501.svg',
    [EInputPolicyName.ribbons]: 'Cancer Ribbon-1.svg',
    [EInputPolicyName.rvs]: 'Railcar-1.svg',
    [EInputPolicyName.substitutions]: 'People-1.svg',
  }

  return policyImages[policyName] || 'Scroll-1.svg'
}

export const defaultPolicies = [
  {
    placeholder: 'Enter Attire/Tack information',
    ruleType: EInputPolicyName.attireTack,
    name: 'Attire/Tack',
    uniqId: '1',
    policy: '',
  },
  {
    placeholder: 'Enter Class division rules',
    ruleType: EInputPolicyName.eventRules,
    name: 'Event rules',
    uniqId: '2',
    policy: '',
  },
  {
    placeholder: 'Enter Order of Go information',
    ruleType: EInputPolicyName.orderOfGo,
    name: 'Order of go',
    uniqId: '3',
    policy: '',
  },
  {
    placeholder: 'Enter parking instructions. Pricing will be entered later.',
    ruleType: EInputPolicyName.parking,
    name: 'Parking',
    uniqId: '4',
    policy: '',
  },
  {
    placeholder: 'Enter Ribbons information',
    ruleType: EInputPolicyName.ribbons,
    name: 'Ribbons',
    uniqId: '5',
    policy: '',
  },
  {
    placeholder: 'Enter RV information',
    ruleType: EInputPolicyName.rvs,
    name: 'Rv’S',
    uniqId: '6',
    policy: '',
  },
  {
    placeholder: 'Enter Substitution information',
    ruleType: EInputPolicyName.substitutions,
    name: 'Substitutions',
    uniqId: '7',
    policy: '',
  },
]
