export const UI_EVENTS_CONST = {
  FILTER: {
    CATEGORIES: {
      SANCTIONED: 'sanctioned',
      UNSANCTIONED: 'unsanctioned',
      LICENSED: 'licensed',
      UNLICENSED: 'unlicensed',
      CLINIC: 'clinic',
      OTHER: 'other',
    },
    TAGS: {
      EVENTING: 'eventing',
      DRESSAGE: 'dressage',
      EQUITATION: 'equitation',
      HUNTER: 'hunter',
      JUMPER: 'jumper',
      HUNTER_JUMPER: 'hunter_jumper',
    },
    STATUS: {
      KEY: 'status',
      VALUE: {
        DRAFT: 'draft',
        CURRENT: 'current',
        PAST: 'past',
      },
    },
  },
  SORT: {
    CRITERIA: {
      ALPHABETICAL_ASC: 'alphabetical_asc',
      ALPHABETICAL_DESC: 'alphabetical_desc',
      TIME_ASC: 'time_asc',
      TIME_DESC: 'time_desc',
      PRICE_ASC: 'price_asc',
      PRICE_DESC: 'price_desc',
    },
  },
  CREATE_EDIT: {
    TABS: {
      EVENT_DETAILS: {
        VALUE: 'event_details',
        SEQUENCE: 0,
        DISABLED: false,
      },
      STAFF: {
        VALUE: 'staff',
        SEQUENCE: 1,
        DISABLED: false,
      },
      REGISTRATIONS: {
        VALUE: 'registrations',
        SEQUENCE: 1,
        DISABLED: false,
      },
      PAYMENTS: {
        VALUE: 'payments',
        SEQUENCE: 14,
        DISABLED: false,
      },
      SCORING: {
        VALUE: 'scoring',
        SEQUENCE: 3,
        DISABLED: false,
      },
      SCHEDULING: {
        VALUE: 'scheduling',
        SEQUENCE: 4,
        DISABLED: false,
      },
      FEES: {
        VALUE: 'fees',
        SEQUENCE: 5,
        DISABLED: false,
      },
      POLICIES: {
        VALUE: 'policies',
        SEQUENCE: 6,
        DISABLED: false,
      },
      PAPERWORK: {
        VALUE: 'paperwork',
        SEQUENCE: 7,
        DISABLED: false,
      },
      TICKETING: {
        VALUE: 'ticketing',
        SEQUENCE: 8,
        DISABLED: false,
      },
      SPONSORS: {
        VALUE: 'sponsors',
        SEQUENCE: 9,
        DISABLED: false,
      },
      VENDORS: {
        VALUE: 'vendors',
        SEQUENCE: 10,
        DISABLED: false,
      },
      SEND_NOTIFICATIONS: {
        VALUE: 'send_notifications',
        SEQUENCE: 12,
        DISABLED: false,
      },
      REQUERED_FIELDS: {
        VALUE: 'required_fields',
        SEQUENCE: 13,
        DISABLED: false,
      },
      REVIEW_PUBLISH: {
        VALUE: 'review_publish',
        SEQUENCE: 14,
        DISABLED: false,
      },
      QUESTIONS: {
        VALUE: 'questions',
        SEQUENCE: 15,
        DISABLED: false,
      },
    },
  },
  MANAGE_EVENT: {
    TABS: {
      ADMIN: {
        VALUE: 'admin',
        SEQUENCE: 0,
      },
      LOGISTICS: {
        VALUE: 'logistics',
        SEQUENCE: 0,
      },
      OPERATIONS: {
        VALUE: 'operations',
        SEQUENCE: 0,
      },
      STAFF: {
        VALUE: 'staff',
        SEQUENCE: 0,
      },
      FINANCES: {
        VALUE: 'finances',
        SEQUENCE: 0,
      },
      COMMENTS: {
        VALUE: 'comments',
        SEQUENCE: 0,
      },
      MESSAGES: {
        VALUE: 'messages',
        SEQUENCE: 0,
      },
      REPORTS: {
        VALUE: 'reports',
        SEQUENCE: 0,
      },
    },
  },
  REVENUE: {
    TABS: {
      ALL: {
        VALUE: 'All',
        SEQUENCE: 0,
      },
      PAID: {
        VALUE: 'Paid',
        SEQUENCE: 0,
      },
      UNPAID: {
        VALUE: 'Unpaid',
        SEQUENCE: 0,
      },
      REFUNDED: {
        VALUE: 'Refunded',
        SEQUENCE: 0,
      },
    },
  },
  REGISTERED_EXHIBITORS: {
    TABS: {
      REGISTERED: {
        KEY: 'Registered',
        VALUE: 'Registered',
        SEQUENCE: 0,
      },
      WAITLIST: {
        KEY: 'Waitlist',
        VALUE: 'Waitlist',
        SEQUENCE: 0,
      },
    },
  },
  PAPERWORK: {
    TABS: {
      ALL: {
        VALUE: 'All',
        SEQUENCE: 0,
      },
      SIGNED: {
        VALUE: 'Signed',
        SEQUENCE: 0,
      },
      UNSIGNED: {
        VALUE: 'Unsigned',
        SEQUENCE: 0,
      },
      RECEIVED: {
        VALUE: 'Received',
        SEQUENCE: 0,
      },
    },
  },
}
