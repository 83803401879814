import clsx from 'clsx'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { calculatePriceWithPercent } from '../../../../helpers/calculatePriceWithPercent'

interface IProps {
  status: boolean
  price: undefined | string
  percent: number
  className?: string
}

export const PriceWithPercent: React.FC<IProps> = ({ status, price, percent, className }) => {
  const getPrice = (value: string) => {
    let price
    if (value[0] === '$') {
      price = +value.substring(1)
    } else {
      price = +value
    }

    const priceWithPercent = calculatePriceWithPercent(price, percent)

    return '$' + priceWithPercent
  }

  if (!status || !price || price === '$0') {
    return <></>
  }

  return (
    <span
      className={twMerge(
        clsx(
          'absolute right-4 bottom-1/2 translate-y-1/2 text-SeabiscuitDark200ThemeColor/50 whitespace-nowrap text-[14px]',
          className
        )
      )}
    >
      {getPrice(price || '$0')}
    </span>
  )
}
