import { useParams } from 'react-router'
import { where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

import {
  JudgingTable,
  OperationsHeader,
  OrderOfGoTable,
  PrizeMoneyTable,
  ScoringTable,
} from './components'

import { CONST } from '../../../../../const/const'

import FirestoreService from '../../../../../services/firestoreService'

import { RegistrationFeesStatsModel } from '../../../../../models/registration-fees-stats/registrationFeesStats.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationFeesType } from '../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../models/event-fees/event-fees.model'
import { UserModel } from '../../../../../models/users/user.model'
import { EventStaffModel } from '../../../../../models/event-staff/event-staff.model'
import { IUserInterface } from '../../../../../models/users/user.interface'

import { MANAGE_OPERATIONS_CONSTS } from '../data/operations.data.const'
import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import { IEventStaffInterface } from '../../../../../models/event-staff/event-staff.interface'

import { sheets } from '../data/sheets'
import { useAppSelector } from '../../../../../store/hooks'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { getUserFullName } from '../../../../../helpers/helpers'
import { EVENT_REGISTERED_CONST } from '../../../../../models/event-registered-users/event-registered-users.constants'
import { selectAllUsers } from '../../../../../store/users/usersSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export type IOperationsTabs = 'judging' | 'order-of-go' | 'scoring' | 'prize-money'

export interface IRegistrationByDayEx extends IRegistrationByDayInterface {
  loading?: boolean
}

export interface RegistrationFeesTypeEx extends RegistrationFeesType {
  loading?: boolean
}

export const DUMMY_REGISTRATION_FEES_STATS = [
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
]

export const sortByOrder = (array: IRegistrationByDayInterface[]) => {
  return array.sort((a, b) => {
    if (a.order === 0 && b.order === 0) {
      return 0
    }
    if (a.order === 0) {
      return 1
    }
    if (b.order === 0) {
      return -1
    }
    return a.order - b.order
  })
}

export const sortByRank = (array: IRegistrationByDayInterface[], direction = 'desc') => {
  return array.sort((a, b) => {
    const totalPointsA = Number(a.score?.totalPoints)
    const totalPointsB = Number(b.score?.totalPoints)

    if (totalPointsA === 0 && totalPointsB === 0) {
      return 0
    }
    if (totalPointsA === 0) {
      return 1
    }
    if (totalPointsB === 0) {
      return -1
    }
    return direction === 'asc' ? totalPointsA - totalPointsB : totalPointsB - totalPointsA
  })
}

const ManageClinicNOtherOperationsTab = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const currentEvent = useAppSelector(selectedEvent)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<IOperationsTabs>('judging')
  const [registrationsByDayByClass, setRegistrationsByDayByClass] = useState<
    IRegistrationByDayInterface[]
  >([])
  const [classOptions, setClassOptions] = useState<{ value: string; label: string }[]>([])
  const [selectedClass, setSelectedClass] = useState<any>('')
  const [increment, setIncrement] = useState<string>('')
  const [registrationFees, setRegistrationFees] = useState<any>(null)
  const [fee, setFee] = useState<RegistrationFeesType | null>(null)
  const [registrationsByDay, setRegistrationsByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)
  const [judgesOptions, setJudgesOptions] = useState<{ label: string; value: string }[] | null>(
    null
  )
  const users = useAppSelector(selectAllUsers)

  const handleGetFeesData = async () => {
    setLoading(true)
    const eventFeesSnapShot = await FirestoreService.getItem(COLLECTIONS.EVENT_FEES.NAME, eventId)

    const registrationFees_ = new EventFeesModel(eventFeesSnapShot.data()).toFirestore()

    const fees_ = [...registrationFees_.registrationFees].map(
      (registrationFees: RegistrationFeesType) => ({
        value: registrationFees.uuid ?? '',
        label: registrationFees.name ?? '',
      })
    )

    setClassOptions(fees_)
    setRegistrationFees(registrationFees_)
    setLoading(false)
  }

  const getEventStaff = async () => {
    const publishedEventSnapShot = await FirestoreService.getItem(
      COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )
    const publishedEvent_ =
      EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()

    const staffs: IEventStaffInterface = new EventStaffModel(
      publishedEvent_?.EventStaff
    ).toFirestore()
    const judgesStaff_ = staffs.eventStaff?.find((staff) => staff.title === 'Judges')

    if (judgesStaff_?.value && judgesStaff_.value.length > 0) {
      const usersSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.USERS.NAME,
        judgesStaff_.value
      )

      const usersList: IUserInterface[] = []
      usersSnaps.forEach((currSnap) =>
        usersList.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      )

      if (usersList.length > 0)
        setJudgesOptions(
          usersList.map((user) => ({
            label: getUserFullName(user),
            value: user.id,
          }))
        )
    }
  }

  const getRegistrationsByDay = async () => {
    let registrationsByDay_: IRegistrationByDayInterface[] = []

    const registrationsByDaySnapShot = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId)]
    )

    if (registrationsByDaySnapShot.size) {
      registrationsByDaySnapShot.docs.forEach((currDoc) => {
        registrationsByDay_.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    setRegistrationsByDay(registrationsByDay_)

    return registrationsByDay_
  }

  // Fetching Riders and Horses from REGISTRATION_BY_DAY Collections (Depends on Class)
  const getRidersByClass = async (activeTab: string) => {
    setLoading(true)
    let uniqueRegistrationsByDayByClass: IRegistrationByDayInterface[]

    let registrationsByDayByClass_: IRegistrationByDayInterface[] =
      registrationsByDay?.filter(
        (registrationByDay) =>
          registrationByDay.uuid === selectedClass.value &&
          registrationByDay.paymentStatus !== EVENT_REGISTERED_CONST.PAYMENT_STATUS.PENDING
      ) ?? []

    uniqueRegistrationsByDayByClass = registrationsByDayByClass_.reduce(
      (acc: IRegistrationByDayInterface[], currentItem, index) => {
        // Check if the riderId of the current item is already in the accumulator
        const isRiderIdExist = acc.some(
          (item) => item.riderId === currentItem.riderId && item.horseId === currentItem.horseId
        )
        const riderAsUserObj = users.find((user) => user.id === currentItem.riderId)
        const sheet = sheets.find((current) => current.id === fee?.scoreCard)

        // If riderId doesn't exist, add the current item to the accumulator
        if (!isRiderIdExist) {
          const newItem = {
            ...currentItem,
            riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : currentItem.riderName,
            score: {
              ...currentItem.score,
              judges:
                currentItem.score?.judges && currentItem.score.judges.length > 0
                  ? currentItem.score?.judges
                  : (fee?.judges?.map((judge) => {
                      const user = users.find((user) => user.id === judge)
                      return {
                        id: judge,
                        name: user ? getUserFullName(user) : 'N/A',
                        furtherRemarks: cloneDeep(sheet?.furtherRemarks) ?? '',
                        sections: cloneDeep(sheet?.sections) ?? [],
                        errors: cloneDeep(sheet?.errors) ?? 0,
                      }
                    }) ?? []),
              sheet: currentItem.score?.sheet?.name
                ? currentItem.score?.sheet
                : fee?.scoreCard
                  ? {
                      name: cloneDeep(sheet?.name) ?? '',
                      type: cloneDeep(sheet?.type) ?? '',
                    }
                  : null,
              rank: currentItem.score?.rank ?? null,
              totalPoints: currentItem.score?.totalPoints ?? null,
              details: currentItem.score?.details ?? '',
              file: currentItem.score?.file ?? '',
            },
            order: currentItem.orderOfGoScratched ? 0 : currentItem.order || index + 1,
          }
          acc.push(newItem)
        }
        return acc
      },
      []
    )

    if (activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value) {
      // Sort array depends on order
      const event = currentEvent?.Event

      const breaks: any[] =
        event && Array.isArray(event.breaks)
          ? event.breaks.filter((itm) => itm.class.value === selectedClass.value)
          : []

      uniqueRegistrationsByDayByClass = sortByOrder([...uniqueRegistrationsByDayByClass, ...breaks])
    } else if (
      activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value ||
      activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value
    ) {
      // Sort array depends on rank
      uniqueRegistrationsByDayByClass = sortByRank(
        uniqueRegistrationsByDayByClass,
        fee?.isAsc ? 'asc' : 'desc'
      )
      uniqueRegistrationsByDayByClass = uniqueRegistrationsByDayByClass.map(
        (registrationByDay, index) => {
          return {
            ...registrationByDay,
            ...(registrationByDay.score && {
              score: {
                ...registrationByDay.score,
                rank: index + 1,
              },
            }),
          }
        }
      )
    }

    setRegistrationsByDayByClass(uniqueRegistrationsByDayByClass)
    setLoading(false)
  }

  useEffect(() => {
    getRegistrationsByDay().then()
    handleGetFeesData().then()
    getEventStaff().then()
  }, [activeTab])

  useEffect(() => {
    if (selectedClass.value && activeTab && fee) getRidersByClass(activeTab).then()
  }, [activeTab, selectedClass, fee, registrationsByDay])

  useEffect(() => {
    if (selectedClass.value) {
      setFee(
        [...registrationFees.registrationFees]?.find(
          (item: any) => item.uuid === selectedClass.value
        )
      )
    }
  }, [registrationFees, selectedClass])

  const updateFeesKeys = async (keys: object, uuid: string) => {
    let registrationFees_ = cloneDeep(registrationFees)

    const indexToUpdateFees = registrationFees_.registrationFees.findIndex(
      (item: any) => item.uuid === uuid
    )

    if (indexToUpdateFees !== -1) {
      const updatedRegistrationFees = [...registrationFees.registrationFees] // Create a copy of the array
      updatedRegistrationFees[indexToUpdateFees] = {
        ...updatedRegistrationFees[indexToUpdateFees], // Copy existing properties
        ...keys,
      }
      // Update the state with the modified array
      registrationFees_ = { ...registrationFees_, registrationFees: [...updatedRegistrationFees] }
    }

    setRegistrationFees(registrationFees_)

    const docToStore = new EventFeesModel(registrationFees_).toFirestore()

    await FirestoreService.updateItem(COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME, eventId, {
      EventFees: docToStore,
    })

    await FirestoreService.updateItem(COLLECTIONS.EVENT_FEES.NAME, eventId, {
      ...docToStore,
      id: eventId,
    })
  }

  const updateRegistrationByDayName = async (
    feeName: string,
    sheetId: string,
    values?: { value: string; label: string }[]
  ) => {
    const promises: Promise<void>[] = []

    const sheet = sheets.find((current) => current.id === sheetId)

    const registrationsByDayByClass = registrationsByDay?.filter(
      (item) => item.registrationByDayName === feeName
    )

    registrationsByDayByClass?.forEach((registrationByDay) => {
      const item = new RegistrationByDayModel({
        ...registrationByDay,
        score: {
          totalPoints: null,
          details: null,
          rank: null,
          file: null,
          earning: null,
          sheet: {
            name: sheet?.name ?? '',
            type: sheet?.type ?? '',
          },
          judges:
            values?.map((judge) => ({
              id: judge.value,
              name: judge.label,
              furtherRemarks: sheet?.furtherRemarks ?? '',
              sections: sheet?.sections ?? [],
              errors: sheet?.errors ?? 0,
            })) ?? null,
        },
      })

      promises.push(
        FirestoreService.updateItem(
          COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          registrationByDay.id,
          item.toFirestore()
        )
      )
    })

    await Promise.all(promises)
  }

  const onChangeJudge = async (
    row: RegistrationFeesTypeEx,
    values: { label: string; value: string }[]
  ) => {
    if (values) {
      await updateFeesKeys({ judges: values.map((value) => value.value) }, row.uuid)

      const sheet = sheets.find((sheet) => sheet.id === row?.scoreCard)
      await updateRegistrationByDayName(row.name ?? '', sheet?.id ?? '', values)
      await getRegistrationsByDay()
    } else {
      await updateFeesKeys({ judges: [] }, row.uuid)
      await updateRegistrationByDayName(row.name ?? '', '', [])
      await getRegistrationsByDay()
    }
  }

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-full flex flex-col">
      <OperationsHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        classOptions={classOptions}
        registrationFees={registrationFees}
        setIncrement={setIncrement}
        updateFeesKeys={updateFeesKeys}
        fee={fee}
        eventId={eventId}
      />
      {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.JUDGING.value ? (
        <JudgingTable
          getRegistrationsByDay={getRegistrationsByDay}
          loading={loading}
          updateFeesKeys={updateFeesKeys}
          registrationFees={registrationFees}
          judgesOptions={judgesOptions}
          onChangeJudge={onChangeJudge}
          updateRegistrationByDayName={updateRegistrationByDayName}
        />
      ) : registrationsByDayByClass.length > 0 ? (
        <div className="mt-8">
          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value && (
            <OrderOfGoTable
              selectedClass={selectedClass}
              registrationsByDayByClass={registrationsByDayByClass}
              setRegistrationsByDayByClass={setRegistrationsByDayByClass}
              increment={increment}
              fee={fee}
              eventId={eventId}
            />
          )}
          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value && (
            <ScoringTable
              loading={loading}
              registrationsByDayByClass={registrationsByDayByClass}
              increment={increment}
              selectedClass={selectedClass}
              updateFeesKeys={updateFeesKeys}
              getRegistrationsByDay={getRegistrationsByDay}
              fee={fee}
              onChangeJudge={onChangeJudge}
              eventId={eventId}
              registrationsByDay={registrationsByDay}
              setRegistrationsByDay={setRegistrationsByDay}
            />
          )}
          {activeTab === MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value && (
            <PrizeMoneyTable
              loading={loading}
              registrationsByDayByClass={registrationsByDayByClass}
              registrationsByDay={registrationsByDay}
              setRegistrationsByDay={setRegistrationsByDay}
              eventId={eventId}
              registrationFees={registrationFees}
            />
          )}
        </div>
      ) : (
        <div className="!rounded-none exhibitorListTable flex justify-center p-12">
          There are no records to display
        </div>
      )}
    </div>
  )
}

export default ManageClinicNOtherOperationsTab
