// Constants
import { CONST } from '../../../../const/const'

// Components
import ManageClinicNOtherFinancesTab from './tabs/finance/ManageClinicNOtherFinancesTab'
import ManageClinicNOtherMessagesTab from './ManageClinicNOtherMessagesTab'
import ManageClinicNOtherLogisticsTab from './ManageClinicNOtherLogisticsTab'
import ManageClinicNOtherOperationsTab from './ManageClinicNOtherOperationsTab/ManageClinicNOtherOperationsTab'
import { ManageClinicNOtherAdminTabNew } from './ManageClinicNOtherAdminTabNew'
import React from 'react'
import ManageClinicReportsRoot from './tabs/reports/ManageClinicReportsRoot'
import StaffListing from '../common/table/Staff/tabs/StaffListing'
import { ManageClinicNOtherCommentsTab } from './ManageClinicNOtherCommentsTab'
// import ManageClinicNOtherAdminTab from './ManageClinicNOtherAdminTab'
// import ManageClinicNOtherAdminTab from './ManageClinicNOtherAdminTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%\

// Types

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 *
 * @TODO todo
 */
const ManageClinicNOtherDisplayTabs = ({
  eventId,
  eventTab,
}: {
  eventId: string
  eventTab: string
}) => {
  // Funtions
  const selectTab = (event_tab: string) => {
    switch (event_tab) {
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE:
        return (
          <>
            <ManageClinicNOtherAdminTabNew eventId={eventId} />
          </>
        )
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.LOGISTICS.VALUE:
        return <ManageClinicNOtherLogisticsTab eventId={eventId} />
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.STAFF.VALUE:
        return (
          <>
            <StaffListing />
          </>
        )
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.OPERATIONS.VALUE:
        return <ManageClinicNOtherOperationsTab />
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE:
        return <ManageClinicNOtherFinancesTab eventId={eventId} />
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.COMMENTS.VALUE:
        return <ManageClinicNOtherCommentsTab />
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.MESSAGES.VALUE:
        return <ManageClinicNOtherMessagesTab eventId={eventId} />
      case CONST.UI.EVENTS.MANAGE_EVENT.TABS.REPORTS.VALUE:
        return <ManageClinicReportsRoot eventId={eventId} />
    }
  }

  return <>{selectTab(eventTab)}</>
}

export default ManageClinicNOtherDisplayTabs
