// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { RUN_MODE_CONST } from './run-mode-const'
import { STORAGE_CONST } from './storage-const'
import { ROUTES_CONST } from './routes-const'
import { DATA_FIRESTORE_CONST } from './data/firestore-const'
import { UI_SHOWS_CONST } from './ui/ui-shows-const'
import { USE_MODE_CONST } from './use-mode'
import { UI_EVENTS_CONST } from './ui/ui-events-const'
import { UI_HOME_CONST } from './ui/ui-home-const'
import { COMPONENTS_CONST } from './components/components-const'
import { DATA_STORAGE_CONST } from './data/storage-const'
import { UI_REGISTER_CONST } from './ui/ui-register.const'
import { UI_EXHIBITOR_CONST } from './ui/ui-exhibitor-const'
import { UI_USER_CONST } from './ui/ui-user-const'
import { SPECTATOR_TICKETS, UI_SPECTATOR_CONST } from './ui/ui-spectator-tickets'
import UI_STAFF_PROFILE_CONST from './ui/ui-staff-profile-const'
import { UI_ROLES_CONST } from './ui/ui-roles-const'
import MAP_CONSTS from './map-const'
import { UI_VENDOR_AND_SPONSOR_CONST } from './ui/ui-vendor-and-sponsor-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export const CONST = {
  RUN_MODE: RUN_MODE_CONST,
  USE_MODE: USE_MODE_CONST,
  STORAGE: STORAGE_CONST,
  ROUTES: ROUTES_CONST,
  DATA: {
    STORAGE: DATA_STORAGE_CONST,
    FIRESTORE: DATA_FIRESTORE_CONST,
  },
  UI: {
    HOME: UI_HOME_CONST,
    USER: UI_USER_CONST,
    SHOWS: UI_SHOWS_CONST,
    EVENTS: UI_EVENTS_CONST,
    REGISTER: UI_REGISTER_CONST,
    EXHIBITOR: UI_EXHIBITOR_CONST,
    STAFF: UI_STAFF_PROFILE_CONST,
    SPECTATOR: SPECTATOR_TICKETS,
    VENDOR_AND_SPONSOR: UI_VENDOR_AND_SPONSOR_CONST,
    TEAM_MEMBERS_ROLES: {
      RIDER: 'Rider',
      COACH: 'Coach',
      GROOM: 'Groom',
      OWNER: 'Owner',
      TRAINER: 'Trainer',
      TEAM_MEMBER: 'Team member',
      GUARDIAN: 'Guardian',
    },
    ROLES: UI_ROLES_CONST,
    SPECTATOR_TABS: UI_SPECTATOR_CONST,
    MAP: MAP_CONSTS,
  },
  FIREBASE: {
    INVALID_FIREBASE_ID: '00000000000000000000',
  },
  COMPONENTS: COMPONENTS_CONST,
  EVENT: {
    PRIZE_LIST: {
      FORM_BUILDER: {
        ITEMS: {
          DIVISION: {
            TYPE: {
              STANDARD: 'TYPE_STANDARD',
            },
          },
          SECTION: {
            TYPE: {
              HUNTER_STANDARD: 'TYPE_HUNTER_STANDARD',
              HUNTER_CLASSIC: 'TYPE_HUNTER_CLASSIC',
              HUNTER_DERBY: 'TYPE_HUNTER_DERBY',
              HUNTER_LEAGUE: 'TYPE_HUNTER_LEAGUE',

              EQUITATION_STANDARD: 'TYPE_EQUITATION_STANDARD',
              JUMPER_STANDARD: 'TYPE_JUMPER_STANDARD',
              STANDARD: 'TYPE_STANDARD',
              EQUITATION: 'TYPE_EQUITATION',
              JUMPER: 'TYPE_JUMPER',
            },
          },
          SUB_SECTION: {
            TYPE: {
              HUNTER_STANDARD: 'TYPE_HUNTER_STANDARD',
              EQUITATION_STANDARD: 'TYPE_EQUITATION_STANDARD',
              JUMPER_STANDARD: 'TYPE_JUMPER_STANDARD',
              STANDARD: 'TYPE_STANDARD',
              EQUITATION: 'TYPE_EQUITATION',
              JUMPER: 'TYPE_JUMPER',
            },
            RULES: {
              COMMON: {},
              HUNTER: {
                // Hunter
                HUNTER_STANDARD_NO_SADDLE_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_NO_SADDLE_CLASS',
                HUNTER_STANDARD_ONE_SADDLE_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_ONE_SADDLE_CLASS',
                HUNTER_STANDARD_NO_HANDY_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_NO_HANDY_CLASS',
                HUNTER_STANDARD_ONE_HANDY_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_ONE_HANDY_CLASS',
                HUNTER_STANDARD_NO_CONFORMATION_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_NO_CONFORMATION_CLASS',
                HUNTER_STANDARD_ONE_CONFORMATION_CLASS:
                  'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_ONE_CONFORMATION_CLASS',
                HUNTER_STANDARD_NO_SAME_CLASS: 'SUBSECTION_ITEM_RULE_HUNTER_STANDARD_NO_SAME_CLASS',
              },
              JUMPER: {
                // Equitation
                // NOTHING SO FAR
              },
              EQUITATION: {
                // Jumper
                // NOTHING SO FAR
              },
            },
          },
          SUMMARY: {
            TYPE: {
              STANDARD: 'TYPE_STANDARD',
            },
          },
        },
        DISCIPLINE: {
          TYPE: {
            STANDARD: 'TYPE_STANDARD',
          },
        },
        DIVISION: {
          TYPE: {
            STANDARD: 'TYPE_STANDARD',
          },
        },
        SECTION: {
          TYPE: {
            HUNTER_STANDARD: 'TYPE_HUNTER_STANDARD',
            HUNTER_CLASSIC: 'TYPE_HUNTER_CLASSIC',
            HUNTER_DERBY: 'TYPE_HUNTER_DERBY',
            HUNTER_LEAGUE: 'TYPE_HUNTER_LEAGUE',

            EQUITATION_STANDARD: 'TYPE_EQUITATION_STANDARD',
            JUMPER_STANDARD: 'TYPE_JUMPER_STANDARD',
            STANDARD: 'TYPE_STANDARD',
            EQUITATION: 'TYPE_EQUITATION',
            JUMPER: 'TYPE_JUMPER',
          },
        },
        SUB_SECTION: {
          TYPE: {
            STANDARD: 'TYPE_STANDARD',
            EQUITATION: 'TYPE_EQUITATION',
            JUMPER: 'TYPE_JUMPER',
          },
        },
        ROW: {
          TYPE: {
            STANDARD: 'TYPE_STANDARD',
          },
        },
        FIELD: {
          TYPE: {
            DATA: 'FIELD_DATA',
            TEXT: 'FIELD_TYPE_TEXT',
            NUMBER: 'FIELD_TYPE_NUMBER',
            BOOLEAN: 'FIELD_TYPE_BOOLEAN',

            // Hunter Standard
            SECTION_HUNTER_STANDARD_MAIN_CHECKBOX: 'FIELD_SECTION_HUNTER_STANDARD_MAIN_CHECKBOX',
            SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE:
              'FIELD_SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE',
            SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
              'FIELD_SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX',
            SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION:
              'FIELD_SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION',
            SUBSECTION_HUNTER_STANDARD_HANDY_OPTION:
              'FIELD_SUBSECTION_HUNTER_STANDARD_HANDY_OPTION',
            SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION:
              'FIELD_SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION',

            // Hunter Classic
            SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX: 'FIELD_SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX',
            SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE:
              'FIELD_SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE',
            SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE:
              'FIELD_SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE',
            SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE:
              'FIELD_SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE',

            // Hunter Derby
            SECTION_HUNTER_DERBY_MAIN_CHECKBOX: 'FIELD_SECTION_HUNTER_DERBY_MAIN_CHECKBOX',
            SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE:
              'FIELD_SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE',

            // Hunter League
            SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX: 'FIELD_SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX',
            SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE:
              'FIELD_SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE',

            // Equitation
            SECTION_EQUITATION_MAIN_CHECKBOX: 'FIELD_SECTION_EQUITATION_MAIN_CHECKBOX',
            SUBSECTION_EQUITATION_MAIN_CHECKBOX: 'FIELD_SUBSECTION_EQUITATION_MAIN_CHECKBOX',
            SUBSECTION_EQUITATION_FLAT_CLASS_OPTION:
              'FIELD_SUBSECTION_EQUITATION_FLAT_CLASS_OPTION',
            SUBSECTION_EQUITATION_OVER_FENCE_OPTION:
              'FIELD_SUBSECTION_EQUITATION_OVER_FENCE_OPTION',

            // Jumper
            SECTION_JUMPER_MAIN_CHECKBOX: 'FIELD_SECTION_JUMPER_MAIN_CHECKBOX',
            SUBSECTION_JUMPER_MAIN_CHECKBOX: 'FIELD_SUBSECTION_JUMPER_MAIN_CHECKBOX',
            SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE: 'FIELD_SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE',
          },
          VALIDATION: {
            NOT_EMPTY: 'VALIDATION_NOT_EMPTY',
            VALUE_BETWEEN: 'VALIDATION_VALUE_BETWEEN',
            NOT_ZERO: 'VALIDATION_NOT_ZERO',
            VALID_NUMBER: 'VALIDATION_VALID_NUMBER',
            NO_NEGATIVE: 'VALIDATION_NO_NEGATIVE',
            NO_LESS_THAN_NUMBER: 'VALIDATION_NO_LESS_THAN',
            NO_MORE_THAN_NUMBER: 'VALIDATION_NO_MORE_THAN_NUMBER',
            MIN_MAX_AGE_NUMERICAL_RELATIONSHIP: 'VALIDATION_MIN_MAX_AGE_NUMERICAL_RELATIONSHIP',
          },
        },
      },
    },
  },
}

export const USER_DEF_PASS = 'defaultPassword123!'
