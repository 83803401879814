import React from 'react'

interface IProps {}

export const IconTeamMember: React.FC<IProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="226"
      height="226"
      fill="none"
      viewBox="0 0 226 226"
    >
      <rect width="226" height="226" fill="#F6F7FB" rx="113" />
      <path
        fill="#D3DAEE"
        d="M112.999 81.834c-10.78 0-19.583 8.802-19.583 19.583s8.803 19.584 19.583 19.584c10.781 0 19.584-8.803 19.584-19.584s-8.803-19.583-19.584-19.583m-22.52 47c-4.833 0-8.813 3.98-8.813 8.812v2.349c0 5.759 3.652 10.925 9.222 14.477s13.191 5.695 22.111 5.695 16.542-2.143 22.112-5.695 9.222-8.718 9.222-14.477v-2.349c0-4.832-3.981-8.812-8.813-8.812zM169.001 88.584c-6.423 0-11.667 5.15-11.667 11.458s5.244 11.459 11.667 11.459c6.422 0 11.666-5.151 11.666-11.459s-5.244-11.458-11.666-11.458m-13.417 27.5c-2.879 0-5.25 2.329-5.25 5.156v1.374c0 3.37 2.175 6.393 5.494 8.471s7.858 3.332 13.173 3.332c5.314 0 9.854-1.254 13.173-3.332 3.318-2.078 5.493-5.101 5.493-8.471v-1.374c0-2.827-2.371-5.156-5.25-5.156zM56.5 88.584c-6.309 0-11.459 5.15-11.459 11.458s5.15 11.459 11.458 11.459 11.459-5.151 11.459-11.459-5.15-11.458-11.459-11.458m-13.178 27.5c-2.827 0-5.156 2.329-5.156 5.156v1.374c0 3.37 2.137 6.393 5.396 8.471s7.718 3.332 12.937 3.332c5.22 0 9.679-1.254 12.938-3.332s5.396-5.101 5.396-8.471v-1.374c0-2.827-2.33-5.156-5.157-5.156z"
      />
    </svg>
  )
}
