// Third party
import clsx from 'clsx'
import { CancelOutlined } from '@mui/icons-material'
import Select from 'react-select'

// Types
import { ITeamMember } from '../../../../models/users/user.interface'

// Constants
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../home/modals/UserProfileTeamTabAddTeamMember'

import { setUserProfileData, selectProfileData } from '../../../../store/user/userSlice'

// Custom hooks
// import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'
import { customStyles_ } from '../../../customStyles/ReactSelectCustomStyle'
import { ITeamMemberRoles } from '../../../../types/competitor_types'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Link } from 'react-router-dom'
import { CONST } from '../../../../const/const'

const LabelIcon = ({ src }: { src: string }) => {
  return <img src={src} width="22px" height="22px" alt="icons" />
}

const TeamMemberStatus = [
  {
    label: 'Authorized',
    value: 'authorized',
    dark_icon: '/assets/og_icons/PublicSafety.svg',
  },
  {
    label: 'Unauthorized',
    value: 'unauthorized',
    dark_icon: '/assets/og_icons/PublicSafety.svg',
  },
]

const TeamMemberRole = [
  {
    label: 'Announcer',
    value: 'announcer',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Course Designer',
    value: 'courseDesigner',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Farrier',
    value: 'farrier',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Groom',
    value: 'groom',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Ingate',
    value: 'ingate',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Judge',
    value: 'judge',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Rider',
    value: 'rider',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Scorer',
    value: 'scorer',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Secretary',
    value: 'secretary',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Show Manager',
    value: 'showManager',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Steward',
    value: 'steward',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Technical Delegate',
    value: 'technicalDelegate',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Veterinarian',
    value: 'veterinarian',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
  {
    label: 'Volunteer',
    value: 'volunteer',
    dark_icon: '/assets/og_icons/Name Tag.svg',
  },
]

type IUserProfileProps = {
  myId?: string
  isOwner: boolean
  user: ITeamMember
  handleModal?: any
  setOwnerRole?: any
  teamMembersList?: ITeamMember[]
}

// User profile card
export const UserProfileSection = ({
  user,
  myId,
  handleModal,
  teamMembersList,
  setOwnerRole,
  ...props
}: IUserProfileProps) => {
  // Hooks and vars
  const isOwner = props.isOwner
  const dispatch = useAppDispatch()
  const profileData = useAppSelector(selectProfileData)

  let data = {
    defaultRole: user?.defaultRole,
    authorized: user?.memberAuthorized ? 'authorized' : 'unauthorized',
    memberAuthorized: user?.memberAuthorized,
  }

  // Functions
  const handleChange = (type: string, e?: any) => {
    if (type === 'roleChange') {
      data = {
        ...data,
        defaultRole: e.value,
      }
    } else if (type === 'statusChange') {
      data = {
        ...data,
        authorized: e.value,
        memberAuthorized: e.value === 'authorized' ? '1' : '0',
      }
    }

    if (user?.memberId === myId && data?.defaultRole) {
      setOwnerRole(data?.defaultRole ?? null)

      dispatch(
        setUserProfileData({
          ...profileData,
          userDefaultRole: data?.defaultRole,
        })
      )
    }
    if (teamMembersList && teamMembersList.length) {
      const teamMembersUpdatedArr = teamMembersList.map((curr) => {
        if (curr.memberId === user?.memberId) return { ...curr, ...data }
        else return curr
      })

      dispatch(setUserProfileData({ ...profileData, userTeamMembers: teamMembersUpdatedArr }))
    }
  }

  const handleRemoveMember = () => {
    handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
      type: user?.defaultRole as ITeamMemberRoles,
      id: user?.memberId,
      modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
    })
  }

  return (
    <div className="flex userList text-nr mb-3">
      <div className="flex flex-wrap md:flex-nowrap w-full items-center gap-3 md:gap-0 relative">
        <div className="flex items-center w-full md:w-1/4 md:mr-2 my-2 shrink-0">
          <img
            src={isOwner ? 'assets/og_icons/User-1.svg' : 'assets/og_icons/UserGroups-1.svg'}
            className="mr-3 w-6 h-6"
            alt="userIcon"
          />
          <p>{isOwner ? 'You' : 'Team member'}</p>
        </div>

        <div className="user flex flex-row items-center rounded-xl py-1 px-2 border border-[#D3DAEE] text-md mr-2 h-[51px] w-full md:max-w-[250px] min-h-[56px]">
          <div
            className={clsx(
              'w-[40px] h-[40px] mr-3 shrink-0',
              !(user?.memberProfilePicture && user?.memberProfilePicture !== '') ? 'p-0.5' : ''
            )}
          >
            <Link
              to={isOwner ? '/allevent' : `${CONST.ROUTES.USER_PROFILE.URL}/${user.memberId}`}
              className="cursor-pointer"
            >
              <img
                src={
                  user?.memberProfilePicture && user?.memberProfilePicture !== ''
                    ? user?.memberProfilePicture
                    : '/assets/placeholders/Emptyuser.png'
                }
                className="rounded-md object-cover w-full h-full"
                onError={(e) => {
                  e.currentTarget.src = '/assets/placeholders/Emptyuser.png'
                }}
                alt="demoUser"
              />
            </Link>
          </div>
          <div className="hourseDetails flex-grow">
            <div className="hourseTitle text-nr capitalize">{user.memberName}</div>
          </div>
        </div>

        <div className="user flex flex-row items-center mr-2 w-full md:max-w-[250px] relative">
          <Select
            isMulti={false}
            onChange={(e: any) => handleChange('roleChange', e)}
            placeholder="Select..."
            value={TeamMemberRole.find((e) => e.value === user.defaultRole)}
            options={TeamMemberRole}
            menuPlacement="auto"
            getOptionLabel={(props: any) => {
              const { dark_icon, label } = props
              return (
                <div className="flex items-center gap-2">
                  {dark_icon && <LabelIcon src={dark_icon} />}
                  <span>{label}</span>
                </div>
              ) as unknown as string
            }}
            className="rounded-xl w-full border-[#D3DAEE] border py-0.5"
            isClearable={false}
            styles={customStyles_}
            isSearchable={true}
          />
        </div>

        {!isOwner ? (
          <div className="user flex flex-row items-center mr-2 w-full md:max-w-[250px] relative">
            <Select
              isMulti={false}
              onChange={(e: any) => handleChange('statusChange', e)}
              placeholder="Select..."
              value={TeamMemberStatus.find((e) => e.value === user.authorized)}
              options={TeamMemberStatus}
              getOptionLabel={(props: any) => {
                const { dark_icon, label } = props
                return (
                  <div className="flex items-center gap-2">
                    {dark_icon && <LabelIcon src={dark_icon} />}
                    <span>{label}</span>
                  </div>
                ) as unknown as string
              }}
              className="rounded-xl w-full border-[#D3DAEE] border py-0.5"
              isClearable={false}
              styles={customStyles_}
              isSearchable={true}
            />
          </div>
        ) : null}

        {!isOwner ? (
          <CancelOutlined
            className="cursor-pointer text-[#898989] absolute md:relative top-0 right-0"
            onClick={handleRemoveMember}
          />
        ) : null}
      </div>
    </div>
  )
}

export default UserProfileSection
