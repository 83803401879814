// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useState, useEffect, useRef } from 'react'
import GlobalButton from './Components/GlobalButton'
import DrawerComponent from './Components/DrawerComponent'

import verifiedIcon from './assets/verified-icon.svg'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import FirestoreService from '../../../../services/firestoreService'
import { CONST } from '../../../../const/const'
import { where } from 'firebase/firestore'
import { UserModel } from '../../../../models/users/user.model'
import { getConvertedData } from '../../../../models/interface.helper'
import { IUserInterface } from '../../../../models/users/user.interface'
import { useDebounceWithSetter } from '../../../../hooks/useDebounceWithSetter'
import ViewsLoader from '../../../loader/ViewsLoader'
import { IUserNotificationInterace } from '../../../../models/user_notifications/notification.interface'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'
import { UserNotificationModal } from '../../../../models/user_notifications/notification.model'
import { selectBasicEventDetails } from '../../../../store/events/eventsSlice'
import helpers from '../../../../commonHelpers/helpers'
import { getUserFullName } from '../../../../helpers/helpers'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ButtonContainer: React.FC<{ children: any; className?: string }> = ({
  children,
  className,
}) => <div className={`w-[80%] flex flex-col gap-3 p-4 mx-auto ${className}`}>{children}</div>

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SelectBox: React.FC<{
  member: IUserInterface
  selected: boolean
  onClick: (caption: IUserInterface) => void
}> = ({ member, selected, onClick }) => (
  <div
    className={`p-4 cursor-pointer mb-2 rounded-2xl flex items-center gap-4 text-[14px] border-[#D3DAEE]`}
    onClick={() => onClick(member)}
  >
    <div className="rounded-full overflow-hidden w-10 h-10">
      <img
        src={member.userProfilePicture ? member.userProfilePicture : '/assets/cp_icons/User.svg'}
        alt="icon"
        className="h-full w-full object-cover"
      />
    </div>
    <div className="flex-1">
      <div>{getUserFullName(member)}</div>
      <div className="opacity-50">{member.userEmail}</div>
    </div>
    <div>{selected && <img src={verifiedIcon} alt="verified" />}</div>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserSearchModal: React.FC<{
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}> = ({ handleModal, dataToPassOn }) => {
  const { debouncedValue, debounceSetter } = useDebounceWithSetter({
    delay: 2000,
    value: null,
  })

  const [selection, setSelection] = useState<IUserInterface[]>([])
  const [title, setTitle] = useState('')

  const userId = useAppSelector(selectUserId)
  const eventId = useAppSelector(selectBasicEventDetails)
  const USER = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS
  const USER_NOTIFICATION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_NOTIFICATION

  const [loading, setLoading] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)
  const searchval = useRef('')
  const [allMembers, setAllMembers] = useState<IUserInterface[]>([])

  useEffect(() => {
    if (debouncedValue !== null && debouncedValue !== '') {
      getUser(debouncedValue && debouncedValue !== null ? debouncedValue : null)
    } else {
      setAllMembers([])
      setLoading(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  async function getUser(value: string | null) {
    try {
      const fetch_user = await FirestoreService.filterItems(USER.NAME, [
        where(USER.FIELDS.USER_TYPE.KEY, '==', USER.FIELDS.USER_TYPE.VALUES.COMPETITOR),
        ...(value === null
          ? []
          : [where(USER.FIELDS.USER_NAME_NGRAMS.KEY, 'array-contains', value)]),
      ])

      if (fetch_user.size) {
        const user: IUserInterface[] = []
        fetch_user.forEach((curr) => {
          user.push(getConvertedData(UserModel.fromFirestoreDoc(curr).toObject()))
        })
        if (searchval.current === '') {
          setAllMembers([])
          return 0
        }
        setAllMembers(user)
      } else {
        setAllMembers([])
      }

      setLoading((prev) => (prev < 1 ? 0 : prev - 1))
    } catch (error) {
      setLoading((prev) => (prev < 1 ? 0 : prev - 1))
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const changeHandle = (value: IUserInterface) => {
    if (selection.find((obj: any) => obj.id === value.id)) {
      setSelection(selection.filter((item: any) => item.id !== value.id))
    } else {
      let temp = [...selection, value]
      setSelection(temp)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const optionExistanceCheckHandle = (value: IUserInterface) => {
    if (selection.find((obj: any) => obj.id === value.id)) return true
    return false
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const saveHandle = async () => {
    try {
      setButtonLoading(true)
      const notification_data: IUserNotificationInterace = {
        senderId: userId || '',
        receiver_type: USER_NOTIFICATION.FIELDS.RECEIVER_TYPE.VALUE.LOGGED_IN,
        receiverId: '',
        eventId: eventId.id,
        status: USER_NOTIFICATION.FIELDS.STATUS.VALUE.UNSEEN,
        notification_type: USER_NOTIFICATION.FIELDS.NOTIFICATION_TYPE.VALUE.INVITE_MEMBER,
      }

      if (selection?.length) {
        let i = 0

        while (i < selection?.length) {
          notification_data.receiverId = selection[i].id

          let insert_data = new UserNotificationModal(notification_data).toFirestore()

          await FirestoreService.createItem(USER_NOTIFICATION.NAME, insert_data)

          i++
        }
      }

      handleModal(false, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS)

      let successModalMessage = ''
      switch (dataToPassOn?.type) {
        case 1:
          successModalMessage = 'Prize list sent'
          break
        case 2:
          successModalMessage = 'Invites sent'
          break
        case 3:
          successModalMessage = 'Invites sent'
          break
        case 4:
          successModalMessage = 'Invites sent'
          break
      }

      handleModal(true, MODAL_CONSTS.SUCCESS, {
        message: successModalMessage,
      })
      setButtonLoading(false)
    } catch (error) {
      helpers.logger({
        message: error,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const typeHandle = () => {
    switch (dataToPassOn?.type) {
      case 1:
        setTitle('Select members to send prize list')
        break
      case 2:
        setTitle('Invite accounts to vendor or sponsor show...')
        break
      case 3:
        setTitle('Invite nearbye businesses to advertise...')
        break
      case 4:
        setTitle('Invite members to this event')
        break
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const searchMembers = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueToSearch = e.target.value

    searchval.current = valueToSearch

    setLoading((prev) => prev + 1)

    debounceSetter(valueToSearch)
    // searchTerm.current = valueToSearch
    // setSelection([])
    // setFilteredMembers(() => {
    //     let allMembers_ = [...allMembers];
    //     allMembers_ = allMembers_.filter(currMember => currMember
    //         ?.name
    //         ?.toLocaleLowerCase()
    //         ?.startsWith(valueToSearch))
    //     return allMembers_
    // })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    typeHandle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn?.type])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent
      show={true}
      onHide={() => handleModal(false, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS)}
      title={title}
      titleClassName="!text-SeabiscuitDark200ThemeColor !font-normal"
      maxWidth={640}
    >
      <div className="pr-2 px-4">
        <div className="flex gap-2 border-[#1F417315] border-solid border-[1px] rounded-full px-6 py-1">
          <input
            type="text"
            className="!border-none !outline-none flex-1"
            placeholder="Search members..."
            onChange={searchMembers}
          />
          <img src="/assets/og_icons/Search-2.svg" alt="search" />
        </div>
        {loading ? (
          <div className="flex justify-center items-center max-h-[calc(100vh_-_350px)] min-h-[50vh]">
            <ViewsLoader size="xl" color="red" />
          </div>
        ) : allMembers?.length ? (
          <div className=" max-h-[calc(100vh_-_350px)] min-h-[50vh] overflow-auto">
            {allMembers.map((member: IUserInterface, index: number) => (
              <SelectBox
                key={`${JSON.stringify(member)}${index}`}
                member={member}
                onClick={changeHandle}
                selected={optionExistanceCheckHandle(member)}
              />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center w-full max-h-[calc(100vh_-_350px)] min-h-[50vh] text-center text-SeabiscuitDark200ThemeColor">
            {searchval.current !== '' ? 'No result found' : ''}
          </div>
        )}
      </div>
      <ButtonContainer className="mt-2 max-w-[260px]">
        <GlobalButton
          caption="Send"
          arrowIcon={true}
          type={1}
          disabled={buttonLoading}
          loading={buttonLoading}
          onClick={saveHandle}
        />
      </ButtonContainer>
    </DrawerComponent>
  )
}

export default UserSearchModal
