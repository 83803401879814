import { useHistory } from 'react-router-dom'

import { useIonRouter } from '@ionic/react'

// Constants
import { CONST } from '../../../../const/const'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { disciplineData } from '../../../../fakeData/disciplineList'

// Types
import { IHorseData } from '../../../../models/horse/horse.interface'

const ProfileHorseDataTab = ({ horses }: { horses: IHorseData[] }) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()

  const getHorseDiscipline = (discipline: any) => {
    const horseBreed = disciplineData.filter((i: any) => {
      return i.value === discipline
    })
    return horseBreed[0]?.label
  }

  const handleHorseData = async (id: string | undefined) => {
    router.push(`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${id}`)
    history.push(`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${id}`)
  }

  return (
    <>
      <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">HORSES</div>
      {horses.length ? (
        horses.map((horse, index) => {
          return (
            <div key={`horse_tab_horse${index}`} onClick={() => handleHorseData(horse.id)}>
              <div
                className="horse flex flex-row flex-wrap items-center rounded-lg py-1 px-2 cursor-pointer"
                key={`teamMember${index}`}
              >
                <img
                  src={horse?.horseProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.HORSE}
                  className="mr-2 w-[45px] h-[45px] rounded-full"
                  alt="horse_image"
                  onError={(e) => {
                    e.currentTarget.src = IMAGE_CONSTS.PLACEHOLDERS.HORSE
                  }}
                />

                <div className="hourseDetails flex flex-col items-start">
                  <div className="hourseTitle text-SeabiscuitDark200ThemeColor text-md font-medium capitalize">
                    {horse?.horseName}
                  </div>
                  <div className="horse_details text-[10px] text-SeabiscuitLightParagraphTextColor flex flex-wrap capitalize">
                    <span className="flex items-center text-sm mr-1">
                      {getHorseDiscipline(horse?.horseDiscipline)}
                      {horse.horseZone && horse.horseZone !== ''
                        ? ` • ${horse.horseZone === 'international' ? '' : 'Zone'} ${horse.horseZone}`
                        : null}
                    </span>
                  </div>
                </div>
              </div>

              {horses.length - 1 > index && <hr className=" md:my-2 my-1" />}
            </div>
          )
        })
      ) : (
        <div className="w-full text-SeabiscuitLightParagraphTextColor/50 text-center">
          No data found
        </div>
      )}
    </>
  )
}

export default ProfileHorseDataTab
