import moment from 'moment'
import { PDFImage, StandardFonts, cmyk } from 'pdf-lib'
import { collection, doc, where } from 'firebase/firestore'

import FirebaseApp from '../../../services/firebaseApp'
import FirestoreService from '../../../services/firestoreService'
import FirebaseStorageService from '../../../services/storageService'
import TimeLib from '../../../lib/Time'
import PdfService from '../../../services/pdfService'

import { IDocument } from '../../../fakeData/fakeDocumentList'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../../models/users/user.interface'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

import { sendEmailForSign } from '../sendEmail/sendEmailForSign'
import { capitalize, createString, getUserFullName } from '../../../helpers/helpers'
import { getConvertedData } from '../../../models/interface.helper'

import { CONST } from '../../../const/const'
import {paperworkPdfPageParams} from "../../../helpers/paperwork";

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  registrationByDay?: IRegistrationByDayInterface
  isMature: boolean
  isStaff?: boolean
  isSigned?: boolean
  eventDraftId: string
  teamMemberRole?: string[]
  index?: number
}

type TCertificate = {
  SX: number
  SY: number
  EX: number
  EY: number
}

export const USDFLiabilityWaiverPDFTemplate = async (
  {
    userData,
    EventDetails,
    isStaff,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    registrationByDay,
    isMature,
    eventDraftId,
    isSigned,
    teamMemberRole,
    index,
  }: IPaperworkTemplateData,
  customDocId?: string
) => {
  const d = new Date()
  let time = d.getTime()

  const pdfService = new PdfService(paperworkPdfPageParams)
  pdfService.registerFontkit()

  // Fonts
  const boldFont = await pdfService.embedFont(StandardFonts.TimesRomanBold)
  const timeRomanFont = await pdfService.embedFont(StandardFonts.TimesRoman)
  const timeRomanBoldItalicFont = await pdfService.embedFont(StandardFonts.TimesRomanBoldItalic)
  const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
  const signatureFont = await pdfService.embedFont(fontByte)

  /**
   * -----------------------------------------------------
   * First page
   * -----------------------------------------------------
   */
  const { shortLocation } = await pdfService.writeFirstPage({
    title: `USDF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
    EventDetails,
    organizerDetails,
    isStaff,
    registrationByDay,
    ridersTeamMember,
    teamMemberRole,
  })

  // Second Page %%%%%%%%%%%%%%%%%
  const secondPage = await pdfService.addNewPdfPage()
  secondPage.drawText('ASSUMPTION OF RISK, WAIVER AND RELEASE OF', {
    x: 45,
    y: 680,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`LIABILITY`, { x: 45, y: 660, font: boldFont, size: 16 })
  secondPage.drawText(`USDF Liability Waiver, ${isMature ? '18+' : 'Minor'}`, {
    x: 45,
    y: 640,
    font: boldFont,
    size: 16,
  })

  secondPage.drawText(`${EventDetails.competitionName}`, {
    x: 45,
    y: 600,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 45,
    y: 580,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${shortLocation}`, { x: 45, y: 560, font: timeRomanFont, size: 14 })

  secondPage.drawText(
    `I ${ridersTeamMember.teamMemberName} (hereafter, "Participant", which term includes Participant’s parent or legally-appointed`,
    { x: 45, y: 520, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `Guardian, if a minor), freely and voluntarily seek to participate in any or all programs,`,
    { x: 45, y: 500, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `events and/or activities sanctioned, produced, or sponsored by the United States Dressage`,
    { x: 45, y: 480, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `Federation (“USDF”) that include educational and training programs, youth programs,`,
    { x: 45, y: 460, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `clinics, and/or competitions at any time and at any location. These activities, programs, and`,
    { x: 45, y: 440, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `events will hereafter be referred to as “the Activities,” and the USDF, together with its`,
    { x: 45, y: 420, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `sponsors, managers, property owners, officials, organizers and affiliates and their respective`,
    { x: 45, y: 400, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `directors, officers, members, employees, agents, volunteers, representatives, and designated`,
    { x: 45, y: 380, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`officials will collectively be referred to as "Event Sponsor.”`, {
    x: 45,
    y: 360,
    font: timeRomanFont,
    size: 12,
  })

  secondPage.drawText(
    `In consideration of the Event Sponsor allowing Participant to participate in the Activities,`,
    { x: 45, y: 320, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`now and in the future, Participant agrees as follows:`, {
    x: 45,
    y: 300,
    font: timeRomanFont,
    size: 12,
  })

  secondPage.drawText(`1. Acknowledgment of Inherent Risks of Equine Activities/Assumption of.`, {
    x: 45,
    y: 260,
    font: timeRomanBoldItalicFont,
    size: 14,
  })
  secondPage.drawText(`Risks. Participant`, { x: 45, y: 240, font: timeRomanFont, size: 12 })

  secondPage.drawText(`acknowledges that there are numerous inherent risks of equine activities,`, {
    x: 140,
    y: 240,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(
    `whether preparing for, entering, attending, participating in, or leaving the Event. The `,
    { x: 45, y: 220, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `inherent risks include those dangers and conditions which are an integral part of equine activities,`,
    { x: 45, y: 200, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `including, but not limited to: (a) the propensity of an equine or other animal to behave in ways `,
    { x: 45, y: 180, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `that may result in injury, harm, or death to persons on or around them; (b) the unpredictability `,
    { x: 45, y: 160, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `of the equine’s reaction to such things as sounds, sudden movements and unfamiliar objects,`,
    { x: 45, y: 140, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `persons, or other animals; (c) certain hazards such as surface or subsurface conditions; (d)`,
    { x: 45, y: 120, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `collisions with other animals or objects; (e) the potential of a participant or other Participant `,
    { x: 45, y: 100, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    ` to act in a negligent manner that may contribute to injury to the participant, Participant, or`,
    { x: 45, y: 80, font: timeRomanFont, size: 12 }
  )

  // Third Page %%%%%%%%%%%%%%%%%
  const thirdPage = await pdfService.addNewPdfPage()
  thirdPage.drawText(
    `others, such as failing to maintain control over the equine or not acting within his or her ability;`,
    { x: 45, y: 680, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    ` (f) the breakage or failure of tack or other equipment; (g) the potential that an equine or animal `,
    { x: 45, y: 660, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `may cause injury or harm to the rider or other persons or animals in the vicinity; (h) and`,
    { x: 45, y: 640, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `the potential transmission of communicable diseases to both humans and equines. Participant`,
    { x: 45, y: 620, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `is not relying on Event Sponsor to list within this document all possible inherent risks or`,
    { x: 45, y: 600, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(`all risks of participating in any of the Activities at any location.`, {
    x: 45,
    y: 580,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`2. Waiver and Release of Liability.`, {
    x: 45,
    y: 540,
    font: timeRomanBoldItalicFont,
    size: 14,
  })

  thirdPage.drawText(`With full knowledge and appreciation of these`, {
    x: 255,
    y: 540,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(
    `and other inherent risks associated with equine activities and the Activities, Participant`,
    { x: 45, y: 520, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    ` freely and voluntarily assumes the risks of the equine activities involved in any aspect of`,
    { x: 45, y: 500, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `them. In this connection, Participant also voluntarily agrees to waive any and all rights to`,
    { x: 45, y: 480, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `sue and hereby releases the Event Sponsor from all liability, loss, claims, or actions for `,
    { x: 45, y: 460, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `injury, death, expenses, or damage to person or property resulting from the inherent risks of `,
    { x: 45, y: 440, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `the Event, or resulting from any action or inaction by the Event Sponsor. This waiver and `,
    { x: 45, y: 420, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `release is effective even if the injury, death or damage to person or property is caused`,
    { x: 45, y: 400, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    ` by, or contributed to by, actions or failure to act of the Event Sponsor and which actions`,
    { x: 45, y: 380, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `or inactions constitute ordinary negligence or a violation of any applicable law pertaining `,
    { x: 45, y: 360, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `to equine activity liabilities. Neither Participant nor Participant's representatives shall`,
    { x: 45, y: 340, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `make any claim against, maintain an action against, or recover from the Event Sponsor or its`,
    { x: 45, y: 320, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `sponsors, directors, officers, members, employees, agents, volunteers, representatives,`,
    { x: 45, y: 300, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `designated officials, or others acting on their behalf for injury, loss, damage or death of the`,
    { x: 45, y: 280, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `Participant to the Participant’s horse, or to the Participant’s personal property (regardless of `,
    { x: 45, y: 260, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `ordinary negligence by the Event Sponsor or regardless of an alleged violation of`,
    { x: 45, y: 240, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(`applicable equine activity liability law`, {
    x: 45,
    y: 220,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`3. Equine Liability Act.`, {
    x: 45,
    y: 180,
    font: timeRomanBoldItalicFont,
    size: 14,
  })

  thirdPage.drawText(`Should the Activities take place in a state with an equine activity`, {
    x: 190,
    y: 180,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(
    `liability law, Participant acknowledges reading the applicable state warnings`,
    { x: 45, y: 160, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(`and/or provisions set forth below and on the next page (if any).`, {
    x: 45,
    y: 140,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`${EventDetails.competitionState} - ${EventDetails.competitionCountry}`, {
    x: 45,
    y: 120,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`4. Miscellaneous.`, { x: 45, y: 80, font: timeRomanBoldItalicFont, size: 14 })

  thirdPage.drawText(`This document is intended to be as broad and inclusive as applicable `, {
    x: 150,
    y: 80,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(
    `state law permits. If any clause conflicts with applicable law, only that clause will`,
    { x: 45, y: 60, font: timeRomanFont, size: 12 }
  )

  // Fourth Page %%%%%%%%%%%%%%%%%
  const fourthPage = await pdfService.addNewPdfPage()
  fourthPage.drawText(`be void but the remainder shall stay in full force and effect.`, {
    x: 45,
    y: 680,
    font: timeRomanFont,
    size: 12,
  })
  fourthPage.drawText(`I HAVE READ THIS ASSUMPTION OF RISK, WAIVER AND RELEASE OF LIABILITY, I`, {
    x: 45,
    y: 640,
    font: timeRomanFont,
    size: 12,
  })
  fourthPage.drawText(`UNDERSTAND THAT IT IS RELEASE OF CLAIMS AND THAT I AM ASSUMING RISKS,`, {
    x: 45,
    y: 620,
    font: timeRomanFont,
    size: 12,
  })
  fourthPage.drawText(`INHERENT TO MY PARTICIPATION AND I AGREE TO BE FULLY BOUND BY ITS TERMS`, {
    x: 45,
    y: 600,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(`Signers name :`, { x: 45, y: 560, font: boldFont, size: 12 })
  fourthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
    x: 130,
    y: 560,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(`Date :`, { x: 45, y: 540, font: boldFont, size: 12 })
  fourthPage.drawText(
    `${moment(getConvertedData({ date: TimeLib.utcTimestamp() }).date).format('MM-DD-YYYY')}`,
    { x: 80, y: 540, font: timeRomanFont, size: 12 }
  )

  fourthPage.drawText(`Signature:`, { x: 45, y: 520, font: boldFont, size: 12 })

  if (isSigned) {
    let AllLogs: {
      logs: any
      id?: string
      signatoryId?: string
      riderId?: string
      eventId?: string
    }

    AllLogs = {
      id: '',
      signatoryId: ridersTeamMember.teamMemberId ?? '',
      riderId: ridersTeamMember.riderId ?? '',
      eventId: ridersTeamMember.eventId ?? '',
      logs: [
        {
          time: TimeLib.utcTimestamp(),
          audit: `Document signed by ${ridersTeamMember.teamMemberName}`,
        },
      ],
    }

    fourthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 45,
      y: 425,
      font: signatureFont,
      size: 28,
    })

    const auditTrailPage = await pdfService.addNewPdfPage()

    let certificateBorder = [
      { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
      { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
      { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
      { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
    ]

    certificateBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    let documentRef = doc(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME)
    )
    let id = documentRef.id
    let documentNameAsPerPdf = `USDF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`
    auditTrailPage.drawText(`Signature Certificate`, {
      x: 80,
      y: 640,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 14,
    })
    auditTrailPage.drawText(`Document Name: ${documentNameAsPerPdf}`, {
      x: 80,
      y: 630,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })
    auditTrailPage.drawText(`Unique Document Id: ${id}`, {
      x: 80,
      y: 620,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 8,
    })

    let Image = ridersTeamMember.teamMemberProfilePicture
      ? ridersTeamMember.teamMemberProfilePicture
      : '/assets/img/Empty user.png'
    let imagetype: string = ''

    const getImageArray = async (imageUrl: string) => {
      return await fetch(imageUrl).then((res) => {
        const contentType = res.headers.get('content-type') ?? ''
        imagetype = contentType?.split('/')[1]
        return res.arrayBuffer()
      })
    }

    let jpgImageBy = await getImageArray(Image)

    let userImage: PDFImage
    if (imagetype === 'jpeg') {
      userImage = await pdfService.embedJpg(jpgImageBy)
    } else if (imagetype === 'png') {
      userImage = await pdfService.embedPng(jpgImageBy)
    } else {
      const defaultImage = await getImageArray('/assets/img/Empty user.png')
      userImage = await pdfService.embedPng(defaultImage)
    }

    auditTrailPage.drawImage(userImage, {
      x: 80,
      y: 480,
      width: 80,
      height: 80,
      opacity: 1,
    })

    // //%%%%%%%%%%%
    const capatlize_name: string = capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    })
    auditTrailPage.drawText(capatlize_name, { x: 170, y: 550, color: cmyk(0, 0, 0, 0.9), size: 10 })
    auditTrailPage.drawText(`Security Level: Email`, {
      x: 170,
      y: 535,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    let signatureBorder = [
      { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
      { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
      { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
      { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
    ]

    signatureBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    auditTrailPage.drawText(`Digital Signature:`, {
      x: 340,
      y: 540,
      color: cmyk(0, 0, 0, 0.9),
      font: boldFont,
      size: 11,
    })
    auditTrailPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 340,
      y: 510,
      color: cmyk(0, 0, 0, 0.9),
      font: signatureFont,
      size: 28,
    })

    if (AllLogs.logs && AllLogs.logs.length) {
      auditTrailPage.drawText(`Timestamp`, {
        x: 110,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      auditTrailPage.drawText(`Audit Trails`, {
        x: 270,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      let logY = 420
      AllLogs.logs.forEach((log: any) => {
        let parsedDate = Date.parse(getConvertedData({ time: log.time }).time)

        let firstLineText = ''
        let secondLineText = ''
        let thirdLineText = ''

        if (log.audit.length > 40 && log.audit.length < 80) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
        } else if (log.audit.length > 40 && log.audit.length < 120) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
          thirdLineText = log.audit.slice(81, 120)
        } else if (log.audit.length < 40) {
          firstLineText = log.audit
        }

        auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
          x: 110,
          y: logY,
          color: cmyk(0, 0, 0, 0.9),
          size: 8,
        })

        if (firstLineText && firstLineText !== '') {
          auditTrailPage.drawText(`${firstLineText}`, {
            x: 270,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (secondLineText && secondLineText !== '') {
          auditTrailPage.drawText(`${secondLineText}`, {
            x: 270,
            y: logY - 10,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (thirdLineText && thirdLineText !== '') {
          auditTrailPage.drawText(`${thirdLineText}`, {
            x: 270,
            y: logY - 20,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        logY -= 40
        if (logY < 140) {
          logY = 620

          let certificateBorder = [
            { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
            { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
            { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
            { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
          ]

          certificateBorder.forEach((item: TCertificate) => {
            auditTrailPage.drawLine({
              start: { x: item.SX, y: item.SY },
              end: { x: item.EX, y: item.EY },
              thickness: 2,
              color: cmyk(0, 0, 0, 0.5),
              opacity: 0.8,
            })
          })
        }
      })
    }

    auditTrailPage.drawLine({
      start: { x: 70, y: 140 },
      end: { x: 470, y: 140 },
      thickness: 2,
      color: cmyk(0, 0, 0, 0.5),
      opacity: 0.8,
    })

    auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
      x: 160,
      y: 110,
      font: boldFont,
      size: 10,
    })
    auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
      x: 170,
      y: 90,
      font: boldFont,
      size: 10,
    })
  }

  const pdfBytes = await pdfService.generatedPdf

  // Step 1: Convert Uint8Array to Blob
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // Step 2: Convert Blob to File
  var file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/documents/${ridersTeamMember.teamMemberRole}${time}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
  )

  const USER_DOCUMENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS
  let retunedData: any = {}

  try {
    const eventsSnapshot = await FirestoreService.filterItems(USER_DOCUMENT.NAME, [
      where(USER_DOCUMENT.FIELDS.SIGNATORY_ID.KEY, '==', ridersTeamMember.teamMemberId),
      where(USER_DOCUMENT.FIELDS.EVENT_ID.KEY, '==', eventId),
      where(USER_DOCUMENT.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
      where(USER_DOCUMENT.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
      where(USER_DOCUMENT.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY, '==', ridersTeamMember.teamMemberRole),
      where(USER_DOCUMENT.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY, '==', registrationByDay?.id),
    ])

    let obj = {
      documentNameAsPerPdf: `USDF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
      documentName: paperwork.key,
      documentOriginalName: paperwork.document_name,
      documentUrl: (fileUrl as string) ?? '',
      eventId,
      eventLogo: EventDetails.eventLogo,
      eventName: EventDetails.competitionName,
      status: isSigned ? 'Signed' : 'Not Signed',
      riderId: ridersTeamMember.riderId,
      riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
      signatoryId: ridersTeamMember.teamMemberId,
      signatoryName: ridersTeamMember.teamMemberName,
      signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
      activityUser: getUserFullName(userData),
      documentOwner: ridersTeamMember.teamMemberId,
      competitorId: userData.id,
      signatoryDefaultRole: ridersTeamMember.teamMemberRole,
      signatoryEmail: ridersTeamMember.teamMemberEmail,
      reminder: false,
      registrationDocId: ridersTeamMember.registrationDocId,
      riderTeamMemberDocId: ridersTeamMember.id,
      registrationByDayDocId: registrationByDay?.id,
      registrationByDayUuid: registrationByDay?.uuid,
    }

    if (eventsSnapshot.size) {
      let docId = ''
      eventsSnapshot.forEach((doc) => {
        docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
      })

      await FirestoreService.updateItem(USER_DOCUMENT.NAME, customDocId || docId, obj)

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        customDocId || docId,
        {
          id: customDocId || docId,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USDF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
      retunedData = {
        docId: customDocId || docId,
      }
    } else {
      const document = await FirestoreService.createItem(USER_DOCUMENT.NAME, obj)
      retunedData = {
        docId: document.id,
      }
      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        document.id,
        {
          id: document.id,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USDF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
    }
  } catch (err: any) {
    console.log(err, 'error')
  }

  if (fileUrl && fileUrl !== '' && eventId) {
    await sendEmailForSign({
      ridersTeamMember,
      paperwork,
      paperworkUrl: fileUrl as string,
      eventId,
      isStaff,
      isMature,
      eventDraftId,
      senderData: {
        senderName: getUserFullName(userData),
        senderEmail: userData.userEmail || '',
      },
    })
  }

  return retunedData
}
