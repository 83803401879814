import EventDetailsViewComponentDataKey from './EventDetailsViewComponentDataKey'
import EventDetailViewComponentFeeSection from './EventDetailsViewComponentFees'
import EventDetailViewComponentPolicies from './EventDetailViewComponentPolicies'
import EventDetailViewComponentStaffSection from './EventDetailViewComponentStaffSection'
import { RedTitle } from './global/RedTitle'
import MoreButton from './global/MoreButton'
import EventDetailViewComponentPaperwork from './EventDetailViewComponentPaperwork'
import EventDetailViewComponentPointOfContact from './EventDetailViewComponentPointOfContact'
import EventDetailViewMap from './EventDetailViewComponentMap'
import HorizantalDivider from './global/HorizantalDivider'
import EventDetailViewComponentRegisterBox from './EventDetailViewComponentRegisterBox'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { useEffect, useState } from 'react'
import { arrangeEventstaff, getConvertedData } from '../../../../models/interface.helper'
import { EventStaffModel } from '../../../../models/event-staff/event-staff.model'
import FirestoreService from '../../../../services/firestoreService'
import { CONST } from '../../../../const/const'
import {
  collection,
  DocumentData,
  documentId,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import { IEventStaffInterfaceTypes as iesit } from '../../../../models/event-staff/event-staff.interface'
import { getUserFullName } from '../../../../helpers/helpers'
import UserService from '../../../../services/userService'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import { useCollection } from 'react-firebase-hooks/firestore'
import FirebaseApp from '../../../../services/firebaseApp'
import { useParams } from 'react-router-dom'

type Props = {
  EventDetails?: any
  EventTickets?: any
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
  setExhibitorsModalShow?: any
  EventFees?: any
  EventPolicies?: any
  EventStaff?: any
  EventPaperwork?: any
  dataToPassOn?: any
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface NewlineTextProps {
  text: string
}

function NewlineText({ text }: NewlineTextProps) {
  return <span style={{ whiteSpace: 'pre-line' }}>{text}</span>
}

const EventDetailTab = (props: Props) => {
  const {
    handleModal,
    EventDetails,
    EventTickets,
    setExhibitorsModalShow,
    EventFees,
    EventPolicies,
    EventStaff,
    EventPaperwork,
    dataToPassOn,
  } = props

  const EVENT_DATA = CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS

  const params = useParams<{ eventId: string }>()
  const eventId = params?.eventId

  const ownerId = (() => {
    let id = EventDetails?.owner
    if (!id || id === '') id = EventStaff?.owner
    return id
  })()

  const EventFirebaseQueries: any[] = (function (): any {
    let queries: any = []
    if (eventId) {
      queries.push(where(documentId(), '==', eventId))
    }
    return queries
  })()

  const [eventStaffData, setEventStaffData] = useState<iesit['IStaffUiListItem'][]>([])
  const [organizerDetails, setOrganizerDetails] = useState<IUserInterface | null>(null)
  const [eventTableData, setEventTableData] = useState<IEventInterface | null>(null)

  const [event] = useCollection(
    query(collection(FirebaseApp.firestore, EVENT_DATA.NAME), ...EventFirebaseQueries),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  useEffect(() => {
    if (ownerId) {
      const getOrganizerDetails = async function () {
        const user_data_doc = await UserService.getUserInfoById(ownerId)
        let userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()
        setOrganizerDetails(userData)
      }
      getOrganizerDetails()
    }
  }, [ownerId])

  useEffect(() => {
    if (typeof dataToPassOn === 'number' && eventTableData?.id) {
      setEventTableData({
        ...eventTableData,
        waitlistCount: Number(eventTableData.waitlistCount ?? 0) + dataToPassOn,
      })
    }
  }, [dataToPassOn])

  useEffect(() => {
    const getEvents = function () {
      const fetched_events =
        event?.docs.map((doc) => {
          let EventsData = EventModel.fromFirestoreDoc(doc).toObject()
          return getConvertedData(EventsData)
        }) ?? []

      if (fetched_events.length) {
        const eventDetailData = fetched_events[0]
        setEventTableData(eventDetailData)
      }
    }
    getEvents()
  }, [event])

  useEffect(() => {
    async function updateStaffUi() {
      const savedEventStaff = new EventStaffModel(EventStaff)
      let users: IUserInterface[] = []
      let userIdsToFind: string[] = []
      let foundUser: IUserInterface | null = null
      let usersLocal: iesit['IStaffUiItem'][] = []
      let usersSnap: QueryDocumentSnapshot<DocumentData>[] = []
      let structuredStaffs = arrangeEventstaff(savedEventStaff)

      structuredStaffs.forEach((currItem) => {
        currItem.staffs.forEach((curr) => {
          if (typeof curr !== 'string' && !!curr) {
            userIdsToFind.push(curr.staffId)
          }
        })
      })

      userIdsToFind = [...new Set(userIdsToFind)]
      usersSnap = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsToFind)
      usersSnap.forEach((curr) => users.push(UserModel.fromFirestoreDoc(curr).toObject()))
      structuredStaffs = structuredStaffs.map((currItem) => {
        return {
          ...currItem,
          staffs: (function () {
            usersLocal = []
            currItem.staffs.forEach((currStaff) => {
              if (typeof currStaff !== 'string' && !!currStaff) {
                foundUser = users.find((user) => user.id === currStaff.staffId) ?? null
                if (foundUser)
                  usersLocal.push({
                    staffId: foundUser.id,
                    staffName: getUserFullName(foundUser),
                    staffType: currStaff.staffType,
                    staffProfilePicture: foundUser.userProfilePicture,
                  })
              }
            })
            return usersLocal
          })(),
        }
      })

      setEventStaffData(structuredStaffs)
    }

    if (EventStaff?.eventStaff?.length) updateStaffUi()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EventStaff?.eventStaff])

  // Functions

  const openMoreModal = (title: string, data: string) => {
    if (typeof props?.handleModal === 'function') {
      props?.handleModal(true, MODAL_CONSTS.SEE_MORE, {
        title,
        dataToShow: data ? <NewlineText text={data} /> : "No 'About' information available.",
        headingClassName: '!text-SeabiscuitDark200ThemeColor',
        bodyClassName: 'min-h-[60vh] text-SeabiscuitDark200ThemeColor text-[16px]',
      })
    }
  }

  return (
    <>
      {/* side card for info */}
      <div className="lg:hidden">
        <EventDetailViewComponentRegisterBox
          EventDetails={EventDetails}
          EventTickets={EventTickets}
          handleModal={handleModal}
          companyLogo={organizerDetails?.userProfilePicture}
          eventTableData={eventTableData}
          companyName={organizerDetails ? getUserFullName(organizerDetails) : ''}
          exhibitorsModal={() => setExhibitorsModalShow(true)}
        />
        <HorizantalDivider />
      </div>
      {/* side card for info */}

      <div className="hidden lg:block">
        <EventDetailsViewComponentDataKey
          EventFees={EventFees}
          EventDetails={EventDetails}
          EventTickets={EventTickets}
        />
        <HorizantalDivider />
      </div>

      {EventDetails?.summaryLong && EventDetails?.summaryLong !== '' ? (
        <>
          <RedTitle text="About" className="mb-1" />
          <p className="mt-4 text-SeabiscuitDark200ThemeColor leading-8 mb-2 overflow-hidden">
            {EventDetails?.summaryLong ? (
              <>
                {EventDetails?.summaryLong?.length > 500
                  ? `${EventDetails?.summaryLong.slice(0, 500)}... `
                  : EventDetails?.summaryLong}
                {EventDetails?.summaryLong?.length > 500 ? (
                  <MoreButton
                    onClick={() => openMoreModal('About', EventDetails?.summaryLong)}
                    className="mt-2"
                  />
                ) : null}
              </>
            ) : (
              EventDetails?.summaryLong
            )}
          </p>
          <HorizantalDivider />
        </>
      ) : null}

      <div className="block lg:hidden">
        <RedTitle text="Key data" className="mb-4 sm:mb-7" />
        <EventDetailsViewComponentDataKey
          EventFees={EventFees}
          EventDetails={EventDetails}
          EventTickets={EventTickets}
        />
        <HorizantalDivider />
      </div>

      <RedTitle className="clear-both mb-4 sm:mb-7" text="Fees" />

      <EventDetailViewComponentFeeSection EventFees={EventFees} handleModal={props.handleModal} />
      <HorizantalDivider />

      <RedTitle text="Policies" className="mb-4 sm:mb-7" />
      <EventDetailViewComponentPolicies
        handleModal={props.handleModal}
        policiesData={EventPolicies}
      />
      <HorizantalDivider />

      <RedTitle text="Staff" className="mb-4 sm:mb-7" />
      <EventDetailViewComponentStaffSection
        staffData={EventStaff}
        eventStaffData={eventStaffData}
      />
      <HorizantalDivider />

      <RedTitle text="Required Paperwork" className="mb-2" />
      <EventDetailViewComponentPaperwork
        EventPaperwork={EventPaperwork}
        EventDetails={EventDetails}
      />
      <HorizantalDivider />

      <RedTitle text="Point of contact" className="my-4 sm:my-7" />
      <EventDetailViewComponentPointOfContact eventData={EventDetails} />
      <HorizantalDivider />
      <RedTitle text="Location" className="my-4 sm:my-7" id="location_target" />
      <EventDetailViewMap eventData={EventDetails} />
    </>
  )
}

export default EventDetailTab
