import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_PROFILES = {
  NAME: 'v01_profiles',
  FIELDS: {
    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ALLOWED_UIDS: {
      KEY: 'allowedUids',
      VALUES: {
        DEFAULT: [],
      },
    },

    TOKEN: {
      KEY: 'token',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    MODIFIED: {
      KEY: 'modified',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
