import axios from 'axios'
import FirebaseApp from './firebaseApp'

type IHttpProps = {
  url: string
  method?: string
  data?: any
  params?: any
  authorization?: string
  abortSignal?: AbortSignal
}

// Constants
const IS_LOCAL_ENV = process.env.REACT_APP_LOCAL === 'LOCAL'
let baseURL = process.env.REACT_APP_GOOGLE_CLOURD_API_BASE_URL

if (IS_LOCAL_ENV) baseURL = process.env.REACT_APP_DEV_API_BASE_URL

console.log('baseURL', baseURL)

/**
 * @param props
 */
export const httpService = async ({
  url,
  method,
  data,
  params,
  abortSignal,
}: IHttpProps): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const Authorization = await FirebaseApp.auth.currentUser?.getIdToken()

    let config = {
      baseURL: `${baseURL}${url}`,
      method: method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: Authorization ? `Bearer ${Authorization}` : null,
      },
      data,
      params,
    }

    try {
      const response = await axios({
        ...config,
        ...(abortSignal && { signal: abortSignal }),
      })
      return resolve(response?.data)
    } catch (error) {
      return reject(error)
    }
  })
}

export const thirdPartyHttpService = async (props: IHttpProps) => {
  return new Promise(async (resolve, reject) => {
    var config = {
      method: 'post',
      baseURL: `${process.env.REACT_APP_THIRD_PARTY_BASE_URL}${props?.url}`,
      headers: {
        Authorization: props.authorization,
        'Content-Type': 'application/json',
        Cookie:
          '__cf_bm=N76nIomejZcr9ifMaAoDMxK74aH94wPfDM5AQQplycY-1677833137-0-Ac3JxraJ0vQj7G0/tCcbAqJpXg8M0GvnUgEUq+HO+yrqbON4A/qY8UjHrsWyld12BryREpkZ14zQa+wl/LHTGhg=',
      },
      data: JSON.stringify(props.data),
    }

    try {
      const response = await axios(config)
      return resolve(response?.data)
    } catch (error) {
      return reject(error)
    }
  })
}
