import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'
import { IEventTicketing, TicketType } from './event-ticketing.interface'
import { v4 as uuidv4 } from 'uuid'
import { selectObjToString } from '../interface.helper'

export class EventTicketingModel
  extends ModelBaseModel<IEventTicketing>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public owner: string | null
  public paymentOption: IEventTicketing['paymentOption']

  public sellSpectatorTickets: boolean
  public tickets: TicketType[] | []

  public created: Date
  public modified: Date

  public constructor(obj?: IEventTicketing) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.paymentOption = obj?.paymentOption ?? null
    this.sellSpectatorTickets = obj?.sellSpectatorTickets ?? false
    this.tickets =
      obj?.tickets && Array.isArray(obj?.tickets)
        ? obj?.tickets.map((curr) => {
            return {
              uuid: this.getUuid(curr.uuid),
              sold: this.getNum(curr?.sold),
              name: this.getString(curr?.name),
              note: this.getString(curr?.note),
              cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
              available: this.getNum(curr?.available),
              costAlias: `$${this.getNum(curr.actualCostAlias, obj?.paymentOption)}`,
              actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
              actualCost: this.getNum(curr?.actualCostAlias),
            }
          })
        : [
            {
              name: '',
              note: '',
              sold: 0,
              cost: 0,
              available: 0,
              costAlias: '$0',
              uuid: this.getUuid(),
              actualCost: 0,
              actualCostAlias: `$${this.getNum(0)}`,
            },
          ]

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  getBool(bool: any) {
    if (typeof bool !== 'boolean') return false
    return bool
  }

  getUuid(uuid?: any) {
    let uuid_
    if (typeof uuid !== 'string') uuid_ = uuidv4()
    else uuid_ = uuid
    uuid_ = uuid_.trim()
    return uuid_
  }

  getString(str?: any) {
    let strToReturn

    if (typeof str === 'object') strToReturn = selectObjToString(str)
    else if (typeof str !== 'string') strToReturn = ''
    else strToReturn = str

    strToReturn = strToReturn.trim()
    return strToReturn
  }

  getNum(number: any, paymentOption?: IEventTicketing['paymentOption']) {
    let incrementBy = 0

    switch (paymentOption) {
      case 'IncreaseAllPrices':
        incrementBy = 0.05
        break

      case 'ChargeRidersAndSpectators':
        incrementBy = 0.029
        break
    }

    if (!number) return 0

    let num

    if (typeof number === 'string')
      // eslint-disable-next-line no-useless-escape
      num = Number(number.replace(/[^0-9\..]+/gi, ''))
    else num = Number(number)

    if (isNaN(num)) return incrementBy ? incrementBy : 0

    return Number((num + num * (incrementBy ? incrementBy : 0)).toFixed(2))
  }

  static fromFirestoreDoc(doc: any) {
    return new EventTicketingModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object({}).required()
  }
}
