import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  setSendMailLoading,
} from '../../../../../store/registration/registrationSlice'
import { cloneDeep } from 'lodash'
import { IRiderTeamMemberInterface } from '../../../../../models/rider-team-member/riderTeamMember.interface'
import { createMailLog } from '../../../../../helpers/sign'
import helpers from '../../../../../commonHelpers/helpers'
import { CustomError } from '../../../../../helpers/helpers'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { IManageInfo } from '../../../event-registration-tabs/EventRegistrationTabs'
import { mergeRidersTeamMembersWithPaperworkTabData } from '../../../../../helpers/teamMembers'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import fakeDocumentList, {
  DOCUMENT_TYPES,
  getPaperWorkIcon,
  IDocument,
} from '../../../../../fakeData/fakeDocumentList'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { useParams } from 'react-router'
import ITypes from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { ILightboxProps } from '../../../../../types/competitor_types'
import { getAllClauses } from '../../../../../helpers/clauses'

interface IProps {
  index: number
  isSigned: boolean
  loading?: boolean
  paperwork: IDocument
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  isManage?: boolean
  ridersTeamMembers: IRiderTeamMemberInterface[]
  saveRidersTeamMembers: (args: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => void
  handleLightBox: (props: ILightboxProps) => void
}

export const Paperwork: React.FC<IProps> = ({
  index,
  isSigned,
  paperwork,
  loading,
  manageInfo,
  setManageInfo,
  isManage,
  ridersTeamMembers,
  saveRidersTeamMembers,
  handleLightBox,
}) => {
  const dispatch = useAppDispatch()
  const { eventId, userId } = useParams<ITypes['IParams']>()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const { EventDetails } = useAppSelector(selectedEvent)

  const [allClauses, setAllClauses] = useState<any[]>([])

  const handleSendMail = async (filteredPaperworkIndex: number) => {
    dispatch(
      setSendMailLoading({
        sendMailLoading: true,
        sendMailIndex: filteredPaperworkIndex,
      })
    )

    let paperworkTabData_ = cloneDeep(paperworkTabData)

    if (isManage) {
      paperworkTabData_ = manageInfo.paperwork
    }

    if (EventDetails && eventId) {
      try {
        let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

        if (isManage) {
          const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
          manageInfo.paperwork.forEach((paperwork) => {
            if (paperwork.ridersTeamMembers.length > 0)
              ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
          })

          ridersTeamMembers_ = ridersTeamMembersManage
        }

        const userTeamMembersWithMailLog = await createMailLog({
          ridersTeamMembers: ridersTeamMembers_,
          teamMemberId: userId,
          paperworkDocument: filteredPaperworksDocuments[filteredPaperworkIndex],
        })

        if (!userTeamMembersWithMailLog) throw new Error('Error get Rider Team Member')

        const paperworkTabData__ = mergeRidersTeamMembersWithPaperworkTabData(
          paperworkTabData_,
          userTeamMembersWithMailLog
        )

        if (isManage) {
          setManageInfo({ ...manageInfo, paperwork: paperworkTabData__ })
        } else {
          saveRidersTeamMembers({ ridersTeamMembersToUpdate: userTeamMembersWithMailLog })
        }

        setTimeout(() => {
          dispatch(
            setSendMailLoading({
              sendMailLoading: false,
              sendMailIndex: null,
            })
          )
        }, 1000)
      } catch (error: any) {
        console.log(error, 'error')
        helpers.logger({
          message: CustomError.somethingWentWrong({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: error?.devMessage ?? error?.message,
            moduleName: 'handleSendMail',
            fileName: 'CompetitorEventRegisterSignTab',
          }),
        })
        dispatch(
          setSendMailLoading({
            sendMailLoading: false,
            sendMailIndex: null,
          })
        )
      }
    }
  }

  /**
   * @param index Index of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (index: number) => {
    let paperworkImages = fakeDocumentList[1].document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: filteredPaperworksDocuments[index]?.document_name })
      return acc
    }, [])

    handleLightBox({
      visible: true,
      slides,
    })
  }

  /**
   * @info Sends mail by taking out the signatories from the document
   */
  useEffect(() => {
    if (EventDetails?.owner)
      getAllClauses(EventDetails.owner).then((data) => {
        if (data) setAllClauses(data)
      })
  }, [EventDetails])

  return (
    <div className="w-full relative p-5 border-SeabiscuitGray500ThemeColor border rounded-[8px] bg-white">
      <div className="flex flex-col sm:flex-row items-center gap-4 pb-5 border-SeabiscuitGray500ThemeColor border-b">
        <div className={'w-[45px] h-[45px] rounded-full flex items-center justify-center'}>
          <img
            className={'w-full h-auto'}
            src={getPaperWorkIcon(paperwork.document_type)}
            alt={paperwork.document_name}
          />
        </div>
        <div className="flex flex-col justify-center gap-0.5">
          <h4 className={'text-[14px]'}>{paperwork.document_name}</h4>
          <div className={'flex flex-wrap text-[12px] gap-x-2 gap-y-0.5 items-center'}>
            <button
              onClick={() => openLightBox(index)}
              className="text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
            >
              View document
            </button>
            {paperwork.document_type === DOCUMENT_TYPES.GENERAL && allClauses.length > 0 && (
              <>
                <div className={'h-[10px] w-[1px] bg-SeabiscuitDark200ThemeColor/50'} />
                <button
                  onClick={() =>
                    handleModal?.(true, MODAL_CONSTS.REVIEW_ANNEX, {
                      ...EventDetails,
                      data: {
                        eventId: eventId,
                        eventName: EventDetails?.competitionName,
                      },
                    })
                  }
                  className="text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
                >
                  View Annex
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={'mt-5'}>
        <p className={'text-[12px]'}>
          {paperwork.document_type === DOCUMENT_TYPES.GENERAL && allClauses.length > 0
            ? 'By clicking sign, you agree you have read and agree to the terms and conditions in this document and the attached annex.'
            : 'By clicking sign, you agree you have read and agree to the terms and conditions in this document.'}
        </p>
      </div>
      <div className={'mt-5'}>
        <button
          onClick={() => !isSigned && handleSendMail(index)}
          className={clsx('w-full  rounded-[8px] h-[43px] text-[14px] transition-all ', {
            'cursor-default': isSigned,
            'bg-SeabiscuitMainThemeColor text-white hover:bg-SeabiscuitMainThemeColorDark':
              !isSigned,
            'bg-SeabiscuitGreenThemeColor/15 text-SeabiscuitGreenThemeColor': isSigned,
          })}
        >
          {isSigned ? 'Signed' : 'Sign'}
        </button>
      </div>
      {loading && (
        <div className={'absolute top-0 left-0 w-full h-full bg-white/70 rounded-[8px]'}>
          <ViewsLoader size={'sm'} />
        </div>
      )}
    </div>
  )
}
