import TopNavigationCommonComponent from '../components/common/navigation/top/TopNavigationCommonComponent'
import PegasusLogo from '../icons/logo/Logo.svg'
import { useAppSelector } from '../store/hooks'
import { selectDisplayName } from '../store/user/userSlice'
import { IonContent, IonPage } from '@ionic/react'

type ICompetitorLayoutProps = {
  children: any
  handleModal?: any
  isLoggedIn?: boolean
  isPublicPage?: boolean
}

const CompetitorLayout = (props: ICompetitorLayoutProps) => {
  const displayName = useAppSelector(selectDisplayName)

  return (
    <IonPage>
      <TopNavigationCommonComponent
        logo={PegasusLogo}
        isLoggedIn={props.isLoggedIn}
        displayName={displayName}
        handleModal={props.handleModal}
      />
      <IonContent
        fullscreen
        style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}
      >
        <div className="pt-14 pb-4 min-h-full flex flex-col">{props.children}</div>
      </IonContent>
    </IonPage>
  )
}

export default CompetitorLayout
