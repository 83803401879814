// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import Profile1 from './assets/Staff/profile-pic-1.png'

import { IEventStaffInterfaceTypes as iesit } from '../../../../models/event-staff/event-staff.interface'
// import Profile2 from "./assets/Staff/profile-pic-2.png";
// import Profile3 from "./assets/Staff/profile-pic-3.png";
// import Profile4 from "./assets/Staff/profile-pic-4.png";
// import Profile5 from "./assets/Staff/profile-pic-5.png";
// import Profile6 from "./assets/Staff/profile-pic-6.png";
// import Profile7 from "./assets/Staff/profile-pic-7.png";
// import Profile8 from "./assets/Staff/profile-pic-8.png";
// import Profile9 from "./assets/Staff/profile-pic-9.png";
// import Profile10 from "./assets/Staff/profile-pic-10.png";
// import Profile11 from "./assets/Staff/profile-pic-11.png";
// import Profile12 from "./assets/Staff/profile-pic-12.png";

// import ArrowIcon from "./assets/more-icon.svg";
// import Judges from "./assets/Staff/judges.svg";
// import Stewards from "./assets/Staff/stewards.svg";
// import Veterinarians from "./assets/Staff/veterinarians.svg";
// import Volunteers from "./assets/Staff/volunteers.svg";
// import { useEffect, useState } from "react";
import { CONST } from '../../../../const/const'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

// const breakPoint = 768;

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentProfileCart: React.FC<{ picture: string, title: string, name: string }> = ({ picture, title, name }) => (
//     <div className="flex gap-2 ml-[-30px] md:ml-0">
//         <div className="flex items-center w-fit shrink-0">
//             <img src={picture} alt="profile" className="rounded-full h-[30px] 2xl:h-[45px] w-[30px] 2xl:w-[45px] object-cover shrink-0" />
//         </div>
//         <div className="flex-col hidden md:flex w-[160px]">
//             <span className="whitespace-nowrap text-ellipsis text-sm 2xl:text-nr text-SeabiscuitDark200ThemeColor text-opacity-[.5] overflow-hidden">{title}</span>
//             <span className="text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.84] ellipsis capitalize">{name}</span>
//         </div>
//     </div>
// )

const generateName = (fullName: string | undefined) => {
  return fullName
    ? fullName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase()
    : ''
}

const EventDetailViewComponentProfileCart_: React.FC<{
  name: string
  title: string
  staffId: string
  picture: string
  userId: string | null
}> = ({ picture, title, name, staffId, userId }) => (
  <Link
    to={
      staffId === userId
        ? CONST.ROUTES.ALL_EVENT.URL
        : `${CONST.ROUTES.USER_PROFILE.URL}/${staffId}`
    }
  >
    <div className="flex gap-2 ml-[-30px] md:ml-0">
      <div className="flex items-center shrink-0 rounded-full">
        <img
          src={picture}
          alt="profile"
          className="rounded-full h-[30px] 2xl:h-[45px] w-[30px] 2xl:w-[45px] object-cover shrink-0"
          onError={(e) =>
            ((e.target as any).src =
              `https://ui-avatars.com/api/?name=${generateName(name ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
          }
        />
      </div>
      <div className="flex-col hidden md:flex w-[160px]">
        <span className="whitespace-nowrap text-ellipsis text-sm 2xl:text-nr text-SeabiscuitDark200ThemeColor text-opacity-[.5] overflow-hidden">
          {title}
        </span>
        <div className="text-nr 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.84] ellipsis capitalize">
          {name}
        </div>
      </div>
    </div>
  </Link>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentProfileCartPlaceholder: React.FC<{ howMany: number }> = ({ howMany }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <>
//             {
//                 windowWidth > breakPoint &&
//                 Array(howMany).fill(0).map((r, index) => (
//                     <div className="hidden md:block" key={`EventDetailViewComponentProfileCartPlaceholder${index}`}>&nbsp;</div>
//                 ))
//             }
//         </>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentStaffSectionManagement: React.FC<{ allStaffsModal: () => void }> = ({ allStaffsModal }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])
//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <div onClick={allStaffsModal} className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
//             <div className="flex gap-2 items-center md:mb-4 flex-1">
//                 <img src="assets/og_icons/MySpace-1.svg" className="w-6 h-6" alt="management" />
//                 <div className="text-SeabiscuitDark200ThemeColor font-bold text-nr 2xl:text-base">Management</div>
//             </div>
//             <div className="flex ml-auto md:gap-x-[10px] md:gap-y-6 flex-wrap">
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile1}
//                     title="Show Manager"
//                     name="Jennifer Tankel"
//                 />

//                 <EventDetailViewComponentProfileCartPlaceholder howMany={4} />

//                 <EventDetailViewComponentProfileCart
//                     picture={Profile2}
//                     title="Secretary"
//                     name="Cindy Holmes"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile3}
//                     title="Course Designer"
//                     name="Lori Barton"
//                 />
//                 {
//                     windowWidth > breakPoint &&
//                     <>
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile4}
//                             title="Technical Delegate"
//                             name="Jason Smith"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile5}
//                             title="Announcer"
//                             name="James Macavy"
//                         />
//                     </>
//                 }
//                 <img src={ArrowIcon} alt="more" className="md:hidden" />

//             </div>
//         </div>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentStaffSectionJudges: React.FC<{ allStaffsModal: () => void }> = ({ allStaffsModal }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <div onClick={allStaffsModal} className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
//             <div className="flex gap-2 items-center md:mb-4 flex-1">
//                 <img src={Judges} alt="Judges" />
//                 <div>Judges & scorers</div>
//             </div>
//             <div className="flex ml-auto md:gap-x-[10px] md:gap-y-6 flex-wrap">
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile1}
//                     title="Judge"
//                     name="Annie Woods"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile2}
//                     title="Judge"
//                     name="Melanie Wyoming"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile3}
//                     title="Judge"
//                     name="Jacinta Burman"
//                 />
//                 {
//                     windowWidth > breakPoint &&
//                     <>
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile4}
//                             title="Judge"
//                             name="Tom Williams"
//                         />
//                         <EventDetailViewComponentProfileCartPlaceholder howMany={1} />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile5}
//                             title="Scorer"
//                             name="Jennifer Butts"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile6}
//                             title="Scorer"
//                             name="Emily Spanner"
//                         />
//                     </>
//                 }
//                 <img src={ArrowIcon} alt="more" className="md:hidden" />
//             </div>
//         </div>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentStaffSectionStewards: React.FC<{ allStaffsModal: () => void }> = ({ allStaffsModal }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <div onClick={allStaffsModal} className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
//             <div className="flex gap-2 items-center md:mb-4">
//                 <img src={Stewards} alt="Stewards" />
//                 <div>Stewards & ingates</div>
//             </div>
//             <div className="flex ml-auto md:gap-x-[10px] md:gap-y-6 flex-wrap">
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile1}
//                     title="Steward"
//                     name="Kate Wilcocks"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile2}
//                     title="Steward"
//                     name="Nacy Bets"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile3}
//                     title="Steward"
//                     name="Rease Yukolo"
//                 />
//                 <EventDetailViewComponentProfileCartPlaceholder howMany={2} />
//                 {
//                     windowWidth > breakPoint &&
//                     <>
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile4}
//                             title="Ingate"
//                             name="Kaleigh Jants"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile5}
//                             title="Ingate"
//                             name="Samantha Hurts"
//                         />
//                     </>
//                 }
//                 <img src={ArrowIcon} alt="more" className="md:hidden" />

//             </div>
//         </div>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentStaffSectionVeterinarians: React.FC<{ allStaffsModal: () => void }> = ({ allStaffsModal }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <div onClick={allStaffsModal} className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
//             <div className="flex gap-2 items-center md:mb-4">
//                 <img src={Veterinarians} alt="Veterinarians" />
//                 <div>Veterinarians & farriers</div>
//             </div>
//             <div className="flex ml-auto md:gap-x-[10px] md:gap-y-6 flex-wrap">
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile1}
//                     title="Farrier"
//                     name="Jacinta Woods"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile2}
//                     title="Veterinarian"
//                     name="Damian Nurol"
//                 />

//                 <img src={ArrowIcon} alt="more" className="md:hidden" />

//             </div>
//         </div>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const EventDetailViewComponentStaffSectionVolunteers: React.FC<{ allStaffsModal: () => void }> = ({ allStaffsModal }) => {

//     const [windowWidth, setWindowWidth] = useState(0);

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     /**
//      * @todo Document this
//      */
//     useEffect(() => {
//         const resizer = () => {
//             setWindowWidth(window.innerWidth)
//         }
//         resizer();
//         window.addEventListener("resize", resizer, true);
//         return () =>
//             window.removeEventListener("resize", resizer, true);

//     }, [])

//     // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//     return (
//         <div onClick={allStaffsModal} className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
//             <div className="flex gap-2 items-center md:mb-4">
//                 <img src={Volunteers} alt="Volunteers" />
//                 <div>Volunteers</div>
//             </div>
//             <div className="flex md:grid ml-auto xl:grid-cols-4 2xl:grid-cols-[repeat(5,20%)] md:gap-x-2 md:gap-y-6 ">
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile1}
//                     title="Volunteer"
//                     name="Jenny Williams"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile2}
//                     title="Volunteer"
//                     name="Katherine Nute"
//                 />
//                 <EventDetailViewComponentProfileCart
//                     picture={Profile3}
//                     title="Volunteer"
//                     name="Jennifer Butts"
//                 />
//                 {
//                     windowWidth > breakPoint &&
//                     <>
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile4}
//                             title="Volunteer"
//                             name="Emily Spanner"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile5}
//                             title="Volunteer"
//                             name="Samantha Jinda"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile6}
//                             title="Volunteer"
//                             name="Giorgia Handy"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile7}
//                             title="Volunteer"
//                             name="Wilci Yang"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile8}
//                             title="Volunteer"
//                             name="Jesse Harper"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile9}
//                             title="Volunteer"
//                             name="henry Carr"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile10}
//                             title="Volunteer"
//                             name="Liam Yella"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile11}
//                             title="Volunteer"
//                             name="Jason Smith"
//                         />
//                         <EventDetailViewComponentProfileCart
//                             picture={Profile12}
//                             title="Volunteer"
//                             name="James Burman"
//                         />
//                     </>
//                 }

//                 <img src={ArrowIcon} alt="more" className="md:hidden" />

//             </div>
//         </div>
//     )
// }

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSection: React.FC<{
  staffData?: any
  eventStaffData?: iesit['IStaffUiListItem'][]
}> = (props) => {
  const userId = useAppSelector(selectUserId)
  const DEFAULT_ICON_FOR_STAFF_CATEGORY = 'assets/og_icons/PeopleB.svg'

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // console.log(
  //   '=>(EventDetailViewComponentStaffSection.tsx:514) props?.eventStaffData',
  //   props?.eventStaffData
  // )
  // console.log('=>(EventDetailViewComponentStaffSection.tsx:514) props?.staffData', props?.staffData)
  return (
    <>
      {props?.eventStaffData && props?.eventStaffData?.length ? (
        props?.eventStaffData?.map((staffUiItem) => {
          return staffUiItem?.staffs?.length ? (
            <div className="grid grid-cols-1 gap-4 mb-4" key={JSON.stringify(staffUiItem)}>
              <div className="flex flex-wrap items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl py-4 px-4 md:px-6 2xl:px-[30px]">
                <div className="flex gap-2 items-center md:mb-4 flex-1">
                  <img
                    src={staffUiItem?.icon ? staffUiItem?.icon : DEFAULT_ICON_FOR_STAFF_CATEGORY}
                    className="w-6 h-6"
                    alt={staffUiItem?.category}
                  />
                  <div className="text-SeabiscuitDark200ThemeColor font-bold text-nr 2xl:text-base capitalize">
                    {staffUiItem?.category}
                  </div>
                </div>

                <div className="flex ml-auto md:gap-x-[10px] md:gap-y-6 flex-wrap">
                  {staffUiItem?.staffs && staffUiItem?.staffs?.length
                    ? staffUiItem?.staffs?.map((staff, index) => {
                        if (typeof staff !== 'string' && 'staffName' in staff) {
                          return (
                            // eslint-disable-next-line react/jsx-pascal-case
                            <EventDetailViewComponentProfileCart_
                              userId={userId}
                              name={staff?.staffName}
                              staffId={staff?.staffId}
                              title={staff?.staffType}
                              key={JSON.stringify({ staff, index })}
                              picture={staff?.staffProfilePicture ?? Profile1}
                            />
                          )
                        }

                        return null
                      })
                    : null}
                </div>
              </div>
            </div>
          ) : (
            ''
          )
        })
      ) : (
        <div className="text-sm 2xl:text-nr text-SeabiscuitDark200ThemeColor">
          No staff have been added to this event
        </div>
      )}

      {/* <EventDetailViewComponentStaffSectionManagement /> */}
      {/* <EventDetailViewComponentStaffSectionJudges allStaffsModal={allStaffsModal}/>
            <EventDetailViewComponentStaffSectionStewards allStaffsModal={allStaffsModal}/>
            <EventDetailViewComponentStaffSectionVeterinarians allStaffsModal={allStaffsModal}/>
            <EventDetailViewComponentStaffSectionVolunteers allStaffsModal={allStaffsModal}/> */}
    </>
  )
}

export default EventDetailViewComponentStaffSection
