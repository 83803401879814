import { CONST } from '../../const/const'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IProfile } from './profile.interface'

const COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.PROFILES

export class ProfileModel extends ModelBaseModel<IProfile> {
  public v: IProfile['v']
  public id: IProfile['id']
  public allowedUids: IProfile['allowedUids']
  public created: IProfile['created']
  public modified: IProfile['modified']

  public constructor(obj?: IProfile) {
    super()
    this.id = obj?.id ?? COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.v = obj?.v ?? COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.allowedUids = obj?.allowedUids ?? COLLECTION.FIELDS.ALLOWED_UIDS.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created!,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      value: obj?.modified!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new ProfileModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
