import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

// Services
import { httpService } from '../../services/httpService'

// Hooks
import useToasterHelper from '../../helpers/ToasterHelper'

// Components
import ViewsLoader from '../../components/loader/ViewsLoader'

// Types
type Props = {
  authPage?: boolean
}

/**
 * @TODO todo
 */
const SignConfirmationRootPage = (props: Props) => {
  // Hooks and vars
  const toasterFunctions = useToasterHelper()

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const { token, signatoryId, eventId, paperworkName, riderId, eventDraftId } = useParams<{
    token: string
    signatoryId: string
    eventId: string
    paperworkName: string
    riderId: string
    eventDraftId: string
  }>()

  const getUserData = async () => {
    try {
      const res = await httpService({
        url: `userData`,
        method: 'POST',
        data: {
          uid: signatoryId,
        },
      })
      setLoading(false)
      return res
    } catch (error) {
      setLoading(false)
      setError((error as any).response?.data?.message)
    }
  }

  const confirmSignature = async () => {
    try {
      const res = await httpService({
        url: `confirm_token`,
        method: 'POST',
        data: {
          signatoryId,
          confirm_token: token,
          eventId,
          paperworkName,
          riderId,
          eventDraftId,
        },
      })
      setLoading(false)
      return toasterFunctions.info({ message: (res as any).message })
    } catch (error) {
      setLoading(false)
      setError((error as any).response?.data?.message)
    }
  }

  useEffect(() => {
    if (token) {
      getUserData()
      confirmSignature()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <>
      {loading && error === '' ? (
        <div className="flex justify-center items-center w-full h-full">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : !loading && error === '' ? (
        <>
          <div className="signatureConfirm p-10 flex flex-col items-center justify-center w-full h-full">
            <img className="w-20 h-20 mb-4" src="/assets/cp_icons/Cancel-2.svg" alt="okIcon" />
            <h1 className="text-[#122B4680] text-lg font-normal">{error}</h1>
          </div>
        </>
      ) : (
        <div className="signatureConfirm p-10 flex flex-col items-center justify-center w-full h-full">
          <img className="w-20 h-20 mb-4" src="/assets/cp_icons/Ok-3.svg" alt="okIcon" />
          <h1 className="text-SeabiscuitMainThemeColor text-2xl font-semibold">Thank you</h1>
          <h1 className="text-[#122B4680] text-lg font-normal">Signature Confirmed</h1>
        </div>
      )}
    </>
  )
}

export default SignConfirmationRootPage
