import React, { useEffect, useRef, useState } from 'react'

// Third-party
import { AutorenewRounded } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import helpers from '../../commonHelpers/helpers'
import WrapperWithHeader from '../../components/common/wrappers/WrapperWithHeader'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IHorseCompetitorDocument } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { getConvertedData } from '../../models/interface.helper'
import FirestoreService from '../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSelectedHorse,
  getSelectedHorseCompetitonPaperwork,
  getSelectedHorseMapping,
  horseSliceThunks,
  selectHorseReducer,
  setSelectedHorseCompetetionPaperwork,
} from '../../store/horses/horseSlice'
import { httpService } from '../../services/httpService'
import { HorsePaperwork } from './HorsePaperwork'

type ISubmitFn = (data: any, keepUnsaved?: boolean) => Promise<void>

const defaultValue = new HorseCompetitonPaperworkModel({
  ...new HorseCompetitonPaperworkModel().toObject(),
  horseMeasurementCards: [
    {
      horseShodStatus: null,
      horseMeasurementDate: null,
      horseHeightMeasurement: null,
      horseMeasurementCardType: null,
      horseLeftHeelMeasurement: null,
      horseRightHeelMeasurement: null,
      horseMeasurementCardsImage: [],
    },
  ],
}).toObject()

interface HorseRootEditCompetitionNumbersTabProps {
  isView?: boolean
}

const HorseRootEditCompetitionNumbersTab: React.FC<HorseRootEditCompetitionNumbersTabProps> = ({
  isView,
}) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const [notEditable, setNotEditable] = useState(true)
  const toastFunctions = useToasterHelper()
  const horseReducer = useAppSelector(selectHorseReducer)
  const horsesPaperwork = useAppSelector(getSelectedHorseCompetitonPaperwork)
  const selectedHorse = useAppSelector(getSelectedHorse)
  const selectedHorseMapping = useAppSelector(getSelectedHorseMapping).data

  const imagesUrlsToDeleteFromStorage = useRef<string[]>([])

  const { control, handleSubmit, reset, watch, setValue } = useForm<IHorseCompetitorDocument>({
    mode: 'onChange',
    defaultValues: horsesPaperwork ?? defaultValue,
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
      !horseReducer.selected.mapping.data?.id ||
      horseReducer.selected.mapping.data.id === horsesPaperwork?.id
    )
      return

    dispatch(
      horseSliceThunks.getHorsePaperworkThunk({
        userHorseMappingDocId: horseReducer.selected.mapping.data?.id,
      })
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        let paperwork = (data.payload as any).paperwork as IHorseCompetitorDocument
        if (paperwork.horseId || paperwork.horseUniqId) {
          reset({
            ...paperwork,
            horseMeasurementCards: paperwork?.horseMeasurementCards?.length
              ? (paperwork?.horseMeasurementCards ?? [])
              : defaultValue.horseMeasurementCards,
          })
        }
      }
    })
  }, [horseReducer.selected.mapping.data?.id])

  // Functions
  const validateAction = (): boolean => {
    if (!selectedHorse) {
      toastFunctions.error({
        message: MESSAGES_CONST.PLEASE_CREATE_HORSE_FIRST,
      })
      return false
    }

    if (!selectedHorse?.id || !selectedHorseMapping?.id) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }

    return true
  }

  const onSubmit: ISubmitFn = async (data, keepUnsaved = false) => {
    if (!validateAction()) return

    data = {
      ...data,
      id: horsesPaperwork?.id,
      horseId: selectedHorse!.horseId,
      horseUniqId: selectedHorse!.id,
      horseOwnerId: selectedHorse!.horseOwnerId,
    }

    setLoading(true)

    let horseCompetitionPaperworkModel = new HorseCompetitonPaperworkModel(data)

    if (imagesUrlsToDeleteFromStorage.current.length) {
      await httpService({
        method: 'POST',
        url: 'remove_images',
        data: {
          urls: imagesUrlsToDeleteFromStorage.current,
        },
      })
    }

    imagesUrlsToDeleteFromStorage.current = []

    try {
      if (!!horsesPaperwork?.id) {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          horsesPaperwork?.id,
          horseCompetitionPaperworkModel.toFirestore()
        )
      } else {
        horseCompetitionPaperworkModel.id = selectedHorseMapping?.id
        await FirestoreService.createItemWithCustomId(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          selectedHorseMapping?.id!,
          horseCompetitionPaperworkModel.toFirestore()
        )
      }

      dispatch(
        setSelectedHorseCompetetionPaperwork({
          competitionPaperwork: getConvertedData(horseCompetitionPaperworkModel.toObject()),
        })
      )

      toastFunctions.success({
        message: MESSAGES_CONST.COMPETITOR_UPDATE_SUCCESSFULLY,
      })
    } catch (err: any) {
      helpers.logger({
        isError: true,
        message: err,
      })
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    setLoading(false)
    if (!keepUnsaved) {
      setNotEditable(true)
    }
  }

  return (
    <WrapperWithHeader
      title="Paperwork"
      description={isView ? '' : 'Add, edit or remove horses paperwork'}
      onSubmit={(e: any) =>
        handleSubmit(
          (data) => {
            onSubmit(data, false).then()
          },
          () => {}
        )(e)
      }
      headerButtonsContainer={
        isView ? (
          <></>
        ) : (
          <div className="flex items-center gap-2">
            <button
              type="button"
              onClick={() => setNotEditable(false)}
              className={clsx(
                'items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn',
                !notEditable && 'hidden'
              )}
            >
              Edit
            </button>
            <button
              type="submit"
              disabled={horseReducer.selected.paperwork.status === 'loading' || loading}
              className={clsx(
                'items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor saveBtn',
                notEditable && 'hidden'
              )}
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        )
      }
    >
      {horseReducer.selected.paperwork.status === 'loading' ? (
        <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
      ) : (
        <HorsePaperwork
          type="horse-paperwork-tab"
          control={control}
          onSubmit={onSubmit}
          selectedHorse={selectedHorse}
          setValue={setValue}
          watch={watch}
          imagesUrlsToDeleteFromStorage={imagesUrlsToDeleteFromStorage}
          notEditable={notEditable}
        />
      )}
    </WrapperWithHeader>
  )
}

export default HorseRootEditCompetitionNumbersTab
