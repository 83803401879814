import { IonList } from '@ionic/react'
import { motion } from 'framer-motion'
import { createContext, useEffect, useMemo, useState } from 'react'
import { CONST } from '../../../const/const'

// Tabs
import ClinicNOtherEventRegisterConfirmationTab from '../clinicAndOther/Tabs/ClinicNOtherEventRegisterConfirmationTab'
import ClinicNOtherRegisterTab from '../clinicAndOther/Tabs/ClinicNOtherRegisterTab'
import ClinicNOtherStatusTab from '../clinicAndOther/Tabs/ClinicNOtherStatusTab'
import CompetitorEventRegisterFeesTab from '../clinicAndOther/Tabs/CompetitorEventRegisterFeesTab'
import CompetitorEventRegisterClassesTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterClassesTab'
import CompetitorEventRegisterPayTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import CompetitorEventRegisterSignTab from '../competitorEventRegister/Tabs/CompetitorEventRegisterSignTab'
import CompetitorEventsRegisterPaperworkTab from '../competitorEventRegister/Tabs/CompetitorEventsRegisterPaperworkTab'

// Component
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'

// Fake data
import { EVENT_REG_CLINIC_TABS_CONST } from '../../../const/ui/ui-register.const'

// Third party
import { cloneDeep, findIndex } from 'lodash'
import { useParams } from 'react-router'

// Custom hooks
import useToasterHelper from '../../../helpers/ToasterHelper'

// Constants
import { FEES_CATEGORY_CONST } from '../../../components/events/views/details/EventDetailsViewComponentFees'
import { MESSAGES_CONST } from '../../../const/messages-const'

// Redux
import { useDispatch } from 'react-redux'
import {
  IEventsRegisterData,
  selectedEvent,
  setRegisterEventData,
} from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'

// Types
import {
  IEventFeeCategory,
  IEventTickets,
} from '../../../models/event-details/event-details.interface'
import { IFees } from '../../../models/event-drafts/event-draft.interface'
import { TEventFees } from '../../../models/event-review-publish/event-review-publish.interface'
import {
  IEventDetailsUi,
  ILightboxProps,
  IRegisterTabsSelectData,
} from '../../../types/competitor_types'

// Helpers
import clsx from 'clsx'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CountryList } from '../../../fakeData/countryList'
import { getUserFullName } from '../../../helpers/helpers'
import {
  IFeesTab,
  IPaperworkTab_,
  IRegisterTab,
  ISignatoryForMail,
  ITicketTab,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { IHorseData } from '../../../models/horse/horse.interface'
import { calculateAge, getConvertedData } from '../../../models/interface.helper'
import { ITeamMember, IUserInterface } from '../../../models/users/user.interface'
import { selectUsersHolder } from '../../../store/holders/holdersSlice'
import {
  selectFeesTabDataR,
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectRegisterTabData,
  selectRegistration,
  selectRegistrationAnswers,
  selectRegistrationByDay,
  selectRegistrationErrors,
  selectRidersTeamMembersInDb,
  selectTicketTabData,
  selectUserTeamMembersR,
  setPaperworkTabData,
  setRegistrationAnswers,
  setRidersTeamMembers,
} from '../../../store/registration/registrationSlice'
import ClinicNOtherRegisterHorseTab from '../clinicAndOther/Tabs/ClinicNOtherHorseTab'
import {
  ICompetitorEventRegisterTypes as ICerhTypes,
  competitorEventRegisterHelper as cerh,
} from '../competitorEventRegister/competitorEventRegisterHelper'
import useEventRegistrationTabs from './hooks/useEventRegistrationTabs/useEventRegistrationTabs'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import ITypes from './hooks/useEventRegistrationTabs.types'
import { generatePaperworkDocument } from '../../../helpers/sign'
import { selectProfileData } from '../../../store/user/userSlice'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import { selectIsLoggedIn } from '../../../store/user/userSlice'
import { CompetitorEventRegisterComments } from '../competitorEventRegister/Tabs/CompetitorEventRegisterComments'
import FirestoreService from '../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { RegistrationButtons } from './components/RegistrationButtons'
import { selectHorses } from '../../../store/horses/horseSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

type EventRegistrationTabsProps = {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  competitonFull?: boolean
  isManage?: boolean
}

type EventT = { tab: string; step: number }

type IMaturityDetail = {
  teamMemberIsMature: boolean
  allRidersAreMature: boolean
  registererIsInRiderOrMembers: boolean
  oneOfRiderTeamMemberIsGuardian: boolean
}

export type IManageInfo = {
  register: IRegisterTab[]
  tickets: ITicketTab[]
  fees: IFeesTab[]
  paperwork: IPaperworkTab_[]
}

const FILE_NAME = 'EventRegistrationTabs'
export const EventRegistrationContext = createContext<ICerhTypes['IEventRegistrationContext']>(null)

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 *
 * @param props EventRegistrationTabsProps
 * @returns ReactJsxElement
 */
const EventRegistrationTabs = (props: EventRegistrationTabsProps) => {
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const answers = useAppSelector(selectRegistrationAnswers)
  // Hooks and vars
  const {
    feesItemsForm,
    ticketItemsForm,
    loading,
    createRegistration,
    saveTeamTabData,
    saveRidersTeamMembers,
    onAddRiderButtonClick,
    onRiderTeamMemberChange,
    onRemoveRiderTeamMemberClick,
    onRegistrationTicketCountChange,
    onRiderTeamMemberRoleChange,
    onInputChangeTicket,
    onChangeFees,
    saveRegisterTabData,
    saveFeesTabData,
    saveTicketTabData,
    saveRecipients,
    savePaperworkDocument,
    setIsManage,
    registeredUser,
    manageInfo,
    setManageInfo,
  } = useEventRegistrationTabs()
  const { eventId, userId } = useParams<ITypes['IParams']>()

  const dispatch = useDispatch()
  const usersHolder = useAppSelector(selectUsersHolder)
  const toastFunctions = useToasterHelper()
  const rootState = useAppSelector((store) => store)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const ticketTabData = useAppSelector(selectTicketTabData)
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registration = useAppSelector(selectRegistration)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const ridersTeamMembersInDb = useAppSelector(selectRidersTeamMembersInDb)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const userData = useAppSelector(selectProfileData)
  const filteredPaperworkDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const myHorses = useAppSelector(selectHorses)

  const event = selectedEvent(rootState)

  const [regExists] = useState(false)
  const [membersCount, setMembersCount] = useState(0)
  const [message, setMessage] = useState<null | string>(null)
  const [membersWithoutHorse, setMembersWithoutHorse] = useState(0)
  const [maturityDetails, setMaturityDetails] = useState<IMaturityDetail[]>([])
  const [allRiderTeamMembers, setAllRiderTeamMembers] = useState<ISignatoryForMail[]>([])
  const [lightBox, setLightBox] = useState<ILightboxProps>({
    visible: false,
    slides: [],
  })
  const [eventTab, setEventTab] = useState<EventT>({
    tab: CONST.UI.REGISTER.TABS.TEAM,
    step: 0,
  })

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  let registerFormData = useMemo(() => {
    let horses: IRegisterTabsSelectData[] = []
    let members: IEventDetailsUi['members'] = []

    let foundHorse: IHorseData | null = null
    let foundUser: IUserInterface | null = null
    let foundTeamMember: ITeamMember | null = null

    let registerTabData = cloneDeep(rootState?.events?.registerEvents)

    registerTabData.events = registerTabData.events.map((currOneDayRegistration) => {
      members = []
      currOneDayRegistration.members.forEach((curr) => {
        foundUser = usersHolder.find((c) => c.id === curr.memberId) ?? null
        foundTeamMember = teamMembersWithUser.find((c) => c.memberId === curr.memberId) ?? null
        horses = curr?.horses ?? []

        horses = horses.reduce((acc: IRegisterTabsSelectData[], currHorse) => {
          if (!currHorse.id) return acc

          foundHorse = myHorses.find((c) => c.id === currHorse.id) ?? null

          if (!foundHorse) return acc

          acc.push({
            ...currHorse,
            name: foundHorse.horseName,
            label: foundHorse.horseName,
            horseProfilePicture: foundHorse?.horseProfilePicture,
          })

          return acc
        }, [])

        if (curr?.memberId && foundTeamMember)
          members.push({
            ...curr,
            horses,
            memberId: curr?.memberId,
            refundAmount: curr?.refundAmount ?? 0,
            memberName: foundUser ? getUserFullName(foundUser) : 'N/A',
            isScratched: curr?.isScratched ?? false,
            memberRole: foundTeamMember?.defaultRole,
            defaultRole: foundUser?.userDefaultRole ?? '',
            memberProfilePicture: foundUser?.userProfilePicture,
            riderTeamMembers: (curr?.riderTeamMembers ?? []).map((currRider) => {
              foundUser = usersHolder.find((c) => c.id === currRider.memberId) ?? null
              return {
                ...currRider,
                memberDob: foundUser?.userDOB ?? '',
                memberName: foundUser ? getUserFullName(foundUser) : 'N/A',
                memberEmail: foundUser?.userEmail ?? '',
                defaultRole: foundUser?.userDefaultRole ?? '',
                memberProfilePicture: foundUser?.userProfilePicture ?? '',
              }
            }),
          })
      })

      currOneDayRegistration.members = members
      return currOneDayRegistration
    }, [])

    return registerTabData
  }, [rootState?.events?.registerEvents, usersHolder, teamMembersWithUser, regExists])
  const registrationHasErrors = useAppSelector(selectRegistrationErrors)

  // Get an event fee list
  const eventFeeList: Record<any, IEventFeeCategory[]> = useMemo(() => {
    const accIniValue = {}

    let availablity = 0
    let availabilityExists: boolean = false
    let isExtraFees = false
    let valueToReturn: any = []
    const EventFees = event?.EventFees
    let updateCurrObj: ICerhTypes['IEvaluatedFeeItem'] = null
    let units: { label: string; value: string }[] = []
    const maxAvailListCount = 20
    const availablityList = (function () {
      let units = []
      for (let i = 1; i <= maxAvailListCount; i++) {
        units.push({ label: i.toString(), value: i.toString() })
      }
      return units
    })()

    if (!EventFees) return valueToReturn

    valueToReturn = Object.keys(EventFees).reduce((acc: Record<string, Array<any>>, currKey) => {
      isExtraFees = false
      updateCurrObj = null
      let currObj = EventFees[currKey as keyof TEventFees]
      if (currObj && typeof currObj === 'object' && !Array.isArray(currObj))
        updateCurrObj = cerh.getEvaluatedFeeItem(currObj)
      else if (currObj && Array.isArray(currObj)) isExtraFees = true
      if (!currObj) return acc

      if (!isExtraFees && !updateCurrObj) return acc

      if (isExtraFees) {
        currObj.forEach((currentExtraFees: any) => {
          updateCurrObj = null
          availabilityExists = false
          updateCurrObj = cerh.getEvaluatedFeeItem(currentExtraFees)

          if (updateCurrObj) {
            availabilityExists = (updateCurrObj?.available ?? 0) > 0
            availablity = availabilityExists ? updateCurrObj.available : 0

            if (availablity < 0) availablity = 0

            units =
              availablity > maxAvailListCount
                ? availablityList
                : [...availablityList].splice(0, availablity)

            acc[`${currentExtraFees.category}`] = [
              ...(acc[`${currentExtraFees.category}`] ?? []),
              {
                units,
                available: availablity,
                cost: updateCurrObj.cost,
                note: updateCurrObj.note,
                uuid: updateCurrObj.uuid,
                title: updateCurrObj.name,
                status: updateCurrObj.status,
                duration: updateCurrObj.duration,
                costAlias: updateCurrObj.costAlias,
                category: updateCurrObj.category,
              },
            ]
          }
        })
      } else if (updateCurrObj && currObj.category !== 'refund') {
        if (Object.values(FEES_CATEGORY_CONST).includes(updateCurrObj.category)) {
          availabilityExists = (updateCurrObj?.available ?? 0) > 0
          availablity = availabilityExists ? updateCurrObj.available : 0

          if (availablity < 0) availablity = 0

          units =
            availablity > maxAvailListCount
              ? availablityList
              : [...availablityList].splice(0, availablity)

          if (FEES_CATEGORY_CONST.GOVERNANCE === updateCurrObj?.category) {
            updateCurrObj.duration =
              FEES_CATEGORY_CONST.GOVERNANCE === updateCurrObj.category
                ? 'Per horse'
                : updateCurrObj.duration

            units = registerFormData.events?.reduce(
              (acc: { label: string; value: string }[], currEvent) => {
                if (!Array.isArray(currEvent)) acc = []
                currEvent.members.forEach((currMember) => {
                  currMember?.horses?.forEach(() =>
                    acc.push({
                      label: `${acc.length + 1}`,
                      value: `${acc.length + 1}`,
                    })
                  )
                })
                return acc
              },
              []
            )
          }

          acc[`${updateCurrObj.category}`] = [
            ...(acc[`${updateCurrObj.category}`] ?? []),
            {
              ...(updateCurrObj ?? {}),
              units,
              available: availablity,
              uuid: updateCurrObj.uuid,
              cost: updateCurrObj.cost,
              title: updateCurrObj.name,
              duration: updateCurrObj.duration,
              costAlias: updateCurrObj.costAlias,
            },
          ]
        }
      }
      return acc
    }, accIniValue)

    return valueToReturn

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.EventFees])

  // Get event tickets list
  const eventTicketsList: IEventTickets[] = useMemo(() => {
    let valueToReturn: any = []
    const maxAvailListCount = 20
    const EventTickets = (event?.EventTickets?.tickets ?? []) as IEventTickets[]

    const availablityList = (function () {
      let units = []
      for (let i = 1; i <= maxAvailListCount; i++) {
        units.push({ label: i.toString(), value: i.toString() })
      }
      return units
    })()

    // let unitsAvailable = 0

    if (EventTickets) {
      valueToReturn = EventTickets?.reduce((acc: any[], currTicket: IEventTickets) => {
        const ticketItem = cerh.getEvaluatedTicketItem(currTicket)

        if (!ticketItem) return acc

        // unitsAvailable = ticketItem.available;
        // units = unitsAvailable > maxAvailListCount ? availablityList : [...availablityList].splice(0, unitsAvailable);

        return [
          ...acc,
          {
            costAlias: ticketItem.costAlias,
            cost: ticketItem.cost,
            name: ticketItem.name,
            note: ticketItem.note,
            uuid: ticketItem.uuid,
            units:
              ticketItem.available > maxAvailListCount
                ? availablityList
                : [...availablityList].splice(0, ticketItem.available),
            available: ticketItem?.available,
          },
        ]
      }, [])
    }

    return valueToReturn
  }, [event?.EventTickets])

  useEffect(() => {
    let membersCount = 0
    let membersWithoutHorseCount = 0
    let localRegisterFormData: IEventsRegisterData | null = null

    if (registerFormData?.events) localRegisterFormData = { ...registerFormData }

    if (!localRegisterFormData) return

    localRegisterFormData.events?.forEach((currOneDayRegistration) => {
      currOneDayRegistration.members.forEach((currMember) => {
        membersCount++
        if (!currMember?.horses?.length) membersWithoutHorseCount++
      })
    }, [])

    setMembersCount(membersCount)
    setMembersWithoutHorse(membersWithoutHorseCount)
  }, [registerFormData])

  useEffect(() => {
    ;(async () => {
      try {
        const registeredEventUser = await getEventRegisteredUser()

        dispatch(setRegistrationAnswers(registeredEventUser?.answers || []))
      } catch (e) {
        console.log('=>(EventRegistrationTabs.tsx:509) e', e)
      }
    })()
  }, [])

  useEffect(() => {
    setIsManage(props.isManage ?? false)
  }, [props.isManage])

  useEffect(() => {
    if (!registeredUser) return
    const GUARDIAN_ROLE = 'Guardian'

    const events = registerFormData?.events ?? []

    if (events && events.length) {
      let riderIsMature = true
      let teamMemberIsMature = true
      let allRidersAreMature = true
      let registererIsInRiderOrMembers = false
      let oneOfRiderTeamMemberIsGuardian = false

      let maturityDetails: IMaturityDetail[] = []

      let horseId: string | null = null
      let riderUser: IUserInterface | null = null
      let memberUser: IUserInterface | null = null

      let allRiderMembers = events.reduce((acc: ISignatoryForMail[], currentOneDayRegistration) => {
        currentOneDayRegistration.members.forEach((currRegisteredTeamMember) => {
          horseId = currRegisteredTeamMember?.horses?.[0]?.id ?? null

          if (
            !currRegisteredTeamMember.memberId ||
            !currRegisteredTeamMember?.horses?.[0]?.label ||
            !horseId
          )
            return

          teamMemberIsMature = false
          allRidersAreMature = true
          registererIsInRiderOrMembers = false
          oneOfRiderTeamMemberIsGuardian = false

          memberUser = usersHolder.find((cu) => cu.id === currRegisteredTeamMember.memberId) ?? null
          teamMemberIsMature = !!(
            memberUser &&
            memberUser.userDOB &&
            calculateAge(memberUser.userDOB) > 18
          )

          if (currRegisteredTeamMember.memberId === registeredUser.id)
            registererIsInRiderOrMembers = true

          currRegisteredTeamMember?.riderTeamMembers?.forEach((currRiderTeamMember) => {
            if (currRiderTeamMember.memberId === registeredUser.id)
              registererIsInRiderOrMembers = true

            if (!currRiderTeamMember.memberId || !memberUser || !memberUser.userDOB) return

            riderUser = usersHolder.find((cu) => cu.id === currRiderTeamMember.memberId) ?? null
            riderIsMature = !!(
              riderUser &&
              riderUser.userDOB &&
              calculateAge(riderUser.userDOB) > 18
            )

            if (
              riderIsMature &&
              currRiderTeamMember.memberRole === GUARDIAN_ROLE &&
              currRiderTeamMember.memberId !== registeredUser.id
            )
              oneOfRiderTeamMemberIsGuardian = true

            if (!riderIsMature) allRidersAreMature = false

            if (!riderUser || !horseId) return

            acc.push({
              horseId: horseId,
              memberId: memberUser.id ?? '',
              riderName: getUserFullName(riderUser),
              memberDob: memberUser.userDOB,
              memberName: getUserFullName(memberUser),
              memberEmail: memberUser.userEmail,
              riderId: currRiderTeamMember.memberId,
              memberAddress: memberUser.userAddress,
              memberPhoneNumber: memberUser.userPhoneNumber,
              memberProfilePicture: memberUser.userProfilePicture,
              defaultRole: currRegisteredTeamMember?.memberRole ?? '',
              horseName: currRegisteredTeamMember?.horses?.[0]?.label ?? '',
              riderCountry:
                CountryList.find((currCountry) => currCountry.value === riderUser?.userNationality)
                  ?.label ?? '',
              memberCountry:
                CountryList.find((currCountry) => currCountry.value === memberUser?.userNationality)
                  ?.label ?? '',
            })
          })

          maturityDetails.push({
            teamMemberIsMature,
            allRidersAreMature,
            registererIsInRiderOrMembers,
            oneOfRiderTeamMemberIsGuardian,
          })
        })

        return acc
      }, [])

      setMaturityDetails(maturityDetails)
      setAllRiderTeamMembers(allRiderMembers)
    }
  }, [registerFormData?.events, registeredUser, usersHolder])

  useEffect(() => {
    if (registeredUser) setMessage(getMessageToShow())
  }, [registerFormData, registeredUser, maturityDetails, membersWithoutHorse])

  function getMessageToShow() {
    let message: string | null = null
    let isMature = (() => {
      if (registeredUser && Object.keys(registeredUser).length) {
        return calculateAge(registeredUser.userDOB) >= 18
      } else return false
    })()

    const immatureRiderWithoutGuardian = !!maturityDetails.find((cmd) => {
      return !cmd.teamMemberIsMature && !cmd.oneOfRiderTeamMemberIsGuardian
    })

    const imInAllRiders = maturityDetails.every((cmd) => cmd.registererIsInRiderOrMembers)
    const allHaveGuardian = maturityDetails.every((cmd) => cmd.oneOfRiderTeamMemberIsGuardian)
    const noEventsWereSelected = !(
      registerFormData &&
      registerFormData.events &&
      registerFormData.events.length
    )

    const imImatureWithoutGuardian = !isMature && !allHaveGuardian

    switch (true) {
      case noEventsWereSelected && !membersWithoutHorse:
        message = 'Please register a user in this event'
        break

      case !!membersWithoutHorse && membersWithoutHorse === membersCount:
        message = MESSAGES_CONST.MEMBERS_WITHOUT_HORSE.replace(
          '[COUNT]',
          `${membersWithoutHorse}`
        ).replace('[MEMBER]', membersWithoutHorse > 1 ? 'members' : 'member')
        break

      case !imInAllRiders:
        message =
          'You have no paperwork to sign because you have not listed yourself as a rider or team member. If you are attending this event, please list yourself as a team member to receive paperwork to sign.'
        break

      case imImatureWithoutGuardian:
        message =
          'Your date of birth identifies you as a minor. Your paperwork will be sent to the person you identified as your guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case immatureRiderWithoutGuardian:
        message =
          'Your one rider date of birth identifies you as a minor. so paperwork will be sent to the person you identified as minor rider guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case !allRiderTeamMembers.length:
        message = `Something went wrong ${FILE_NAME}`
    }

    return message
  }

  // Handles lightbox
  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  // Closes lightbox
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  // Handles Fees step of fees tab
  const feesHandler = ({ updatedObjOfFeesGroup }: { updatedObjOfFeesGroup: IFees }) => {
    let fees: IFees[]
    let dataIndex: number

    fees = [...(registerFormData?.fees ?? [])]
    dataIndex = findIndex(fees, {
      title: updatedObjOfFeesGroup.title,
      category: updatedObjOfFeesGroup.category,
    })
    if (dataIndex === -1) {
      // Not exists
      fees = [...fees, updatedObjOfFeesGroup]
    } // Exists
    else {
      fees.splice(dataIndex, 1, { ...updatedObjOfFeesGroup })
    }

    fees = [...(fees ?? [])]
    dispatch(setRegisterEventData({ ...registerFormData, fees, step: 7 }))
  }

  // Handlers Tickets step of fees tab
  const ticketsHandler = () => {}

  const getEventRegisteredUser = async (): Promise<
    EventRegisteredUsersModel | null | undefined
  > => {
    const registeredUsers: any = []
    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredUsersSnaps.forEach((currDoc) => {
      registeredUsers.push(
        getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    return registeredUsers[0]
  }

  const setnavigateTab = (data: any) => {
    setEventTab(data)
  }

  const saveAllTabs = async ({
    isManage,
    isPayByCash,
    isPaid,
    invoiceId,
    invoiceUrl,
    cardNo,
  }: ICerhTypes['ISaveAllTabsFnArgs']) => {
    let registers: IRegisterTab[]
    let tickets: ITicketTab[]
    let feesItems: IFeesTab[]
    let paperworkItems: IPaperworkTab_[]
    const horsesIds: string[] = []
    const registrationsByDayHorsesIds: string[] = []
    const feesGovernance: IFeesTab[] = []
    let registrationsByDayToUpdate: IRegistrationByDayInterface[] | null = null
    let registrationsByDayToAdd: IRegistrationByDayInterface[] | null = null
    let registrationByDayForPaperwork: IRegistrationByDayInterface = new RegistrationByDayModel()
    let promisesList: Promise<any>[] = []

    if (!registration?.id) {
      return toastFunctions.info({
        message: MESSAGES_CONST.PLEASE_ADD_TEAM_MEMBERS,
      })
    }

    if (isManage) {
      feesTabData.forEach((feesTab) => {
        if (feesTab.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
          if (feesTab.registrationFees) {
            feesGovernance.push(feesTab)
          }
        }
      })

      registers = manageInfo.register
      tickets = manageInfo.tickets
      feesItems = [...manageInfo.fees, ...feesGovernance]
      paperworkItems = manageInfo.paperwork

      registrationsByDay.forEach((registrationByDay) => {
        if (registrationByDay.horseId && !registrationByDay.isScratched) {
          registrationsByDayHorsesIds.push(registrationByDay.horseId)
        }
      })
    } else {
      registers = registerTabData
      tickets = ticketTabData
      feesItems = feesTabData
      paperworkItems = paperworkTabData
    }

    const getRegistrationsByDay = (registrationsByDay: IRegistrationByDayInterface[]) => {
      registrationsByDay.forEach((registrationByDay) => {
        newRegistersByDay.push({
          ...registrationByDay,
          paymentStatus: isPayByCash ? PAYMENT_STATUSES.CASH : PAYMENT_STATUSES.PAID,
          recipientId: !isPayByCash ? registration.userId : null,
          invoiceId,
          invoiceUrl,
        })
        if (
          registrationByDay.horseId &&
          !horsesIds.includes(registrationByDay.horseId) &&
          !registrationsByDayHorsesIds.includes(registrationByDay.horseId)
        ) {
          horsesIds.push(registrationByDay.horseId)
        }
      })
    }

    const newRegistersByDay: IRegistrationByDayInterface[] = []
    registers.forEach((register) => {
      getRegistrationsByDay(register.registrationsByDay)
      register.children?.forEach((child) => {
        getRegistrationsByDay(child.registrationsByDay)
      })
    })

    const newRegistrationTickets: IRegistrationTicketInterface[] = []
    tickets.forEach((ticket) => {
      let selectedUnitsCount = 0

      if (isManage) {
        ticketTabData.forEach((ticketTab) => {
          if (ticket.ticketItemId === ticketTab.ticketItemId) {
            selectedUnitsCount = ticketTab.registrationTicket?.selectedUnitsCount ?? 0
          }
        })
      }

      if (ticket.registrationTicket)
        newRegistrationTickets.push({
          ...ticket.registrationTicket,
          selectedUnitsCount:
            selectedUnitsCount + (ticket.registrationTicket.selectedUnitsCount ?? 0),
          paymentStatus: isPayByCash ? PAYMENT_STATUSES.CASH : PAYMENT_STATUSES.PAID,
          recipientId: !isPayByCash ? registration.userId : null,
          invoiceId,
          invoiceUrl,
        })
    })

    const newRegistrationFees: IRegistrationFeesInterface[] = []
    feesItems.forEach((fees) => {
      let selectedUnitsCount = 0

      if (fees.registrationFees) {
        if (fees.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
          if (isManage) {
            selectedUnitsCount = horsesIds.length
          }

          newRegistrationFees.push({
            ...fees.registrationFees,
            selectedUnitsCount:
              selectedUnitsCount + (fees.registrationFees.selectedUnitsCount ?? 0),
            paymentStatus: isPayByCash ? PAYMENT_STATUSES.CASH : PAYMENT_STATUSES.PAID,
            recipientId: !isPayByCash ? registration.userId : null,
            invoiceId,
            invoiceUrl,
          })
        } else {
          if (isManage) {
            feesTabData.forEach((feesTab) => {
              if (feesTab.registrationFees?.id === fees.registrationFees?.id) {
                selectedUnitsCount = feesTab.registrationFees?.selectedUnitsCount ?? 0
              }
            })
          }

          newRegistrationFees.push({
            ...fees.registrationFees,
            selectedUnitsCount:
              selectedUnitsCount + (fees.registrationFees.selectedUnitsCount ?? 0),
            paymentStatus: isPayByCash ? PAYMENT_STATUSES.CASH : PAYMENT_STATUSES.PAID,
            recipientId: !isPayByCash ? registration.userId : null,
            invoiceId,
            invoiceUrl,
          })
        }
      }
    })

    const newRidersTeamMembers: IRiderTeamMemberInterface[] = []
    paperworkItems.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0)
        newRidersTeamMembers.push(...paperwork.ridersTeamMembers)
    })

    if (newRegistersByDay.length > 0) {
      registrationsByDayToAdd = newRegistersByDay.filter((newRegisterByDay) => !newRegisterByDay.id)

      registrationsByDayToUpdate = newRegistersByDay.filter(
        (newRegisterByDay) => newRegisterByDay.id
      )

      const riders = await saveRegisterTabData({
        registrationsByDayToAdd,
        registrationsByDayToUpdate,
      })
      if (riders.registrationsByDayToAdd) registrationsByDayToAdd = riders.registrationsByDayToAdd
    }
    if (newRegistrationTickets.length > 0) {
      const registrationTicketsToAdd = newRegistrationTickets.filter(
        (newRegistrationTicket) => !newRegistrationTicket.id
      )
      const registrationTicketsToUpdate = newRegistrationTickets.filter(
        (newRegistrationTicket) => newRegistrationTicket.id
      )
      await saveTicketTabData({
        registrationTicketsToAdd,
        registrationTicketsToUpdate,
      })
    }
    if (newRegistrationFees.length > 0) {
      const registrationFeesToAdd = newRegistrationFees.filter(
        (newRegistrationFee) => !newRegistrationFee.id
      )
      const registrationFeesToUpdate = newRegistrationFees.filter(
        (newRegistrationFee) => newRegistrationFee.id
      )
      await saveFeesTabData({
        registrationFeesToAdd,
        registrationFeesToUpdate,
      })
    }
    if (newRidersTeamMembers.length > 0) {
      let ridersTeamMembersToAdd = newRidersTeamMembers.filter(
        (newRidersTeamMember) => !newRidersTeamMember.id
      )
      const ridersTeamMembersToUpdate = newRidersTeamMembers.filter(
        (newRidersTeamMember) => newRidersTeamMember.id
      )

      //  we need add "registrationByDayDocId" if it is "Manage"
      if (isManage) {
        ridersTeamMembersToAdd = ridersTeamMembersToAdd?.map((ridersTeamMemberToAdd) => {
          const current = registrationsByDayToAdd?.find(
            (registrationByDay) =>
              registrationByDay.riderId === ridersTeamMemberToAdd.riderId &&
              ridersTeamMemberToAdd.registrationByDayUniqId === registrationByDay.uniqId
          )
          return { ...ridersTeamMemberToAdd, registrationByDayDocId: current?.id ?? '' }
        })
      }

      await saveRidersTeamMembers({
        ridersTeamMembersToAdd,
        ridersTeamMembersToUpdate,
      })

      const filteredTeamMembers = [...ridersTeamMembersToAdd, ...ridersTeamMembersToUpdate].filter(
        (rider) => rider.teamMemberId !== userId
      )

      await savePaperworkDocument({ ridersTeamMembersToAdd: filteredTeamMembers })

      if (event?.EventDetails) {
        for (const document of filteredPaperworkDocuments) {
          let userTeamMembers = [...ridersTeamMembersToAdd, ...ridersTeamMembersToUpdate].filter(
            (teamMember) => teamMember.teamMemberId === userId
          )

          const newRegistrationsByDay = [
            ...(registrationsByDayToAdd ?? []),
            ...(registrationsByDayToUpdate ?? []),
          ]

          //  we need to add "registrationByDayDocId" if it is "Manage"
          if (isManage) {
            userTeamMembers = userTeamMembers?.map((userTeamMember) => {
              const current = registrationsByDayToAdd?.find(
                (registrationByDay) =>
                  registrationByDay.riderId === userTeamMember.riderId &&
                  userTeamMember.registrationByDayUniqId === registrationByDay.uniqId
              )
              return { ...userTeamMember, registrationByDayDocId: current?.id ?? '' }
            })
          }

          for (const userTeamMember of userTeamMembers) {
            newRegistrationsByDay.forEach((registrationByDay_) => {
              if (registrationByDay_.id === userTeamMember?.registrationByDayDocId) {
                registrationByDayForPaperwork = registrationByDay_
              }
            })

            let age = calculateAge(userTeamMember.teamMemberDob)

            if (userTeamMember && age > 18) {
              promisesList.push(
                generatePaperworkDocument({
                  paperwork: document,
                  currRidersTeamMember: userTeamMember,
                  registrationByDay: registrationByDayForPaperwork,
                  userData,
                  eventId: eventId,
                  EventDetails: event?.EventDetails,
                })
              )
            }
          }

          await Promise.all(promisesList)
        }
      }
    }

    await saveRecipients({ isPaid, cardNo })
  }

  const loadPaperworkData = () => {
    let ridersTeamMembersFiltered: IRiderTeamMemberInterface[] = []
    let paperworkTabData_: IPaperworkTab_[] = []
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []

    if (props.isManage) {
      manageInfo.register.forEach((register) => {
        register.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDay_.push(registrationByDay)
        })

        register.children?.forEach((child) => {
          child.registrationsByDay.forEach((registrationByDay) => {
            registrationsByDay_.push(registrationByDay)
          })
        })
      })
    } else {
      registrationsByDay_ = registrationsByDay
    }

    registrationsByDay_.forEach((registrationByDay) => {
      if (!props.isManage) {
        ridersTeamMembersFiltered = ridersTeamMembersInDb.filter(
          (currentRider) => currentRider.registrationByDayDocId === registrationByDay.id
        )
      }

      paperworkTabData_.push(
        getConvertedData({
          riderDob: registrationByDay.riderDob ?? null,
          riderName: registrationByDay.riderName ?? null,
          riderId: registrationByDay.riderId ?? null,
          horseId: registrationByDay.horseId ?? null,
          horseName: registrationByDay.horseName ?? null,
          registrationByDayDocId: registrationByDay.id ?? null,
          registrationByDayUuid: registrationByDay.uuid ?? null,
          registrationByDayUniqId: registrationByDay.uniqId || '',
          horseProfilePicture: registrationByDay.horseProfilePicture ?? null,
          riderProfilePicture: registrationByDay.riderProfilePicture ?? null,
          riderNameNGram: registrationByDay.riderNameNGram ?? [],
          ridersTeamMembers: [
            ...(ridersTeamMembersFiltered.length <= 0
              ? [
                  {
                    ...getConvertedData(
                      new RiderTeamMemberModel({
                        mailLog: [],
                        userId: userId,
                        eventId: eventId,
                        riderDob: registrationByDay.riderDob ?? null,
                        riderId: registrationByDay.riderId ?? null,
                        riderName: registrationByDay.riderName ?? null,
                        eventName: registrationByDay.eventName ?? null,
                        registrationDocId: registration?.id ?? null,
                        registrationByDayDocId: registrationByDay.id ?? null,
                        registrationByDayUuid: registrationByDay.uuid ?? null,
                        registrationByDayUniqId: registrationByDay.uniqId || '',
                        teamMemberId: registrationByDay.riderId ?? null,
                        teamMemberDob: registrationByDay.riderDob ?? null,
                        teamMemberName: registrationByDay.riderName,
                        teamMemberRole: TEAM_MEMBER_ROLES.RIDER ?? null,
                        teamMemberEmail: registrationByDay.riderEmail ?? null,
                        teamMemberProfilePicture: registrationByDay.riderProfilePicture ?? null,
                      }).toObject()
                    ),
                  },
                ]
              : []),
            ...ridersTeamMembersFiltered,
          ],
          disableAddButton: false,
        })
      )
    })

    const uniquePaperwork = paperworkTabData_.filter((paperwork, index) => {
      return (
        index ===
        paperworkTabData_.findIndex(
          (currentPaperwork) =>
            paperwork.riderName === currentPaperwork.riderName &&
            paperwork.horseName === currentPaperwork.horseName
        )
      )
    })

    uniquePaperwork.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))
    if (props.isManage) {
      setManageInfo({ ...manageInfo, paperwork: uniquePaperwork })
    } else {
      dispatch(setPaperworkTabData(uniquePaperwork))
    }
  }

  useEffect(() => {
    if (registration?.id) loadPaperworkData()
  }, [
    registration?.id,
    registrationsByDay,
    manageInfo.register,
    ridersTeamMembersInDb,
    props.isManage,
  ])

  return (
    <EventRegistrationContext.Provider value={{ saveAllTabs }}>
      <ul className="hidden md:flex flex-wrap">
        {(isLoggedIn ? EVENT_REG_CLINIC_TABS_CONST : [EVENT_REG_CLINIC_TABS_CONST[0]]).map(
          (curr, index: number) => {
            return (
              <button
                key={JSON.stringify(curr) + `${index}`}
                className={clsx(
                  `text-nr inline-flex items-center text-SeabiscuitDark200ThemeColor mr-2 bg-transparent py-2 px-4 rounded-full mb-2 !border-none !ring-0 cursor-default`,
                  eventTab.tab === curr.tabName &&
                    '!bg-SeabiscuitWhiteThemeColor text-SeabiscuitMainThemeColor',
                  { 'opacity-50': !registration?.id }
                )}
              >
                {eventTab.tab === curr.tabName ? (
                  <img className="mr-2" src={curr.activeIcon} alt={curr.tabName} />
                ) : (
                  <img className="mr-2" src={curr.icon} alt={curr.tabName} />
                )}
                {curr.tabName}
              </button>
            )
          }
        )}
      </ul>

      <IonList className="home_root_ion_list flex flex-col-reverse lg:flex-col">
        <RegistrationButtons
          eventTab={eventTab}
          saveRidersTeamMembers={saveRidersTeamMembers}
          createRegistration={createRegistration}
          setEventTab={setEventTab}
          saveTeamTabData={saveTeamTabData}
          loading={loading}
          tabsArrayToSearch={EVENT_REG_CLINIC_TABS_CONST}
          isManage={props.isManage}
          manageInfo={manageInfo}
          filteredPaperworkDocuments={filteredPaperworkDocuments}
          getEventRegisteredUser={getEventRegisteredUser}
          registeredUser={registeredUser}
          registrationHasErrors={registrationHasErrors}
          saveAllTabs={saveAllTabs}
        />
        {loading ? (
          <ViewsLoader
            className="flex items-center w-full justify-center flex-col flex-1"
            size="xl"
            color="red"
          />
        ) : (
          <>
            {eventTab.tab === CONST.UI.REGISTER.TABS.TEAM && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherStatusTab
                  teamMembersLoading={loading}
                  title={eventTab.step === 0 ? 'Confirm team details' : 'Status'}
                  description={
                    eventTab.step === 0
                      ? 'Add riders and horses to start your registration'
                      : 'Licensed competitons require complete profiles to register.'
                  }
                  step={eventTab.step}
                  handleModal={props.handleModal}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.CLASSES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                {eventTab.step === 0 && (
                  <CompetitorEventRegisterClassesTab
                    title="Classes"
                    description="Select the classes you wish to enter"
                  />
                )}
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.HORSES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherRegisterHorseTab
                  title="Horses"
                  description="Match horses to riders, per entry"
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  saveRegisterTabData={saveRegisterTabData}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.REGISTER && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherRegisterTab
                  usersHolder={usersHolder}
                  handleModal={props.handleModal}
                  saveRegisterTabData={saveRegisterTabData}
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  title="Register"
                  description="Add riders to classes to enter. You can add more than one rider per class."
                  eventId={eventId}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.FEES && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterFeesTab
                  feesItemsForm={feesItemsForm}
                  ticketItemsForm={ticketItemsForm}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  isManage={props.isManage}
                  onInputChangeTicket={onInputChangeTicket}
                  onChangeFees={onChangeFees}
                  feesHandler={feesHandler}
                  eventFeeList={eventFeeList}
                  fees={registerFormData.fees}
                  ticketsHandler={ticketsHandler}
                  handleModal={props.handleModal}
                  eventTicketsList={eventTicketsList}
                  onRegistrationTicketCountChange={onRegistrationTicketCountChange}
                  title="Fees"
                  description={'Add items to your registration'}
                  step={eventTab.step}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.TICKETS && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterFeesTab
                  feesItemsForm={feesItemsForm}
                  ticketItemsForm={ticketItemsForm}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                  isManage={props.isManage}
                  onInputChangeTicket={onInputChangeTicket}
                  feesHandler={feesHandler}
                  eventFeeList={eventFeeList}
                  fees={registerFormData.fees}
                  ticketsHandler={ticketsHandler}
                  handleModal={props.handleModal}
                  eventTicketsList={eventTicketsList}
                  onRegistrationTicketCountChange={onRegistrationTicketCountChange}
                  title="Tickets"
                  description={'Add spectator tickets your order'}
                  step={1}
                  onChangeFees={onChangeFees}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.PAPERWORK && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventsRegisterPaperworkTab
                  title="Team Roles"
                  onAddRiderButtonClick={onAddRiderButtonClick}
                  onRiderTeamMemberChange={onRiderTeamMemberChange}
                  onRemoveRiderTeamMemberClick={onRemoveRiderTeamMemberClick}
                  onRiderTeamMemberRoleChange={onRiderTeamMemberRoleChange}
                  description="Add team members to each entry"
                  isManage={props.isManage}
                  manageInfo={manageInfo}
                  setManageInfo={setManageInfo}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.SIGN && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterSignTab
                  message={message}
                  title="Your Paperwork"
                  handleLightBox={handleLightBox}
                  saveRidersTeamMembers={saveRidersTeamMembers}
                  description="Review and sign your paperwork to participate in this event"
                  isManage={props.isManage}
                  setManageInfo={setManageInfo}
                  manageInfo={manageInfo}
                  userId={userId}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.COMMENTS && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterComments event={event?.Event} comments={answers || []} />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.CONFIRMATION && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <ClinicNOtherEventRegisterConfirmationTab
                  title="Confirmation"
                  message={message}
                  step={eventTab.step}
                  activeTab={eventTab}
                  setEventTab={setnavigateTab}
                  registerFormData={registerFormData}
                  competitonFull={props.competitonFull}
                  allRiderTeamMembers={allRiderTeamMembers}
                  description="Review and confirm your registation details"
                  manageInfo={manageInfo}
                  isManage={props.isManage}
                />
              </motion.div>
            )}

            {eventTab.tab === CONST.UI.REGISTER.TABS.REVIEWANDPAY && (
              <motion.div
                className="w-full mx-auto flex flex-col flex-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                <CompetitorEventRegisterPayTab
                  title="Pay"
                  activeTab={eventTab}
                  step={eventTab.step}
                  setEventTab={setnavigateTab}
                  handleModal={props.handleModal}
                  registerFormData={registerFormData}
                  competitonFull={props.competitonFull}
                  description="Select a payment method and complete registration"
                  manageInfo={manageInfo}
                  isManage={props.isManage}
                  registeredUser={registeredUser}
                  registrationHasErrors={
                    registrationHasErrors.users || registrationHasErrors.horses
                  }
                />
              </motion.div>
            )}
          </>
        )}
      </IonList>

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </EventRegistrationContext.Provider>
  )
}

export default EventRegistrationTabs
