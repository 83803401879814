import { ReactElement } from 'react'
import { motion } from 'framer-motion'

type IProps = {
  children: ReactElement | null
}

const EventFormContainerComponent = (props: IProps) => {
  return (
    <motion.div
      className="bg-SeabiscuitGrayThemeColor overflow-auto flex flex-col flex-1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.1,
      }}
    >
      <div className="flex flex-col flex-1 mb-0 bg-white rounded-2xl py-4 pb-24 md:pb-10 px-4 md:px-10 my-4 shadow-2xl 4324324324324324324324">
        {props.children}
      </div>
    </motion.div>
  )
}

export default EventFormContainerComponent
