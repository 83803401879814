import React, { FC, useContext } from 'react'

import { useFieldArray } from 'react-hook-form'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'
import { v4 as uuidv4 } from 'uuid'

import { Subheader } from '../Subheader/Subheader'
import { EventFeeSimple } from '../EventFeeSimple/EventFeeSimple'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'

interface EventFeesStallsProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
  dataIncrementAttrProps: { [key: string]: string }
  getDurationSelectedValue: (value: string) => { label: string; value: string } | undefined
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()

export const EventFeesStalls: FC<EventFeesStallsProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
  dataIncrementAttrProps,
  getDurationSelectedValue,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraStallFees = useFieldArray({
    control,
    name: 'extraStallFees',
  })

  const onAdd = () => {
    extraStallFees.append({
      status: false,
      title: '',
      category: 'stalls',
      available: '',
      sold: '',
      actualCostAlias: '',
      duration: '',
      note: '',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraStallFees.remove()
    reset({
      ...getValues(),
      temporaryStallsFee: eventFeesFormDefaultValues.temporaryStallsFee,
      permanentStallsFee: eventFeesFormDefaultValues.permanentStallsFee,
      privateStallsFee: eventFeesFormDefaultValues.privateStallsFee,
      circuitStallsFee: eventFeesFormDefaultValues.circuitStallsFee,
      muckFee: eventFeesFormDefaultValues.muckFee,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraStallFees.remove(index)
      },
    })
  }

  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader title="Stalls" subtitle="Select the stalls you wish to sell at this event" />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Temporary stalls"
        name="temporaryStallsFee"
        withHeader
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Permanent Stalls"
        name="permanentStallsFee"
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Private Stalls"
        name="privateStallsFee"
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Circuit Stalls"
        name="circuitStallsFee"
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Muck Fee"
        name="muckFee"
      />
      {extraStallFees.fields.map((field, index) => (
        <EventFeeSimple
          key={field.id}
          getDurationSelectedValue={getDurationSelectedValue}
          dataIncrementAttrProps={dataIncrementAttrProps}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraStallFees.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
