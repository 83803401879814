import React, { MouseEvent, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// Styles
import './EventOrganizationHorizontalCardComponent.css'

// Third party
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Constants
import { CONST } from '../../../../const/const'

// Types
import clsx from 'clsx'
import { IEventInterface } from '../../../../models/events/event.interface'

import 'swiper/css'
import 'swiper/css/lazy'

import customImageComponent from '../../../common/CustomImageComponent'
import { ModelBaseModel } from '../../../../models/model-base/model-base.model'
import { useLocation } from 'react-router-dom'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const DUMMY_USER_IMAGE = 'assets/img/Empty user.png'
const DEFAULT_CARD_IMAGE_URL = 'assets/img/Empty Event Portrait.png'
const ORGANIZER_MANAGE_EVENT_URL = CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL + '/'
const ORGANIZER_EDIT_EVENT_URL = '/organizer-edit/'

interface ExtendedSwiper extends SwiperCore {
  swiper?: any
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface EventOrganizationHorizontalCardComponentProps
  extends Omit<
    IEventInterface,
    | 'expectedRegistrationRevenue'
    | 'expectedTicketRevenue'
    | 'expectedFeesRevenue'
    | 'currentRegistrationRevenue'
    | 'currentFeesRevenue'
    | 'ticketItemsCount'
    | 'soldTicketItemsCount'
    | 'totalStallsCount'
    | 'soldStallsCount'
    | 'totalFeedCount'
    | 'soldFeedCount'
    | 'totalBeddingCount'
    | 'soldBeddingCount'
    | 'totalOtherCount'
    | 'soldOtherCount'
  > {
  id: any
  city?: string
  sale?: string
  state?: string
  title?: string
  tags?: string[]
  onSelected?: any
  country?: string
  available?: number
  isLicensed?: boolean
  briefDescription?: string
  cardCoverImageUrl?: string
  registrationCloseDays?: number
  goToViewPage: (link: string) => void
  competitionCourseMapImages?: string[]
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const getEventDetails = (obj: EventOrganizationHorizontalCardComponentProps) => {
  const imagesArr = [
    {
      src: '/assets/og_icons/Prize-1.svg',
      alt: 'Registration',
      key: 'registration',
      className: '!mr-1',
    },
    { src: '/assets/og_icons/Two Tickets-1.svg', alt: 'Ineligible', key: 'ineligible' },
    { src: '/assets/og_icons/Medal-1.svg', alt: 'Messages', key: 'messages' },
    { src: '/assets/cp_icons/DepartmentShop-1.svg', alt: 'Sales', key: 'sales' },
  ]

  const getVal = (curr: any) => {
    let strToReturn = ''
    let registrationsRevenue = new ModelBaseModel().getNum(obj.totalRegistrationRevenue)
    switch (curr.key) {
      case 'sales':
        strToReturn = 'Vendor sale coming soon '
        break
      case 'ineligible':
        strToReturn = `$${Number(obj.currentTicketRevenue) ?? 0} ticket sales`
        break
      case 'messages':
        strToReturn = 'Sponsor sale coming soon'
        break
      case 'registration':
        strToReturn = `$${registrationsRevenue} registration sales`
        break
    }

    return strToReturn
  }

  return (
    <>
      {imagesArr.map((curr) => {
        return (
          <div className={'flex items-center mb-1'} key={curr.src}>
            <img
              src={curr?.src}
              alt={curr?.alt}
              className={clsx('object-cover mr-2', curr.className)}
              height="18px"
              width={curr.key === 'registration' ? '21px' : '18px'}
            />
            <span className="capitalize text-SeabiscuitDark200ThemeColor text-[12px]">
              {getVal(curr)}
            </span>
          </div>
        )
      })}
    </>
  )
}

const EventOrganizationHorizontalCardComponent: React.FC<
  EventOrganizationHorizontalCardComponentProps
> = (props) => {
  /* Vars */
  const { eventStartDate } = props
  const swiper = useRef<ExtendedSwiper>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const disablePrev = currentIndex === 0
  const disableNext = swiper.current?.swiper?.isEnd || !props?.eventImages?.length

  let registeredUsersImages = [
    ...(props?.registeredUsersImages ?? []),
    ...[...Array(3)].map(() => DUMMY_USER_IMAGE),
  ].slice(0, 3)

  const goNext = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slideNext()
    }
  }

  const goPrev = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (swiper.current !== null && swiper.current.swiper !== null) {
      swiper.current.swiper.slidePrev()
    }
  }

  const goToViewPage = (e: any) => {
    const sliderImageClicked = e.target?.classList?.contains('sliderImage')
    if (sliderImageClicked) props.goToViewPage(`${ORGANIZER_MANAGE_EVENT_URL}${props?.id}`)
  }

  const customNavigation = (length: number) => {
    if (length > 1) {
      return (
        <div className={clsx(`flex flex-row  absolute z-10 bottom-[10px]`)}>
          {[...Array(length <= 6 ? length : 6)].map((data, currDotIndex) => {
            return (
              <span
                key={`${JSON.stringify(data)}${currDotIndex}`}
                className={` rounded ${currDotIndex <= currentIndex ? `bg-[white]` : `bg-[white]`} p-[4px] mx-[2px] ${currDotIndex > currentIndex ? `opacity-[0.4]` : ''}`}
              >
                {data}
              </span>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }

  const location = useLocation()
  const pathSegments = location.pathname.split('/')
  const lastSegment = pathSegments[pathSegments.length - 1]

  let linkTo: string

  if (lastSegment === 'draft') {
    linkTo = ORGANIZER_EDIT_EVENT_URL
  } else {
    linkTo = ORGANIZER_MANAGE_EVENT_URL
  }

  return (
    <div className="event-post-horizontal-card-component transition-shadow duration-300 ease-in-out relative mb-4 h-full w-auto">
      <Link to={`${linkTo}${props?.id}`}>
        <Swiper
          lazy={{ enabled: true, loadPrevNext: true, loadPrevNextAmount: 1 }}
          ref={swiper as any}
          spaceBetween={10}
          scrollbar={{ draggable: true }}
          pagination={{ clickable: true }}
          className="mb-4 group rounded-xl w-full h-auto aspect-square max-h-[26vh] xl:h-[26vh] 2xl:h-[26vh] xl:max-h-[26vh] 2xl:max-h-[26vh] overflow-hidden"
          modules={[Navigation, Pagination, Scrollbar, A11y, Lazy]}
          onActiveIndexChange={(activeIndex_) => {
            setCurrentIndex(activeIndex_.activeIndex)
          }}
        >
          {props?.eventImages?.length && typeof props?.eventImages === 'object' ? (
            props?.eventImages.map((currImage, index) => {
              return (
                <SwiperSlide
                  key={`${currImage}${index}`}
                  className="rounded-xl overflow-hidden"
                  onClick={goToViewPage}
                  style={{ height: '267px' }}
                >
                  {currentIndex === index && customNavigation(Number(props?.eventImages?.length))}
                  {customImageComponent(
                    currImage ?? DEFAULT_CARD_IMAGE_URL,
                    props.title,
                    'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
                    true
                  )}
                  <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                    <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
                  </div>
                </SwiperSlide>
              )
            })
          ) : (
            <SwiperSlide className="rounded-xl overflow-hidden" onClick={goToViewPage}>
              {customImageComponent(
                props?.eventCardCoverImageUrl
                  ? props?.eventCardCoverImageUrl
                  : DEFAULT_CARD_IMAGE_URL,
                props.title,
                'event-post-horizontal-card-component__image cursor-pointer swiper-lazy w-full !h-full',
                true
              )}
              <div className="swiper-lazy-preloader-wrapper rounded-xl border border-SeabiscuitMainThemeColor/20 absolute z-10 left-0 top-0 h-full w-full bg-white">
                <div className="swiper-lazy-preloader border-r-SeabiscuitMainThemeColor border-l-SeabiscuitMainThemeColor border-b-SeabiscuitMainThemeColor"></div>
              </div>
            </SwiperSlide>
          )}

          {!disablePrev ? (
            <button
              onClick={goPrev}
              className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in left-3  rounded-full"
            >
              <img src="/assets/next_swiper.svg" alt="next" className="h-[40px]" />
            </button>
          ) : null}

          {!disableNext ? (
            <button
              onClick={goNext}
              className="absolute z-[1] group-hover:visible invisible top-[calc(50%-16px)] transition py-[2px] px-1 ease-in right-3 rounded-full"
            >
              <img src="/assets/prev_swiper.svg" alt="next" height={50} className="h-[40px]" />
            </button>
          ) : null}
        </Swiper>

        {props.status ===
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL ? (
          <div className="event-post-horizontal-card-component__bookmark-label !w-fit flex items-center content-center z-50 text-white bg-[#FC2804] px-3 rounded-lg text-nr py-4 capitalize !left-4">
            Cancelled
          </div>
        ) : (
          <>
            <div className="event-post-horizontal-card-component__date-label z-50">
              <div className="event-post-horizontal-card-component__upper-date-day__text">
                {new Date(eventStartDate ?? '').getDate()}
              </div>
              <div className="event-post-horizontal-card-component__upper-date-month__text">
                {new Date(eventStartDate ?? '').toLocaleString('default', { month: 'short' })}
              </div>
            </div>
            {0 ? (
              <div className="event-post-horizontal-card-component__bookmark-label !w-fit flex items-center content-center z-50 text-white bg-SeabiscuitMainThemeColor px-3 font-semibold rounded-2xl text-sm py-1.5 capitalize">
                Expired
              </div>
            ) : null}

            {0 && props?.category && props?.category !== '' ? (
              <div className="event-post-horizontal-card-component__bookmark-label !w-fit flex items-center content-center z-50 text-white bg-SeabiscuitMainThemeColor px-3 font-semibold rounded-2xl text-sm py-1.5 capitalize">
                {props?.category}
              </div>
            ) : null}
          </>
        )}

        <div className="event-post-details">
          <div className="event-post-horizontal-card-component__card__title w-full overflow-hidden mb-4 text-SeabiscuitDark200ThemeColor cursor-pointer capitalize">
            {props.eventName}
          </div>

          {/* EVENT DETAILS */}

          {getEventDetails(props)}

          {/* EVENT DETAILS */}
          <div className="event-post-horizontal-card-component__card__registration__counter items-center flex pt-2">
            <div className="flex -space-x-1 relative z-0 overflow-hidden">
              {registeredUsersImages.length
                ? registeredUsersImages.map((currUserImage, index) => {
                    return (
                      <img
                        alt={'UI'}
                        onError={(e) => ((e.target as any).src = DUMMY_USER_IMAGE)}
                        src={currUserImage}
                        key={index}
                        className={clsx(
                          'relative inline-block object-cover w-[28px] h-[26px] xl:h-[26px] xl:w-[26px] 2xl:h-[28px] 2xl:w-[28px] rounded-full  bg-white'
                        )}
                      />
                    )
                  })
                : null}
            </div>
            <div className="text-sm text-SeabiscuitDark200ThemeColor pl-2">
              <span className="text-SeabiscuitMainThemeColor font-semibold pr-1">
                {`${props.registeredCountIncludingUnpaid ? props.registeredCountIncludingUnpaid : 0}`}{' '}
                Registered,
              </span>
              {props.waitlistCount ?? 0} on waitlist
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

EventOrganizationHorizontalCardComponent.defaultProps = {
  id: '',
  cardCoverImageUrl: '',
  isLicensed: true,
  title: 'Default Title',
  briefDescription: 'Temporary Brief Description',
  available: 0,
  registrationCloseDays: 0,
  sale: '0.00',
}

export default EventOrganizationHorizontalCardComponent
