import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import React, { createContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import SideMenuNavigation from '../../navigation/SideMenuNavigation'
import './MainLayout.css'

// Component imports
import EventDetailsViewComponent from '../../components/events/views/details/EventDetailsViewComponent'
import FourOFourPage from '../../pages/404/FourOFourPage'
import AllEventRootPage from '../../pages/allEvents/root/AllEventRootPage'
import CompetitorEventRegisterRootPage from '../../pages/competitor/competitorEventRegister/CompetitorEventRegisterRootPage'
import HomeRootPage from '../../pages/home/root/HomeRootPage'
import HorseRootEditPage from '../../pages/horse/HorseRootEditPage'
import MyEvent from '../../pages/myEvent/root/MyEvent'
import ExhibitorMessageUserModal from '../../pages/organizer/exhibitorProfile/modal/ExhibitorMessageUserModal'
import OrganizerRootPage from '../../pages/organizer/root/OrganizerRootPage'
import ProfileRootPage from '../../pages/profile/root/ProfileRootPage'
import ShowsDetailPage from '../../pages/shows/detail/ShowsDetailPage'
import ShowsHomePage from '../../pages/shows/home/ShowsHomePage'
import SignUpPage from '../../pages/signup/SignUpPage'
import UserLoginPage from '../../pages/user/login/UserLoginPage'
import UserRegistrationContainer from '../../pages/user/registration/UserRegistrationContainer'
import RedirectToLogin from '../../pages/user/utils/RedirectToLogin'
import CompetitorLayout from '../CompetitorLayout'
import ChatHOC from '../../pages/messages/components/ChatHOC'

// Pages
import SignConfirmationRootPage from '../../pages/confirmation/SignConfirmationRootPage'
import EarnCommission from '../../pages/earncommission/root/EarnCommission'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectMenuCollapse } from '../../store/system/systemSlice'
import {
  selectIsFirstTime,
  selectIsLoggedIn,
  setProfileDetails,
  setStripeAccountId,
  setStripeId,
  setUserNotificationSettings,
  setUserType,
} from '../../store/user/userSlice'

// Constants
import { CONST } from '../../const/const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { MODALS_INI_STATE } from './MainLayout.constants'

// Images
// import PegasusLogo from '../icons/logo/Logo.svg';

// Custom hooks
import BackgroundProvider from '../../helpers/BackgroundProvider'
import useProfileHook from '../../hooks/users/competitor/profile/useProfileHook'

// Helpers
import { getConvertedData, interfaceHelper } from '../../models/interface.helper'

// Modals
import UserProfileCompleteConfirmation from '../../components/home/modals/UserProfileCompleteConfirmation'
// import UserProfileHorseTabAddHorse from "../../components/home/modals/UserProfileHorseTabAddHorse";
import RegisterForCompetitionModal from '../../components/competitions/modals/RegisterForCompetitionModal'
import RegisterForCompetitionPaperworkModal from '../../components/competitions/modals/RegisterForCompetitionPaperworkModal'
import RegisterForCompetitionPayByCardModal from '../../components/competitions/modals/RegisterForCompetitionPayByCardModal'
import OrganizerMarkPaidModal from '../../components/competitions/modals/OrganizerMarkPaidModal'
import RegisterForCompetitionPayModal from '../../components/competitions/modals/RegisterForCompetitionPayModal'
import RemoveTeamModal from '../../components/competitions/modals/RemoveTeamModal'
import UserProfileAccountTabAddCard from '../../components/competitions/modals/UserProfileAccountTabAddCard'
import StaffModal from '../../components/events/forms/staff/StaffModal'
import EventDetailViewComponentAboutModal from '../../components/events/views/details/modals/EventDetailViewComponentAboutModal'
import EventDetailViewComponentAllFeesModal from '../../components/events/views/details/modals/EventDetailViewComponentAllFeesModal'
import EventDetailViewComponentAllPoliciesModal from '../../components/events/views/details/modals/EventDetailViewComponentAllPoliciesModal'
import EventDetailViewComponentExhibitorsModal from '../../components/events/views/details/modals/EventDetailViewComponentExhibitorsModal'
import EventDetailViewComponentPartnersModal from '../../components/events/views/details/modals/EventDetailViewComponentPartners'
import EventDetailViewComponentZoneSpecsModal from '../../components/events/views/details/modals/EventDetailViewComponentZoneSpecsModal'
import HomeRootAllFilters from '../../components/home/modals/HomeRootAllFilters'
import HomeRootGetLocationFilter from '../../components/home/modals/HomeRootGetLocationFilter'
import AddPoliciesModal from '../../components/modals/AddPoliciesModal'
import CommonTabNoteModal from '../../components/modals/CommonTabNoteModal'
import PublishDoneModal from '../../components/modals/PublishDoneModal'
import ZoneInfoModal from '../../components/modals/ZoneInfoModal'
import { UserModel } from '../../models/users/user.model'
import ViewFilterModal from '../../pages/allEvents/modal/ViewFilterModal'
import ViewModal from '../../pages/allEvents/modal/ViewModal'
import ViewModalResult from '../../pages/allEvents/modal/ViewModalResult'

// Redux
import AuthenticationModal from '../../components/modals/AuthenticationModal'
import useGuest from '../../hooks/useGuest'
import useCompetitorProfileHook from '../../hooks/users/competitor/profile/useCompetitorProfileHook'
import CompetitorRootRegisteredEvent from '../../pages/competitor/registeredEvent/CompetitorRootRegisteredEvent'
import FollowingRootPage from '../../pages/following/FollowingRootPage'
import HelpPageModal from '../../pages/help/modal/HelpPageModal'
import HorsePublicProfile from '../../pages/horse-public-profile'
import ExhibitorProfileRevenuePage from '../../pages/organizer/exhibitorProfile/ExhibitorProfileRevenuePage'
import ExhibitorsListingRoot from '../../pages/organizer/manage/common/table/Exhibitors/ExhibitorsListingRoot'
import StaffListingRoot from '../../pages/organizer/manage/common/table/Staff/tabs/StaffListingRoot'
import RegistrationRevenue from '../../pages/organizer/manage/revenue/exhibitor/RegistrationRevenue'
import SpectatorRevenue from '../../pages/organizer/manage/revenue/spectator/SpectatorRevenue'
import StaffProfileRootPage from '../../pages/organizer/staffProfile/StaffProfileRootPage'
import SpectatorTicketsPurchaseRootPage from '../../pages/spectatorTickets/SpectatorTicketsPurchaseRootPage'
import TicketDashboard from '../../pages/ticket-dashboard/TicketDashboard'
import ProtectedRoute from '../ProtectedRoute'
import IMPORTS from './MainLayout.imports'
import MainLayoutModals from './MainLayoutModals'
import { GenerateTokenPage } from '../../pages/user/generateToken/GenerateTokenPage'
import HorsesManage from '../../pages/organizer/manage/common/table/HorsesManage/HorsesManage'
import { setRegisteredUser } from '../../store/registeredUser/registeredUserSlice'
import { useDispatch } from 'react-redux'
import FirestoreService from '../../services/firestoreService'
import { DATA_FIRESTORE_V01_CONST } from '../../const/data/v01/firestore-v01-const'
import ManageClinicNOtherRootPage from '../../pages/organizer/manage/clinic/ManageClinicNOtherRootPage'
import ManageRiderPage from '../../pages/organizer/manage/clinic/ManageRiderPage'
import CompetitorBillsRootPage from '../../pages/Biils/CompetitorBills/CompetitorBillsRootPage'

interface MainLayoutContainerProps {
  isLoggedIn: boolean
  isFirstTime: boolean
  isMenuCollapsed: boolean
  update: boolean
  children: React.ReactElement
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
}

export type IHandleModal = {
  setDataToPassOn: React.Dispatch<any>
  dataToPassOn: Record<any, any>
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

export const HandleModalContext = createContext<IHandleModal | null>(null)

const MainLayoutContainer: React.FC<MainLayoutContainerProps> = (props) => {
  useCompetitorProfileHook({})

  const dispatch = useAppDispatch()
  const { mUserDetails } = useProfileHook({})

  const [endpoint, setEndpoint] = useState<string>('')

  const currLocation = useLocation()

  useEffect(() => {
    if (currLocation.pathname) {
      setEndpoint((currLocation.pathname as any).split('/').pop())
    }
  }, [currLocation])

  const checkRegistration = async () => {
    if (mUserDetails && mUserDetails.userType) {
      let userDataFromDb = getConvertedData(mUserDetails) ?? {}

      if (mUserDetails.userStripeId && mUserDetails.userStripeId !== '') {
        dispatch(setStripeId(mUserDetails.userStripeId))
      }
      let profileDetails_ = new UserModel(userDataFromDb).toObject()

      if (mUserDetails) {
        dispatch(setProfileDetails(getConvertedData(profileDetails_)))
        dispatch(setStripeAccountId(profileDetails_.userStripeAccountId))
      }
      let notificationSettings = interfaceHelper.getNotificationSettings(
        userDataFromDb.userNotificationsSettings
      )

      dispatch(setUserType(mUserDetails.userType ?? ''))
      dispatch(setUserNotificationSettings(notificationSettings))
    }
  }

  useEffect(() => {
    checkRegistration().then()
  }, [mUserDetails])

  return (
    <>
      {`/${endpoint}` !== CONST.ROUTES.SIGNUP.URL &&
      `/${endpoint}` !== CONST.ROUTES.LOGIN.URL &&
      `/${endpoint}` !== CONST.ROUTES.GENERATE_TOKEN.URL ? (
        <>
          <IonSplitPane
            contentId="main"
            when={'xs'}
            id={props.isMenuCollapsed ? 'main-split-pane1' : 'main-split-pane'}
            className="bg-SeabiscuitGrayThemeColor"
          >
            <SideMenuNavigation
              handleModal={props.handleModal}
              isLoggedIn={props.isLoggedIn}
              logo="assets/Pegasus_app_logo.svg"
            />
            {props.children}
          </IonSplitPane>

          {props.isFirstTime ? <UserProfileCompleteConfirmation /> : null}
        </>
      ) : (
        props.children
      )}
    </>
  )
}

MainLayoutContainer.defaultProps = {
  isLoggedIn: false,
  isFirstTime: true,
}

const MainLayout = () => {
  // Hooks and vars
  const guestHook = useGuest()

  const isMenuCollapsed = useAppSelector(selectMenuCollapse)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isFirstTime = useAppSelector(selectIsFirstTime)
  const [update, setUpdate] = useState<boolean>(false)
  const [zoneModalShow, setZoneModalShow] = useState(false)
  const [dataToPassOn, setDataToPassOn] = useState<any>({})
  const [messageUserModal, setMessageUserModal] = useState(false)
  const [paperworkModalShow, setPaperworkModalShow] = useState(false)
  const [authentication, setAuthentication] = useState<boolean>(false)
  const [viewButtonModalShow, setViewButtonModalShow] = useState(false)
  const [eventsFeesModalShow, setEventsFeesModalShow] = useState(false)
  const [RemoveTeamModalShow, setRemoveTeamModalShow] = useState(false)
  const [helpVideoPlayer, setHelpVideoPlayer] = useState<boolean>(false)
  const [getLocationModalShow, setGetLocationModalShow] = useState(false)
  const [aboutEventsModalShow, setAboutEventsModalShow] = useState(false)
  const [modalsState, setModalsState] = useState({ ...MODALS_INI_STATE })
  const [allPoliciesModalShow, setAllPoliciesModalShow] = useState(false)
  const [addPoliciesModal, setAddPoliciesModal] = useState<boolean>(false)
  const [publishDoneModal, setPublishDoneModal] = useState<boolean>(false)
  const [compitionPayModalShow, setCompitionPayModalShow] = useState(false)
  const [filterButtonModalShow, setFilterButtonModalShow] = useState(false)
  const [getAllFiltersModalShow, setGetAllFiltersModalShow] = useState(false)
  const [vendorTabNoteModel, setVendorTabNoteModel] = useState<boolean>(false)
  const [eventPartnersModalShow, setEventPartnersModalShow] = useState(false)
  const [profileAddCardModalShow, setProfileAddCardModalShow] = useState(false)
  const [eventZoneSpecsModalShow, setEventZoneSpecsModalShow] = useState(false)
  const [nearybyTabNoteModel, setNearybyTabNoteModel] = useState<boolean>(false)
  const [resultViewButtonModalShow, setResultViewButtonModalShow] = useState(false)
  const [eventCreateStaffModel, setEventCreateStaffModel] = useState<boolean>(false)
  const [registerExhibitorModalShow, setregisterExhibitorModalShow] = useState(false)
  const [registerForCompetitionShow, setRegisterForCompetitionShow] = useState(false)
  const [compitionPayByCardModalShow, setCompitionPayByCardModalShow] = useState(false)
  const [organizerMarkPaidModalShow, setOrganizerMarkPaidModalShow] = useState(false)
  const { registeredUser } = useAppSelector((state) => state)
  const dispatch = useDispatch()
  const handleModal = (showHide: boolean, typeOfModal: string, data?: any) => {
    if (data) {
      setDataToPassOn(data)
      if (data.registeredUser && !registeredUser.data) {
        ;(async () => {
          const usersSnaps = await FirestoreService.getItem(
            DATA_FIRESTORE_V01_CONST.COLLECTIONS.USERS.NAME,
            data.registeredUser.id
          )
          const userData = getConvertedData(UserModel.fromFirestoreDoc(usersSnaps).toObject())
          dispatch(setRegisteredUser(userData))
        })()
      }
    }

    setModalsState((prevState) => ({
      ...prevState,
      [typeOfModal]: showHide,
    }))

    switch (typeOfModal) {
      case MODAL_CONSTS.ZONE_MODAL:
        setZoneModalShow(showHide)
        break
      case MODAL_CONSTS.HELP_VIDEO:
        setHelpVideoPlayer(showHide)
        break
      case MODAL_CONSTS.ADD_NOTE:
        setVendorTabNoteModel(showHide)
        break
      case MODAL_CONSTS.CARD:
        setProfileAddCardModalShow(showHide)
        break
      case MODAL_CONSTS.AUTHENTICATION:
        setAuthentication(showHide)
        break
      case MODAL_CONSTS.LOCATION:
        setGetLocationModalShow(showHide)
        break
      case MODAL_CONSTS.POLICIES:
        setAllPoliciesModalShow(showHide)
        break
      case MODAL_CONSTS.MESSAGE_USER:
        setMessageUserModal(showHide)
        break
      case MODAL_CONSTS.FILTERS:
        setGetAllFiltersModalShow(showHide)
        break
      case MODAL_CONSTS.PARTNER:
        setEventPartnersModalShow(showHide)
        break
      case MODAL_CONSTS.EVENTS_FEES:
        setEventsFeesModalShow(showHide)
        break
      case MODAL_CONSTS.ABOUT_EVENTS:
        setAboutEventsModalShow(showHide)
        break
      case MODAL_CONSTS.ADD_POLICIES_MODAL:
        setAddPoliciesModal(showHide)
        break
      case MODAL_CONSTS.ADD_NEARBY_NOTE:
        setNearybyTabNoteModel(showHide)
        break
      case MODAL_CONSTS.VIEW_BUTTON_EVENT:
        setViewButtonModalShow(showHide)
        break
      case MODAL_CONSTS.REMOVE_TEAM_MEMBER:
        setRemoveTeamModalShow(showHide)
        break
      case MODAL_CONSTS.EVENT_ZONE_SPECS:
        setEventZoneSpecsModalShow(showHide)
        break
      case MODAL_CONSTS.FILTER_BUTTON_EVENT:
        setFilterButtonModalShow(showHide)
        break
      case MODAL_CONSTS.REGISTER_FOR_PAPERWORK:
        setPaperworkModalShow(showHide)
        break
      case MODAL_CONSTS.EVENT_PUBLISH_DONE_MODAL:
        setPublishDoneModal(showHide)
        break
      case MODAL_CONSTS.REGISTER_EXHIBITORS:
        setregisterExhibitorModalShow(showHide)
        break
      case MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD:
        setEventCreateStaffModel(showHide)
        break
      case MODAL_CONSTS.RESULT_VIEW_BUTTON_EVENT:
        setResultViewButtonModalShow(showHide)
        break
      case MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY:
        setCompitionPayModalShow(showHide)
        break
      case MODAL_CONSTS.REGISTER_FOR_COMPETETION:
        setRegisterForCompetitionShow(showHide)
        break
      case MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD:
        setCompitionPayByCardModalShow(showHide)
        break
      case MODAL_CONSTS.ORGANIZER_MARK_PAID:
        setOrganizerMarkPaidModalShow(showHide)
        break
    }
  }

  useEffect(() => {
    setUpdate((prev) => !prev)
  }, [])

  useEffect(() => {
    if (!isLoggedIn && guestHook.guestId) {
      guestHook.getGuestFromDb(true).then()
    }
  }, [isLoggedIn])

  if (typeof isLoggedIn !== 'boolean') return null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <Router>
      <BackgroundProvider>
        <HandleModalContext.Provider value={{ handleModal, dataToPassOn, setDataToPassOn }}>
          <UserRegistrationContainer />
          <MainLayoutContainer
            update={update}
            handleModal={handleModal}
            isMenuCollapsed={isMenuCollapsed}
            isLoggedIn={isLoggedIn}
            isFirstTime={isFirstTime}
          >
            <IonRouterOutlet id="main" animated={false}>
              <Switch>
                <Route
                  path={CONST.ROUTES.GENERATE_TOKEN.URL}
                  exact
                  render={() => {
                    return <GenerateTokenPage />
                  }}
                />
                <Route
                  path={CONST.ROUTES.LOGIN.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.LOGIN.SCOPE}>
                        <UserLoginPage handleModal={handleModal} />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.SIGNUP.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.SIGNUP.SCOPE}>
                        <SignUpPage />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.HOME.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HOME.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HomeRootPage isShowMap handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.EVENT_DETAILS.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.EVENT_DETAILS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <EventDetailsViewComponent
                            isLoggedIn={isLoggedIn}
                            handleModal={handleModal}
                            dataToPassOn={dataToPassOn}
                          />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.TICKET_DASHBOARD.URL}/:eventId/:activeTab`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.TICKET_DASHBOARD.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <TicketDashboard />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.REGISTER_EVENT.URL}/:eventId/:userId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.REGISTER_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <CompetitorEventRegisterRootPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/:eventId/:userId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE_REGISTER_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <CompetitorEventRegisterRootPage isManage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.TICKETS.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.TICKETS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <SpectatorTicketsPurchaseRootPage
                            handleModal={handleModal}
                            isLoggedIn={isLoggedIn}
                          />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.CLINIC_N_OTHER.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ManageClinicNOtherRootPage
                            handleModal={handleModal}
                            dataToPassOn={dataToPassOn}
                          />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.MANAGE_RIDER.URL}/:userId/:eventId/:registrationId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.MANAGE_RIDER.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ManageRiderPage handleModal={handleModal} dataToPassOn={dataToPassOn} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.SPECTATOR.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.SPECTATOR.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <SpectatorRevenue />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.EARN_COMMISSION.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.EARN_COMMISSION.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <EarnCommission />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.REGISTRATION.URL}/:eventId`}
                  exact
                  render={() => {
                    if (!isLoggedIn) {
                      return <Redirect to={CONST.ROUTES.LOGIN.URL} />
                    }
                    return (
                      <ProtectedRoute
                        scope={CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.REGISTRATION.SCOPE}
                      >
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <RegistrationRevenue />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.EXHIBITORS.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.EXHIBITORS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ExhibitorsListingRoot isFullScreen={true} className={`w-full`} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.HORSES.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.HORSES.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HorsesManage isFullScreen className="w-full" />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MANAGE.STAFF.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MANAGE.STAFF.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <StaffListingRoot isFullScreen={true} className={`w-full`} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.STAFF_PROFILE.URL}/:staffId/:staffRole/:isOtherStaff/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.STAFF_PROFILE.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <StaffProfileRootPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.COMPETITOR_REGISTERED_EVENT.URL}/:userId/:eventId/:registrationId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.COMPETITOR_REGISTERED_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <CompetitorRootRegisteredEvent handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.EXHIBITOR_REFUND.URL}/:userId/:eventId/:registrationId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.EXHIBITOR_REFUND.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ExhibitorProfileRevenuePage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.LOGOUT.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.LOGOUT.SCOPE}>
                        <RedirectToLogin />
                      </ProtectedRoute>
                    )
                  }}
                />
                <Route
                  path={`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/:userHorseMappingId/:id`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HORSES.SUBMENU.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HorseRootEditPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL2}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HORSES.SUBMENU.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HorseRootEditPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />
                <Route
                  path={`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL3}/:userHorseMappingId/:id`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HORSES.SUBMENU.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HorseRootEditPage isView handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.ORGANIZER_HOME.REACT_URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ORGANIZER_HOME.SCOPE}>
                        <OrganizerRootPage handleModal={handleModal} />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.ORGANIZER_EDIT.URL}/:eventId`}
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ORGANIZER_EDIT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ShowsDetailPage handleModal={handleModal} dataToPassOn={dataToPassOn} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.ORGANIZER_MANAGE.URL}/:eventId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ORGANIZER_MANAGE.SCOPE}>
                        <ShowsHomePage />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.PROFILE_SETTINGS.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.PROFILE_SETTINGS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <ProfileRootPage handleModal={handleModal} settingsOnly={true} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/:horseDocId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HORSE_PUBLIC_PROFILE.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HorsePublicProfile />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.ALL_EVENT.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ALL_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <AllEventRootPage handleModal={handleModal} settingsOnly={true} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.USER_PROFILE.URL}/:userId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.USER_PROFILE.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <AllEventRootPage handleModal={handleModal} isOthersProfile={true} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.ALL_EVENT.URL}/:registerId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ALL_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <AllEventRootPage handleModal={handleModal} isOthersProfile={true} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.COMING_SOON.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.COMING_SOON.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.COMING_SOON />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.MESSAGES.URL}/:channelId?`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MESSAGES.SCOPE}>
                        <ChatHOC>
                          <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                            <IMPORTS.PAGES.MESSAGES handleModal={handleModal} />
                          </CompetitorLayout>
                        </ChatHOC>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.NOTIFICATIONS.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.NOTIFICATIONS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.NOTIFICATION_PAGE />;
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.PAPERWORK.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.PAPERWORK.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.PAPERWORK />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.BILLS.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.BILLS.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <CompetitorBillsRootPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={CONST.ROUTES.MY_EVENT.URL}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.MY_EVENT.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <MyEvent handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.SIGN_CONFIRMED.URL}/:token/:signatoryId/:eventId/:paperworkName/:riderId/:eventDraftId`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.SIGN_CONFIRMED.SCOPE}>
                        <SignConfirmationRootPage />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.HELP.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.HELP.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.HELP_PAGE handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.FAQ.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.FAQ.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.HELP_PAGE
                            handleModal={handleModal}
                            tabToOpen={CONST.ROUTES.FAQ.URL}
                          />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.POLICIES.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.POLICIES.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <IMPORTS.PAGES.HELP_PAGE
                            handleModal={handleModal}
                            tabToOpen={CONST.ROUTES.POLICIES.URL}
                          />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.ROOT.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.ROOT.SCOPE}>
                        <Redirect to={CONST.ROUTES.SEARCH.URL} />
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.SEARCH.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.SEARCH.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <HomeRootPage handleModal={handleModal} />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  path={`${CONST.ROUTES.FOLLOWING_USER.URL}`}
                  exact
                  render={() => {
                    return (
                      <ProtectedRoute scope={CONST.ROUTES.FOLLOWING_USER.SCOPE}>
                        <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                          <FollowingRootPage />
                        </CompetitorLayout>
                      </ProtectedRoute>
                    )
                  }}
                />

                <Route
                  render={() => {
                    return (
                      <CompetitorLayout handleModal={handleModal} isLoggedIn={isLoggedIn}>
                        <FourOFourPage />
                      </CompetitorLayout>
                    )
                  }}
                />
              </Switch>
            </IonRouterOutlet>
          </MainLayoutContainer>
          <>
            {profileAddCardModalShow && (
              <UserProfileAccountTabAddCard
                dataToPassOn={dataToPassOn}
                show={profileAddCardModalShow}
                handleModal={handleModal}
              />
            )}

            {getLocationModalShow && (
              <HomeRootGetLocationFilter
                show={getLocationModalShow}
                handleModal={setGetLocationModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {getAllFiltersModalShow && (
              <HomeRootAllFilters show={getAllFiltersModalShow} handleModal={handleModal} />
            )}
            {allPoliciesModalShow && (
              <EventDetailViewComponentAllPoliciesModal
                show={allPoliciesModalShow}
                handleModal={setAllPoliciesModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {aboutEventsModalShow && (
              <EventDetailViewComponentAboutModal
                show={aboutEventsModalShow}
                handleModal={setAboutEventsModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {eventsFeesModalShow && (
              <EventDetailViewComponentAllFeesModal
                show={eventsFeesModalShow}
                handleModal={setEventsFeesModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {eventZoneSpecsModalShow && (
              <EventDetailViewComponentZoneSpecsModal
                show={eventZoneSpecsModalShow}
                handleModal={setEventZoneSpecsModalShow}
              />
            )}
            {eventPartnersModalShow && (
              <EventDetailViewComponentPartnersModal
                show={eventPartnersModalShow}
                handleModal={setEventPartnersModalShow}
              />
            )}
            {registerExhibitorModalShow && (
              <EventDetailViewComponentExhibitorsModal
                show={registerExhibitorModalShow}
                handleModal={setregisterExhibitorModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {registerForCompetitionShow && (
              <RegisterForCompetitionModal
                show={registerForCompetitionShow}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {RemoveTeamModalShow && (
              <RemoveTeamModal
                show={RemoveTeamModalShow}
                handleModal={setRemoveTeamModalShow}
                dataToPassOn={dataToPassOn}
              />
            )}
            {paperworkModalShow && (
              <RegisterForCompetitionPaperworkModal
                show={paperworkModalShow}
                handleModal={setPaperworkModalShow}
              />
            )}
            {paperworkModalShow && (
              <RegisterForCompetitionPaperworkModal
                show={paperworkModalShow}
                handleModal={setPaperworkModalShow}
              />
            )}
            {compitionPayModalShow && (
              <RegisterForCompetitionPayModal
                show={compitionPayModalShow}
                handleModal={setCompitionPayModalShow}
              />
            )}
            {compitionPayByCardModalShow && (
              <RegisterForCompetitionPayByCardModal
                show={compitionPayByCardModalShow}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {organizerMarkPaidModalShow && (
              <OrganizerMarkPaidModal
                show={organizerMarkPaidModalShow}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {zoneModalShow && <ZoneInfoModal show={zoneModalShow} handleModal={setZoneModalShow} />}
            {viewButtonModalShow && (
              <ViewModal show={viewButtonModalShow} handleModal={setViewButtonModalShow} />
            )}
            {filterButtonModalShow && (
              <ViewFilterModal
                show={filterButtonModalShow}
                handleModal={setFilterButtonModalShow}
              />
            )}
            {resultViewButtonModalShow && (
              <ViewModalResult
                show={resultViewButtonModalShow}
                handleModal={setResultViewButtonModalShow}
              />
            )}
            {eventCreateStaffModel && (
              <StaffModal
                show={eventCreateStaffModel}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {addPoliciesModal && (
              <AddPoliciesModal
                show={addPoliciesModal}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {vendorTabNoteModel && (
              <CommonTabNoteModal
                constValue={MODAL_CONSTS.ADD_NOTE}
                show={vendorTabNoteModel}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {nearybyTabNoteModel && (
              <CommonTabNoteModal
                constValue={MODAL_CONSTS.ADD_NEARBY_NOTE}
                show={nearybyTabNoteModel}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {publishDoneModal && (
              <PublishDoneModal
                show={publishDoneModal}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {messageUserModal && (
              <ExhibitorMessageUserModal show={messageUserModal} handleModal={handleModal} />
            )}
            {helpVideoPlayer && (
              <HelpPageModal
                show={helpVideoPlayer}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            {authentication && (
              <AuthenticationModal
                show={authentication}
                handleModal={handleModal}
                dataToPassOn={dataToPassOn}
              />
            )}
            <MainLayoutModals
              modalsState={modalsState}
              dataToPassOn={dataToPassOn}
              handleModal={handleModal}
            />
          </>
        </HandleModalContext.Provider>
      </BackgroundProvider>
    </Router>
  )
}

export default MainLayout
