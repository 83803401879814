import React, { FC, useContext } from 'react'
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form'

import Input from '../../../../../common/inputs/Input'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'
import { IFeeRefundsName } from '../EventFeesRefunds/EventFeesRefunds'
import { IFeeSimpleName } from '../EventFeeSimple/EventFeeSimple'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'

interface NoteFieldProps {
  name: IFeeSimpleName | IFeeRefundsName
  watch: UseFormWatch<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  withHeader?: boolean
}
export const NoteField: FC<NoteFieldProps> = ({ name, withHeader, watch, register, setValue }) => {
  const handleModalContext = useContext(HandleModalContext)

  return (
    <div className="flex-1 min-w-[150px]">
      {withHeader && (
        <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
          Note
        </div>
      )}
      <Input
        valid={watch(`${name}.status`)}
        readOnly={true}
        register={register}
        showPostFixIcon={!!watch(`${name}.note`) && !!watch(`${name}.status`)}
        placeholder="Enter note"
        className="!h-[48px] px-4 relative"
        name={`${name}.note`}
        postFixIcon="/assets/cp_icons/Ok-3.svg"
        clear={!watch(`${name}.status`)}
        disabled={!watch(`${name}.status`)}
        value={watch(`${name}.note`)}
        validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
        invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
        onClick={(e) => {
          handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
            noteInputRef: e.target,
            setValue,
          })
        }}
      />
    </div>
  )
}
