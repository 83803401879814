import React, { FC, useContext } from 'react'

import Select, { components } from 'react-select'

import { IconSearch } from '../../../../components/icons/IconSearch'
import { IconMail } from '../../../../components/icons/IconMail'

import { formatOptionLabel } from '../../../../helpers/select'
import { getConvertedData } from '../../../../models/interface.helper'

import FirestoreService from '../../../../services/firestoreService'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'

import { ALL_CLASSES, ALL_STATUSES, EStatuses } from './hooks/useManageClinicNOtherAdminTabNew'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { CONST } from '../../../../const/const'
import { IRider } from '../../../../types/entryRider'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const statuses = [
  ALL_STATUSES,
  { value: EStatuses.good, label: EStatuses.good },
  { value: EStatuses.bad, label: EStatuses.bad },
  { value: EStatuses.scratched, label: EStatuses.scratched },
]

interface ManageEntriesActionsProps {
  activeStatus: { value: string; label: string }
  setActiveStatus: (value: { value: string; label: string }) => void
  setRiders: (value: IRider[]) => void
  frozenRiders: IRider[]
  setSearchNameValue: (value: string) => void
  setActiveClass: (value: { value: string; label: string }) => void
  activeClass: { value: string; label: string }
  searchNameValue: string
  ridersClasses: { value: string; label: string }[]
  riders: IRider[]
}

export const ManageEntriesActions: FC<ManageEntriesActionsProps> = ({
  activeStatus,
  setActiveStatus,
  setRiders,
  activeClass,
  setActiveClass,
  searchNameValue,
  setSearchNameValue,
  frozenRiders,
  ridersClasses,
  riders,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const statusSelectHandler = (itm: typeof ALL_STATUSES) => {
    setActiveStatus(itm)
    if (itm.value === ALL_STATUSES.value) {
      return setRiders(frozenRiders)
    }

    if (EStatuses.scratched === itm.value) {
      const newRiders = frozenRiders.filter((rider) => rider.isScratched)
      return setRiders(newRiders)
    }
    if (EStatuses.good === itm.value) {
      const newRiders = frozenRiders.filter(
        (rider) =>
          rider.paymentStatus === PAYMENT_STATUSES.PAID &&
          rider.riderTeamMember.mailLog &&
          rider.riderTeamMember.mailLog.find((m) => m.status === 'Signed') &&
          rider.classes.length
      )
      return setRiders(newRiders)
    }
    if (EStatuses.bad === itm.value) {
      const newRiders = frozenRiders.filter(
        (rider) =>
          rider.paymentStatus !== PAYMENT_STATUSES.PAID ||
          (rider.riderTeamMember.mailLog &&
            rider.riderTeamMember.mailLog.find((m) => m.status !== 'Signed')) ||
          rider.classes.length === 0
      )
      return setRiders(newRiders)
    }
  }
  const selectClassHandler = (itm: typeof ALL_CLASSES) => {
    setActiveClass(itm)
    if (itm.value === ALL_CLASSES.value) {
      return setRiders(frozenRiders)
    }

    const newRiders = frozenRiders.filter((rider) => rider.classes.find((cl) => cl === itm.value))
    setRiders(newRiders)
  }

  const onchangeSearchRiderName = (value: string) => {
    setSearchNameValue(value)
    if (value.length < 1) {
      return setRiders(frozenRiders)
    }
    const newRiders = frozenRiders.filter(
      (rider) =>
        rider.riderName?.toLowerCase().includes(value.toLowerCase()) ||
        rider.horseName?.toLowerCase().includes(value.toLowerCase())
    )

    return setRiders(newRiders)
  }

  return (
    <div className={'flex flex-col md:flex-row gap-4'}>
      <div className="relative rounded-full">
        <div className="search-div-wrapper border relative h-[45px] rounded-[8px] w-full md:w-[272px] border-[#D3DAEE] mb-2">
          <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
            <IconSearch />
          </div>

          <input
            type="search"
            id="default-search"
            className="block w-full bg-transparent p-0 h-[45px] pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
            placeholder="Search name"
            value={searchNameValue}
            onChange={(event) => onchangeSearchRiderName(event.target.value)}
            onFocus={() => null}
            onBlur={() => null}
          />
        </div>
      </div>
      <Select
        value={activeClass}
        defaultValue={activeClass}
        options={ridersClasses}
        formatOptionLabel={formatOptionLabel}
        className={'w-full md:w-[20vw]'}
        styles={{
          control: (styles) => ({
            ...styles,
            height: '45px',
            borderRadius: '8px',
            borderColor: '#D3DAEE',
            // width: '20vw',
          }),
        }}
        onChange={(selectedOption) => selectClassHandler(selectedOption as typeof ALL_CLASSES)}
        components={{
          DropdownIndicator: (props_) => {
            return (
              <components.DropdownIndicator {...props_} className="[&>svg>path]:fill-[#122B46]" />
            )
          },
          IndicatorSeparator: () => null,
        }}
      />
      <Select
        isClearable={false}
        isSearchable={false}
        placeholder="All"
        menuShouldScrollIntoView={true}
        value={activeStatus}
        options={statuses}
        className={'w-full md:w-[123px]'}
        styles={{
          control: (styles) => ({
            ...styles,
            height: '45px',
            borderRadius: '8px',
            borderColor: '#D3DAEE',
          }),
        }}
        onChange={(selectedOption: any) => statusSelectHandler(selectedOption)}
        components={{
          DropdownIndicator: (props_) => {
            return (
              <components.DropdownIndicator {...props_} className="[&>svg>path]:fill-[#122B46]" />
            )
          },
          IndicatorSeparator: () => null,
        }}
      />
      {riders && riders[0] && (
        <div className="flex items-start">
          <button
            type="button"
            className="border flex items-center rounded-[8px] justify-center border-solid border-[#D3DAEE] w-[45px] h-[45px] hover:opacity-50"
            onClick={async () => {
              const trainersIds: string[] = []
              riders.forEach((rider) => {
                if (rider.horse && rider.horse.memberId) {
                  trainersIds.push(rider.horse.memberId)
                }
              })
              const usersList = [...new Set(trainersIds)]

              let usersSnaps = await FirestoreService.getItemsUsingIds(
                COLLECTIONS.USERS.NAME,
                usersList
              )
              const usersData: IUserInterface[] = []
              usersSnaps.forEach((currSnap) => {
                usersData.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
              })

              const emails: string[] = riders.map((rider) => rider.riderEmail || '')

              usersData.forEach((user) => {
                const isExist = emails.find((email) => email === user.userEmail)
                if (!isExist && user.userEmail) {
                  emails.push(user.userEmail)
                }
              })

              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE_ENTRIES, {
                emailId: emails.join(','),
              })
            }}
          >
            <IconMail />
          </button>
        </div>
      )}
    </div>
  )
}
