import clsx from 'clsx'

type Props = {
  title?: string
  children?: any
  className?: string
  description?: string
}

const CompetitorEventRegisterWrapper = (props: Props) => {
  return (
    <>
      <div className="py-[20px] px-[15px] lg:px-[30px] flex flex-col flex-1">
        <div className="px-4 lg:px-0 headerSection lg:max-w-[55%]">
          <div className="inline-flex items-center justify-end h-6">
            <h2 className="text-SeabiscuitMainThemeColor text-xl 2xl:text-2xl font-semibold mr-1">
              {props.title}
            </h2>
          </div>
          <p className="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base">
            {props.description}
          </p>
        </div>
        <hr className="mt-4 h-1 w-full"></hr>
        <div
          className={clsx(
            'overflow-y-auto mt-4 flex flex-col flex-1',
            props?.className && props?.className
          )}
        >
          {props.children}
        </div>
      </div>
    </>
  )
}

export default CompetitorEventRegisterWrapper
