import { FC } from 'react'

import EventPoliciesFormComponent from '../../../components/events/forms/policies/EventPoliciesFormComponent'

import FirestoreService from '../../../services/firestoreService'
import useToasterHelper from '../../../helpers/ToasterHelper'

import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'

import { IEventPolicies } from '../../../models/event-policies/event-policies.interface'
import { EventPoliciesModel } from '../../../models/event-policies/event-policies.model'

import { CONST } from '../../../const/const'

interface ShowsDetailPageEventPoliciesFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal?: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  nextEventTab: string
  eventTab?: string
}

const ShowsDetailPageEventPoliciesFormSubSection: FC<
  ShowsDetailPageEventPoliciesFormSubSectionProps
> = (props) => {
  const toasterFunctions = useToasterHelper()

  const selectedEventR = useAppSelector(selectedEvent)

  const eventId = selectedEventR.Event?.id ?? ''

  const validFormHandler = async (data: IEventPolicies) => {
    let updated = true

    const updated_data = new EventPoliciesModel({
      ...data,
    })

    await new Promise((resolve) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_POLICIES.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .catch((reason) => {
          updated = false
          console.error('validFormHandler:' + reason)
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab:
        props.nextEventTab === '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab(''),
    }
  }

  const invalidFormHandler = async () => {
    props.onSetNextEventTab('')
    toasterFunctions.error({ message: 'Please check required fields below.' })
  }

  return (
    <>
      <EventPoliciesFormComponent
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        handleModal={props.handleModal}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
        eventId={eventId}
      />
    </>
  )
}

export default ShowsDetailPageEventPoliciesFormSubSection
