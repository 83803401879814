import TimeLib from '../../../../lib/Time'
import { IProductInterface } from '../../../../models/product/product.interface'

export const DATA_FIRESTORE_V01_PRODUCTS = {
  NAME: 'v01_products',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    TAGS: {
      KEY: 'tags',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATOR_ID: {
      KEY: 'creatorId',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    PICTURES: {
      KEY: 'pictures',
      VALUES: {
        DEFAULT: [],
      },
    },

    NAME: {
      KEY: 'name',
      VALUES: {
        DEFAULT: null,
      },
    },

    URL: {
      KEY: 'url',
      VALUES: {
        DEFAULT: null,
      },
    },

    NAME_N_GRAMS: {
      KEY: 'nameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATOR_TYPE: {
      KEY: 'creatorType',
      VALUES: {
        DEFAULT: null,
        VENDOR: 'vendor' as IProductInterface['creatorType'],
        ORGANIZER: 'organizer' as IProductInterface['creatorType'],
        COMPETITOR: 'competitor' as IProductInterface['creatorType'],
      },
    },

    CATEGORY: {
      KEY: 'category',
      VALUES: {
        DEFAULT: null,
      },
    },

    COUPON_CODE: {
      KEY: 'couponCode',
      VALUES: {
        DEFAULT: null,
      },
    },

    PRICE: {
      KEY: 'price',
      VALUES: {
        DEFAULT: 0,
      },
    },

    DISCOUNT_PRICE: {
      KEY: 'discountPrice',
      VALUES: {
        DEFAULT: 0,
      },
    },

    STATUS: {
      KEY: 'status',
      VALUES: {
        LIVE: 'live' as IProductInterface['status'],
        DRAFT: 'draft' as IProductInterface['status'],
        DEFAULT: 'draft' as IProductInterface['status'],
      },
    },

    DESCRIPTION: {
      KEY: 'description',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATOR_NAME: {
      KEY: 'creatorName',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATOR_NAME_N_GRAMS: {
      KEY: 'creatorNameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATOR_PICTURE: {
      KEY: 'creatorPicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    MODIFIED: {
      KEY: 'modified',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
