import { Search } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import helpers from '../../commonHelpers/helpers'
import { CONST } from '../../const/const'
import { IMAGE_CONSTS } from '../../const/image-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { useDebounceWithSetter } from '../../hooks/useDebounceWithSetter'
import { IHandleModal } from '../../layout/mainlayout/MainLayout'
import { IHorseData } from '../../models/horse/horse.interface'
import { HorseModel } from '../../models/horse/horse.model'
import { getConvertedData } from '../../models/interface.helper'
import { UserHorseMappingModel } from '../../models/user-horse-mapping/userHorseMapping.model'
import FirestoreService from '../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'
import ViewsLoader from '../loader/ViewsLoader'
import MainModal from './common/MainModal'
import { selectHorses, selectMappings, setHorses } from '../../store/horses/horseSlice'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Types

type Props = {
  show?: boolean
  handleModal?: IHandleModal['handleModal']
  dataToPassOn?: {
    removeHorse?: true
  }
}

export interface IHorseDataWithSelected extends IHorseData {
  selected?: boolean
}

const SearchHorseByName = (props: Props) => {
  // Hooks and vars
  const { debouncedValue, debounceSetter } = useDebounceWithSetter({
    delay: 1000,
    value: null,
  })

  const dispatch = useAppDispatch()
  const myHorses = useAppSelector(selectHorses)
  const mappings = useAppSelector(selectMappings)
  const userId = useAppSelector(selectUserId)

  const toastFunctions = useToasterHelper()
  const searchBoxRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)
  const [selectedHorses, setSelectedHorses] = useState<IHorseData[]>([])
  const [addHorseToDbLoading, setAddHorseToDbLoading] = useState(false)
  const [listToShow, setListToShow] = useState<IHorseDataWithSelected[]>([])

  const showSearchList = !!searchBoxRef?.current?.value
  const removeHorse = props?.dataToPassOn?.removeHorse ?? false

  useEffect(() => {
    getHorses(debouncedValue ?? null).then()
  }, [debouncedValue])

  useEffect(() => {
    setSelectedHorses([])
    async function getHorses() {
      if (!userId) return 0

      if (removeHorse) {
        setListToShow(myHorses)
      }
    }

    if (userId) getHorses().then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, removeHorse])

  // Functions
  async function getHorses(value: string | null) {
    try {
      let horses: IHorseData[] = []
      let alreadyAddedToProfile = false

      const fetch_horse = await FirestoreService.filterItems(COLLECTIONS.HORSES.NAME, [
        where(COLLECTIONS.HORSES.FIELDS.HORSE_NAME_N_GRAM.KEY, 'array-contains', value),
      ])

      fetch_horse.forEach((curr) => {
        alreadyAddedToProfile = !!myHorses.find((currMyHorse) => currMyHorse.id === curr.id)
        if (!alreadyAddedToProfile)
          horses.push(getConvertedData(HorseModel.fromFirestoreDoc(curr).toObject()))
      })

      if (!searchBoxRef.current?.value) horses = []
      setListToShow(horses)
    } catch (error) {
    } finally {
      if (searchBoxRef.current?.value.toLowerCase() === value || searchBoxRef.current?.value === '')
        setLoading(false)
    }
  }

  const filterHorses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value.toLowerCase() ?? ''
    let filteredHorses_: IHorseData[] = []
    const isAddHorseModel = !removeHorse

    if (isAddHorseModel) {
      debounceSetter(strToSearch)
      if (!strToSearch) {
        return setListToShow([])
      }
      setLoading(true)
      return
    }

    setListToShow([...filteredHorses_])
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, horse: IHorseDataWithSelected) => {
    const remove = !e.target.checked

    let horseIndex = -1
    let selectedHorses_ = [...selectedHorses]

    if (!horse.id)
      return toastFunctions.error({
        message: MESSAGES_CONST.CANNOT_SELECT_HORSE,
      })

    horseIndex = selectedHorses.findIndex((currHorse) => currHorse.id === horse.id)

    if (horseIndex !== -1 && remove) selectedHorses_.splice(horseIndex, 1)
    else selectedHorses_.push(horse)

    setSelectedHorses(selectedHorses_)
  }

  const onRemoveHorseButtonClick = () => {
    props?.handleModal?.(false, MODAL_CONSTS.SEARCH_HORSE_BY_NAME)
    props?.handleModal?.(true, MODAL_CONSTS.REMOVE_HORSE, {
      selectedHorses,
      mappings,
    })
  }

  const onAddHorseButtonClick = async () => {
    let currLoopIndex = 0
    let isMyHorse = false
    let createdMappingId = null
    let addedHorseCount: number = 0
    let horseId: string | null = null
    let horseOwnerId: string | null = null
    let currHorse: IHorseData | null = null
    let updatedListToShow_ = [...listToShow]
    let createdMappings: IUserHorseMappingInterface[] = []

    setAddHorseToDbLoading(true)

    try {
      while (currLoopIndex < selectedHorses.length) {
        currHorse = selectedHorses?.[currLoopIndex] ?? null
        isMyHorse = currHorse.horseOwnerId === userId

        horseId = currHorse.id ?? null
        horseOwnerId = currHorse?.horseOwnerId ?? null

        if (!horseId || !horseOwnerId || !currHorse) {
          currLoopIndex++
          return
        }

        let docToSave = new UserHorseMappingModel({
          userId,
          horseId,
          isMyHorse,
          horseOwnerId,
          horseName: currHorse.horseName,
          horseZone: currHorse.horseZone,
          horseSelectedForCompeletion: true,
          horseNameNGram: currHorse?.horseNameNGram,
          horseDiscipline: currHorse.horseDiscipline,
          horseProfilePicture: currHorse.horseProfilePicture,
        })

        createdMappingId = (
          await FirestoreService.createItem(
            COLLECTIONS.USER_HORSE_MAPPING.NAME,
            docToSave.toFirestore()
          )
        )?.id

        createdMappings.push({
          ...getConvertedData(docToSave.toObject()),
          id: createdMappingId,
        })

        addedHorseCount++
        currLoopIndex++

        // eslint-disable-next-line no-loop-func
        updatedListToShow_ = updatedListToShow_.filter((currHorse) => {
          return currHorse.id !== horseId
        })
      }

      const horseMapping = createdMappings.map((item) => {
        return listToShow.find((horse) => horse.id === item.horseId)
      })
      dispatch(setHorses([...myHorses, ...horseMapping]))
      setListToShow(updatedListToShow_)
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    } finally {
      if (addedHorseCount === selectedHorses.length) {
        toastFunctions.success({
          message:
            selectedHorses.length === 1
              ? MESSAGES_CONST.HORSE_ADDED_SUCCESSFULLY
              : MESSAGES_CONST.HORSES_ADDED_SUCCESSFULLY,
        })
        props?.handleModal?.(false, MODAL_CONSTS.SEARCH_HORSE_BY_NAME)
      } else {
        toastFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }
      setAddHorseToDbLoading(false)
    }
  }

  const onSubmitButtonClick = () => {
    if (!selectedHorses.length)
      return toastFunctions.info({
        message: MESSAGES_CONST.PLEASE_ADD_HORSES,
      })

    if (removeHorse) onRemoveHorseButtonClick()
    else onAddHorseButtonClick().then()
  }

  const focusSearchBox = () => {
    if (searchBoxRef.current) searchBoxRef.current.focus()
  }

  const closeThisAndOpenPrev = () => {
    props?.handleModal?.(false, MODAL_CONSTS.SEARCH_HORSE_BY_NAME)
    props?.handleModal?.(true, MODAL_CONSTS.HORSE)
  }

  return (
    <MainModal
      size="md"
      titleClassName="!font-[500]"
      title="Search horse by name"
      type="SEARCH_HORSE_BY_NAME"
      show={props.show ?? false}
      // loading={myHorsesLoading}
      onTransitionEnd={focusSearchBox}
      buttons={[
        {
          label: removeHorse ? 'REMOVE' : 'ADD',
          loading: addHorseToDbLoading,
          disabled: !selectedHorses.length,
          onClick: () => onSubmitButtonClick(),
          bgClass: '!bg-SeabiscuitMainThemeColor',
          textClass: '!text-white',
        },
        {
          label: 'CANCEL',
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: '!border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: closeThisAndOpenPrev,
        },
      ]}
    >
      <>
        <div className="inputSearch mt-2 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={filterHorses}
            placeholder="Search..."
            className="rounded-full w-full border focus:border-[#D3DAEE] text-SeabiscuitDark200ThemeColor border-[#D3DAEE] focus:ring-0"
          />
          <Search className="absolute text-SeabiscuitMainThemeColor right-4" />
        </div>

        {loading ? (
          <div className="flex justify-center items-center min-h-[200px] mr-4">
            <div className="flex justify-center items-center min-h-[200px]">
              <ViewsLoader size="xl" color="red" />
            </div>
          </div>
        ) : (
          <div className="uselListWarp mt-4 flex flex-col">
            {listToShow?.length ? (
              listToShow?.map((item, index) => {
                const cond = selectedHorses.find((data) => data.id === item.id)
                return (
                  <label
                    key={index}
                    className="form-check mb-3 cursor-pointer"
                    htmlFor={'teammember' + index}
                  >
                    <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                      <div className="min-w-fit flex items-center">
                        <img
                          src={
                            item.horseProfilePicture && item.horseProfilePicture !== ''
                              ? item.horseProfilePicture
                              : IMAGE_CONSTS.PLACEHOLDERS.HORSE
                          }
                          className="w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover"
                          alt="demoUser"
                        />
                        <div className="hourseDetails w-10/12">
                          <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                            <p className="text-SeabiscuitDark200ThemeColor ">{item?.horseName}</p>
                            {item?.horseBreed?.toString()} •{' '}
                            {item.horseZone === 'international'
                              ? item.horseZone
                              : item.horseZone === 'n/a'
                                ? 'N/A'
                                : `Zone ${item.horseZone}`}
                          </div>
                        </div>
                      </div>
                      {cond ? (
                        <img
                          src={CHECKED_ICON}
                          className="relative right-[-3px] top-[0px]"
                          width={20}
                          height={20}
                          alt="checked"
                        />
                      ) : null}
                      <input
                        onChange={(e) => handleChange(e, item)}
                        checked={item?.selected}
                        id={'teammember' + index}
                        className={`ml-2 form-check-input appearance-none h-4 w-4 border rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer  hidden`}
                        type="checkbox"
                      />
                    </div>
                  </label>
                )
              })
            ) : (
              <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
                {showSearchList ? 'No result found.' : 'Please search.'}
              </div>
            )}
          </div>
        )}
      </>
    </MainModal>
  )
}

export default SearchHorseByName
