import React, { useEffect, useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MainModal from '../../modals/common/MainModal'
import 'react-datepicker/dist/react-datepicker.css'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IconHorseImg } from '../../icons/IconHorseImg'
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'
import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'
import { IconBreadImg } from '../../icons/IconBreadImg'
import { IconGenderImg } from '../../icons/IconGenderImg'
import { IconColoringImg } from '../../icons/IconColoringImg'
import { IconHeightImg } from '../../icons/IconHeightImg'
import { IconMicrochipImg } from '../../icons/IconMicrochipImg'
import { IconPlaneImg } from '../../icons/IconPlaneImg'
import { horseBreedList } from '../../../fakeData/horseBreedList'
import { HeightRestOptions, SexRestOptions } from '../../customStyles/ReactSelectCustomStyle'
import { horseColorList } from '../../../fakeData/horseColorList'
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'
import FirestoreService from '../../../services/firestoreService'
import { CONST, USER_DEF_PASS } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import { IInputHorseData, IInputHorseDataKey } from '../types/inputData'
import { HorseModel } from '../../../models/horse/horse.model'
import { IHorseData } from '../../../models/horse/horse.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import {
  selectHorses,
  setHorses,
  setIsMyHorse,
  setSelectedHorseStatus,
} from '../../../store/horses/horseSlice'
import { selectEventDetails } from '../../../store/events/eventsSlice'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import FirebaseStorageService from '../../../services/storageService'
import helpers from '../../../commonHelpers/helpers'
import { authHelpers, getUserFullName } from '../../../helpers/helpers'
import { UserModel } from '../../../models/users/user.model'
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { storeUseMode } from '../../../store/system/systemThunk'
import FIREBASE_CONST from '../../../const/firebase-const'
import { useHistory } from 'react-router'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}
const auth = getAuth()

export const AddNewHorseModal = (props: Props) => {
  const event = useAppSelector(selectEventDetails)
  const myHorses = useAppSelector(selectHorses)
  const userIcon = '/assets/img/HorsesSign.png'
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const [horsePicture, setHorsePicture] = useState<string>('')
  const [isValid, setIsValid] = React.useState(false)
  const [imgLoading, setImgLoading] = React.useState(false)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const [inputData, setInputDate] = React.useState<IInputHorseData>({
    name: {
      name: 'name',
      value: '',
      required: true,
      placeholder: 'Enter name...',
      type: 'text',
      label: 'Name',
      icon: <IconHorseImg />,
    },
    breed: {
      name: 'breed',
      value: '',
      required: true,
      placeholder: 'Select breed...',
      type: 'select',
      label: 'Breed',
      selectData: horseBreedList,
      icon: <IconBreadImg />,
    },
    gender: {
      name: 'gender',
      value: '',
      required: true,
      placeholder: 'Select gender...',
      type: 'select',
      label: 'Gender',
      selectData: SexRestOptions,
      icon: <IconGenderImg />,
    },
    coloring: {
      name: 'coloring',
      value: '',
      required: true,
      placeholder: 'Select coloring...',
      type: 'select',
      label: 'Coloring',
      selectData: horseColorList,
      icon: <IconColoringImg />,
    },
    height: {
      name: 'height',
      value: '',
      required: false,
      placeholder: 'Select coloring...',
      type: 'select',
      label: 'Height',
      selectData: HeightRestOptions,
      icon: <IconHeightImg />,
    },
    microchipNumber: {
      name: 'microchipNumber',
      value: '',
      required: false,
      placeholder: 'Enter number...',
      type: 'text',
      label: 'Microchip number',
      icon: <IconMicrochipImg />,
    },
    passportNumber: {
      name: 'passportNumber',
      value: '',
      required: false,
      placeholder: 'Enter number...',
      type: 'text',
      label: 'Passport number',
      icon: <IconPlaneImg />,
    },
  })
  const history = useHistory()

  React.useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key as IInputHorseDataKey]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleOnLoginValid = async (data: { email: string; password: string }) => {
    try {
      const emailOrError = await authHelpers.getEmail(data.email)

      const user = await signInWithEmailAndPassword(auth, emailOrError as any, USER_DEF_PASS)

      if (user) {
        const user_data_doc: any = await FirestoreService.getItem(
          COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setEmail(userData.userEmail?.trim()))
        dispatch(setUsername(userData.userName?.trim()))
        dispatch(setDisplayName(getUserFullName(userData)))
        dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          if (event.id) {
            history.replace(`${CONST.ROUTES.REGISTER_EVENT.URL}/${event.id}/${userData.id}`, {
              direction: 'none',
            })
          } else {
            history.replace('/home', { direction: 'none' })
          }
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, {
            direction: 'none',
          })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))
      }
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND)
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD)
        return toastFunctions.error({ message: 'Credentials are incorrect' })
    } finally {
      setLoading(false)
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.HORSE.HORSE_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (downloadUrl) {
        setHorsePicture(`${downloadUrl}`)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  const submitHandler = async () => {
    let userData: { id: string | null; email: string | null } | null

    if (!props.dataToPassOn.userId && props.dataToPassOn.submitOnlyMemberHandler) {
      setLoading(true)
      userData = await props.dataToPassOn.submitOnlyMemberHandler({
        isSetLoading: false,
        fromHorse: true,
      })
    } else {
      userData = {
        id: props.dataToPassOn.userId,
        email: '',
      }
    }

    let mapping: IUserHorseMappingInterface | null = null
    try {
      if (!userData || !userData.id) {
        alert('Error Create Rider')
        return null
      }

      const horseData: IHorseData = {
        horseOwnerId: userData.id,
        horseProfilePicture: horsePicture,
        horseName: `${inputData.name.value}` || '',
        horseBreed: `${(inputData.breed.value as IOption).value}` || '',
        horseGender: `${(inputData.gender.value as IOption).value}` || '',
        horseColor: `${(inputData.coloring.value as IOption).value}` || '',
        horseHeight: `${(inputData.height.value as IOption).value}` || '',
        horseMicrochipNumber: `${inputData.microchipNumber.value}` || '',
        horsePassportNumber: `${inputData.passportNumber.value}` || '',
      }

      let horseToCreate = new HorseModel(horseData)
      const ref = await FirestoreService.createItem(
        COLLECTIONS.HORSES.NAME,
        new HorseModel(horseToCreate).toFirestore()
      )

      horseToCreate.id = ref.id
      horseToCreate = getConvertedData(horseToCreate)

      let docToSave = new UserHorseMappingModel({
        userId: userData.id,
        horseId: (horseToCreate ? horseToCreate.id : null) ?? null,
        isMyHorse: true,
        horseOwnerId: userData.id,
        horseName: horseToCreate.horseName,
        horseZone: horseToCreate.horseZone,
        horseSelectedForCompeletion: true,
        horseNameNGram: horseToCreate?.horseNameNGram,
        horseDiscipline: horseToCreate.horseDiscipline,
        horseProfilePicture: horseToCreate.horseProfilePicture,
      })

      mapping = docToSave.cloneDeep().toFirestore()

      mapping!.id = (
        await FirestoreService.createItem(
          COLLECTIONS.USER_HORSE_MAPPING.NAME,
          docToSave.toFirestore()
        )
      )?.id

      dispatch(setHorses([...myHorses, horseToCreate]))

      dispatch(setIsMyHorse(true))
      dispatch(setSelectedHorseStatus('fulfilled'))
      if (props.dataToPassOn.createCb) props.dataToPassOn.createCb(horseToCreate)
      if (props.dataToPassOn.isLogin && userData.email)
        await handleOnLoginValid({
          email: userData.email,
          password: USER_DEF_PASS,
        })
      toastFunctions.success({ message: MESSAGES_CONST.HORSE_TEAM_MEMBER_CREATED })
    } catch (e) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key as IInputHorseDataKey]) {
      newInputData[key as IInputHorseDataKey].value = value
      newInputData[key as IInputHorseDataKey].hasError = false
      setInputDate(newInputData)
    }
    return {}
  }

  return (
    <>
      <MainModal
        title="Add new horse"
        show={props.show}
        type="ADD_NEW_HORSE_MEMBER"
        size="md"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            label: 'SAVE',
            onClick: submitHandler,
            disabled: !isValid,
            fullWidth: true,
            loading,
          },

          {
            label: 'SAVE WITHOUT HORSE',
            onClick: async () => {
              if (loading) return null
              setLoading(true)
              if (props.dataToPassOn.submitOnlyMemberHandler) {
                await props.dataToPassOn.submitOnlyMemberHandler({ isSetLoading: true })
              }
              setLoading(false)
            },
            disabled: false,
            fullWidth: true,
            loading,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER),
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4 '}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              onchangeHandler={updateProfilePic}
              picture={horsePicture}
              isLoading={imgLoading}
              title={'Profile picture'}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key) => (
              <InputNewMemberRow
                key={key}
                value={inputData[key as IInputHorseDataKey].value}
                onChangeHandler={onChangeHandler}
                placeholder={inputData[key as IInputHorseDataKey].placeholder}
                inputName={inputData[key as IInputHorseDataKey].name}
                name={inputData[key as IInputHorseDataKey].label}
                icon={inputData[key as IInputHorseDataKey].icon}
                isRequired={inputData[key as IInputHorseDataKey].required}
                type={inputData[key as IInputHorseDataKey].type}
                selectData={inputData[key as IInputHorseDataKey].selectData || []}
              />
            ))}
          </div>
          <InputCheckMemberInfo
            onClick={() => setIsConfirmed(!isConfirmed)}
            isChecked={isConfirmed}
            text={
              'By ticking this box I confirm that I have been given permission to create a horse on behalf of this person and register the horse for events.'
            }
          />
        </div>
      </MainModal>
    </>
  )
}
