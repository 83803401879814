import { cloneDeep } from 'lodash'

import { IRiderTeamMemberInterface } from '../models/rider-team-member/riderTeamMember.interface'
import { IRegistrationTabs } from '../models/event-registered-users/event-registered-users.interface'
import { RiderTeamMemberModel } from '../models/rider-team-member/riderTeamMember.model'
import { getConvertedData, getSerializedRiderTeamMembers } from '../models/interface.helper'

export function mergeRidersTeamMembersWithPaperworkTabData(
  paperworkTabData_: IRegistrationTabs['IPaperworkTab'][],
  ridersTeamMembers: IRiderTeamMemberInterface[],
  keysToNotRemove: (keyof IRiderTeamMemberInterface)[] = []
) {
  let foundRiderTeamMembers: IRiderTeamMemberInterface[] = []
  let paperworkTabData__ = cloneDeep(paperworkTabData_)
  let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

  ridersTeamMembers_ = ridersTeamMembers_.map((currRidersTeamMember) => {
    return getConvertedData({
      ...new RiderTeamMemberModel(currRidersTeamMember).toObject(),
      ...keysToNotRemove.reduce(
        (
          acc: (keyof IRiderTeamMemberInterface)[],
          currKeyToNotRemove: keyof IRiderTeamMemberInterface
        ) => {
          acc = {
            ...acc,
            [currKeyToNotRemove]: currRidersTeamMember[currKeyToNotRemove],
          }
          return acc
        },
        []
      ),
    })
  })

  paperworkTabData__ = paperworkTabData__.map((currPaperworkRow) => {
    foundRiderTeamMembers = ridersTeamMembers_.filter((currRidersTeamMember) => {
      return currRidersTeamMember.registrationByDayDocId === currPaperworkRow.registrationByDayDocId
    })
    return {
      ...currPaperworkRow,
      ridersTeamMembers: getSerializedRiderTeamMembers(foundRiderTeamMembers),
    }
  })

  return paperworkTabData__
}
