// import { IonListHeader } from "@ionic/react";
import './OrganizerRootPage.css'

import React, { Suspense, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'

import { CONST } from '../../../const/const'
import PegasusLogo from '../../../icons/logo/Logo.svg'
import { EventModel } from '../../../models/events/event.model'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUseMode } from '../../../store/system/systemSlice'
import {
  selectUserId,
  setCompetitorProfileData,
  setProfileDetails,
  setUserDocumentsData,
} from '../../../store/user/userSlice'

// Custom hooks
import useCompetitorProfileHook from '../../../hooks/users/competitor/profile/useCompetitorProfileHook'
import useDocumentHook from '../../../hooks/users/competitor/profile/useDocumentHook'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import { getConvertedData } from '../../../models/interface.helper'
import { UserModel } from '../../../models/users/user.model'
import {
  initialSystemEventsState,
  selectEvents,
  selectOrganizerEvents,
  setOrganizerFilteredEvents,
  setSelectedEvent,
} from '../../../store/events/eventsSlice'
import { selectedfilters, setActiveFilters } from '../../../store/filters/filterSlice'
import HomeRootPageContentCategoriesSection from '../../home/root/HomeRootPageContentCategoriesSection'
import OrganizerEventListing from './OrganizerEventListing'
import { IonContent, IonPage, useIonRouter } from '@ionic/react'
import EventCreateShowInitialDialogComponent from '../../../components/events/dialogs/create-show-initial/EventCreateShowInitialDialogComponent'
import FirestoreService from '../../../services/firestoreService'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Fading from '../../../components/common/overlay/Fading'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import useDebounce from '../../../hooks/debounce'
import useHomeFilters from '../../../hooks/useHomeFilters'
import { IEventInterface } from '../../../models/events/event.interface'
import CardsLoader from '../../../components/loader/CardsLoader'
import { EventStaffModel } from '../../../models/event-staff/event-staff.model'
import TopNavigationCommonComponent from '../../../components/common/navigation/top/TopNavigationCommonComponent'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Constants
const SELECTED_EVENT_INI_VAL = initialSystemEventsState
export const EVENTS_STATUS_CONSTS = COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  handleModal?: any
}

const OrganizerRootPage: React.FC<Props> = ({ handleModal }) => {
  // Hooks and vars

  const params = useParams()
  const router = useIonRouter()
  const history = useHistory()
  const { mUserDocData } = useDocumentHook()
  const { mUserDetails } = useProfileHook({})
  const { fetchMore } = useHomeFilters({})

  const { mCompetitorProfile } = useCompetitorProfileHook({})
  const [searchValue] = useState('')
  const [tabs, setTabs] = useState([EVENTS_STATUS_CONSTS.CURRENT])
  const [createEventModal, setCreateEventModal] = useState(false)
  const [createEventLoading, setCreateEventLoading] = useState(false)

  const allEventsR = useAppSelector(selectEvents)
  const allEvents = allEventsR.data
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const useMode = useAppSelector(selectUseMode)
  const filterData = useSelector(selectedfilters)
  const organizerEvents = useAppSelector(selectOrganizerEvents)

  const debouncedSearchTerm = useDebounce(searchValue, 500)

  useEffect(() => {
    const activeTabFromParams = (params as any)?.activeTab

    const { PAST, CURRENT, DRAFT } = EVENTS_STATUS_CONSTS
    const validTabs = [PAST, CURRENT, DRAFT]

    if (!CURRENT || !PAST || !DRAFT) return

    if (validTabs.includes(activeTabFromParams)) {
      setTabs([activeTabFromParams as IEventInterface['status']])
      dispatch(
        setActiveFilters(
          getConvertedData({
            ...filterData,
            statusFilter: activeTabFromParams,
          })
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, (params as any)?.activeTab])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const heading = (function () {
    switch (tabs[0]) {
      case EVENTS_STATUS_CONSTS.CURRENT:
        return 'Current Events'
      case EVENTS_STATUS_CONSTS.DRAFT:
        return 'Draft Events'
      case EVENTS_STATUS_CONSTS.PAST:
        return 'Past Events'
      default:
        return 'Current Events'
    }
  })()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    dispatch(setSelectedEvent(SELECTED_EVENT_INI_VAL.selectedEvent))
  }, [dispatch])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    const checkRegistration = async () => {
      if (mUserDetails) {
        let userDataFromDb = getConvertedData(mUserDetails) ?? {}
        let profileDetails_ = new UserModel(userDataFromDb).toObject()
        dispatch(setProfileDetails(getConvertedData(profileDetails_)))
      }
    }
    checkRegistration().then()
  }, [mUserDetails, dispatch])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    if (mUserDocData) {
      dispatch(setUserDocumentsData(getConvertedData(mUserDocData)))
    }
  }, [mUserDocData, dispatch])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    if (mCompetitorProfile) {
      let dataToThrow = { ...(getConvertedData(mCompetitorProfile) ?? {}) }
      dispatch(setCompetitorProfileData(dataToThrow))
    }
  }, [mCompetitorProfile, dispatch])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    const filteredEvents = organizerEvents.events?.filter((curr) => {
      return curr?.eventName
        ?.toLocaleLowerCase()
        ?.startsWith(debouncedSearchTerm?.toLocaleLowerCase())
    })
    dispatch(setOrganizerFilteredEvents(filteredEvents ?? []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, dispatch])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleTagChange = (tag: IEventInterface['status']) => {
    router.push(`${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${tag}`)
    history.push(`${CONST.ROUTES.ORGANIZER_HOME.BASE_PATH}/${tag}`)
  }

  /**
   * @TODO Document this
   */
  const handleSubmitEventModal = async (arg: IEventInterface) => {
    setCreateEventLoading(true)
    await FirestoreService.createItem(
      COLLECTIONS.EVENTS.NAME,
      new EventModel({ ...arg, owner: userId }).toFirestore()
    ).then(async (value) => {
      const intervalId = setInterval(async () => {
        const staffSnap = await FirestoreService.getItem(COLLECTIONS.EVENT_STAFF.NAME, value.id)
        const staffs = EventStaffModel.fromFirestoreDoc(staffSnap).toObject()
        if (staffs.owner) {
          clearInterval(intervalId)
          router.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${value.id}`)
          history.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${value.id}`)
          setCreateEventLoading(false)
          setCreateEventModal(false)
        }
      }, 1000)
    })
  }

  const openCreateEventModal = async () => {
    setCreateEventModal(true)
  }

  const closeCreateEventModal = async () => {
    setCreateEventModal(false)
  }

  return (
    <IonPage>
      <TopNavigationCommonComponent title="Organizer" logo={PegasusLogo} />
      <IonContent
        fullscreen
        style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}
      >
        <div className="pt-14 pb-4 min-h-full flex flex-col">
          <WrapperContainer title="My Events" removePadding={true}>
            <motion.div
              className="w-full mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
              }}
            >
              {allEventsR.hasMore ? <Fading /> : null}

              <EventCreateShowInitialDialogComponent
                isOpen={createEventModal}
                handleSubmitEventModal={handleSubmitEventModal}
                closeCreateEventModal={closeCreateEventModal}
                createEventLoading={createEventLoading}
                setCreateEventLoading={setCreateEventLoading}
              />

              <HomeRootPageContentCategoriesSection
                useMode={useMode}
                onTagsChanged={handleTagChange}
                handleModal={handleModal}
                openCreateEventModal={openCreateEventModal}
              />

              {allEventsR.status === 'loading' ? (
                <CardsLoader />
              ) : (
                <Suspense fallback={<div>Loading...</div>}>
                  <OrganizerEventListing
                    activeTab={tabs[0]}
                    fetchMore={fetchMore}
                    title={
                      filterData.search.searchValue !== ''
                        ? 'Searching for ' + filterData.search.searchValue
                        : heading
                    }
                    openCreateEventModal={openCreateEventModal}
                    events={allEvents}
                    loading={false}
                  />
                </Suspense>
              )}
            </motion.div>
          </WrapperContainer>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default OrganizerRootPage
