import React, { FC, useState } from 'react'

import clsx from 'clsx'
import Select from 'react-select'

import {
  IRegisterTab,
  IRegisterTabItem,
} from '../../../../../models/event-registered-users/event-registered-users.interface'
import { ITeamMember } from '../../../../../models/users/user.interface'
import { IManageInfo } from '../../../event-registration-tabs/EventRegistrationTabs'
import { IEventFees } from '../../../../../models/event-fees/event-fees.interface'

import { customStyles } from '../../../../../components/customStyles/ReactSelectCustomStyle'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { daysOfWeek } from '../../../../../helpers/time'
import { IHandleTeamMateChange } from '../ClinicNOtherRegisterTab'

interface ItemProps {
  row: IRegisterTab
  teamMembersWithUser: ITeamMember[]
  handleTeamMateChange: ({
    id,
    isRemove,
    currentRow,
    isChildren,
  }: IHandleTeamMateChange) => Promise<void>
  isManage?: boolean
  manageInfo: IManageInfo
  isChildren?: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  eventId: string
  activeDay: string
  EventFees: IEventFees | null
}

export const RegisterItem: FC<ItemProps> = ({
  row,
  teamMembersWithUser,
  handleTeamMateChange,
  isManage,
  manageInfo,
  isChildren,
  handleModal,
  eventId,
  activeDay,
  EventFees,
}) => {
  const [loading, setLoading] = useState(false)

  let currentRow = row
  if (isManage) {
    let filtered: IRegisterTabItem[] = []

    if (isChildren) {
      manageInfo.register.forEach((register) =>
        register.children?.forEach((children) => {
          if (children.uuid === currentRow.uuid) {
            filtered.push(children)
          }
        })
      )
    } else {
      filtered = manageInfo.register.filter((register) => register.uuid === currentRow.uuid)
    }

    if (filtered && filtered.length > 0) {
      currentRow = { ...row, registrationsByDay: filtered[0].registrationsByDay }
    } else {
      currentRow = { ...row, registrationsByDay: [] }
    }
  }

  if (daysOfWeek[new Date(row.startDate)?.getDay()] === activeDay || activeDay === 'All') {
    return (
      <div
        className={clsx(
          `w-full flex justify-between flex-wrap lg:mb-0 gap-2 text-SeabiscuitDark200ThemeColor`,
          row.children &&
            row.children.length > 0 &&
            'border border-SeabiscuitLightThemeColorD3 rounded-md p-4'
        )}
      >
        <div
          className={clsx(
            'w-full md:w-auto flex-1 gap-2 text-base flex flex-wrap items-center  pr-4',
            row.children && row.children.length > 0
              ? ''
              : 'py-3 pl-4 border rounded-lg border-SeabiscuitLightThemeColorD3'
          )}
        >
          {row.registrationByDayName}{' '}
          {row.order && (
            <span className="text-[14px] text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5 rounded-full py-0.5 px-3">
              {row.order}
            </span>
          )}
          <div className="ml-auto flex flex-wrap items-center justify-end text-SeabiscuitDark200ThemeColor/50">
            <span className="text-SeabiscuitDark200ThemeColor mr-1">{row.priceAlias ?? '$0'}</span>{' '}
            entry fee •{' '}
            <span className="text-SeabiscuitDark200ThemeColor mx-1">
              {row.qualifyFee && EventFees?.qualifyingClasses?.isEnable && row.qualifyFeeName
                ? `$${row.qualifyFee}`
                : ''}
            </span>{' '}
            {row.qualifyFeeName || 'No extra fee'} •{' '}
            <button
              className="ml-1 underline hover:no-underline"
              onClick={() => handleModal(true, MODAL_CONSTS.CLASS_MORE, { row, eventId })}
            >
              More
            </button>
          </div>
        </div>
        <div
          className={clsx(
            'w-full md:w-[20%] flex items-center border border-SeabiscuitLightThemeColorD3 min-w-[180px] text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative'
          )}
        >
          <Select
            isMulti
            menuPlacement="auto"
            menuPortalTarget={document.body}
            onChange={async (_currenValue, actionMeta: any) => {
              setLoading(true)
              await handleTeamMateChange({
                id: actionMeta.option?.value
                  ? actionMeta.option?.value.split('_')[0]
                  : actionMeta.removedValue?.value
                    ? actionMeta.removedValue?.value.split('_')[0]
                    : '',
                currentRow,
                removedIndex: actionMeta.removedValue?.index,
                isRemove: actionMeta.action === 'remove-value',
                isChildren,
              })
              setLoading(false)
            }}
            value={currentRow.registrationsByDay?.map((currRegistrationByDay, index) => ({
              index,
              value: `${currRegistrationByDay?.riderId}_${index}`,
              label: currRegistrationByDay?.riderName ?? '',
            }))}
            isClearable={false}
            isSearchable={false}
            isLoading={loading}
            isDisabled={loading}
            styles={customStyles}
            options={
              teamMembersWithUser?.map((curr, index) => ({
                index,
                label: curr.memberName ?? '',
                value: `${curr.memberId}_${new Date().getTime()}`,
              })) ?? []
            }
            placeholder="Select riders"
            className="w-full rounded-lg searchableComponent focus:ring-0 p-0 focus:ring-transparent min-h-[52px] flex items-center"
          />
        </div>
        {row.children?.map((children, childrenIndex) => (
          <RegisterItem
            key={childrenIndex}
            row={children}
            teamMembersWithUser={teamMembersWithUser}
            handleTeamMateChange={handleTeamMateChange}
            isManage={isManage}
            manageInfo={manageInfo}
            handleModal={handleModal}
            isChildren
            eventId={eventId}
            activeDay={activeDay}
            EventFees={EventFees}
          />
        ))}
      </div>
    )
  } else {
    return null
  }
}
