import React, { FC, useContext } from 'react'

import { Controller, useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'

import TogglesElement from '../../../../../elements/toggles/toggles/TogglesElement'
import AmountInput from '../../../../../common/inputs/AmountInput'
import Input from '../../../../../common/inputs/Input'
import { Subheader } from '../Subheader/Subheader'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'
import { NoteField } from '../NoteField/NoteField'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'

import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'
import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'

const eventFeesFormDefaultValues = new EventFeesModel().toObject()

export type IFeeRefundsName =
  | 'afterClosingDateRefund'
  | 'beforeClosingDateRefund'
  | 'showCancellationRefundWeather'
  | 'showCancellationRefundBioSecurity'
  | `extraRefunds.${number}`

interface EventFeeRefundsRowProps {
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  watch: UseFormWatch<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  label?: string
  name: IFeeRefundsName
  onExtraRemove?: () => void
  withHeader?: boolean
}

const EventFeeRefundsRow: FC<EventFeeRefundsRowProps> = ({
  control,
  watch,
  setValue,
  register,
  label,
  name,
  onExtraRemove,
  withHeader,
}) => {
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      <div className="w-full lg:w-[200px] flex items-center gap-1">
        {onExtraRemove ? (
          <Input
            className="!h-12 px-4"
            register={register}
            placeholder="Enter Name..."
            valid={watch(`${name}.status`)}
            name={`${name}.name`}
            clear={!watch(`${name}.status`)}
            value={watch(`${name}.name`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
            disabled={!watch(`${name}.status`)}
          />
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor">{label}</div>
        )}
      </div>
      <div className="w-[120px] flex items-end">
        <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
        <Controller
          name={`${name}.status`}
          control={control}
          render={({ field: { value } }) => (
            <TogglesElement
              on={value}
              onToggle={() => setValue(`${name}.status`, !watch(`${name}.status`))}
            />
          )}
        />
      </div>
      <div className="flex-1 min-w-[200px] lg:max-w-[200px]">
        {withHeader && (
          <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
            Percentage
          </div>
        )}
        <AmountInput
          name={`${name}.percentageAlias`}
          disable={!watch(`${name}.status`)}
          register={register}
          postFix="%"
          placeholder={!watch(`${name}.status`) ? 'Eg: 50%' : 'Percent'}
          clear={!watch(`${name}.status`)}
          childWidth={24}
          className="!h-[48px] flex"
          inputClassName="px-4"
          valid={watch(`${name}.status`)}
          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
          value={watch(`${name}.percentageAlias`)}
        />
      </div>
      <NoteField
        name={name}
        watch={watch}
        register={register}
        setValue={setValue}
        withHeader={withHeader}
      />
      {onExtraRemove && (
        <button onClick={onExtraRemove}>
          <div className="cursor-pointer">
            <img src="/assets/og_icons/Cancel.svg" className="h-[24px] w-[24px]" alt="Cancel" />
          </div>
        </button>
      )}
    </div>
  )
}

interface EventFeesRefundsProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
}

export const EventFeesRefunds: FC<EventFeesRefundsProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraRefunds = useFieldArray({
    control,
    name: 'extraRefunds',
  })

  const onAdd = () => {
    extraRefunds.append({
      name: '',
      category: 'refund',
      status: false,
      percentage: '',
      note: '',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraRefunds.remove()
    reset({
      ...getValues(),
      beforeClosingDateRefund: eventFeesFormDefaultValues.beforeClosingDateRefund,
      afterClosingDateRefund: eventFeesFormDefaultValues.afterClosingDateRefund,
      showCancellationRefundWeather: eventFeesFormDefaultValues.showCancellationRefundWeather,
      showCancellationRefundBioSecurity:
        eventFeesFormDefaultValues.showCancellationRefundBioSecurity,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraRefunds.remove(index)
      },
    })
  }

  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader title="Refunds" subtitle="Define refund policies for this event" />
      <EventFeeRefundsRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Before closing date"
        name="beforeClosingDateRefund"
        withHeader
      />
      <EventFeeRefundsRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="After closing date"
        name="afterClosingDateRefund"
      />
      <EventFeeRefundsRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Show cancellation - weather"
        name="showCancellationRefundWeather"
      />
      <EventFeeRefundsRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Show cancellation - biosecurity"
        name="showCancellationRefundBioSecurity"
      />
      {extraRefunds.fields.map((field, index) => (
        <EventFeeRefundsRow
          key={field.id}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraRefunds.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
