import { ModelBaseModel } from '../model-base/model-base.model'
import { IHorseCompetitorDocument } from './horse-competiton-paperwork-interface'

/**
 * Holds the models to store events
 */

export class HorseCompetitonPaperworkModel extends ModelBaseModel<IHorseCompetitorDocument> {
  public id: IHorseCompetitorDocument['id']
  public horseId: IHorseCompetitorDocument['horseId']
  public horseUniqId: IHorseCompetitorDocument['horseUniqId']
  public horseOwnerId: IHorseCompetitorDocument['horseOwnerId']
  public horseMeasurementCards: IHorseCompetitorDocument['horseMeasurementCards']

  public cogginsInspectionDate: IHorseCompetitorDocument['cogginsInspectionDate']
  public cogginsCertificate: IHorseCompetitorDocument['cogginsCertificate']

  public healthInspectionDate: IHorseCompetitorDocument['healthInspectionDate']
  public healthCertificate: IHorseCompetitorDocument['healthCertificate']

  public vaccinationCertificate: IHorseCompetitorDocument['vaccinationCertificate']
  public vaccinationInspectionDate: IHorseCompetitorDocument['vaccinationInspectionDate']

  public rhinopneumonitisCertificate: IHorseCompetitorDocument['rhinopneumonitisCertificate']
  public rhinopneumonitisInspectionDate: IHorseCompetitorDocument['rhinopneumonitisInspectionDate']

  public influenzaCertificate: IHorseCompetitorDocument['influenzaCertificate']
  public influenzaInspectionDate: IHorseCompetitorDocument['influenzaInspectionDate']

  public constructor(obj?: IHorseCompetitorDocument) {
    super()
    this.id = obj?.id ?? null
    this.horseId = obj?.horseId ?? null
    this.horseUniqId = obj?.horseUniqId ?? null
    this.horseOwnerId = obj?.horseOwnerId

    this.horseMeasurementCards =
      obj?.horseMeasurementCards?.map((data) => {
        return {
          horseShodStatus: data.horseShodStatus ?? null,
          horseMeasurementDate: data.horseMeasurementDate
            ? this.utcTimestamp({
                key: 'horseMeasurementDate' as any,
                isTimestamp: true,
                value: data.horseMeasurementDate,
              })
            : null,
          horseHeightMeasurement: data.horseHeightMeasurement ?? null,
          horseMeasurementCardType: data.horseMeasurementCardType ?? null,
          horseLeftHeelMeasurement: data.horseLeftHeelMeasurement ?? null,
          horseRightHeelMeasurement: data.horseRightHeelMeasurement ?? null,
          horseMeasurementCardsImage: data.horseMeasurementCardsImage ?? [],
        }
      }) ?? []

    this.cogginsCertificate = obj?.cogginsCertificate || []
    this.cogginsInspectionDate = obj?.cogginsInspectionDate
      ? this.utcTimestamp({
          key: 'cogginsInspectionDate',
          isTimestamp: true,
          value: obj?.cogginsInspectionDate,
        })
      : null

    this.vaccinationCertificate = obj?.vaccinationCertificate || []
    this.vaccinationInspectionDate = obj?.vaccinationInspectionDate
      ? this.utcTimestamp({
          key: 'vaccinationInspectionDate',
          isTimestamp: true,
          value: obj?.vaccinationInspectionDate,
        })
      : null

    this.healthCertificate = obj?.healthCertificate ?? []
    this.healthInspectionDate = obj?.healthInspectionDate
      ? this.utcTimestamp({
          key: 'healthInspectionDate',
          isTimestamp: true,
          value: obj?.healthInspectionDate,
        })
      : null

    this.rhinopneumonitisCertificate = obj?.rhinopneumonitisCertificate ?? []
    this.rhinopneumonitisInspectionDate = obj?.rhinopneumonitisInspectionDate
      ? this.utcTimestamp({
          key: 'rhinopneumonitisInspectionDate',
          isTimestamp: true,
          value: obj?.rhinopneumonitisInspectionDate,
        })
      : null

    this.influenzaCertificate = obj?.influenzaCertificate ?? []
    this.influenzaInspectionDate = obj?.influenzaInspectionDate
      ? this.utcTimestamp({
          key: 'influenzaInspectionDate',
          isTimestamp: true,
          value: obj?.influenzaInspectionDate,
        })
      : null
  }

  static fromFirestoreDoc(doc: any) {
    return new HorseCompetitonPaperworkModel({
      ...doc.data(),
      id: doc?.id,
    })
  }
}
