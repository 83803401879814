import { IonItem } from '@ionic/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { CONFIG } from '../../../config/config'
import { CONST } from '../../../const/const'
import { EventModel } from '../../../models/events/event.model'
import FirestoreService from '../../../services/firestoreService'
import { ispublished, setisPublished } from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import './ShowsDetailPage.css'
import { IconErrorSvg } from '../../../components/icons/IconErrorSvg'
import { IconQuestion } from '../../../components/icons/IconQuestion'

interface ShowsDetailPageHeaderSectionProps {
  event: any
  eventTab: string
  onSetEventTab: (tab: string) => void
}

const ShowsDetailPageTabControlSection: React.FC<ShowsDetailPageHeaderSectionProps> = (props) => {
  const event = props.event
  const isPublished = useAppSelector(ispublished)
  const dispatch = useAppDispatch()

  // Functions
  /**
   * @info Scrolls the page to next on next button click
   */

  const eventId = useParams<{ eventId: string }>()?.eventId

  const handleCheckPublished = async (eventId: string) => {
    if (eventId) {
      const eventSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        eventId
      )

      if (eventSnapshot.exists()) {
        const event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()
        dispatch(setisPublished(event.isPublished && event.status !== 'draft'))
      }
    }
  }

  // Functions

  useEffect(() => {
    if (eventId) {
      handleCheckPublished(eventId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const eventFormIndex = (function () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let return_value = CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.SEQUENCE
    for (const [key, value] of Object.entries(CONST.UI.EVENTS.CREATE_EDIT.TABS)) {
      if (0) console.log({ key })
      if (value.VALUE === event?.eventFormState) {
        return_value = value.SEQUENCE
        break
      }
    }
    return return_value
  })()

  const setTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-4 text-center h-10 relative min-w-fit text-sm transition ease-in duration-200 text-md focus:outline-none rounded-full`

    if (props.eventTab === value || sequence < event_index) {
      style = `${style} `

      if (props.eventTab === value) {
        return `${style} bg-white text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitGrayThemeColor`
  }

  const handleScroll = (e: any) => {
    // const page = e.target
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonItem lines="none" className="bg-red-400 editEventTabs w-full md:w-[initial]">
      <div className="Innrclass bg-SeabiscuitGrayThemeColor w-full">
        <div
          onScroll={handleScroll}
          className="flex overflow-x-auto gap-x-2 pb-3 tabScroll bg-SeabiscuitGrayThemeColor w-full manage_event_tabs_container"
        >
          {/**************************************************************/}
          {/* Event Details */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE)
              }
            }}
            className={`
                        ${setTabStyle(
                          CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE,
                          CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.SEQUENCE,
                          eventFormIndex
                        )}`}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE ? (
                  <img
                    alt="icons"
                    className="mr-2 w-5"
                    src="/assets/cp_icons/RegularDocument-2.svg"
                  />
                ) : (
                  <img
                    alt="icons"
                    className="mr-2 w-5"
                    src="/assets/cp_icons/RegularDocument-1.svg"
                  />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Event Details
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Payments */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/MagneticCard-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/MagneticCard-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Payments
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Entries */}
          {/**************************************************************/}
          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE)
              }
            }}
            className={`
                        ${setTabStyle(
                          CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE,
                          CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.SEQUENCE,
                          eventFormIndex
                        )}`}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Prize-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Prize-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Entries
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Fees */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE ? (
                  <img
                    alt="icons"
                    className="mr-2 w-5"
                    src="/assets/cp_icons/UsDollarCircled.svg"
                  />
                ) : (
                  <img
                    alt="icons"
                    className="mr-2 w-5"
                    src="/assets/cp_icons/UsDollarCircled1.svg"
                  />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Fees
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Tickets */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Two Tickets-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/TwoTickets-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Tickets
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Staff */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/People-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/People-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Staff
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Prize List */}
          {/**************************************************************/}

          {/* <button type="button"
                        onClick={() => {
                            if (CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.SEQUENCE <= eventFormIndex || CONFIG.EVENT.BYPASS_FORM_SEQUENCING) {
                                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.VALUE);
                            }
                        }}
                        className={
                            setTabStyle(
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.VALUE,
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.SEQUENCE,
                                eventFormIndex
                            )
                        }
                    >
                        <div className="flex ion-align-items-center">
                            <div className="inline-block shrink-0">
                                {
                                    props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.VALUE ? <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/Laurel Wreath-2.svg" /> : <img alt="icons" className="mr-2 w-5" src="/assets/og_icons/Laurel Wreath-1.svg" />
                                }
                            </div>
                            <div className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.VALUE && "text-SeabiscuitDark200ThemeColor"} pl whitespace-nowrap`}>
                                Prize List
                            </div>
                        </div>
                    </button> */}

          {/**************************************************************/}
          {/* Scorring */}
          {/**************************************************************/}
          {/* <button type="button"
                        onClick={() => {
                            if (CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.SEQUENCE <= eventFormIndex || CONFIG.EVENT.BYPASS_FORM_SEQUENCING) {
                                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE);
                            }
                        }}
                        className={
                            setTabStyle(
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE,
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.SEQUENCE,
                                eventFormIndex
                            )
                        }
                    >
                        <div className="flex ion-align-items-center">
                            <div className="inline-block shrink-0">
                                {
                                    props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE ? <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Laurel Wreath-2.svg" /> : <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Laurel Wreath-1.svg" />
                                }
                            </div>
                            <div className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE && "text-SeabiscuitDark200ThemeColor"} pl whitespace-nowrap`}>
                                Scoring
                            </div>
                        </div>
                    </button> */}

          {/*<IonChip color="danger"*/}
          {/*         className="overflow-visible"*/}
          {/*         onClick={()=>{*/}
          {/*             props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PRIZE_LIST.VALUE);*/}
          {/*         }}>*/}
          {/*    <IonLabel>*/}
          {/*        <div className="flex ion-align-items-center">*/}
          {/*            <div className="inline-block shrink-0">*/}
          {/*                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>*/}
          {/*                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />*/}
          {/*                </svg>*/}
          {/*            </div>*/}
          {/*            <div className="pl-2">*/}
          {/*                Prize List*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </IonLabel>*/}
          {/*</IonChip>*/}
          {/*<IonChip color="danger"*/}
          {/*         className="overflow-visible"*/}
          {/*         onClick={()=>{*/}
          {/*             props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SCORING.VALUE);*/}
          {/*         }}>*/}
          {/*    <IonLabel>*/}
          {/*        <div className="flex ion-align-items-center">*/}
          {/*            <div className="inline-block shrink-0">*/}
          {/*                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>*/}
          {/*                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />*/}
          {/*                </svg>*/}
          {/*            </div>*/}
          {/*            <div className="pl-2">*/}
          {/*                Scoring*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </IonLabel>*/}
          {/*</IonChip>*/}

          {/**************************************************************/}
          {/* Scheduling */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Clock-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Clock-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Scheduling
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Policies */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Scroll-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Scroll-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Policies
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Paperwork */}
          {/**************************************************************/}

          <button
            type="button"
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.DISABLED}
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE)
              }
            }}
            className={setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.SEQUENCE,
              eventFormIndex
            )}
          >
            <div className="flex ion-align-items-center">
              <div className="inline-block shrink-0">
                {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE ? (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Pen-2.svg" />
                ) : (
                  <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Pen-1.svg" />
                )}
              </div>
              <div
                className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
              >
                Paperwork
              </div>
            </div>
          </button>

          {/**************************************************************/}
          {/* Sponsors */}
          {/**************************************************************/}

          {/* <button type="button"
                        disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.DISABLED}
                        onClick={() => {
                            if (CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.SEQUENCE <= eventFormIndex || CONFIG.EVENT.BYPASS_FORM_SEQUENCING) {
                                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE);
                            }
                        }}
                        className={
                            setTabStyle(
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE,
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.SEQUENCE,
                                eventFormIndex
                            )
                        }
                    >
                        <div className="flex ion-align-items-center">
                            <div className="inline-block shrink-0">
                                {
                                    props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE ? <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Medal-2.svg" /> : <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Medal-1.svg" />
                                }
                            </div>
                            <div className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE && "text-SeabiscuitDark200ThemeColor"} pl whitespace-nowrap`}>
                                Sponsors
                            </div>
                        </div>
                    </button> */}

          {/**************************************************************/}
          {/* Vendors */}
          {/**************************************************************/}

          {/* <button type="button"
                        disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.DISABLED}
                        onClick={() => {
                            if (CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.SEQUENCE <= eventFormIndex || CONFIG.EVENT.BYPASS_FORM_SEQUENCING) {
                                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE);
                            }
                        }}
                        className={
                            setTabStyle(
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE,
                                CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.SEQUENCE,
                                eventFormIndex
                            )
                        }
                    >
                        <div className="flex ion-align-items-center">
                            <div className="inline-block shrink-0">
                                {
                                    props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE ? <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/DepartmentShop-2.svg" /> : <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/DepartmentShop-1.svg" />
                                }
                            </div>
                            <div className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE && "text-SeabiscuitDark200ThemeColor"} pl whitespace-nowrap`}>
                                Vendors
                            </div>
                        </div>
                    </button> */}

          {/**************************************************************/}
          {/* Review / Publish */}
          {/**************************************************************/}

          <button
            type="button"
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE)
              }
            }}
            className={`${setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.SEQUENCE,
              eventFormIndex
            )} flex items-center gap-2`}
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.DISABLED}
          >
            <span className={'w-[20px]'}>
              <IconErrorSvg
                color={
                  props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE
                    ? '#F70763'
                    : '#535e7d'
                }
              />
            </span>
            Required fields
          </button>
          <button
            type="button"
            onClick={() => {
              if (
                CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.SEQUENCE <= eventFormIndex ||
                CONFIG.EVENT.BYPASS_FORM_SEQUENCING
              ) {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE)
              }
            }}
            className={`${setTabStyle(
              CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE,
              CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.SEQUENCE,
              eventFormIndex
            )} flex items-center gap-2`}
            disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.DISABLED}
          >
            <span className={'w-[20px]'}>
              <IconQuestion
                color={
                  props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE
                    ? '#F70763'
                    : '#535e7d'
                }
              />
            </span>
            Questions
          </button>
          {!isPublished && (
            <button
              type="button"
              disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.DISABLED}
              onClick={() => {
                if (
                  CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.SEQUENCE <= eventFormIndex ||
                  CONFIG.EVENT.BYPASS_FORM_SEQUENCING
                ) {
                  props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE)
                }
              }}
              className={setTabStyle(
                CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE,
                CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.SEQUENCE,
                eventFormIndex
              )}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/publish-4.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/publish-2.svg" />
                  )}
                </div>
                <div
                  className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Publish
                </div>
              </div>
            </button>
          )}

          {/**************************************************************/}
          {/* Send Notifications */}
          {/**************************************************************/}

          {!isPublished && (
            <button
              type="button"
              disabled={CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.DISABLED}
              onClick={() => {
                props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE)
              }}
              className={setTabStyle(
                CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE,
                CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.SEQUENCE,
                eventFormIndex
              )}
            >
              <div className="flex ion-align-items-center">
                <div className="inline-block shrink-0">
                  {props.eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE ? (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Map-4.svg" />
                  ) : (
                    <img alt="icons" className="mr-2 w-5" src="/assets/cp_icons/Map-2.svg" />
                  )}
                </div>
                <div
                  className={`${props.eventTab !== CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE && 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  Send Notifications
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </IonItem>
  )
}

export default ShowsDetailPageTabControlSection
