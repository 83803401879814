import React, { FC, useContext } from 'react'

import { Controller, useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'

import { Subheader } from '../Subheader/Subheader'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'
import Input from '../../../../../common/inputs/Input'
import TogglesElement from '../../../../../elements/toggles/toggles/TogglesElement'
import AmountInput from '../../../../../common/inputs/AmountInput'

import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'
import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'

interface EventFeeRowProps {
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  watch: UseFormWatch<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  label?: string
  name: 'USEFFee' | 'USEFDrugFee' | 'USEFShowPassFee' | 'USDFFee' | `extraGovernanceFees.${number}`
  onExtraRemove?: () => void
  withHeader?: boolean
}
const EventFeeMandatoryRow: FC<EventFeeRowProps> = ({
  control,
  watch,
  setValue,
  register,
  label,
  name,
  onExtraRemove,
  withHeader,
}) => {
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      <div className="w-full lg:w-[200px] flex items-center gap-1">
        {onExtraRemove ? (
          <Input
            className="!h-12 px-4"
            register={register}
            placeholder="Enter Name..."
            valid={watch(`${name}.status`)}
            name={`${name}.name`}
            clear={!watch(`${name}.status`)}
            value={watch(`${name}.name`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
            disabled={!watch(`${name}.status`)}
          />
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor">{label}</div>
        )}
      </div>
      <div className="w-[120px] flex items-end">
        <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
        <Controller
          name={`${name}.status`}
          control={control}
          render={({ field: { value } }) => (
            <TogglesElement
              on={value}
              onToggle={() => setValue(`${name}.status`, !watch(`${name}.status`))}
            />
          )}
        />
      </div>
      <div className="flex-1 min-w-[250px] lg:max-w-[250px]">
        {withHeader && (
          <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
            Amount
          </div>
        )}
        <div className="relative">
          <AmountInput
            name={`${name}.actualCostAlias`}
            disable={!watch(`${name}.status`)}
            register={register}
            prefix="$"
            clear={!watch(`${name}.status`)}
            placeholder="$0"
            childWidth={24}
            value={watch(`${name}.actualCostAlias`)}
            className="!h-[48px] flex"
            inputClassName="px-4"
            valid={watch(`${name}.status`)}
            validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
            invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
          />
        </div>
      </div>
      {onExtraRemove && (
        <button onClick={onExtraRemove}>
          <div className="cursor-pointer">
            <img src="/assets/og_icons/Cancel.svg" className="h-[24px] w-[24px]" alt="Cancel" />
          </div>
        </button>
      )}
    </div>
  )
}

interface EventFeesMandatoryProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
export const EventFeesMandatory: FC<EventFeesMandatoryProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraGovernanceFees = useFieldArray({
    control,
    name: 'extraGovernanceFees',
  })

  const onAdd = () => {
    extraGovernanceFees.append({
      name: '',
      status: false,
      category: 'governance',
      actualCostAlias: '$0',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraGovernanceFees.remove()
    reset({
      ...getValues(),
      USEFFee: eventFeesFormDefaultValues.USEFFee,
      USEFDrugFee: eventFeesFormDefaultValues.USEFDrugFee,
      USEFShowPassFee: eventFeesFormDefaultValues.USEFShowPassFee,
      USDFFee: eventFeesFormDefaultValues.USDFFee,
      USDFShowPassFee: eventFeesFormDefaultValues.USDFShowPassFee,
      USEAFee: eventFeesFormDefaultValues.USEAFee,
      USEAShowPassFee: eventFeesFormDefaultValues.USEAShowPassFee,
      USHJAHoursFee: eventFeesFormDefaultValues.USHJAHoursFee,
      USHJAShowPassFee: eventFeesFormDefaultValues.USHJAShowPassFee,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraGovernanceFees.remove(index)
      },
    })
  }
  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader
        title="Mandatory Fees"
        subtitle="Add mandatory fees that will be charged per horse registered"
      />
      <EventFeeMandatoryRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Office fee"
        name="USEFFee"
        withHeader
      />
      <EventFeeMandatoryRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Ship in / Ground fee"
        name="USEFDrugFee"
      />
      <EventFeeMandatoryRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Ambulance fee"
        name="USEFShowPassFee"
      />
      <EventFeeMandatoryRow
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Night Watch"
        name="USDFFee"
      />
      {extraGovernanceFees.fields.map((field: any, index) => (
        <EventFeeMandatoryRow
          key={field.id}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraGovernanceFees.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
