import { useEffect, useState, useRef } from 'react'

// Components
import NotifivationsListing from './NotifivationsListing'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import NotificationCell from './NotificationCell'

// Constants
import { PAPERWORK_DATA } from './data/tabs'

// Redux
// import { useAppSelector } from "../../store/hooks"
// import { selectUserId } from "../../store/user/userSlice"

// Services
// import FirestoreService from "../../services/firestoreService"

// Firestore
// import { where } from "firebase/firestore"

// Models
// import { IPaperworkTab } from "../../models/event-drafts/event-draft.interface"
// import fakeDocumentList, { IDocument } from "../../fakeData/fakeDocumentList"
import ViewsLoader from '../../components/loader/ViewsLoader'
import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'
import { NotificationFeed } from '@knocklabs/react'
import { useKnockFeed } from '@knocklabs/react'
import { withKnockProvider } from '../../components/hoc/withKnockProvider'
import { FilterStatus } from '@knocklabs/react-core'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export type INotifivationSettingsDoc = {
  setting_logo: string
  setting_title: string
  setting_description: string
}

// type ICategorizedDocs = {
//     all: IPaperworkListingDoc[],
//     unRead: IPaperworkListingDoc[]
// }

const NotificationsRootPage = () => {
  // Hooks and vars
  const [tabs, setTabs] = useState({ ...PAPERWORK_DATA.TABS })
  const [settingsData, setSettingsData] = useState<INotifivationSettingsDoc[]>([])
  const loading = useState(false)[0]
  // Functions

  const [modalState, setModalState] = useState<{
    isOpen: boolean
    anchorEl: HTMLElement | null
    item: any | null
  }>({
    isOpen: false,
    anchorEl: null,
    item: null,
  })
  const modalRef = useRef<HTMLDivElement>(null)
  const { feedClient } = useKnockFeed()

  const [manageAllModalOpen, setManageAllModalOpen] = useState(false)
  const manageAllModalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    //setAllData(PAPERWORK_DATA?.listing)
    // setUnReadData(PAPERWORK_DATA?.listing2)
    setSettingsData(PAPERWORK_DATA?.list3)
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setModalState({ isOpen: false, anchorEl: null, item: null })
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (manageAllModalRef.current && !manageAllModalRef.current.contains(event.target as Node)) {
        setManageAllModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOpenModal = (anchorEl: HTMLElement, item: any) => {
    setModalState({ isOpen: true, anchorEl, item })
  }

  return (
    <WrapperContainer title="Notifications">
      <>
        <div className="mb-4 flex items-center justify-between">
          <div>
            {tabs.tablist.map((currTab, tabIndex) => {
              return (
                <PillButtonElement
                  key={`${currTab}${tabIndex}`}
                  text={currTab}
                  Class="h-[45px] w-[120px] border-none inline-block"
                  onButtonClicked={() => setTabs({ ...tabs, active: tabIndex })}
                  value={tabIndex}
                  selected={tabIndex === tabs.active}
                />
              )
            })}
          </div>
          <div className="relative">
            <button className="text-gray-400" onClick={() => setManageAllModalOpen(true)}>
              Manage all
            </button>
            {manageAllModalOpen && (
              <div
                ref={manageAllModalRef}
                className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-50 w-40"
              >
                <button
                  onClick={() => {
                    feedClient?.markAllAsRead()
                    setManageAllModalOpen(false)
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Mark all as read
                </button>
                <button
                  onClick={() => {
                    feedClient?.markAllAsArchived()
                    setManageAllModalOpen(false)
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Delete all
                </button>
              </div>
            )}
          </div>
        </div>
        {tabs.tablist[tabs.active] === 'Settings' && (
          <div className="mb-2">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1">
              Notification settings
            </h1>
            <div className="text-SeabiscuitLightParagraphTextColor capitalize font-normal">
              {tabs.tabDescriptionList[tabs.active]}
            </div>
          </div>
        )}

        <hr className="my-[30px]" />

        {loading && (
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F70763"
          />
        )}
        {!loading && tabs.active !== 2 && (
          <div className="relative">
            <NotificationFeed
              renderHeader={() => <></>}
              initialFilterStatus={
                tabs.tablist[tabs.active] === 'Unread' ? FilterStatus.Unread : FilterStatus.All
              }
              renderItem={(props) => <NotificationCell {...props} onOpenModal={handleOpenModal} />}
            />
            {modalState.isOpen && modalState.anchorEl && (
              <div
                ref={modalRef}
                className="absolute bg-white border border-gray-200 rounded-lg shadow-lg z-50"
                style={{
                  top: modalState.anchorEl.getBoundingClientRect().y - 100,
                  left: modalState.anchorEl.getBoundingClientRect().left - 300,
                }}
              >
                <button
                  onClick={() => {
                    if (modalState.item) feedClient?.markAsRead(modalState.item)
                    setModalState({ isOpen: false, anchorEl: null, item: null })
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Mark as Read
                </button>
                <button
                  onClick={() => {
                    // Implement delete functionality here
                    setModalState({ isOpen: false, anchorEl: null, item: null })
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        )}
        {!loading && tabs.active === 2 && (
          <NotifivationsListing type={'Settings'} data={settingsData} />
        )}
      </>
    </WrapperContainer>
  )
}

export default withKnockProvider(NotificationsRootPage)
