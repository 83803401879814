import { SetStateAction, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useIonRouter } from '@ionic/react'

import customImageComponent from '../../../common/CustomImageComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import { ProfileDetailsButtons } from './ProfileDetailsButtons'

import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { getUserFullName } from '../../../../helpers/helpers'
import useProfileDetailCard from './useProfileDetailCard'

import { IHorseData } from '../../../../models/horse/horse.interface'

import PROFILE_DETAIL_CARD_CONST from './profileDetailCard.const'
import { CONST } from '../../../../const/const'

type IProps = {
  showHorseData?: IHorseData[]
  setActiveTab?: (value: SetStateAction<number>) => void
  isChatPage?: boolean
}
const DEFAULT_HORSE_PIC = '/assets/og_icons/YearofHorse.svg'

const ProfileDetailsCard = ({ showHorseData, setActiveTab, isChatPage }: IProps) => {

  const { getMapping } = useHorses()

  const router = useIonRouter()
  const history = useHistory()

  const {
    profileDetails,
    alreadyFollowed,
    loading,
    isMyProfile,
    following,
    messageUser,
    onFollowBtnClick,
  } = useProfileDetailCard()

  const [mappingId, setMappingId] = useState<any>('')
  const [horseId, setHorseId] = useState<any>('')

  const handleGetMapping = async (owner: string) => {
    return await getMapping(owner)
  }

  useEffect(() => {
    if (showHorseData?.length) {
      if (showHorseData?.[0]?.horseOwnerId) {
        handleGetMapping(showHorseData[0].horseOwnerId).then(({ horsesTeamsMappings }) => {
          setMappingId(horsesTeamsMappings?.[0]?.id)
          setHorseId(horsesTeamsMappings?.[0]?.horseId)
          if (setActiveTab) setActiveTab(3)
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHorseData])

  if (loading || alreadyFollowed === null)
    return (
      <div className="min-h-[204px] flex items-center justify-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )

  if (showHorseData && showHorseData.length) {
    return (
      <>
        <span className="w-[110px] h-[110px] rounded-full border-[3px] border-white overflow-hidden">
          {customImageComponent(
            showHorseData[0]?.horseProfilePicture
              ? showHorseData[0]?.horseProfilePicture
              : DEFAULT_HORSE_PIC,
            showHorseData[0].horseName,
            'object-cover w-full bg-white h-full'
          )}
        </span>

        <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
          {showHorseData[0].horseName}
        </span>

        {isMyProfile ? (
          <button
            type="button"
            className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46]  hover:text-white focus:text-white active:text-white "
            onClick={() => {
              router.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
              history.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
            }}
          >
            Edit Profile
          </button>
        ) : null}
      </>
    )
  }

  return (
    <>
      <span className="w-[110px] h-[110px] rounded-full flex justify-center items-center border-[3px] bg-white border-white overflow-hidden">
        {customImageComponent(
          profileDetails?.userProfilePicture
            ? profileDetails.userProfilePicture
            : PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC,
          profileDetails?.userName,
          '!w-[calc(100%-5px)] bg-white !h-[calc(100%-5px)] rounded-full'
        )}
      </span>

      <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize text-center">
        {profileDetails && getUserFullName(profileDetails!)}
      </span>

      {!isChatPage && (
        <ProfileDetailsButtons
          isMyProfile={isMyProfile}
          loading={loading}
          following={following}
          alreadyFollowed={alreadyFollowed}
          messageHandler={messageUser}
          onFollowHandler={onFollowBtnClick}
          profileDetails={profileDetails}
        />
      )}
    </>
  )
}

export default ProfileDetailsCard
