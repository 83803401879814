import { Link } from 'react-router-dom'
import IProfileCard from '../../types'
import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseTeamMembers = (props: IProfileCard['IHorseTeamMembersTabProps']) => {
  return (
    <>
      <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">TEAM</div>

      {props.horseTeamMembers.length ? (
        props.horseTeamMembers.map((currTeamMember, index) => {
          return (
            <div key={`team_member_tab_team_member${index}`}>
              <div
                className="user flex flex-row flex-wrap items-center rounded-lg py-1"
                key={`teamMember${index}`}
              >
                <Link to={`${CONST.ROUTES.USER_PROFILE.URL}/${currTeamMember?.memberId}`}>
                  <img
                    src={
                      !!currTeamMember.memberProfilePicture
                        ? currTeamMember?.memberProfilePicture
                        : '/assets/og_icons/User.svg'
                    }
                    className="mr-3 rounded-full bg-SeabiscuitLightGrayThemeColor horseImg w-[45px] h-[45px] object-cover"
                    alt="horse team member"
                    onError={(e) => {
                      e.currentTarget.src = '/assets/og_icons/User.svg'
                    }}
                  />
                </Link>
                <div className="flex flex-col items-start">
                  <div className='"team_member_name text-SeabiscuitDark200ThemeColor text-[14px] font-medium capitalize'>
                    {currTeamMember.memberName}
                  </div>
                  <div className="team_member_details text-[12px] text-SeabiscuitDark200ThemeColor opacity-[.5] capitalize">
                    {currTeamMember?.memberRole ?? 'Unknown'}
                  </div>
                </div>
              </div>
              {props.horseTeamMembers.length - 1 > index && <hr className=" md:my-2 my-1" />}
            </div>
          )
        })
      ) : (
        <div className="text-SeabiscuitDark200ThemeColor opacity-50">Horse has no team members</div>
      )}
    </>
  )
}

export default HorseTeamMembers
