import React from 'react'

import { IconEmpty } from '../../../../components/icons/IconEmpty'

import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import { ManageClinicSpectators } from './ManageClinicSpectators'
import { ManageClinicHorses } from './ManageClinicHorses'
import ViewsLoader from '../../../../components/loader/ViewsLoader'

import {
  ETabId,
  MANAGE_CLINIC_TABS,
  useManageClinicNOtherAdminTabNew,
} from './hooks/useManageClinicNOtherAdminTabNew'
import { ManageEntriesActions } from './ManageEntriesActions'

interface ManageClinicNOtherAdminTabNewProps {
  eventId: string
}

export const ManageClinicNOtherAdminTabNew: React.FC<ManageClinicNOtherAdminTabNewProps> = ({
  eventId,
}) => {
  const {
    horses,
    tableColumns,
    riders,
    activeTab,
    setActiveTab,
    loading,
    setActiveStatus,
    setRiders,
    frozenRiders,
    setSearchNameValue,
    activeClass,
    searchNameValue,
    ridersClasses,
    activeStatus,
    setActiveClass,
  } = useManageClinicNOtherAdminTabNew(eventId)

  if (loading) {
    return (
      <div className="w-full min-h-[400px] flex justify-center items-center">
        <ViewsLoader size="lg" color="#F70763" />
      </div>
    )
  }

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-full flex flex-col">
      <div className="flex flex-col md:flex-row md:items-center  md:h-[60px] justify-between">
        <div className="flex flex-wrap mb-4 md:mb-0">
          {MANAGE_CLINIC_TABS.map((currentItem, index) => {
            return (
              <div
                key={`${currentItem}b${index}`}
                onClick={() => setActiveTab(currentItem.id)}
                className={`py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl ${
                  activeTab === currentItem.id
                    ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
                    : 'text-SeabiscuitDark200ThemeColor'
                }`}
              >
                {currentItem.tab}
              </div>
            )
          })}
        </div>
        {activeTab === ETabId.entries && (
          <ManageEntriesActions
            activeStatus={activeStatus}
            setActiveStatus={setActiveStatus}
            setRiders={setRiders}
            frozenRiders={frozenRiders}
            setSearchNameValue={setSearchNameValue}
            setActiveClass={setActiveClass}
            activeClass={activeClass}
            searchNameValue={searchNameValue}
            ridersClasses={ridersClasses}
            riders={riders}
          />
        )}
      </div>
      {ETabId.entries === activeTab && (
        <InfiniteScrollDataTable
          hasMore={false}
          columns={tableColumns}
          data={riders}
          noDataComponent={
            <div className={'min-h-[65vh] flex items-center justify-center flex-col gap-[36px]'}>
              <IconEmpty />
              <p className={'text-center m-0 text-[#122B46] text-[16px]'}>
                No horses have registered for this event
              </p>
            </div>
          }
        />
      )}
      {ETabId.spectators === activeTab && <ManageClinicSpectators eventId={eventId} />}
      {ETabId.horses === activeTab && <ManageClinicHorses horses={horses} eventId={eventId} />}
    </div>
  )
}
