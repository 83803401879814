import React, { CSSProperties } from 'react'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form'

import Accordion from '../../../accordion/common/Accordion'

import { IEventPolicies } from '../../../../models/event-policies/event-policies.interface'

interface IProps {
  field: FieldArrayWithId<IEventPolicies>
  header: string
  isExtra: boolean
  register: UseFormRegister<IEventPolicies>
  index: number
  id: string
}

export const EventDraggableFormElement: React.FC<IProps> = ({
  field,
  isExtra,
  header,
  register,
  index,
  id,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  })
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }
  const renderTitle = (title: string) => {
    return (
      <div className={'flex items-center'}>
        <div
          {...attributes}
          {...listeners}
          className="hidden md:flex cursor-pointer opacity-50 hover:opacity-100 w-11 p-0 transition-all -ml-4"
        >
          <img src="/assets/cp_icons/Move.svg" alt="moveIcon" className="w-full px-3" />
        </div>
        {title ? title : <span className="opacity-40">N/A</span>}
      </div>
    )
  }

  return (
    <div ref={setNodeRef} style={style} className={'flex items-start gap-2 w-full relative mt-3'}>
      <Accordion initialState={false} header={renderTitle(header)} className="w-full">
        <div key={field.id}>
          <>
            {isExtra && (
              <div className="flex mt-2 gap-2 w-full mx-auto">
                <div className="flex items-center w-full justify-between">
                  <input
                    type="text"
                    className="text-[14px] w-full text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor rounded-md outline-0 ring-0 border-solid border-transparent focus:border-[#D3DAEE] focus:outline-0 focus:ring-0 capitalize"
                    placeholder="Enter Policy Title"
                    {...register(`policies.${index}.name`)}
                  />
                </div>
              </div>
            )}

            <textarea
              className="mt-4 bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor rounded w-full h-40 text-base EventDetailFormComponent__input  placeholder:text-[16px] border-solid border-transparent placeholder:font-light placeholder:text-gray-400 focus:border-solid focus:ring-0 focus:border-[#D3DAEE] border capitalize"
              placeholder={isExtra ? 'Enter Policy Description' : field.placeholder}
              {...register(`policies.${index}.policy`)}
            />
          </>
        </div>
      </Accordion>
    </div>
  )
}
