import * as EventPaymentSettingsService from './event-payment-settings/EventPaymentSettingsService'
import * as RegistrationTicketService from './registration-tickets/RegistrationTicketService'
import * as EventService from './event/EventService'

const FirebaseService = {
  EventPaymentSettingsService: EventPaymentSettingsService.default,
  RegistrationTicketService: RegistrationTicketService.default,
  EventService: EventService.default,
  // RegistrationByDayService: RegistrationByDayService.default
}

export default FirebaseService
