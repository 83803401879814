import React, { useEffect, useState } from 'react'

import { useLocation, useParams } from 'react-router'
import clsx from 'clsx'

import CompetitorRootSideProfileDisplayTabs from './Tabs/CompetitorRootSideProfileDisplayTabs'
import ProfileHorseDataTab from './Tabs/ProfileHorseDataTab'
import TeamMembersTab from './Tabs/ProfileTeamMembersTab'
import ProfileUserProfileDataTab from './Tabs/ProfileUserProfileDataTab'
import ProfileDetailsCard from '../../../components/pageWise/visitedUserDetails/profile-details-card/ProfileDetailsCard'

import useHorses from '../../../hooks/users/competitor/profile/useHorses'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../store/user/userSlice'

import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'

// Styles
import './allEventRootPage.css'

const AllEventList = ({
  isMyProfile,
  isChatPage,
}: {
  isMyProfile: boolean
  isChatPage?: boolean
}) => {
  // Hooks and vars
  const currLocation = useLocation()
  const { userId } = useParams<{ userId: string }>()

  const dispatch = useAppDispatch()

  const currentUserId = useAppSelector(selectUserId)
  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const horseData = useAppSelector(selectHorses)
  const userDocumentData = useAppSelector(selectProfileData)
  const profileDetails = visitedUserDetails?.profileDetails ?? null

  const { loadHorsesFromDb } = useHorses()

  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    const id = userId || currentUserId
    loadHorsesFromDb(id ?? '').then()
    return () => {
      dispatch(setHorses([]))
    }
  }, [currentUserId, currLocation.pathname, userId])

  // Functions
  const setActiveTabVal = (tabToSwitch: number) => {
    setActiveTab(tabToSwitch)
  }

  return (
    <div className={clsx('flex flex-col items-center', !isChatPage && 'pt-7 pl-7 pr-7')}>
      <div
        className={clsx(
          'bg-SeabiscuitLightGrayThemeColor flex flex-col items-center justify-center px-6 py-5',
          isChatPage ? 'w-full' : 'w-[375px] rounded-xl'
        )}
      >
        <ProfileDetailsCard isChatPage={isChatPage} setActiveTab={setActiveTab} />
        {!isChatPage && (
          <div
            className={clsx(
              (profileDetails?.userDiscipline ||
                profileDetails?.userZone ||
                profileDetails?.userNationality) &&
                'bg-white  px-4 py-2 flex mt-2 flex-wrap rouded-lg w-full rounded-xl justify-between'
            )}
          >
            {profileDetails?.userDiscipline && profileDetails?.userDiscipline !== '' && (
              <>
                <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                  <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base line-clamp-1">
                    {profileDetails?.userDiscipline}
                  </span>
                  <span className="text-sm text-SeabiscuitLightParagraphTextColor">Discipline</span>
                </div>
              </>
            )}

            {profileDetails?.userZone && profileDetails?.userZone !== '' && (
              <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base line-clamp-1">
                  {profileDetails?.userZone}
                </span>
                <span className="text-sm text-SeabiscuitLightParagraphTextColor">Zone</span>
              </div>
            )}

            {profileDetails?.userNationality && profileDetails?.userNationality !== '' && (
              <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base break-normal">
                  {profileDetails?.userNationality}
                </span>
                <span className="text-sm text-SeabiscuitLightParagraphTextColor">Nationality</span>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col items-center pt-[22px] w-full  bg-white">
        <div className="flex flex-wrap rouded-lg w-full items-start justify-center">
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 1 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(1)}
          >
            {activeTab === 1 ? (
              <img src="/assets/cp_icons/User-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 2 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(2)}
          >
            {activeTab === 2 ? (
              <img src="/assets/cp_icons/User_Groups-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User_Groups-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 3 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(3)}
          >
            {activeTab === 3 ? (
              <img src="/assets/og_icons/YearofHorse-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/og_icons/YearofHorse-1.svg" alt="userIcons" />
            )}
          </div>
        </div>

        <div className="scrollable overflow-y-auto w-full md:h-[500px] mt-2 pt-2">
          <div className="content px-2">
            {activeTab === 1 &&
              (!profileDetails?.userAccountPrivacy || isMyProfile ? (
                <ProfileUserProfileDataTab
                  profileDetails={profileDetails ?? []}
                  userDocumentData={userDocumentData}
                />
              ) : (
                <CompetitorRootSideProfileDisplayTabs />
              ))}
            {activeTab === 2 &&
              (!profileDetails?.userAccountPrivacy || isMyProfile ? (
                <TeamMembersTab
                  tabHeading="Team"
                  team_members={profileDetails?.userTeamMembers ?? []}
                />
              ) : (
                <CompetitorRootSideProfileDisplayTabs />
              ))}
            {activeTab === 3 &&
              (!profileDetails?.userAccountPrivacy || isMyProfile ? (
                <ProfileHorseDataTab horses={horseData} />
              ) : (
                <CompetitorRootSideProfileDisplayTabs />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllEventList
