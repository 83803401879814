import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

type IProps = {
  title?: string
  link?: string
  className?: string
  children?: any
}

const ExhibitorProfilePageWrapper = ({ title, className, children, link }: IProps) => {
  return (
    <>
      <div className="w-full flex-1 md:px-[25px] 2xl:px-[45px] px-4 flex flex-col">
        <div className={clsx('mt-6', className)}>
          <div className="flex items-center mb-4 justify-between">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[20px] font-bold">
              {link ? (
                <Link to={link} className="hover:opacity-70 transition-all">
                  <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
                  {title}
                </Link>
              ) : (
                <>
                  <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
                  {title}
                </>
              )}
            </h1>
          </div>
        </div>
        <div className="p-3 flex flex-col flex-1 md:p-8 rounded-xl bg-SeabiscuitWhiteThemeColor">
          {children}
        </div>
      </div>
    </>
  )
}

export default ExhibitorProfilePageWrapper
