// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

// Custom imports
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Types
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import {
  IEventFeeCategory,
  IEventTickets,
} from '../../../../models/event-details/event-details.interface'
import { IFees } from '../../../../models/event-drafts/event-draft.interface'
import { IRegistrationTabs } from '../../../../models/event-registered-users/event-registered-users.interface'
import { useAppSelector } from '../../../../store/hooks'
import { selectTicketTabData } from '../../../../store/registration/registrationSlice'
import FeesRoot from '../../event-registration-tabs/components/fees/FeesRoot'
import TicketsHeader from '../../event-registration-tabs/components/tickets/TicketHeader'
import IUseEventRegistrationTabsTypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import AmountInput from '../../../../components/common/inputs/AmountInput'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { capitalize } from '../../../../helpers/helpers'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import {
  IOnChangeFees,
  ISaveTicketTabData,
} from '../../event-registration-tabs/hooks/useEventRegistrationTabs/useEventRegistrationTabs'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../helpers/TooltipIcon'

type Props = {
  step?: number
  title?: string
  feesItemsForm: UseFormReturn
  ticketItemsForm: UseFormReturn
  description?: string
  ticketsHandler: ({ updatedObjOfTicketGroup }: { updatedObjOfTicketGroup: IEventTickets }) => void
  feesHandler: ({
    updatedObjOfFeesGroup,
    foundError,
  }: {
    updatedObjOfFeesGroup: IFees
    foundError: boolean
  }) => void
  eventFeeList: Record<any, IEventFeeCategory[]>
  eventTicketsList: IEventTickets[]
  fees: IFees[]
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  onRegistrationTicketCountChange: IUseEventRegistrationTabsTypes['IOnRegistrationTicketCountChangeFn']
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  onInputChangeTicket: ({ setManageInfo, manageInfo }: ISaveTicketTabData) => void
  onChangeFees: ({ setManageInfo, manageInfo }: IOnChangeFees) => void
}

type ITicketsColumnComp = (props: {
  id: string
  index: number
  ticketItemsForm: UseFormReturn
  ticket: IRegistrationTabs['ITicketTab']
  keyProp: number | string
  handleChange: IChangeHandler
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  listing: { [key: string]: boolean }
  onRegistrationTicketCountChange: IUseEventRegistrationTabsTypes['IOnRegistrationTicketCountChangeFn']
  setListing: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean
    }>
  >
  onInputChangeTicket: ({ setManageInfo, manageInfo, isManage }: ISaveTicketTabData) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}) => ReactJSXElement

type IChangeHandler = (data: any, e: any) => void

// Constants
const widths = [
  'shrink-0 w-[170px]',
  'shrink-0 w-[100px]',
  'shrink-0 w-[150px]',
  'shrink-0 w-[130px]',
  'grow min-w-[230px]',
  'shrink-0 w-[130px] mr-3',
]

const TABS = {
  FEES: 0,
  TICKETS: 1,
}

const CompetitorEventRegisterFeesTab = (props: Props) => {
  // Hooks and vars
  const ticketTabData = useAppSelector(selectTicketTabData)

  const { ticketsHandler } = props

  const [listing, setListing] = useState<{ [key: string]: boolean }>({})

  const handleTicketChange: IChangeHandler = (data, e) => {
    let updatedObjOfTicketGroup = {
      ...data,
      sold: Number(data.sold),
      units: Number(e.value),
    }
    ticketsHandler({ updatedObjOfTicketGroup })
  }

  useEffect(() => {
    let selectedUnitsCount = 0
    let ticketsStateInHookForm: Record<string, number> = {}

    ticketTabData.forEach((currTicketRow) => {
      if (currTicketRow.registrationTicket) {
        let ticket = currTicketRow
        if (props.isManage) {
          const filtered = props.manageInfo.tickets.filter(
            (manageInfoTicket) => manageInfoTicket.ticketItemId === ticket.ticketItemId
          )
          if (filtered.length > 0) {
            ticket = { ...currTicketRow, registrationTicket: filtered[0].registrationTicket }
          } else {
            ticket = { ...currTicketRow, registrationTicket: null }
          }
        }

        selectedUnitsCount = ticket?.registrationTicket?.selectedUnitsCount ?? 0
        ticketsStateInHookForm = {
          ...ticketsStateInHookForm,
          [currTicketRow.ticketItemId]: selectedUnitsCount,
        }
      }
    })

    props.ticketItemsForm.reset(ticketsStateInHookForm)
  }, [props.isManage, props.ticketItemsForm, ticketTabData])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div id="rtc">
        {/* Fees list */}
        {props.step === TABS.FEES ? (
          <FeesRoot
            feesItemsForm={props.feesItemsForm}
            handleModal={props.handleModal}
            onChangeFees={props.onChangeFees}
            isManage={props.isManage}
            manageInfo={props.manageInfo}
            setManageInfo={props.setManageInfo}
          />
        ) : null}

        {/* Ticket list */}
        {props.step === TABS.TICKETS ? (
          <>
            {ticketTabData?.length ? (
              <>
                <TicketsHeader />

                {ticketTabData.map((ticket, index) => {
                  return (
                    <TicketsColumn
                      ticket={ticket}
                      index={index}
                      listing={listing}
                      id={`fees${index}`}
                      setListing={setListing}
                      handleModal={props?.handleModal}
                      handleChange={handleTicketChange}
                      ticketItemsForm={props.ticketItemsForm}
                      onInputChangeTicket={props.onInputChangeTicket}
                      isManage={props.isManage}
                      manageInfo={props.manageInfo}
                      setManageInfo={props.setManageInfo}
                      key={`${JSON.stringify(ticket)}${index}`}
                      keyProp={`${JSON.stringify(ticket)}${index}`}
                      onRegistrationTicketCountChange={() => {
                        return null
                      }}
                    />
                  )
                })}
              </>
            ) : (
              <DataNotAvailable
                mode="text"
                containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
                text="No ticket items have been added to this event"
              />
            )}
          </>
        ) : null}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}
let classList: string = 'border border-solid border-SeabiscuitLightThemeColor !rounded-[8px]'
let commonClasses: string = 'text-ellipsis overflow-hidden whitespace-nowrap'
const TicketsColumn: ITicketsColumnComp = ({
  ticket,
  keyProp,
  handleModal,
  handleChange,
  onRegistrationTicketCountChange,
  ...props
}) => {
  // Vars
  const [inputValue, setInputValue] = useState<string | null>(null)

  const openSeeMoreModal = (content: string) => {
    handleModal(true, MODAL_CONSTS.ADD_NOTE, { noteInputRef: content })
  }

  return (
    <div
      className={clsx(
        'border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-5 lg:p-0  flex flex-col lg:flex-row w-full text-nr gap-[10px]',
        props.index && 'mt-2'
      )}
      key={keyProp}
    >
      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[0])}>
        Ticket Item
        <Tooltip
          title={<h1 className="tooltip_title">{`Ticket items added by event organizer`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          `w-full lg:w-[170px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px] `,
          classList,
          widths[0],
          commonClasses,
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {capitalize(ticket?.ticketTitle, {
          capitalizeAll: false,
        })}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[1])}>
        Price
        <Tooltip
          title={<h1 className="tooltip_title">{`Cost per ticket`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'lg:text-center w-full lg:w-[100px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px]',
          classList,
          commonClasses,
          widths[1],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        ${ticket?.ticketPrice}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[3])}>
        Availability
        <Tooltip
          title={<h1 className="tooltip_title">{`Number of tickets remaining`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'lg:text-center  w-full lg:w-[130px] text-SeabiscuitDark200ThemeColor py-4 px-2 rounded-lg h-[56px]',
          classList,
          commonClasses,
          widths[3],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.avaliability && !isNaN(Number(ticket?.avaliability))
          ? `${ticket?.avaliability} available`
          : 'N/A'}
      </div>

      <span
        className={clsx(
          'lg:hidden mb-[-10px] registration_tabs_column_title !justify-start',
          widths[4]
        )}
      >
        Details
        <Tooltip
          title={<h1 className="tooltip_title">{`Click for more details about the ticket`}</h1>}
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'text-SeabiscuitDark200ThemeColor p-4 rounded-lg w-full lg:w-[unset] flex flex-nowrap',
          classList,
          widths[4],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.ticketDescription ? (
          <>
            <div className="w-full line-clamp-1">
              {capitalize(ticket?.ticketDescription, {
                capitalizeAll: false,
              })}
            </div>
            <img
              onClick={() =>
                openSeeMoreModal(
                  capitalize(ticket?.ticketDescription, {
                    capitalizeAll: false,
                  }) as string
                )
              }
              src={'assets/og_icons/FullScreen-1.svg'}
              alt="fullScreenIcon"
              className="w-6 h-6 ml-2 cursor-pointer"
            />
          </>
        ) : (
          'N/A'
        )}
      </div>

      <span className={clsx('lg:hidden mb-[-10px] registration_tabs_column_title', widths[5])}>
        Select units
        <Tooltip
          title={
            <h1 className="tooltip_title">{`Select the number of tickets you wish to purchase`}</h1>
          }
          placement="top"
          arrow
        >
          <button>
            <TooltipIcon color="#122B46" />
          </button>
        </Tooltip>
      </span>
      <div
        className={clsx(
          'w-full lg:w-[130px] text-SeabiscuitDark200ThemeColor rounded-lg h-[56px] flex items-center',
          classList,
          widths[5],
          inputValue && 'bg-[#f6f7fb] border-[#f6f7fb]'
        )}
      >
        {ticket?.avaliability !== 0 ? (
          <Controller
            name={ticket.ticketItemId}
            control={props.ticketItemsForm.control}
            render={({ field: { value, name, onChange } }) => {
              return (
                <AmountInput
                  name={name}
                  onChange={(value: string) => {
                    setInputValue(value)
                    if (Number(value) > Number(ticket?.avaliability))
                      onChange(Number(ticket?.avaliability))
                    else onChange(Number(value))

                    props.onInputChangeTicket({
                      currentRow: ticket,
                      setManageInfo: props.setManageInfo,
                      manageInfo: props.manageInfo,
                      isManage: props.isManage,
                    })
                  }}
                  inputClassName="w-full rounded-lg searchableComponent focus:ring-0 lg:p-0 focus:ring-transparent lg:text-center border-none bg-[transparent] h-full"
                  placeholder="Enter units"
                  value={!value ? 0 : value}
                />
              )
            }}
          />
        ) : (
          <div className="text-center w-[100%]">N/A</div>
        )}
      </div>
    </div>
  )
}

export default CompetitorEventRegisterFeesTab
