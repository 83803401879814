import React, { FC } from 'react'

interface SubheaderProps {
  title: string
  subtitle: string
}

export const Subheader: FC<SubheaderProps> = ({ title, subtitle }) => (
  <>
    <p className="text-lg font-bold text-SeabiscuitDark200ThemeColor mt-5">{title}</p>
    <div className="text-SeabiscuitDark200ThemeColor text-[14px] opacity-50 mb-6">{subtitle}</div>
  </>
)
