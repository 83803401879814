import { httpService } from '../services/httpService'

import { IPaymentMethod } from '../store/storeHelpers/userSlice/types'

import { IUserInterface } from '../models/users/user.interface'

import { getUserFullName } from './helpers'

export const setPaymentMethods = async (
  userProfile: IUserInterface,
  organiserProfile?: IUserInterface
) => {
  let data
  let payments = []
  try {
    if (organiserProfile?.userStripeAccountId && userProfile?.userStripeId) {
      const response = await httpService({
        url: `get_payment_methods/${userProfile?.userEmail}/${organiserProfile?.userStripeAccountId}`,
      })
      data = response.data
    } else if (userProfile?.userStripeId) {
    }

    if (data && data.length > 0) {
      payments = data?.map((payment: IPaymentMethod) => ({
        cardNumber: payment.card.last4,
        cardType: payment.card.brand,
        pmId: payment.id,
        expiryMonth: payment.card.exp_month,
        expiryYear: payment.card.exp_year,
      }))
    }
    return payments
  } catch (error) {
    console.log(error, 'error')
    return []
  }
}
