// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useMemo } from 'react'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { DurationList } from '../../../../fakeData/durationList'
import { TEventFees } from '../../../../models/event-review-publish/event-review-publish.interface'
import MoreButton from './global/MoreButton'

export const FEES_CATEGORY_CONST = {
  OTHER: 'other',
  GOVERNANCE: 'governance',
  REFUND: 'refund',
  BEDDING: 'bedding',
  STALLS: 'stalls',
  REGISTRATION: 'registrations',
  FEED: 'feed',
}

/* Local Functions */

const getDurationText = (duration: string | { label: string; value: string }): string => {
  let valueToReturn = ''
  let durationSelected = (() => {
    let ds
    if (typeof duration === 'string') ds = DurationList.find((curr) => curr.value === duration)
    else ds = DurationList.find((curr) => curr.value === duration.value)
    return ds
  })()
  if (durationSelected) {
    valueToReturn = durationSelected.text
  }
  return valueToReturn
}

const getTitleOfFees = (row: any): string => {
  let price = row?.cost
  let valueToReturn = ''
  let percentage = row?.percentage
  let units = row?.available ?? row?.available
  let isGovernanceCategoryFee = row?.category === FEES_CATEGORY_CONST.GOVERNANCE

  if (isGovernanceCategoryFee) {
    valueToReturn = `$${price}`
    return valueToReturn
  }

  if (!Number(percentage)) if (Number(units)) valueToReturn = `${units} available`

  if (Number(price) && (row?.duration || isGovernanceCategoryFee) && !Number(percentage))
    valueToReturn = `${valueToReturn ? valueToReturn : '0 available'}, $${Number(price)} ${getDurationText(row?.duration)}`
  else if (!Number(percentage)) valueToReturn = `N/A`

  if (!units && Number(percentage))
    if (Number(percentage)) valueToReturn = `${percentage ?? 0}% refunded`

  return valueToReturn
}

/* Local Functions */

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailsViewComponentFeeCart: React.FC<{
  icon: string
  title: string
  data: Array<any>
  handleModal?: any
}> = ({ icon, title, data, handleModal }) => {
  const maxItemsToShow = 3
  let itemsToShow = [...(data ?? [])]

  if (title === 'Stalls') {
    itemsToShow = itemsToShow.filter((item) => {
      return item.title !== 'Private stalls' && item.title !== 'Muck Fee'
    })
  }

  itemsToShow = itemsToShow.splice(0, maxItemsToShow)

  if (!itemsToShow.length) return null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <div className="border-transparent sm:border-[#D3DAEE] border-[1px] border-solid rounded-lg 2xl:p-5 p-2 sm:p-4 relative">
      <div className="flex gap-2 items-center text-SeabiscuitDark200ThemeColor">
        <img src={icon} alt="icon" className="w-5 h-5 2xl:w-6 2xl:h-6" />
        <div className="font-semibold text-nr 2xl:text-lg">{title}</div>
      </div>
      <div className="mt-5 2xl:mt-[30px] flex flex-col mb-0 sm:mb-6 justify-center">
        {itemsToShow.map((row, index) => {
          if (!row?.status) return null

          return (
            <span
              className="w-100 flex flex-wrap xl:flex-nowrap mb-4 2xl:mb-5 text-sm 2xl:text-base justify-between"
              key={`${JSON.stringify(row)}${row?.title}${index}`}
            >
              <div className="mb-4 w-[120px] mr-0 shrink-0 text-SeabiscuitDark200ThemeColor pr-4 capitalize text-opacity-[.85] xl:h-[60px]">
                {row.title}
              </div>

              <div className="text-[#3C4B71AA] sm:block w-[83%]">
                <div className="flex text-SeabiscuitDark200ThemeColor text-opacity-[.85]">
                  {getTitleOfFees(row)}
                </div>

                {FEES_CATEGORY_CONST.GOVERNANCE !== row?.category ? (
                  <div className="last:hidden sm:last:block whitespace-nowrap last:whitespace-normal text-opacity-[.5] text-SeabiscuitLightParagraphTextColor line-clamp-2 overflow-hidden">
                    {row?.note ? (
                      row.note
                    ) : (
                      <div className="text-SeabiscuitLightParagraphTextColor">
                        No details were provided
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </span>
          )
        })}
      </div>

      <div className="absolute bottom-4 hidden sm:block">
        <MoreButton
          onClick={() => handleModal(true, MODAL_CONSTS.EVENTS_FEES, { title, data, icon })}
          className="opacity-50"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailsViewComponentFeeSection: React.FC<{
  onOverallMore?: () => void
  handleModal?: any
  EventFees?: TEventFees
}> = ({ handleModal, EventFees }) => {
  /**
   * @info Divides all the fees types into a object with category as its name,
   * and array of that category elements as its values
   */
  const feeDivisionsCategoryWise = useMemo(() => {
    const accIniValue = {}
    let valueToReturn: any = []
    let title = ''

    if (EventFees) {
      valueToReturn = Object.keys(EventFees).reduce((acc: Record<string, Array<any>>, currKey) => {
        let currObj = EventFees[currKey as keyof TEventFees]
        if (!currObj) return acc
        let objectHasCategory = typeof currObj === 'object' && 'category' in currObj

        title =
          currObj.hasOwnProperty('title') && currObj.title && currObj.title !== ''
            ? currObj.title
            : currObj.hasOwnProperty('name') && currObj.name && currObj.name !== ''
              ? currObj.name
              : null

        if (objectHasCategory) {
          if (Object.values(FEES_CATEGORY_CONST).includes(currObj.category) && currObj.status)
            acc[`${currObj.category}`] = [
              ...(acc[`${currObj.category}`] ?? []),
              { ...(currObj ?? {}), title },
            ]
        }
        return acc
      }, accIniValue)
    }

    return valueToReturn ?? []
  }, [EventFees])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="grid grid-2 md:grid-cols-2 gap-2">
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/Farm-1.svg"
        handleModal={handleModal}
        title="Stalls"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.STALLS] ?? []}
      />
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/Feed.svg"
        handleModal={handleModal}
        title="Feed"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.FEED] ?? []}
      />
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/Bedding.svg"
        handleModal={handleModal}
        title="Bedding"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.BEDDING] ?? []}
      />
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/ExpensivePrice-1.svg"
        handleModal={handleModal}
        title="Other"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.OTHER] ?? []}
      />
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/Transaction-2.svg"
        handleModal={handleModal}
        title="Refunds"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.REFUND] ?? []}
      />
      <EventDetailsViewComponentFeeCart
        icon="/assets/cp_icons/Parliament-1.svg"
        handleModal={handleModal}
        title="Mandatory"
        data={feeDivisionsCategoryWise?.[FEES_CATEGORY_CONST.GOVERNANCE] ?? []}
      />
    </div>
  )
}
export default EventDetailsViewComponentFeeSection
