import { useState } from 'react'

// Components
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AutorenewRounded } from '@mui/icons-material'

// Components
import DrawerComponent from '../../../../components/events/forms/review-publish/Components/DrawerComponent'

// Redux
import {
  resetScratchItems,
  selectScratch,
  setScratchViewVisibility,
} from '../../../../store/events/eventsSlice'
import { selectExhibitorDetails, setRegistration } from '../../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

// Services
import useToasterHelper from '../../../../helpers/ToasterHelper'
import FirestoreService from '../../../../services/firestoreService'

// Models
import { CustomError, getUserFullName } from '../../../../helpers/helpers'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { getConvertedData } from '../../../../models/interface.helper'
import helpers from '../../../../commonHelpers/helpers'
import { useLocation } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'

const UNKNOWN = 'Unknown'
const FILE_NAME = 'ExhibitorScratchConfirmModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const ButtonContainer: React.FC<{ children: any; className?: string }> = ({
  children,
  className,
}) => <div className={`w-full flex flex-col mt-2 mx-auto ${className}`}>{children}</div>

const ExhibitorScratchConfirmModal: React.FC<{
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}> = ({ show, handleModal, dataToPassOn }) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const exhibitor = useAppSelector(selectExhibitorDetails)
  const scratchDetails = useAppSelector(selectScratch)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const router = useIonRouter()
  const history = useHistory()

  const handleRemove = async (update = false) => {
    if (0) {
      return
    }

    try {
      if (!update) {
        return handleModal(false, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL)
      }

      if (!scratchDetails?.registrationId) throw new Error('Registration id not provided')

      setLoading(true)

      const registrationSnapShot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        scratchDetails.registrationId
      )

      if (!registrationSnapShot.exists()) throw new Error('Registration does not exist')

      const registration =
        EventRegisteredUsersModel.fromFirestoreDoc(registrationSnapShot).cloneDeep()

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        scratchDetails.registrationId,
        new EventRegisteredUsersModel(registration).toFirestore()
      )

      if (location.pathname.includes('/registered/event')) {
        router.push('/competitor/allbills')
        history.push('/competitor/allbills')
      }

      dispatch(setScratchViewVisibility(false))
      dispatch(setRegistration(getConvertedData(registration.toObject())))
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'handleRemove',
          devMessage: error?.message,
        }),
      })
    } finally {
      dispatch(resetScratchItems())
      handleModal(false, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL)
    }
  }

  const getScratchDetails = (): ReactJSXElement => {
    let jsxToReturn = <></>
    let innerJSX: ReactJSXElement | null = <></>

    switch (dataToPassOn.tab) {
      case 'Classes':
        innerJSX = (
          <div>
            {/* This item was scratched. If you scratch all items, event paperwork and registration details will still be available. */}
            Refunds are not automatically processed. You must manually refund each item in the event
            finances tab in line with your refund policies.
          </div>
        )
        break

      case 'Fees':
        innerJSX = (
          <div>
            Refunds are not automatically processed. You must manually refund each item in the event
            finances tab in line with your refund policies.
          </div>
        )
        break

      case 'Tickets':
        innerJSX = (
          <div>
            Refunds are not automatically processed. You must manually refund each item in the event
            finances tab in line with your refund policies.
          </div>
        )
        break
    }

    if (!innerJSX) return <></>

    jsxToReturn = (
      <div className="flex items-start mt-2">
        <img src="/assets/cp_icons/Error-1.svg" alt="warningIcon" className="w-5 h-5 mx-2" />
        {innerJSX}
      </div>
    )

    return jsxToReturn
  }

  console.log('=>(ExhibitorScratchConfirmModal.tsx:234) dataToPassOn', dataToPassOn)

  return (
    <DrawerComponent
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL)}
      title=""
      parentClasses="!mt-0"
      maxWidth={540}
    >
      <div className="px-2 text-SeabiscuitDark200ThemeColor">
        <div className="text-[24px] mb-3 top-5 left-6 absolute">Scratch successful</div>

        {/*This div just covers the x mark on the top right corner, it is just a work around because we need the users to click the "submit" button currently called closed*/}
        <div className="bg-[white] absolute top-1 right-1 w-10 h-10"></div>

        <div className="flex items-center mt-8 border border-[#122B461A] py-1 px-2 pl-1 rounded-lg text-base">
          <img
            className="w-10 h-10 mr-2 object-cover rounded-md"
            src={
              exhibitor?.userProfilePicture
                ? exhibitor?.userProfilePicture
                : 'assets/cp_icons/User-3.svg'
            }
            alt="exhibitorProfilePicutre"
          />
          <span className="font-normal">{exhibitor ? getUserFullName(exhibitor) : UNKNOWN}</span>
        </div>

        <div className="border border-[#122B461A] py-1 px-2 mt-2 rounded-lg text-nr p-3 pb-3 overflow-auto">
          <div className="mb-2">Scratch Details:</div>
          <div className="flex items-start">
            <div className="w-full">
              <div className="flex items-start mt-3">
                <img
                  src="/assets/cp_icons/UsDollarCircled1.svg"
                  alt="dollarIcon"
                  className="w-5 h-5 mx-2"
                />
                Amount scratched: {`$${dataToPassOn?.scratchTotal}`}
              </div>
              <div className="flex items-start mt-3">
                <img
                  src="/assets/cp_icons/MagneticCard-1.svg"
                  alt="dollarIcon"
                  className="w-5 h-5 mx-2"
                />
                Refund to: XXXX-XXXX-XXXX-{dataToPassOn?.cardNo}
              </div>
              {/* <div className="flex items-start mt-3">
                                    <div className="w-5 h-5 mx-2 shrink-0"></div>
                                    Refunds are not automatically processed. You must manually refund each item in the event finances tab in line with your refund policies.
                                </div> */}

              {getScratchDetails()}
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <ButtonContainer className="mb-1.5">
            <button
              onClick={() => handleRemove(true)}
              className="items-center py-4 rounded-xl w-full relative bg-SeabiscuitMainThemeColor text-white disabled:opacity-[.5] border-1 border-transparent border-solid mt-0 text-nr text-center h-[53px]"
            >
              {loading ? <AutorenewRounded className="animate-spin" /> : 'Close'}
            </button>
          </ButtonContainer>
          {/* <ButtonContainer>
                            <button
                                onClick={() => handleRemove(false)}
                                className="items-center py-4 rounded-xl w-full relative bg-[#1F41731A] text-[#1F417380] disabled:opacity-[.5] border-1 border-transparent border-solid mt-0 text-nr">
                                <span>Close</span>
                            </button>
                        </ButtonContainer> */}
        </div>
      </div>
    </DrawerComponent>
  )
}
export default ExhibitorScratchConfirmModal
