import { Clear } from '@mui/icons-material'
import React, { FC } from 'react'

export interface PropTypes {
  show: boolean
  onHide: () => void
}

const EventPoliciesFormModal: FC<PropTypes> = ({ onHide, show }: PropTypes) => {
  return (
    <div
      className={`fixed left-0 right-0 top-0 bottom-0 z-10 transition-all ${show ? 'visible' : 'invisible'}`}
    >
      <div
        className={`fixed bg-white p-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${show ? '1' : '0'} transition-all`}
      >
        <div className="overflow-auto max-h-screen">
          <div className="text-right">
            <button onClick={onHide}>
              <Clear
                fontSize={'small'}
                style={{
                  color: 'grey',
                  fontWeight: '400',
                }}
              />
            </button>
          </div>
          <h2 className="m-0 text-[25px] font-normal mt-[-1rem] text-SeabiscuitDark200ThemeColor">
            Add Policy
          </h2>
          <div className="text-center m-1 mt-4">
            <input
              type="text"
              placeholder="Name of policy"
              className="rounded-xl w-full border-1 border-[#D3DAEE]"
            />
            <textarea
              placeholder="Policy details"
              className="rounded-xl w-full border-1 border-[#D3DAEE] mt-4 h-[400px]"
            />
            <button className="rounded-lg bg-SeabiscuitMainThemeColor text-white w-[250px] h-9 mt-4">
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${show ? '1' : '0'} transition-all`}
      />
    </div>
  )
}

export default EventPoliciesFormModal
