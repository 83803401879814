import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { loadUserStoredData, storeIsFirstTime, storeUserId, clearUserStoredData } from './userThunk'
import { CONST } from '../../const/const'
import { userSliceIniState } from '../storeHelpers/userSlice/data'
import {
  ICompetitorProfileData,
  IUserInterfaceExtended,
  IUserSliceIniState,
} from '../storeHelpers/userSlice/types'
import { ITeamMember } from '../../models/users/user.interface'
import { INotificationSettings } from '../../types/competitor_types'
import { ILegalClauseInterface } from '../../models/user-legal-clauses/user-legal-clauses.interface'
import { createString, getUserFullName } from '../../helpers/helpers'
import { IGuestInterface } from '../../models/guests/guest.interface'

const userSlice = createSlice({
  name: 'user',
  initialState: userSliceIniState,
  reducers: {
    setStripeId: (state, { payload }: { payload: string }) => {
      state.userStripeId = payload
    },
    setStripeAccountId: (
      state,
      { payload }: { payload: IUserSliceIniState['userStripeAccountId'] }
    ) => {
      state.profileDetails.userStripeAccountId = payload
      state.userStripeAccountId = payload
    },
    setStripeAccountStatus: (
      state,
      {
        payload,
      }: {
        payload: IUserSliceIniState['profileDetails']['userStripeAccountStatus']
      }
    ) => {
      state.profileDetails.userStripeAccountStatus = payload
    },
    setStripeAccountMessage: (
      state,
      {
        payload,
      }: {
        payload: IUserSliceIniState['profileDetails']['userConnectAccountStatusMessage']
      }
    ) => {
      state.profileDetails.userConnectAccountStatusMessage = payload
    },
    setUserLegalClauses: (
      state,
      {
        payload,
      }: {
        payload: {
          index?: null | number
          clause: ILegalClauseInterface | ILegalClauseInterface[]
        }
      }
    ) => {
      /**
       * If index is null, then add a new clause,
       * else modify the clause at index,
       * In clause arg when we send array, then it will overwrite clauses in state
       * */
      let { index, clause } = payload
      let modifiedClauses: ILegalClauseInterface[] = [...state.userLegalClauses]

      if (Array.isArray(clause)) modifiedClauses = clause
      else {
        if (typeof index === 'number') modifiedClauses.splice(index, 1, clause)
        else modifiedClauses.push(clause)
      }
      state.userLegalClauses = modifiedClauses
    },
    setProfileDetails(state, { payload }: { payload: IUserInterfaceExtended }) {
      state.profileDetails = payload
      state.displayName = getUserFullName(payload)
      state.userStripeAccountId = payload.userStripeAccountId ?? null
    },
    setUserId(state, { payload }) {
      state.userId = payload
    },
    clearUserId(state) {
      state.userId = null
    },
    setUserType(state, { payload }: { payload: string }) {
      state.userType = payload === 'staff' ? 'competitor' : payload
    },
    setDisplayName(state, { payload }: { payload: string }) {
      state.displayName = payload
    },
    setUsername(state, { payload }) {
      state.profileDetails.userName = payload
    },
    setUserAccountPrivacy(state, { payload }) {
      state.profileDetails.userAccountPrivacy = payload
    },
    setEmail(state, { payload }) {
      state.profileDetails.userEmail = payload
    },
    setCompetitionProfileImageUrl(state, { payload }) {
      state.profileDetails.userProfilePicture = payload
    },
    setOrganizerProfileImageUrl(state, { payload }) {
      state.organiserProfileData.organizerProfileImageUrl = payload
    },
    setVendorProfileImageUrl(state, { payload }) {
      state.vendorProfileData.vendorProfileImageUrl = payload
    },
    setIsLoggedIn(state, { payload }) {
      state.isLoggedIn = payload
    },
    setIsFirstTime(state, { payload }) {
      state.isFirstTime = payload
    },
    setShowRegistration(state, { payload }) {
      state.showRegistration = payload
    },
    setHorseTeamMembers(state, { payload }: { payload: ITeamMember[] }) {
      state.HorseTeamMembers = payload
    },
    setHorseIdList(state, { payload }: { payload: string[] }) {
      state.HorseTeamIdLIst = payload
    },
    setUserProfileComplete(state, { payload }) {
      state.profileCompleted = payload
    },
    setUserProfileData(state, { payload }) {
      state.profileDetails = payload
    },
    setCompetitorProfileData(state, { payload }: { payload: ICompetitorProfileData }) {
      state.competitorProfileData = payload
    },
    setUserNotificationSettings(state, { payload }: { payload: INotificationSettings }) {
      state.userNotificationsSettings = payload
    },
    setOrganiserProfileData() {
      // state.organiserProfileData = payload
    },
    setUserDocumentsData(state, { payload }) {
      state.userDocumentsData = payload
    },

    setPocDetails(state, { payload }) {
      state.profileDetails.userPocDetails = payload
    },
    resetUserData(state) {
      state.userStripeId = ''
      state.displayName = ''
      state.profileDetails.userEmail = ''
    },
    setGuestData(state, { payload }: { payload: IGuestInterface }) {
      state.guestId = payload.id!
      state.displayName = getUserFullName(payload)
      state.userStripeId = payload.userStripeId as any
      state.profileDetails.userName = payload.userName as any
      state.profileDetails.userEmail = payload.userEmail as any
      state.profileDetails.userNameNGram = payload.userNameNGram
      state.profileDetails.userLastName = payload.userLastName as any
      state.profileDetails.userStripeId = payload.userStripeId as any
      state.profileDetails.userFirstName = payload.userFirstName as any
    },
    setPaymentStatus(state, { payload }: { payload: boolean }) {
      state.paymentStatus = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserStoredData.fulfilled, (state, action) => {
      state.userId = action.payload[CONST.STORAGE.LATEST.USERID.KEY]
      state.isFirstTime = action.payload[CONST.STORAGE.LATEST.FIRST_TIME.KEY] === 'true'
      if (typeof action.payload[CONST.STORAGE.LATEST.DISPLAY_NAME.KEY] === 'string') {
        state.displayName = action.payload[CONST.STORAGE.LATEST.DISPLAY_NAME.KEY]
      }
    })

    builder.addCase(storeUserId.fulfilled, (state, action) => {
      state.userId = action.payload
    })

    builder.addCase(storeIsFirstTime.fulfilled, (state, action) => {
      state.isFirstTime = action.payload
    })

    builder.addCase(clearUserStoredData.fulfilled, (state) => {
      state.userId = null
      state.displayName = null
      state.profileDetails.userName = ''
      state.profileDetails.userProfilePicture = ''
      state.organiserProfileData.organizerProfileImageUrl = null
      state.vendorProfileData.vendorProfileImageUrl = null
      state.isLoggedIn = false
    })
  },
})

export { loadUserStoredData, storeUserId, storeIsFirstTime, clearUserStoredData }

export const {
  setEmail,
  setUserId,
  setUsername,
  setStripeId,
  setDisplayName,
  setUserProfileData,
  setUserAccountPrivacy,
  setUserProfileComplete,
  setCompetitionProfileImageUrl,
  setCompetitorProfileData,
  setUserDocumentsData,
  setShowRegistration,
  setIsLoggedIn,
  setUserType,
  setPocDetails,
  setProfileDetails,
  setStripeAccountId,
  setUserLegalClauses,
  setStripeAccountStatus,
  setStripeAccountMessage,
  setUserNotificationSettings,
  resetUserData,
  setGuestData,
  setPaymentStatus,
  setHorseTeamMembers,
} = userSlice.actions

export const selectUserId = (state: RootState) => state.user.userId
export const selectPocDetails = (state: RootState) => state.user.profileDetails.userPocDetails

export const selectUserStripeId = (state: RootState) => state.user.userStripeId
export const selectUserStripeAccountId = (state: RootState) => state.user.userStripeAccountId
export const selectDisplayName = (state: RootState) => state.user.displayName
export const selectUsername = (state: RootState) => state.user?.profileDetails?.userName
export const selectUseremail = (state: RootState) => state.user?.profileDetails?.userEmail
export const selectUserProfileImageUrl = (state: RootState) =>
  state.user?.profileDetails.userProfilePicture
export const selectUserNameAc = (state: RootState) =>
  createString(getUserFullName(state.user?.profileDetails))
export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn
export const selectIsFirstTime = (state: RootState) => state.user.isFirstTime
export const selectUserReducerData = (state: RootState) => state.user
export const selectUserLegalClauses = (state: RootState) => state.user.userLegalClauses
export const selectShowRegistration = (state: RootState) => state.user.showRegistration
export const selectProfileCompleted = (state: RootState) => state.user.profileCompleted
export const selectProfileData = (state: RootState) => state.user.profileDetails
export const selectCompetitorProfileData = (state: RootState) => state.user.competitorProfileData
export const selectHorseTeamMembers = (state: RootState) => state.user?.HorseTeamMembers ?? []
export const selectUserStripeAccountStatus = (state: RootState) =>
  state.user.profileDetails.userStripeAccountStatus

export const selectUserBankConnected = (state: RootState) =>
  state.user.profileDetails.userBankConnected
export const getGuestData = (
  state: RootState
): IGuestInterface & {
  isLoggedIn: boolean
  isPlatformUser: boolean
  userType: 'user' | 'guest' | null
  userProfilePic: string | null
  userId: string | null
} => {
  const user = state.user
  return {
    id: user.guestId!,
    userId: user.userId,
    fullName: getUserFullName(user.profileDetails),
    userEmail: user.profileDetails.userEmail ?? null,
    userName: user.profileDetails.userName ?? null,
    userStripeId: user.userStripeId,
    userNameNGram: user.profileDetails.userNameNGram ?? [],
    userFirstName: user.profileDetails.userFirstName ?? null,
    userLastName: user.profileDetails.userLastName ?? null,
    userProfilePic: user.profileDetails.userProfilePicture ?? null,
    userAddress: user.profileDetails.userAddress ?? null,
    isLoggedIn: !!user.isLoggedIn,
    userType: !!user.guestId ? 'guest' : !!user.userId ? 'user' : null,
    isPlatformUser: !!user.userId,
  }
}
// organiser
export const selectpaymentStatus = (state: RootState) => state.user.paymentStatus
export const selectUserReducer = (state: RootState) => state.user
export const selectUserType = (state: RootState) => state.user.userType

export default userSlice.reducer
