import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

// Styles
import { customStyles } from '../../Tabs/AllRevenueTableStyle'

// Third party

// Types
import { ROUTES_CONST } from '../../../../../../const/routes-const'

// Helpers
import { getConvertedData } from '../../../../../../models/interface.helper'

// Hooks
import FirestoreService from '../../../../../../services/firestoreService'

// Firestore
import { QueryConstraint, where } from 'firebase/firestore'

// Constants
import { CONST } from '../../../../../../const/const'

// Components
import InfiniteScrollDataTable from '../../../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../../../components/loader/ViewsLoader'
import NoDataAvailable from '../../Tabs/NoDataAvailable'

// Models
import clsx from 'clsx'
import moment from 'moment'
import customImageComponent from '../../../../../../components/common/CustomImageComponent'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { TEventRegisteredUsers } from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { ITicketBuyer } from '../../../../../../models/ticket-buyers/ticket-buyers.interface'
import { TicketBuyersModel } from '../../../../../../models/ticket-buyers/ticket-buyers.model'
import { holdersSliceInitialState } from '../../../../../../store/holders/holdersSlice.data'
import { useAppSelector } from '../../../../../../store/hooks'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IPaidSpectatorRevenueTabProps = {
  eventId?: string
  activeTab: string
  className?: string
  searchValue: string
  isFullScreen?: boolean
}

type IGetUsersList = (args: { lastDocId: string | null }) => Promise<number>

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ACTIVE_TABS_KEYS = {
  ALL: 'all',
  PAID: 'paid',
  UNPAID: 'unpaid',
  PENDING: 'pending',
  REFUNDED: 'refunded',
}

const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

// const LINKS = {
//   MESSAGE_USER: "/messageuser/",
//   REFUND_TO_EXHIBITOR: ROUTES_CONST.EXHIBITOR_REFUND.URL
// }

const COLS = {
  EXHIBITOR: 'Exhibitor',
  PAYMENT_STATUS: 'Status',
  AMOUNT: 'Amount',
  REGISTERED: 'Registered',
  RECEIPT: 'Receipt',
  CONTACT: 'Contact',
}

const PER_PAGE = 10

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 *
 * @TODO todo
 */
const PaidSpectatorRevenueTab = (props: IPaidSpectatorRevenueTabProps) => {
  // Hooks and vars
  const holder = useAppSelector((state) => state.holder)
  const holderRef = useRef(holdersSliceInitialState)

  const { eventId } = useParams<{ eventId?: any }>()
  const handleModalContext = useContext(HandleModalContext)

  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [data, setData] = useState<ITicketBuyer[]>([])
  const [filterData, setFilterData] = useState<any[]>([])

  const registrations = useRef<TEventRegisteredUsers[]>([])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    setHasMore(true)
    setLoading(true)
    setData([])
    registrations.current = []
    getUsersList({
      lastDocId: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    getUsersList({
      lastDocId: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchValue])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let s = props.searchValue?.toLowerCase()
    if (!!s) {
      setFilterData(
        data.filter((c) => {
          return c.ticketBuyerName?.toLowerCase().includes(s)
        })
      )
    }
  }, [data, props.searchValue])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Returns structured users registration details as per datatable
   */
  const getUsersList: IGetUsersList = async (args) => {
    try {
      let lastDocId = args.lastDocId
      let ticketBuyer: ITicketBuyer | null = null
      let paymentStatus: string | null = null

      let data: ITicketBuyer[] = []
      let ticketBuyers: ITicketBuyer[] = []

      const paymentStatusQuery: QueryConstraint[] = []

      if (!lastDocId) {
        setLoading(true)
      }

      switch (props.activeTab?.toLocaleLowerCase()) {
        case ACTIVE_TABS_KEYS.PAID:
          paymentStatus = PAYMENT_STATUSES.PAID
          break

        case ACTIVE_TABS_KEYS.UNPAID:
          paymentStatus = PAYMENT_STATUSES.PENDING
          break

        case ACTIVE_TABS_KEYS.REFUNDED:
          paymentStatus = 'refunded'
          break
      }

      if (paymentStatus) {
        paymentStatusQuery.push(
          where(COLLECTIONS.TICKET_BUYERS.FIELDS.PAYMENT_STATUS.KEY, '==', paymentStatus)
        )
      }

      paymentStatusQuery.push(
        where(COLLECTIONS.TICKET_BUYERS.FIELDS.EVENT_DOC_ID.KEY, '==', eventId)
      )

      const ticketBuyerSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.TICKET_BUYERS.NAME,
        paymentStatusQuery,
        PER_PAGE,
        null,
        null,
        lastDocId
      )

      ticketBuyerSnapshots.forEach((currDoc) => {
        ticketBuyers.push(
          (ticketBuyer = getConvertedData(TicketBuyersModel.fromFirestoreDoc(currDoc).toObject()))
        )

        if (ticketBuyer) data.push(ticketBuyer)
      })

      if (!data.length) setHasMore(false)

      if (lastDocId) setData((prev) => [...prev, ...data])
      else setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      return 0
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onViewReceiptClick = (row: ITicketBuyer) => {
    handleModalContext?.handleModal?.(true, MODAL_CONSTS.SPECTATOR_TICKET_RECEIPT, row)
  }

  // Datatable cols
  const [columns] = useState<any[]>([
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Customers</span>
        </span>
      ),
      cell: (row: ITicketBuyer) => (
        <div className="flex items-center text-SeabiscuitDark200ThemeColor font-semibold capitalize">
          {!!row.isPlatformUser && !!row.userId ? (
            <Link
              to={`${ROUTES_CONST.USER_PROFILE.URL}/${row?.userId}`}
              className="flex items-center"
            >
              {customImageComponent(
                row.ticketBuyerProfilePicture,
                row.ticketBuyerName,
                '!w-[45px] !h-[45px] object-cover rounded-full mr-5 shrink-0'
              )}
              {row?.ticketBuyerName}
            </Link>
          ) : (
            <>
              {customImageComponent(
                row.ticketBuyerProfilePicture,
                row.ticketBuyerName,
                '!w-[45px] !h-[45px] rounded-full mr-5 shrink-0'
              )}
              {row?.ticketBuyerName}
            </>
          )}
        </div>
      ),
      width: '30%',
    },
    {
      name: 'Purchase',
      draggable: false,
      cell: (row: ITicketBuyer) => (
        <div
          className="text-SeabiscuitDark200ThemeColor cursor-pointer"
          onClick={() =>
            handleModalContext?.handleModal?.(true, MODAL_CONSTS.TICKET_BUYER_DETAILS, row)
          }
        >
          <div className="underline pointer-events-none flex gap-2.5 items-center">
            <img src="assets/og_icons/singleTicket.svg" alt="icon" className="w-[20px] h-[20px]" />
            {`${row.ticketBoughtCount} tickets`}
          </div>
        </div>
      ),
      width: '13%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.PAYMENT_STATUS}</span>
        </span>
      ),
      cell: (row: ITicketBuyer) => (
        <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
          <img src="/assets/cp_icons/MagneticCard.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {row.paymentStatus}
          </span>
        </div>
      ),
      width: '10%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.AMOUNT}</span>
        </span>
      ),
      cell: (row: ITicketBuyer) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountPaid?.toFixed(2)}
            </span>
          </div>
        </>
      ),
      width: '10%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Purchased date</span>
        </span>
      ),
      cell: (row: ITicketBuyer) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/Tear-Off Calendar.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              {moment(row.purchasedOn).format('MM/DD/YY')}
            </span>
          </div>
        </>
      ),
      width: '15%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">{COLS.RECEIPT}</span>
        </span>
      ),
      cell: (row: ITicketBuyer) => (
        <>
          <div className="underline flex items-center w-full">
            <img src="/assets/cp_icons/Bill.svg" className="mr-2" alt="paidStatus" />
            <button onClick={() => onViewReceiptClick(row)}>View</button>
          </div>
        </>
      ),
      width: '10%',
    },
    {
      name: (
        <div className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-center w-full">
          <span className="whitespace-nowrap">{COLS.CONTACT}</span>
        </div>
      ),
      cell: (row: ITicketBuyer) => (
        <div className="flex justify-center w-full">
          <button
            className="bg-[#F2F3F8] py-3 px-5 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor"
            onClick={() => {
              handleModalContext?.handleModal?.(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: row.ticketBuyerEmailId,
                user_name: row.ticketBuyerName,
                profilePicture: row.ticketBuyerProfilePicture,
              })
            }}
          >
            Message
          </button>
        </div>
      ),
      width: '12%',
    },
  ])

  return (
    <InfiniteScrollDataTable
      data={props.searchValue !== '' ? filterData : data}
      hasMore={hasMore}
      columns={columns}
      fixedHeader={true}
      fetchMore={() =>
        getUsersList({
          lastDocId: data.at(-1)?.id ?? null,
        })
      }
      progressPending={loading}
      customStyles={customStyles}
      reloadOnBasedOf={props.activeTab}
      className={clsx('!rounded-none', data.length || loading ? 'h-[50vh]' : '')}
      noDataComponent={
        <NoDataAvailable
          buttonText={'Edit Spectator Tickets >'}
          imgSrc="/assets/og_icons/People-4.svg"
          text={props.activeTab === '' ? 'test' : 'No specatators tickets found'}
        />
      }
      progressComponent={
        <ViewsLoader
          className="flex items-center w-full justify-center my-10"
          size="md"
          color="#F70763"
        />
      }
    />
  )
}

export default PaidSpectatorRevenueTab
