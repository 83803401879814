import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'
import { IEventPolicies } from './event-policies.interface'

export class EventPoliciesModel extends ModelBaseModel<IEventPolicies> implements IModelBaseModel {
  public v: IEventPolicies['v']
  public id: IEventPolicies['id']
  public owner: IEventPolicies['owner']

  public policies: IEventPolicies['policies']

  public created: IEventPolicies['created']
  public modified: IEventPolicies['modified']

  public constructor(obj?: IEventPolicies) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? ''
    this.owner = obj?.owner ?? ''

    this.policies = obj?.policies ?? []

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  static fromFirestoreDoc(doc: any) {
    return new EventPoliciesModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup
      .object({
        policies: yup.array().of(
          yup.object({
            name: yup.string().required('Title is required'),
          })
        ),
      })
      .required()
  }
}
