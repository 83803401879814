import { useEffect, useState } from 'react'
import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { motion } from 'framer-motion'

import { IconClose } from '../components/icons/IconClose'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectMenuCollapse, selectUseMode, setMenuCollapse } from '../store/system/systemSlice'
import { registerMobileMenu, selectMenuToggle } from '../store/mobileMenu/mobileMenuSlice'

import { useWindowResize } from '../hooks/useWindowResize'

// Constants
import { CONST } from '../const/const'
import { PAGES } from './pages'

// Styles
import './SideMenuNavigation.css'

import { MD_WIDTH } from '../helpers/mdWidth'
import { MODAL_CONSTS } from '../const/modal-const'

interface SideMenuNavigationProps {
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
  logo?: string
  isLoggedIn?: boolean
}

const SideMenuNavigation = ({ logo, handleModal, isLoggedIn }: SideMenuNavigationProps) => {
  let highlight = false

  const history = useHistory()
  const location = useLocation()

  const dispatch = useAppDispatch()
  const { windowWidth } = useWindowResize()

  const eventId = location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)

  const isMenuCollapsed = useAppSelector(selectMenuCollapse)
  const isOpenMobileMenu = useAppSelector(selectMenuToggle)
  const useMode = useAppSelector(selectUseMode)

  const [isArrowCollapse, setIsArrowCollapse] = useState(false)

  let descendentOf: null | string = null

  Object.keys(CONST.ROUTES).forEach((c) => {
    let currValue: any = CONST.ROUTES[c as keyof typeof CONST.ROUTES]
    if (currValue?.URL === location.pathname) descendentOf = currValue?.DESCENDANT_OF ?? null
  })

  // Functions
  const handleToggleMenu = (isCollapsed: boolean) => {
    if (windowWidth >= MD_WIDTH && !isArrowCollapse) dispatch(setMenuCollapse(isCollapsed))
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < MD_WIDTH) dispatch(setMenuCollapse(false))
    }
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <>
      {isOpenMobileMenu && (
        <div
          onClick={() => dispatch(registerMobileMenu.toggleMobileMenu(false))}
          className={'fixed top-0 left-0 w-full h-full bg-black/[0.8] z-10'}
        />
      )}

      <IonMenu
        className={clsx(
          'fixed md:relative top-0 md:left-0 z-[99]',
          isOpenMobileMenu ? 'left-0' : 'left-full',
          isMenuCollapsed ? 'main-menu-collapsed' : 'main-menu'
        )}
        contentId="main"
        type="overlay"
        id="ion-menu"
        onMouseEnter={() => handleToggleMenu(false)}
        onMouseLeave={() => handleToggleMenu(true)}
      >
        <IonContent
          className={clsx(
            'bg-SeabiscuitMainThemeColor',
            !isMenuCollapsed || windowWidth < MD_WIDTH ? 'unCollapsed' : 'collapsed'
          )}
        >
          <IonList
            id="inbox-list"
            style={{ margin: '0px !important' }}
            className="bg-SeabiscuitMainThemeColor"
          >
            <IonListHeader class="pb-0 w-full pl-0">
              <button
                onClick={() => dispatch(registerMobileMenu.toggleMobileMenu(false))}
                className={`absolute top-0 right-2 w-[20px] h-[20px] text-white  md:hidden`}
              >
                <IconClose />
              </button>
              <div className="flex mb-[60px] md:mb-[10px] lg:mb-[60px] flex-col w-full">
                <div className="flex justify-center w-full mx-auto">
                  {logo ? (
                    <IonAvatar className="w-16">
                      <img src={logo} alt="logo" />
                    </IonAvatar>
                  ) : (
                    <IonAvatar>
                      <img
                        src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                        alt="avatar"
                      />
                    </IonAvatar>
                  )}
                </div>

                <div className="relative hidden h-[60px] md:flex items-center togglerContainer">
                  <span
                    className="togglerIcon cursor-pointer text-white border-solid rounded-full border-[1.5px] border-white h-[22px] flex items-center transition-all"
                    onClick={() => {
                      setIsArrowCollapse(true)
                      setTimeout(() => setIsArrowCollapse(false), 300)
                      dispatch(setMenuCollapse(true))
                    }}
                  >
                    {!isMenuCollapsed ? (
                      <KeyboardArrowLeft fontSize="small" />
                    ) : (
                      <KeyboardArrowRight fontSize="small" />
                    )}
                  </span>
                </div>
              </div>
            </IonListHeader>
            <motion.div
              className="w-full mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 0.1,
              }}
            >
              {useMode === CONST.USE_MODE.COMPETITOR && (
                <div
                  className={'md:max-h-[50vh] lg:max-h-[70vh] overflow-auto flex flex-col gap-1'}
                >
                  {PAGES.COMPETITOR.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={appPage.url}
                          onClick={() =>
                            appPage.modal && handleModal
                              ? handleModal(true, MODAL_CONSTS.AI_RULE_BOOK)
                              : history.push(appPage.url)
                          }
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="alternate icon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel">{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })}
                </div>
              )}
              {useMode === CONST.USE_MODE.ORGANIZER && (
                <div
                  className={'md:max-h-[50vh] lg:max-h-[70vh] overflow-auto flex flex-col gap-1'}
                >
                  {PAGES.ORGANIZER.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={
                            appPage.url === CONST.ROUTES.ORGANIZER_EDIT.URL
                              ? `${appPage.url}/${eventId}`
                              : appPage.url
                          }
                          onClick={() =>
                            history.push(
                              appPage.url === CONST.ROUTES.ORGANIZER_EDIT.URL
                                ? `${appPage.url}/${eventId}`
                                : appPage.url
                            )
                          }
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="navIcon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel">{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })}
                </div>
              )}

              {!isLoggedIn
                ? PAGES.PUBLIC.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={appPage.url}
                          onClick={() => history.push(appPage.url)}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="navIcon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel hover:text-SeabiscuitWhiteThemeColor">
                            {appPage.title}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })
                : null}
            </motion.div>
          </IonList>
        </IonContent>

        {(useMode === CONST.USE_MODE.COMPETITOR || useMode === CONST.USE_MODE.ORGANIZER) && (
          <div className="openMenuIcon absolute bottom-10 flex items-center justify-center w-full">
            <Link
              to={CONST.ROUTES.EARN_COMMISSION.URL}
              className={clsx(
                'border rounded-lg px-4 py-2 flex gap-3',
                location.pathname === CONST.ROUTES.EARN_COMMISSION.URL || highlight
                  ? 'bg-white text-SeabiscuitMainThemeColor'
                  : 'hover:opacity-70 transition-all'
              )}
            >
              <img
                className={clsx(
                  !(location.pathname === CONST.ROUTES.EARN_COMMISSION.URL || highlight) && 'hidden'
                )}
                src={'assets/cp_icons/ExpensivePrice-2.svg'}
                alt=""
              />
              <img
                className={clsx(
                  (location.pathname === CONST.ROUTES.EARN_COMMISSION.URL || highlight) && 'hidden'
                )}
                src={'assets/cp_icons/Expensive Price-4.svg'}
                alt=""
              />
              {!isMenuCollapsed && (
                <div
                  className={
                    location.pathname === CONST.ROUTES.EARN_COMMISSION.URL
                      ? 'text-SeabiscuitMainThemeColor'
                      : 'text-white'
                  }
                >
                  Earn
                </div>
              )}
            </Link>
          </div>
        )}
      </IonMenu>
    </>
  )
}

export default SideMenuNavigation
