import React, { FC, useContext } from 'react'

import { useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form'

import { Subheader } from '../Subheader/Subheader'
import { EventFeeSimple } from '../EventFeeSimple/EventFeeSimple'
import { EventFeeActions } from '../EventFeeActions/EventFeeActions'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'

import { IEventFees } from '../../../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../../../models/event-fees/event-fees.model'

interface EventFeesOthersProps {
  watch: UseFormWatch<IEventFees>
  control: Control<IEventFees>
  register: UseFormRegister<IEventFees>
  setValue: UseFormSetValue<IEventFees>
  reset: UseFormReset<IEventFees>
  getValues: UseFormGetValues<IEventFees>
  dataIncrementAttrProps: { [key: string]: string }
  getDurationSelectedValue: (value: string) => { label: string; value: string } | undefined
}

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
export const EventFeesOthers: FC<EventFeesOthersProps> = ({
  watch,
  control,
  register,
  setValue,
  reset,
  getValues,
  dataIncrementAttrProps,
  getDurationSelectedValue,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const extraOtherFees = useFieldArray({
    control,
    name: 'extraOtherFees',
  })

  const onAdd = () => {
    extraOtherFees.append({
      title: '',
      status: false,
      category: 'other',
      available: 0,
      actualCostAlias: '$0',
      sold: 0,
      duration: '',
      note: '',
      uuid: uuidv4(),
    })
  }

  const onClearAll = () => {
    extraOtherFees.remove()
    reset({
      ...getValues(),
      paddockFee: eventFeesFormDefaultValues.paddockFee,
      RVHookupFee: eventFeesFormDefaultValues.RVHookupFee,
    })
  }

  const onExtraRemove = (index: number) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: function () {
        extraOtherFees.remove(index)
      },
    })
  }

  return (
    <div className="pb-4 mb-4 border-b border-solid border-[#e4e4e4]">
      <Subheader title="Other Fees" subtitle="Add other miscellaneous fees to this event" />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="Paddock"
        name="paddockFee"
        withHeader
      />
      <EventFeeSimple
        getDurationSelectedValue={getDurationSelectedValue}
        dataIncrementAttrProps={dataIncrementAttrProps}
        watch={watch}
        control={control}
        setValue={setValue}
        register={register}
        label="RV hookup"
        name="RVHookupFee"
      />
      {extraOtherFees.fields.map((field, index) => (
        <EventFeeSimple
          key={field.id}
          getDurationSelectedValue={getDurationSelectedValue}
          dataIncrementAttrProps={dataIncrementAttrProps}
          watch={watch}
          control={control}
          setValue={setValue}
          register={register}
          name={`extraOtherFees.${index}`}
          onExtraRemove={() => onExtraRemove(index)}
        />
      ))}
      <EventFeeActions onAdd={onAdd} onClearAll={onClearAll} />
    </div>
  )
}
