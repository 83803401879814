import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import { cloneDeep } from 'lodash'

import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { Peoples } from './components/Peoples'
import { Horses } from './components/Horses'

import { getConvertedData, getSelectedUserAsTeamMember } from '../../../../models/interface.helper'
import { getHorseProfileReqField, getUserProfileReqField } from '../../../../helpers/requiredFields'
import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { selectProfileData } from '../../../../store/user/userSlice'

import { IRegistrationTeamInterface } from '../../../../models/registeration-teams/registration-teams.interface'

import FirestoreService from '../../../../services/firestoreService'

import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectRegistration,
  selectTeamTabData,
  setHorseRegistrationErrors,
  setTeamTabData,
  setUserRegistrationErrors,
} from '../../../../store/registration/registrationSlice'
import { selectAllUsers } from '../../../../store/users/usersSlice'
import { selectHorses, setHorses } from '../../../../store/horses/horseSlice'

import ITypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../models/horse/horse.model'
import { IHorseCompetitorDocument } from '../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IUserInterface } from '../../../../models/users/user.interface'

import { CONST } from '../../../../const/const'
import { REQUIERED_FILEDS } from '../../../../const/requeredFields'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type Props = {
  step?: number
  title?: string
  description?: string
  handleForm?: () => void
  teamMembersLoading?: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}

const ClinicNOtherStatusTab = (props: Props) => {
  const { userId } = useParams<ITypes['IParams']>()
  const dispatch = useAppDispatch()
  const { getMapping, getTeamMembersHorses } = useHorses()

  const { Event } = useAppSelector(selectedEvent)
  const teamTabData = useAppSelector(selectTeamTabData)
  const myHorses = useAppSelector(selectHorses)
  const registration = useAppSelector(selectRegistration)
  const users = useAppSelector(selectAllUsers)
  const profileDetails = useAppSelector(selectProfileData)

  const [teamTabDataWithUser, setTeamTabDataWithUser] = useState<IUserInterface[]>([])
  const [mainLoading, setMainLoading] = useState(true)
  const [horsesPaperwork, setHorsesPaperwork] = useState<IHorseCompetitorDocument[] | null>(null)

  const getTeamDataFromDb = async () => {
    let teams: IRegistrationTeamInterface[] = []
    let teamsIds: string[] = []

    if (registration?.membersIds && registration.membersIds.length > 0) {
      teamsIds = cloneDeep(registration.membersIds)
    } else {
      const { horsesTeamsMappings } = await getMapping(userId)
      horsesTeamsMappings.forEach((item) => {
        Array.isArray(item.teamMembers) &&
          item.teamMembers.forEach((member) => {
            if (member.memberId) teamsIds.push(member.memberId)
          })
      })

      profileDetails.userTeamMembers?.forEach((member) => {
        if (member.memberId && !teamsIds.includes(member.memberId)) teamsIds.push(member.memberId)
      })
    }

    teamsIds.forEach((id) => {
      const user = users.find((u) => u.id === id)
      if (user) {
        const current = getConvertedData(getSelectedUserAsTeamMember({ ...user }, profileDetails))
        teams.push(current)
      }
    })

    dispatch(setTeamTabData(teams))
  }

  const getHorsesFromDB = async () => {
    const newHorses: IHorseData[] = []
    let horsesIds: string[] = []

    try {
      if (registration?.horsesIds && registration.horsesIds?.length > 0) {
        horsesIds = cloneDeep(registration.horsesIds)
      } else {
        const { horsesTeamsMappings } = await getMapping(userId)
        const teamMembersHorses = await getTeamMembersHorses()
        horsesIds = [
          ...horsesTeamsMappings.map((horse) => horse.horseId ?? ''),
          ...teamMembersHorses.map((horse) => horse.id ?? ''),
        ]
      }
      const horsesSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.HORSES.NAME,
        horsesIds
      )

      horsesSnaps.forEach((currDoc) => {
        newHorses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })
      dispatch(setHorses(newHorses))
    } catch (error) {
      console.error('error', error)
    } finally {
    }
  }

  const loadAllData = async () => {
    setMainLoading(true)
    await getTeamDataFromDb()
    await getHorsesFromDB().then()
    setMainLoading(false)
  }

  useEffect(() => {
    if (Event?.id && userId) loadAllData().then()
  }, [Event?.id, userId, registration])

  useEffect(() => {
    let hasHorseError = false
    const requiredFiled = [
      ...REQUIERED_FILEDS.horseMain.filter((itm) => Event?.requiredHorseFields.includes(itm.value)),
      ...REQUIERED_FILEDS.horseSecondary.filter((itm) =>
        Event?.requiredHorseFields.includes(itm.value)
      ),
      ...REQUIERED_FILEDS.horseNumbers.filter((itm) =>
        Event?.requiredHorseFields.includes(itm.value)
      ),
    ]

    myHorses.forEach((horse) => {
      requiredFiled.forEach((field) => {
        const profileReqField = getHorseProfileReqField({ horse, field, horsesPaperwork })
        if (!profileReqField?.profileValue || profileReqField?.isExpired) hasHorseError = true
      })
    })

    dispatch(setHorseRegistrationErrors(hasHorseError))
  }, [myHorses, Event?.requiredHorseFields])

  useEffect(() => {
    let hasUserError = false
    const requiredFiled = [
      ...REQUIERED_FILEDS.people.filter((itm) => Event?.requiredFields.includes(itm.value)),
      ...REQUIERED_FILEDS.peopleNumbers.filter((itm) => Event?.requiredFields.includes(itm.value)),
    ]

    teamTabDataWithUser.forEach((user) => {
      requiredFiled.forEach((field) => {
        const profileReqField = getUserProfileReqField({ user, field })
        if (!profileReqField?.profileValue || profileReqField?.isExpired) hasUserError = true
      })
    })

    dispatch(setUserRegistrationErrors(hasUserError))
  }, [teamTabDataWithUser, Event?.requiredFields])

  useEffect(() => {
    const team: IUserInterface[] = []

    teamTabData.forEach((member) => {
      const current = users.find((user) => user.id === member.memberId)
      if (current?.id) team.push(current)
    })

    if (profileDetails) {
      setTeamTabDataWithUser([profileDetails, ...team])
    }
  }, [teamTabData, userId, users])

  return (
    <>
      <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
        <div className="flex justify-between flex-wrap gap-5">
          <Peoples
            teamTabDataWithUser={teamTabDataWithUser}
            horsesPaperwork={horsesPaperwork}
            mainLoading={mainLoading}
          />

          <Horses
            mainLoading={mainLoading}
            horsesPaperwork={horsesPaperwork}
            setHorsesPaperwork={setHorsesPaperwork}
          />
        </div>
      </CompetitorEventRegisterWrapper>
    </>
  )
}

export default ClinicNOtherStatusTab
