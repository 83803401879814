import React from 'react'

interface IProps {
  color?: string
}

export const IconAddedPeople: React.FC<IProps> = ({ color = '#E06C30' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="none" viewBox="0 0 18 22">
      <path
        fill="#122B46"
        d="M8.97.5a.75.75 0 0 0-.43.158c-.784.609-1.385.91-2.315 1.1S4.02 2 2.063 2a.75.75 0 0 0-.592.289L.158 3.976a.75.75 0 0 0 .098 1.026S1.5 6.083 1.5 8c0 .958-.267 1.298-.662 2.038-.394.74-.838 1.788-.838 3.587 0 2.696 2.371 6.364 8.831 7.855a.75.75 0 0 0 .338 0C15.63 19.99 18 16.321 18 13.625c0-1.799-.443-2.847-.838-3.587S16.5 8.958 16.5 8c0-1.917 1.244-2.998 1.244-2.998a.75.75 0 0 0 .067-1.063l-1.5-1.687A.75.75 0 0 0 15.75 2c-1.863 0-3.09-.053-3.996-.243s-1.51-.49-2.294-1.1a.75.75 0 0 0-.49-.156M9 2.132c.755.517 1.524.902 2.448 1.095 1.018.213 2.3.244 3.946.251l.883.993C15.77 5.056 15 6.122 15 8c0 1.291.483 2.076.838 2.743.356.666.662 1.305.662 2.882 0 1.784-1.63 4.934-7.5 6.345-5.87-1.411-7.5-4.56-7.5-6.345 0-1.577.307-2.216.662-2.882C2.518 10.076 3 9.29 3 8c0-1.915-.802-2.994-1.302-3.56l.75-.964c1.713-.007 3.043-.038 4.078-.25.943-.193 1.716-.578 2.473-1.095m0 4.391a.49.49 0 0 0-.448.256L7.53 8.846l-2.283.332a.5.5 0 0 0-.276.853l1.651 1.61-.39 2.274a.5.5 0 0 0 .725.527L9 13.369l2.041 1.073a.5.5 0 0 0 .527-.038.5.5 0 0 0 .2-.49l-.39-2.273 1.652-1.61a.5.5 0 0 0-.277-.853l-2.283-.332-1.022-2.068A.49.49 0 0 0 9 6.522"
      />
    </svg>
  )
}
