import React, { useState } from 'react'
import clsx from 'clsx'
import { AutorenewRounded } from '@mui/icons-material'

import ModalComponent from '../../../../events/views/details/global/modalComponent'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

//type
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  dataToPassOn: { confirmDelete: () => Promise<void> }
}

const ConfirmRemoveModal = ({ handleModal, dataToPassOn }: Props) => {
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.CONFIRM_REMOVE_MODAL)
  }

  const handelDelete = async () => {
    setLoading(true)
    await dataToPassOn.confirmDelete()
    setLoading(false)
  }

  return (
    <ModalComponent
      size="xs"
      show={true}
      fullScreen={false}
      onHide={closeModal}
      headingClassName={'flex items-center justify-center w-full'}
      ModalBetweenModal="h-fit"
      modaltopclassname={clsx('md:max-h-[240px] !min-h-[100px] pb-0')}
      type={MODAL_CONSTS.CONFIRM_REMOVE_MODAL}
    >
      <div className="h-full flex flex-col justify-between text-center pb-10">
        <p className="text-SeabiscuitDark200ThemeColor text-lg font-semibold">
          Are you sure you want to remove?
        </p>
        <div className="ActionButton mt-5">
          <button
            disabled={loading}
            type="button"
            onClick={handelDelete}
            className="w-52 mb-2 h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor transition-all hover:bg-[#D70443] hover:text-white"
          >
            {loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : 'REMOVE'}
          </button>

          <button
            type="button"
            onClick={closeModal}
            className="w-52 h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor transition-all hover:bg-[#D70443] hover:text-white"
          >
            CANCEL
          </button>
        </div>
      </div>
    </ModalComponent>
  )
}

export default ConfirmRemoveModal
