import React from 'react'
import { IRider } from '../../../../types/entryRider'
import { INewMembers } from './SetTeamMembers'
import { RegistrationFeesType } from '../../../../models/event-fees/event-fees.interface'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { StepTitle } from './StepTitle'

interface IProps {
  selectedHorse: IHorseData
  selectedEntries: RegistrationFeesType[]
  rider: IRider
  selectedRiderMultiHorse: IHorseData | null
  newTeamMembers: INewMembers[]
}

export const Confirm: React.FC<IProps> = ({
  selectedHorse,
  selectedEntries,
  rider,
  selectedRiderMultiHorse,
  newTeamMembers,
}) => {
  return (
    <>
      <StepTitle title={'Confirm details'} desc={'Click ‘Switch’ to confirm change'} />
      <div className={'flex flex-col gap-3 text-[#122B46] text-[16px]'}>
        <div className={'bg-[#F6F7FB] rounded-[10px] p-5 flex justify-between'}>
          <div className={''}>
            <p>Class</p>
          </div>
          <div className={'flex flex-col gap-3 items-end'}>
            {selectedEntries.map((itm) => (
              <p key={itm.uuid}>{itm.name}</p>
            ))}
          </div>
        </div>
        <div className={'bg-[#F6F7FB] rounded-[10px] p-5 flex justify-between'}>
          <div className={''}>
            <p>Old Horse</p>
          </div>
          <div className={'text-right'}>
            <p>{selectedRiderMultiHorse ? selectedRiderMultiHorse.horseName : rider.horseName}</p>
          </div>
        </div>
        <div className={'bg-[#F6F7FB] rounded-[10px] p-5 flex justify-between'}>
          <div className={''}>
            <p>New Horse</p>
          </div>
          <div className={'text-right'}>
            <p>{selectedHorse.horseName}</p>
          </div>
        </div>
        {newTeamMembers.length > 0 && (
          <div className={'bg-[#F6F7FB] rounded-[10px] p-5 flex justify-between'}>
            <div className={''}>
              <p>New Team</p>
            </div>
            <div className={'flex flex-col gap-3 items-end'}>
              {newTeamMembers.map((t, i) => {
                if (!t.member) return <React.Fragment key={i} />
                if (!t.role) return <React.Fragment key={i} />
                return (
                  <p key={`${i}_${t.member.label}_${t.role.label}`}>
                    {t.member.label} • {t.role.label}
                  </p>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
